import React, { useEffect, useRef, useState } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import GlobalConfig from "@common/data/GlobalConfig";
import { useSelector } from "react-redux";
import { selectIsLoading } from "../../app/UISlice";

interface Fields {
  firstName?: string;
  lastName?: string;
  email?: string;
  message?: string;
}

interface IProps {
  isApiLoading: boolean;
  submitLabel: string;
  handleSubmit: (payload: Fields) => void;
  serverResponse?: JSX.Element;
  darkTheme?: boolean;
  initialData?: Fields;
}

export const useStyles = (darkTheme: boolean) =>
  makeStyles((theme) => ({
    input: {
      "& .MuiInputBase-root": {
        backgroundColor: theme.palette.common[darkTheme ? "black" : "white"],
        color: theme.palette.common[darkTheme ? "white" : "black"],
      },
      "& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: darkTheme ? theme.palette.primary.main : undefined,
      },
    },
    button: {
      "&:hover": {
        backgroundColor: darkTheme ? theme.palette.primary.light : undefined,
      },
      "&.Mui-disabled": {
        backgroundColor: darkTheme ? theme.palette.common.black : undefined,
      },
    },
  }));

export function ContactForm(props: IProps) {
  const trans = GlobalConfig?.appData.blocks.contactForm;

  const classes = useStyles(props.darkTheme)();
  const formRef = useRef<ValidatorForm>(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const UILoading = useSelector(selectIsLoading);
  const UISuccesses = useSelector(selectIsLoading);

  useEffect(() => {
    formRef.current?.isFormValid(true).then(setIsFormValid);
  });

  useEffect(() => {
    if (props.initialData?.firstName) {
      setFirstName(props.initialData.firstName);
    }
    if (props.initialData?.lastName) {
      setLastName(props.initialData.lastName);
    }
    if (props.initialData?.email) {
      setEmail(props.initialData.email);
    }
  }, [props.initialData]);

  // when stops loading & success, reset the form
  useEffect(() => {
    if (UILoading.contactForm === false) {
      setEmail("");
      setFirstName("");
      setLastName("");
      setMessage("");
    }
  }, [UILoading.contactForm]);

  const handleFormSubmit = () => {
    const payload: Fields = { firstName, lastName, email, message };
    props.handleSubmit(payload);
  };

  return (
    <ValidatorForm ref={formRef} onSubmit={handleFormSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextValidator
            className={classes.input}
            variant="outlined"
            label={trans.form.lastNameLabel}
            value={lastName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setLastName(e.target.value);
            }}
            fullWidth
            validators={["required"]}
            errorMessages={[trans.form.required]}
            type="text"
            name="lastName"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextValidator
            className={classes.input}
            variant="outlined"
            label={trans.form.firstNameLabel}
            value={firstName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFirstName(e.target.value);
            }}
            fullWidth
            validators={["required"]}
            errorMessages={[trans.form.required]}
            type="text"
            name="firstName"
          />
        </Grid>
        <Grid item xs={12}>
          <TextValidator
            className={classes.input}
            variant="outlined"
            label={trans.form.emailLabel}
            fullWidth
            type="email"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value);
            }}
            value={email}
            validators={["required", "isEmail"]}
            errorMessages={[trans.form.required, trans.form.emailInvalid]}
            autoComplete="email"
            name="email"
          />
        </Grid>
        <Grid item xs={12}>
          <TextValidator
            className={classes.input}
            variant="outlined"
            label={trans.form.messageLabel}
            value={message}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMessage(e.target.value);
            }}
            fullWidth
            validators={["required"]}
            errorMessages={[trans.form.required]}
            type="text"
            name="message"
            multiline
            rows={5}
          />
        </Grid>
        {props.serverResponse && (
          <Grid item xs={12}>
            {props.serverResponse}
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={props.isApiLoading || !isFormValid}
            className={classes.button}
          >
            {props.isApiLoading ? trans.loading : props.submitLabel}
          </Button>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
}
