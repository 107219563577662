import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core";

/**
 * @name Loader
 */

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Loader = () => {
  const rootRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  return (
    <div ref={rootRef} className={classes.root}>
      <div id="preloader">
        <div id="iconWrapper">
          <svg id="icon" viewBox="0 0 1511.2 1516.6">
            <path
              transform="translate(-571.55 -654.8)"
              d="m1676.3 2171.4h389c17.48 0 17.5 0 17.5-17.9v-257c0-18.63 0-18.64-18.81-18.64q-331.47 0-662.92-0.08c-28.29 0-56.58-1.11-84.88-1.7-26.71-0.56-53.07-4.14-79.22-9.38-9.13-1.83-18.34-3.66-27.15-6.59-18.89-6.28-37.74-12.76-56.24-20.11a410.31 410.31 0 0 1-87.14-47.94c-46.46-33.21-86-73.3-117.9-120.84a466.16 466.16 0 0 1-51.33-101.06 442.84 442.84 0 0 1-19.33-72.12c-5.21-28.6-7.7-57.4-8.54-86.36-0.29-9.93 1-19.92 1.8-29.86 1.1-12.93 1.48-26 4-38.72 4.06-20.51 9.79-40.69 14.47-61.08 5.4-23.51 14.74-45.64 25.18-67.07 22.42-46 51-87.85 87.26-124.43 23.76-24 49.29-45.71 77.51-64.1a449.71 449.71 0 0 1 95.16-47.6 533.37 533.37 0 0 1 68-19.38c32.38-6.87 65.36-8.93 98.31-9q361.45-0.4 722.92-0.15c18.62 0 18.79-0.18 18.79-19.15q0-130 0-260c0-18-0.21-16.13-16.54-16.13q-361.95 0-723.91 0.1c-28.87 0-57.85-2.09-86.51 4.8-14.15 3.4-28.93 4.3-43.47 5.94-29.89 3.39-58.9 11-87.58 19.42-24.8 7.28-49.23 16.16-73.24 25.79-23.4 9.38-46.45 19.83-68.94 31.23-30 15.18-58.43 33-85.89 52.4-48.29 34.19-92.51 72.8-131.85 117.08a780.16 780.16 0 0 0-84.71 114.57c-16 26.47-30.6 53.72-42.79 82.18-6.67 15.57-13.47 31.11-19.23 47-6.79 18.74-13.25 37.67-18.53 56.87-5.19 18.9-8.8 38.23-13 57.4a491.41 491.41 0 0 0-10.63 73c-1.33 20.25-3.1 40.48-4.08 60.74-1.3 26.67 1.63 53.21 4.09 79.69 2 21.86 4.71 43.72 8.57 65.32 3.68 20.6 9 40.93 14.1 61.24 3.83 15.14 7.89 30.28 13 45 5.91 16.91 12.55 33.61 19.91 49.94 10.48 23.28 21.29 46.45 33.07 69.1 14.6 28.06 32.11 54.37 50.63 80a748.29 748.29 0 0 0 109.16 121.6 800.58 800.58 0 0 0 116.09 86 719.57 719.57 0 0 0 116 57.44c27.56 10.62 56.07 18.82 84.26 27.76a264.32 264.32 0 0 0 27.14 6.6c13.7 2.78 27.44 5.39 41.24 7.6 19 3 38.09 6.57 57.27 8.23 20.21 1.75 40.58 2.07 60.89 2.1q174.57 0.43 349.04 0.25zm13.73-905.15h-371.88c-3 0-6-0.08-9 0-8.39 0.34-9.51 1.52-9.84 10.16v4q0 133 0 265.91c0 15 0.53 15.55 15.94 15.55q375.38 0 750.74 0c16 0 16.77-0.85 16.77-17.15q0-130.46 0-260.91c0-17.58 0-17.59-17.86-17.59z"
            />
            <path
              transform="translate(-571.55 -654.8)"
              d="M1676.27,2171.36q-174.46,0-349-.09c-20.31,0-40.68-.35-60.89-2.1-19.18-1.66-38.22-5.18-57.27-8.23-13.8-2.21-27.54-4.82-41.24-7.6a264.32,264.32,0,0,1-27.14-6.6c-28.19-8.94-56.7-17.14-84.26-27.76a719.57,719.57,0,0,1-116-57.44,800.58,800.58,0,0,1-116.09-86,748.29,748.29,0,0,1-109.16-121.6c-18.52-25.67-36-52-50.63-80-11.78-22.65-22.59-45.82-33.07-69.1-7.36-16.33-14-33-19.91-49.94-5.14-14.72-9.2-29.86-13-45-5.13-20.31-10.42-40.64-14.1-61.24-3.86-21.6-6.54-43.46-8.57-65.32-2.46-26.48-5.39-53-4.09-79.69,1-20.26,2.75-40.49,4.08-60.74a491.41,491.41,0,0,1,10.63-73c4.18-19.17,7.79-38.5,13-57.4,5.28-19.2,11.74-38.13,18.53-56.87,5.76-15.91,12.56-31.45,19.23-47,12.19-28.46,26.77-55.71,42.79-82.18a780.16,780.16,0,0,1,84.71-114.57c39.34-44.28,83.56-82.89,131.85-117.08,27.46-19.45,55.93-37.22,85.89-52.4,22.49-11.4,45.54-21.85,68.94-31.23,24-9.63,48.44-18.51,73.24-25.79,28.68-8.42,57.69-16,87.58-19.42,14.54-1.64,29.32-2.54,43.47-5.94,28.66-6.89,57.64-4.78,86.51-4.8q362-.29,723.91-.1c16.33,0,16.55-1.92,16.54,16.13q-.06,130,0,260c0,19-.17,19.15-18.79,19.15q-361.47,0-722.92.15c-33,0-65.93,2.1-98.31,9a533.37,533.37,0,0,0-68,19.38,449.71,449.71,0,0,0-95.16,47.6c-28.22,18.39-53.75,40.11-77.51,64.1-36.23,36.58-64.84,78.43-87.26,124.43-10.44,21.43-19.78,43.56-25.18,67.07-4.68,20.39-10.41,40.57-14.47,61.08-2.51,12.68-2.89,25.79-4,38.72-.84,9.94-2.09,19.93-1.8,29.86.84,29,3.33,57.76,8.54,86.36a442.84,442.84,0,0,0,19.29,72.19,466.16,466.16,0,0,0,51.33,101.06c31.91,47.54,71.44,87.63,117.9,120.84a410.31,410.31,0,0,0,87.14,47.94c18.5,7.35,37.35,13.83,56.24,20.11,8.81,2.93,18,4.76,27.15,6.59,26.15,5.24,52.51,8.82,79.22,9.38,28.3.59,56.59,1.68,84.88,1.7q331.45.23,662.92.08c18.8,0,18.81,0,18.81,18.64v257c0,17.88,0,17.9-17.5,17.9Z"
            />
            <path
              transform="translate(-571.55 -654.8)"
              d="m1690 1266.2h374.88c17.84 0 17.86 0 17.86 17.59q0 130.45 0 260.91c0 16.3-0.81 17.15-16.77 17.15q-375.37 0-750.74 0c-15.41 0-15.94-0.52-15.94-15.55q0-133 0-265.91v-4c0.33-8.64 1.45-9.82 9.84-10.16 3-0.12 6 0 9 0z"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
export { Loader as default };
