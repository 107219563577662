import css from "./EcologyForm.module.less";
import React, { useEffect, useRef, useState } from "react";
import { merge } from "@common/lib/helpers/classNameHelper";
import GlobalConfig from "@common/data/GlobalConfig";
import { serializeForm } from "../../utils/Utils";
import { Button } from "@material-ui/core";
import { withStyles, Theme } from "@material-ui/core/styles";

let trans = GlobalConfig.appData.blocks.ecologyBlock;

interface IProps {
  classNames?: string[];
}

const componentName = "EcologyForm";
const debug = require("debug")(`front:${componentName}`);

/**
 * @name EcologyForm
 */
function EcologyForm(props: IProps) {
  const [showResult, setShowResult] = useState(false);
  const [showError, setShowError] = useState(false);
  const [totalSavings, setTotalSavings] = useState(0);
  const [model, setModel] = useState("e50");
  const [batteryCount, setBatteryCount] = useState("1");
  const [individualType, setIndividualType] = useState("private");
  const [postalCode, setPostalCode] = useState(null);
  const [isModelSelected, setModelSelected] = useState(false);
  const [isBatterySelected, setBatterySelected] = useState(false);
  const [isIndividualTypeSelected, setIndividualTypeSelected] = useState(false);

  const formRef = useRef<HTMLFormElement>();
  const sendForm = (e) => {
    e.preventDefault();
    const fd = serializeForm(formRef.current);
    const re = /^[0-9\b]+$/;
    let postalCodeValue = postalCode ? postalCode.toString() : "";
    if (
      !postalCodeValue ||
      !re.test(postalCodeValue) ||
      postalCodeValue.length != 5 ||
      !isModelSelected ||
      !isBatterySelected ||
      !isIndividualTypeSelected
    ) {
      setShowError(true);
    } else {
      let total = 0;
      setShowError(false);
      setModelSelected(false);
      setBatterySelected(false);
      setIndividualTypeSelected(false);
      total += model == "e50" ? 605 : 900;
      if (individualType == "professional") {
        let dep = postalCode.toString().substring(0, 2);
        if (["75", "77", "78", "91", "92", "93", "94", "95"].includes(dep))
          total += 1500;
      }
      setTotalSavings(total);
      setShowResult(true);
    }
  };

  const renderFormComponent = (showResult) => {
    if (showResult) {
      return (
        <div className={css.result}>
          <p className={css.result_title}>{trans.result.title}</p>
          <span className={css.result_total}> {totalSavings} €</span>
          <p className={css.result_subtitle}>{trans.result.subtitle}</p>
          <WhiteButton
            onClick={() => setShowResult(false)}
            variant="contained"
            color="default"
          >
            {trans.result.recalculateBonus}
          </WhiteButton>
        </div>
      );
    } else {
      return (
        <>
          <div
            className={merge([
              css.form_input_group,
              css.form_input_group_inline,
            ])}
          >
            <label className={css.form_input_label}>{trans.form.model}</label>
            <div className={css.form_radio_wrapper}>
              <label>
                <input
                  type="radio"
                  name="model"
                  value={"e50"}
                  onChange={(e) => {
                    setModel(e.target.value);
                    setModelSelected(true);
                    setBatterySelected(false);
                  }}
                />
                <div className={css.form_radio_input} />
                <span>E50</span>
              </label>
              <label>
                <input
                  required
                  type="radio"
                  name="model"
                  value={"e100"}
                  onChange={(e) => {
                    setModel(e.target.value);
                    setModelSelected(true);
                    setBatterySelected(false);
                  }}
                />
                <div className={css.form_radio_input} />
                <span>E100</span>
              </label>
              <label>
                <input
                  required
                  type="radio"
                  name="model"
                  value={"e125"}
                  onChange={(e) => {
                    setModel(e.target.value);
                    setModelSelected(true);
                    setBatterySelected(false);
                  }}
                />
                <div className={css.form_radio_input} />
                <span>E125</span>
              </label>
            </div>
          </div>

          <div
            className={merge([
              css.form_input_group,
              css.form_input_group_inline,
            ])}
          >
            <label className={css.form_input_label}>
              {trans.form.batteriesCount}
            </label>
            <div className={css.form_radio_wrapper}>
              {!model ||
                (model == "e50" && (
                  <label>
                    <input
                      required
                      type="radio"
                      name="battery_count"
                      onChange={(e) => {
                        setBatteryCount(e.target.value);
                        setBatterySelected(true);
                      }}
                      value={1}
                    />
                    <div className={css.form_radio_input} />
                    <span>1</span>
                  </label>
                ))}

              {model == "e125" && (
                <label>
                  <input
                    required
                    type="radio"
                    name="battery_count"
                    value={4}
                    onChange={(e) => {
                      setBatteryCount(e.target.value);
                      setBatterySelected(true);
                    }}
                  />
                  <div className={css.form_radio_input} />
                  <span>4</span>
                </label>
              )}

              {model != "e125" && (
                <label>
                  <input
                    required
                    type="radio"
                    name="battery_count"
                    value={2}
                    onChange={(e) => {
                      setBatteryCount(e.target.value);

                      setBatterySelected(true);
                    }}
                  />
                  <div className={css.form_radio_input} />
                  <span>2 et +</span>
                </label>
              )}
            </div>
          </div>
          <div
            className={merge([
              css.form_input_group,
              css.form_input_group_inline,
            ])}
          >
            <label className={css.form_input_label}>Vous êtes</label>
            <div className={css.form_radio_wrapper}>
              <label>
                <input
                  required
                  type="radio"
                  name="individual_type"
                  onChange={(e) => {
                    setIndividualType(e.target.value);
                    setIndividualTypeSelected(true);
                  }}
                  value={"private"}
                />
                <div className={css.form_radio_input} />
                <span>{trans.form.personal}</span>
              </label>
              <label>
                <input
                  required
                  type="radio"
                  onChange={(e) => {
                    setIndividualType(e.target.value);
                    setIndividualTypeSelected(true);
                  }}
                  name="individual_type"
                  value={"professional"}
                />
                <div className={css.form_radio_input} />
                <span>{trans.form.professional}</span>
              </label>
            </div>
          </div>
          <div
            className={merge([
              css.form_input_group,
              css.form_input_group_inline,
            ])}
          >
            <label className={merge([css.hidden_sm, css.form_input_label])}>
              {trans.form.postalCode}
            </label>
            <div className={css.form_radio_wrapper}>
              <input
                required
                type="text"
                name="postal_code"
                onChange={(e) => setPostalCode(e.target.value)}
                className={css.form_input}
                placeholder={trans.form.postalCode}
              />
            </div>
          </div>
          {showError && !isModelSelected && (
            <p className={css.result_subtitle}>
              Veuillez sélectionner un Model
            </p>
          )}
          {showError && !isBatterySelected && (
            <p className={css.result_subtitle}>
              Veuillez indiquer un Nombre de batteries
            </p>
          )}
          {showError && !isIndividualTypeSelected && (
            <p className={css.result_subtitle}>
              Veuillez indiquer le type d'achat
            </p>
          )}
          {showError && postalCode?.toString().length != 5 && (
            <p className={css.result_subtitle}>
              Veuillez entrer un code postal sous format numérique à 5 chiffres
            </p>
          )}
          <WhiteButton onClick={sendForm} variant="contained" color="default">
            {trans.form.calculateBonus}
          </WhiteButton>
        </>
      );
    }
  };

  return (
    <form onSubmit={sendForm} ref={formRef} className={css.form}>
      {renderFormComponent(showResult)}
    </form>
  );
}

export const WhiteButton = withStyles((t: Theme) => ({
  root: {
    background: "white",
    margin: "1rem auto",
    display: "block",
    "&:hover": {},
  },
  label: {
    color: t.palette.secondary.main,
  },
}))(Button);

export default EcologyForm;
