import css from "./CardSliderBlock.module.less";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { merge } from "@common/lib/helpers/classNameHelper";
import GlobalConfig from "../../../common/data/GlobalConfig";
import { useWindowSize } from "@wbe/libraries";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { gsap, Quad } from "gsap";
import { useHorizontalSwipe } from "../../hooks/useHorizontalSwipe";
import { isMobile } from "../../utils/devices";

interface IProps {
  classNames?: string[];
}

const componentName = "CardSlider";
const debug = require("debug")(`front:${componentName}`);

/**
 * @name CardSliderBlock
 */
function CardSliderBlock(props: IProps) {
  // --------------------------------------------------------------------------- PREPARE

  // -- Data
  const blockData = GlobalConfig?.appData?.blocks?.cardSliderBlock;

  // -- Others
  //const isMobile = window.innerWidth < EBreakpoints.TABLET;

  const swipe = useHorizontalSwipe();

  // -- Refs
  const rootRef = useRef<HTMLDivElement>(null);
  const selectorRef = useRef<HTMLDivElement>(null);
  const sliderBarRef = useRef<HTMLDivElement>(null);
  const sliderContentRef = useRef<HTMLDivElement>();
  const sliderContainerRef = useRef<HTMLDivElement>();
  const cardsContainerRefs = useRef<HTMLDivElement[]>([]);
  const cardsRefs = useRef<HTMLDivElement[]>([]);

  const biggestCardHeightIndex = useRef<number>(null);

  // -- States
  const [swipeDirection, setSwipeDirection] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);

  // -- Hooks
  const resize = useWindowSize();

  // --------------------------------------------------------------------------- USE EFFECT

  useEffect(() => {
    let width = sliderBarRef.current.getBoundingClientRect().width;
    width /= isMobile() ? blockData.cards.length : 2;

    gsap.set(selectorRef.current, {
      width: width,
    });
  }, []);

  useEffect(() => {
    if (swipe === 0) {
      setSwipeDirection(0);
    } else {
      if (swipeDirection === 0 && Math.abs(swipe) > 50) {
        setSwipeDirection(swipe > 0 ? 1 : -1);
      }
    }
  }, [swipe]);

  useEffect(() => {
    setProgress(
      Math.min(
        Math.max(progress + (1 / blockData.cards.length) * swipeDirection, 0),
        isMobile() ? 0.75 : 0.25
      )
    );
  }, [swipeDirection]);

  useEffect(() => {
    let margin =
      parseInt(getComputedStyle(cardsContainerRefs.current[0]).marginRight) * 2;
    let totalWidth =
      cardsContainerRefs.current[0].getBoundingClientRect().width *
        cardsContainerRefs.current.length +
      margin * cardsContainerRefs.current.length;

    //console.log(getComputedStyle(cardsContainerRefs.current[0]).marginRight,getComputedStyle(cardsContainerRefs.current[0]).margin);
    gsap.to(sliderContentRef.current, {
      x: `${-totalWidth * progress}px`,
      duration: 0.5,
      ease: Quad.easeInOut,
    });

    let selectorPosition =
      progress * sliderBarRef.current.getBoundingClientRect().width;
    if (!isMobile()) selectorPosition *= 2;

    gsap.to(selectorRef.current, {
      x: `${selectorPosition}px`,
      duration: 0.5,
      ease: Quad.easeInOut,
    });
  }, [progress]);

  /**
   * Update slider params on resize
   */

  /**
   * Apply uniform height on cards
   */
  useLayoutEffect(() => {
    let heights: number[] = [];
    let maxHeight: number;
    if (biggestCardHeightIndex.current === null) {
      // At first, identify the tallest one
      // Get cards heights
      cardsRefs.current.map((card) => heights.push(card.offsetHeight));
      // Extract biggest height
      maxHeight = Math.max(...heights);
      biggestCardHeightIndex.current = heights.indexOf(maxHeight);
    } else {
      // On resize, use the tallest one height to change height of others
      maxHeight =
        cardsRefs.current[biggestCardHeightIndex.current].offsetHeight;
    }
    // Apply biggest height to all cards except the biggest one
    cardsRefs.current.map((card, index) => {
      if (index !== biggestCardHeightIndex.current) {
        card.style.minHeight = maxHeight + "px";
      }
    });
  }, [resize]);

  // --------------------------------------------------------------------------- HANDLER

  // --------------------------------------------------------------------------- RENDER

  return (
    <div ref={rootRef} className={merge([css.Root, props.classNames])}>
      <h1 className={css.title}>{blockData.title}</h1>
      <h2 className={css.subtitle}>{blockData.subtitle}</h2>

      <div ref={sliderContainerRef} className={css.slider}>
        <div ref={sliderContentRef} className={css.container}>
          {blockData.cards.map((card, i) => {
            const thumb = require(`${card.thumbnail}`);
            return (
              <div
                ref={(el) => (cardsContainerRefs.current[i] = el)}
                className={css.cardContainer}
                key={i}
              >
                <div
                  ref={(el) => (cardsRefs.current[i] = el)}
                  className={css.card}
                  key={i}
                >
                  <img
                    className={css.cardThumbnail}
                    src={thumb?.default}
                    alt={card?.title}
                  />
                  <div className={css.cardTextContainer}>
                    <h3 className={css.cardTitle}>{card?.title}</h3>
                    <p className={css.cardText}>{card?.text}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div
        className={css.sliderBarContainer}
        onClick={(e) => {
          let sliderBarBox = sliderBarRef.current.getBoundingClientRect();
          if (
            e.clientX > sliderBarBox.left &&
            e.clientX < sliderBarBox.left + sliderBarBox.width
          ) {
            let position = (e.clientX - sliderBarBox.left) / sliderBarBox.width;

            let posTab = isMobile() ? [0, 0.25, 0.5, 0.75] : [0, 0.25];
            const closest = posTab.reduce((a, b) => {
              return Math.abs(b - position) < Math.abs(a - position) &&
                b !== progress
                ? b
                : a;
            });

            setProgress(closest);
          }
        }}
      >
        <div className={css.sliderBar} ref={sliderBarRef}>
          <div className={css.sliderSelectorContainer} ref={selectorRef}>
            <span className={css.sliderSelector} />
          </div>
        </div>
      </div>
      <div className={css.row}>
        <div className={css.col}>
          <div className={css.cta}>
            <div className={css.cta_arrow} />
            <b><a className={css.cta_text} href="/technology" children="En savoir plus sur nos technologies embarquées" /></b>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardSliderBlock;
