import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      drawer: {
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: 99,
      },
      drawerPaper: {
        position: "absolute",
        width: "100%",
      },
      drawerOpen: {
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      drawerClose: {
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(6) + 1,
      },
      toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      },
      drawerContainer: {
        overflow: "auto",
        paddingTop: "8rem",
      },
    })
  );
