import React, { useRef, useState } from "react";
import { merge } from "@common/lib/helpers/classNameHelper";
import GlobalConfig from "@common/data/GlobalConfig";
import { serializeForm } from "../../utils/Utils";
import {
  Box,
  Dialog,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  useTheme,
  Select,
  Typography,
  Button,
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";

let trans = GlobalConfig.appData.blocks.ecologyBlock;

interface IProps {
  classNames?: string[];
}

const componentName = "Co2Calculator";
const debug = require("debug")(`front:${componentName}`);

/**
 * @name Co2Calculator
 */
function Co2Calculator(props: IProps) {
  const [showResult, setShowResult] = useState(false);
  const [totalSavings, setTotalSavings] = useState(0);
  const [mileage, setMileage] = useState(10);
  const [co2Consumption, setCo2Consumption] = useState(null);

  const formRef = useRef<HTMLFormElement>();
  const theme = useTheme();

  const sendForm = (e) => {
    e.preventDefault();
    setCo2Consumption(mileage * 0.071 * 365);
    setShowResult(true);
  };

  return (
    <Box p={2} my={5} borderRadius={5} bgcolor={theme.palette.primary.main}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={6}>
          {!showResult ? (
            <Box>
              <Grid container alignItems="center">
                <Grid item xs={7}>
                  <Typography
                    gutterBottom
                    variant={"body1"}
                    children={"Nombre de km par jour"}
                  />
                </Grid>
                <Grid item xs={5}>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel id="mileageLabel"></InputLabel>
                    <Select
                      style={{ marginBottom: 0 }}
                      labelId="mileageLabel"
                      id={"mileage"}
                      fullWidth
                      value={mileage}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setMileage(parseInt(e?.target?.value || "0", 10));
                      }}
                      label="Km/jour"
                    >
                      {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(
                        (val) => (
                          <MenuItem value={val}>{val}</MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box>
              <Typography align="center" variant="body1">
                Avec un Model E, vous n’émettez pas de CO2, quelque soit le
                nombre de km parcouru.
                <br />
                Alors qu’avec un thermique, vous rejetez
                <Typography variant="h2" style={{ marginBottom: "0" }}>
                  {co2Consumption}
                </Typography>
                kg de CO2 par an.
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            fullWidth
            onClick={
              showResult
                ? () => {
                    setShowResult(false);
                  }
                : sendForm
            }
          >
            {!showResult ? "Calculer les émissions de Co2" : "Recommencer"}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Co2Calculator;
