import css from "./AutonomyBlock.module.less";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { merge } from "@common/lib/helpers/classNameHelper";
import GlobalConfig from "@common/data/GlobalConfig";
// import { ReactComponent as PictoRoad } from "../../img/picto/picto-battery.svg";

import pictoRoad from "asset/img/picto/picto-road.png";
import pictoSpeed from "asset/img/picto/picto-speed.png";
import pictoBattery from "asset/img/picto/picto-battery.png";
import scooterImg from "asset/img/scooter.png";

interface IProps {
  classNames?: string[];
}

const componentName = "AutonomyBlock";
const debug = require("debug")(`front:${componentName}`);

let trans = GlobalConfig.appData.blocks.autonomyBlock;

function AutonomyBlock(props: IProps) {
  return (
    <div className={merge([css.Root, props.classNames])}>
      <div className={css.container}>
        <div className={css.row}>
          <div className={css.col}>
            <h2
              className={css.title}
              dangerouslySetInnerHTML={{ __html: trans.title }}
            />
            <p
              className={css.paragraph}
              dangerouslySetInnerHTML={{ __html: trans.subtitle1 }}
            />
            <p
              className={css.paragraph}
              dangerouslySetInnerHTML={{ __html: trans.subtitle2 }}
            />
          </div>
          <div className={merge([css.col, css.scooter_img])}>
            <img src={scooterImg} alt="" />
          </div>
        </div>
        <div className={css.row}>
          {[pictoRoad, pictoSpeed, pictoBattery].map((picto, index) => (
            <div key={index} className={css.col}>
              <div className={css.icon_box}>
                <div className={css.icon_img}>
                  <img src={picto} alt="" />
                </div>
                <div className={css.icon_text}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: trans.blocks[index].title,
                    }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: trans.blocks[index].text,
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={css.row}>
          <div className={css.col}>
            <div className={css.cta}>
              <div className={css.cta_arrow} />
              <b><a className={css.cta_text} href="/technology" children={trans.cta} /></b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AutonomyBlock;
