import css from "./ModelBox.module.less";
import React, { useEffect, useRef, useState } from "react";
import { merge } from "@common/lib/helpers/classNameHelper";
import GlobalConfig from "@common/data/GlobalConfig";
import { IScootData } from "@common/data/IScootData";
import { useScroll } from "../../hooks/useScroll";
import { gsap, Quad } from "gsap";
import { Button } from "@material-ui/core";
import { onClickOrderButton } from "../../app/gtmMiddleware";

ModelBox.defaultProps = {};

const componentName = "ModelBox";
const debug = require("debug")(`front:${componentName}`);
let trans = GlobalConfig.appData.blocks.modelEBlock.modelBox;

/**
 * @name ModelBox
 */

interface IProps {
  scootsData?: IScootData;
}

function ModelBox(props: IProps) {
  // -- Hooks
  const scroll = useScroll();
  const sliderBarRangeRef = useRef<any>();
  const { scootsData } = props;
  const [animateSlider, setAnimateSlider] = useState(false);

  /**
   * On scroll
   */
  useEffect(() => {
    const top = sliderBarRangeRef.current.getBoundingClientRect().top;
    if (top >= 0 && top <= window.innerHeight) {
      setAnimateSlider(true);
    } else {
      setAnimateSlider(false);
    }
  }, [scroll]);

  useEffect(() => {
    const percent = Math.round((scootsData.speed * 100) / scootsData.maxSpeed);
    if (animateSlider) {
      gsap.fromTo(
        sliderBarRangeRef.current,
        {
          width: `0%`,
        },
        {
          width: `${percent}%`,
          duration: 3,
          ease: Quad.easeInOut,
        }
      );
    }
  }, [animateSlider]);

  /**
   * Render
   */

  return (
    <div className={css.Root}>
      <span className={css.model_name}>{scootsData?.modelName}</span>
      <span className={css.model_range}> {scootsData?.power}</span>
      <img
        className={css.model_img}
        src={require(`${scootsData?.modelImg}`).default}
        alt={scootsData?.modelName}
      />
      <div className={css.model_box}>
        <span className={css.model_tagline}>{scootsData?.tagLine}</span>
        <div className={css.slider}>
          <span className={css.slider_title}>{scootsData?.speed} km/h</span>
          <div className={css.slider_bar}>
            <div ref={sliderBarRangeRef} className={css.slider_bar_range} />
          </div>
          <span className={css.slider_subtitle}>{trans.maximumSpeed}</span>
        </div>
        <span className={css.slider_title}>{scootsData?.maxAutonomy} km</span>
        <span className={css.slider_subtitle}>{trans.maximumAutonomy}</span>

        <div className={css.color_container}>
          <div className={css.color_wrapper}>
            <div
              style={{ background: scootsData.colors[0].hexa }}
              className={css.color_icon}
            />
            <span className={css.color_name}>{scootsData.colors[0].name}</span>
          </div>
          <div className={css.color_wrapper}>
            <div
              style={{ background: scootsData.colors[1].hexa }}
              className={css.color_icon}
            />
            <span className={css.color_name}>{scootsData.colors[1].name}</span>
          </div>
        </div>
        <div className={css.price_container}>
          <p className={css.price_text}>
            {trans.startingFrom}
            <span>
              {scootsData?.monthlyPrice}€<sup>/{trans.month}</sup>
            </span>
            <br />
            {trans.rentIncrease}
            <br />
            {trans.or}
            <b>
              {scootsData?.price}€<sup>{trans.ttc}*</sup>
            </b>
          </p>
          <p className={css.price_subtext}>{trans.paymentPlan}</p>

          <p className={css.preorder_text}>
            <span>Jusqu’au 15.02,
              <br/>précommandez pour 590€ et bénéficiez
              du chargeur ultra rapide HSC® offert
            </span>
          </p>
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            onClickOrderButton();
            window.location.href =
              process.env.MENU_CREATE_ORDER + scootsData.customOrderLink;
          }}
        >
          {trans.cta}
        </Button>
      </div>
    </div>
  );
}

export default ModelBox;
