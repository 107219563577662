import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  Divider,
  makeStyles,
} from "@material-ui/core";
import { Theme, useTheme, withStyles } from "@material-ui/core/styles";
import pictoFilter from "asset/img/picto/picto-filter.png";
import pictoLld from "asset/img/picto/picto-lld.png";
import pictoForm from "asset/img/picto/picto-form.png";
import pictoCheckCircle from "asset/img/picto/picto-check-circle.png";
import pictoScooter from "asset/img/picto/picto-scooter.png";
import pictox10 from "asset/img/picto/picto-x10.png";
import pictoCreditcard from "asset/img/picto/picto-credit-card.png";

import bg1 from "asset/img/background/bg1.jpg";
import bg8 from "asset/img/background/bg8.png";
import bg5 from "asset/img/background/bg5.png";
import bg9 from "asset/img/background/bg9.jpg";

import Header from "../../components/header";
import { LoadingService } from "@common/services/LoadingService";
import EcologyBlock from "../../components/ecologyBlock";
import NewsletterBlock from "../../components/newsletterBlock";
import { NavBar } from "../../components/navBar/NavBar";
import compiledTheme from "../../theme";
import ConversionBlock from "../../components/conversionBlock";
import { onClickOrderButton } from "../../app/gtmMiddleware";

interface IProps {}

const componentName = "TechnologyPage";
const debug = require("debug")(`front:${componentName}`);

/**
 * @name FinancePage
 */

const BackgroundImage = (props) => (
  <div
    style={{
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      zIndex: 1,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${props.bg})`,
      backgroundPosition: "50% 50%",
      filter: `blur(${props.blur}px)`,
    }}
  >
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        background: `rgba(0,0,0,${props.opacity})`,
      }}
    />
  </div>
);

const LateralBackgroundImage = (props, theme) => (
  <div
    style={{
      position: "absolute",
      left: "25%",
      top: 0,
      width: "75%",
      height: "100%",
      zIndex: 1,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${props.bg})`,
      backgroundPosition: "50% 50%",
      filter: `blur(${props.blur}px)`,
      [compiledTheme.breakpoints.down("sm")]: {
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      },
    }}
  >
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        background: `rgba(0,0,0,${props.opacity})`,
      }}
    />
  </div>
);

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "hidden",
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("xs")]: {
      paddingTop: theme.spacing(18),
    },
  },
  sectionTitle: {
    paddingTop: "4rem",
    paddingBottom: "2rem",
  },
  sectionInfo: {
    paddingTop: "1rem",
  },
  sectionInfoNumber: {
    marginTop: "1.5rem",
    marginBottom: "1rem",
  },
  imageLateralContainer: {
    position: "absolute",
    left: "50%",
    top: 0,
    bottom: 0,
    right: 0,
    [theme.breakpoints.down("sm")]: {
      left: 0,
    },
  },
  picto: {
    margin: "0 auto",
    height: "3rem",
  },
  lettersPicto: {
    margin: "0 auto",
    height: "2rem",
  },
}));

const FinancePage = (props: IProps) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState(false);
  const theme = useTheme();
  const classes = useStyles();
  const subNavLinks = [
    {
      label: "Location Longue Durée",
      link: "location",
    },
    {
      label: "Paiement en plusieurs fois",
      link: "payment",
    },
    {
      label: "La Prime Ecologique",
      link: "ecology",
    },
  ];

  useEffect(() => {
    LoadingService?.instance?.on("loadingDone", () => setLoaded(true));
  }, []);

  return (
    <div ref={rootRef}>
      <NavBar links={subNavLinks} />
      <div style={{ paddingTop: "8rem" }}>
        <Box mt={2}>
          <Section py={2} style={{ background: "transparent" }}>
            <BackgroundImage bg={bg1} blur={5} opacity={0.5} />
            <Container>
              <Grid
                container
                alignItems="center"
                justify="center"
                style={{
                  minHeight: "50vh",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Grid
                  md={6}
                  xs={10}
                  item
                  style={{ zIndex: 2, position: "relative" }}
                >
                  <Typography variant="h1" align="center">
                    Financer <br />
                    votre Model E
                  </Typography>
                  <Typography variant="body1" align="center">
                    RED Electric vous propose un ensemble de solutions de
                    financement : Paiement en plusieurs fois, Crédit, Location
                    Longue Durée, etc. vous permettent d’amortir votre
                    investissement sur la durée.
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Section>
          <div id="location">
            <Section style={{ background: "transparent" }}>
              <BackgroundImage bg={bg5} blur={10} opacity={0.5} />
              <Container className={classes.sectionTitle}>
                <Grid container>
                  <Grid xs={12} item style={{ zIndex: 2 }}>
                    <Box py={2}>
                      <Typography variant="h2" color="primary" align="left">
                        01
                      </Typography>
                      <Typography variant="h2" color="inherit" align="left">
                        Location Longue Durée
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
            <Section style={{ background: "white", padding: 0 }}>
              <Box className={classes.imageLateralContainer}>
                <LateralBackgroundImage bg={bg9} blur={0} />
              </Box>
              <Container>
                <Grid style={{ height: "100%" }} container spacing={5}>
                  <Grid
                    xs={12}
                    sm={6}
                    item
                    style={{
                      zIndex: 2,
                      background: "white",
                      height: "100%",
                      position: "relative",
                      marginTop: "2rem",
                    }}
                  >
                    <Box my={3} position={"relative"} color="main.primary">
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        align="left"
                      >
                        RED Electric et son partenaire en solutions de leasing
                        MyPangee vous proposent un ensemble de solutions de LLD.
                      </Typography>
                      <Typography variant="h2" color="textPrimary" align="left">
                        Comment ça marche ?
                      </Typography>
                      <Grid container className={classes.sectionInfo}>
                        <Grid item xs={2}>
                          <img
                            className={classes.picto}
                            src={pictoFilter}
                            alt=""
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography
                            variant="h3"
                            gutterBottom
                            color="primary"
                            align="left"
                          >
                            1.
                          </Typography>
                          <Typography
                            variant="h4"
                            gutterBottom
                            color="primary"
                            align="left"
                          >
                            Configurez votre Model E
                          </Typography>
                          <Typography
                            variant="body1"
                            gutterBottom
                            color="textPrimary"
                            align="left"
                          >
                            Vous pourrez ainsi découvrir le montant de vos
                            mensualités. Ce montant dépend du modèle choisi, du
                            nombre de batteries et des accessoires souhaités.
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container className={classes.sectionInfo}>
                        <Grid item xs={2}>
                          <img
                            className={classes.lettersPicto}
                            src={pictoLld}
                            alt=""
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography
                            variant="h3"
                            gutterBottom
                            color="primary"
                            align="left"
                          >
                            2.
                          </Typography>
                          <Typography
                            variant="h4"
                            gutterBottom
                            color="primary"
                            align="left"
                          >
                            Choisissez l'option LLD
                          </Typography>
                          <Typography
                            variant="body1"
                            gutterBottom
                            color="textPrimary"
                            align="left"
                          >
                            Au moment du paiement, choisissez l’option LLD. Vous
                            serez redirigé vers le serveur sécurisé de notre
                            partenaire financier.
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.sectionInfo}>
                        <Grid item xs={2}>
                          <img
                            className={classes.picto}
                            src={pictoForm}
                            alt=""
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography
                            variant="h3"
                            gutterBottom
                            color="primary"
                            align="left"
                          >
                            3.
                          </Typography>
                          <Typography
                            variant="h4"
                            gutterBottom
                            color="primary"
                            align="left"
                          >
                            Saisissez vos informations
                          </Typography>
                          <Typography
                            variant="body1"
                            gutterBottom
                            color="textPrimary"
                            align="left"
                          >
                            Sur cette plateforme, vous pourrez choisir votre
                            durée de location, fournir l’ensemble des documents
                            nécessaires.
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.sectionInfo}>
                        <Grid item xs={2}>
                          <img
                            className={classes.picto}
                            src={pictoCheckCircle}
                            alt=""
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography
                            variant="h3"
                            gutterBottom
                            color="primary"
                            align="left"
                          >
                            4.
                          </Typography>
                          <Typography
                            variant="h4"
                            gutterBottom
                            color="primary"
                            align="left"
                          >
                            Validez votre contrat
                          </Typography>
                          <Typography
                            variant="body1"
                            gutterBottom
                            color="textPrimary"
                            align="left"
                          >
                            Vous serez recontacté sous 48h pour valider votre
                            contrat de LLD
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={2}>
                          <img
                            className={classes.picto}
                            src={pictoScooter}
                            alt=""
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography
                            variant="h3"
                            gutterBottom
                            color="primary"
                            align="left"
                          >
                            5.
                          </Typography>
                          <Typography
                            variant="h4"
                            gutterBottom
                            color="primary"
                            align="left"
                          >
                            Recevez votre scooter
                          </Typography>
                          <Typography
                            variant="body1"
                            gutterBottom
                            color="textPrimary"
                            align="left"
                          >
                            Après le versement du premier loyer à la commande,
                            les mensualités suivantes ne seront prélevées qu'une
                            fois votre scooter livré.
                          </Typography>
                        </Grid>
                      </Grid>
                      <Box my={2}>
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          align="left"
                        >
                          Vous avez des questions ? Contactez notre Service
                          Clients par mail <a href="mailto:support@redelectric.fr">support@redelectric.fr</a> ou téléphone
                          au 01 44 01 53 00.
                        </Typography>
                      </Box>

                      <Typography variant="h2" color="textPrimary" align="left">
                        Vos Avantages
                      </Typography>
                      <Box my={2} style={{ marginBottom: "4rem" }}>
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          align="left"
                        >
                          - La LLD vous permet d'intégrer un ensemble
                          d'accessoires et de services dans votre financement.
                          <br />
                          - Vous ne commencez à payer qu'à réception de votre
                          scooter (hors premier loyer à la commande)
                          <br />
                          - Le Bonus Écologique sera directement déduit du
                          montant financé, pour diminuer vos mensualités.
                          <br />
                        </Typography>

                        <Box textAlign="center">
                          <Button
                            href={process.env.MENU_CREATE_ORDER}
                            variant="contained"
                            color="primary"
                            onClick={()=> onClickOrderButton()}
                          >
                            Je configure mon Model E en LLD
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
            <Section
              style={{
                background: theme.palette.background.default,
                padding: 0,
              }}
            >
              <Container className={classes.sectionInfoNumber}>
                <Box py={5}>
                  <Typography variant="h2">Exemples de tarifs</Typography>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={4}>
                      <Box textAlign={"center"}>
                        <Typography
                          align="center"
                          color="primary"
                          gutterBottom
                          variant="h4"
                        >
                          Model E50
                        </Typography>
                        <Divider
                          style={{ background: "rgba(255,255,255,0.3)" }}
                          light
                        />
                        <Box m={3}>
                          <Typography
                            align="center"
                            gutterBottom
                            variant="body1"
                          >
                            À partir de
                            <span style={{ fontSize: "2.8rem" }}> 69€</span>
                            <sup style={{ fontSize: "xx-small" }}>TTC/MOIS</sup>
                            <br />
                            Sur 48 mois après un premier loyer
                            <br />
                            majoré de 401€ TTC.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box textAlign={"center"}>
                        <Typography
                          align="center"
                          color="primary"
                          gutterBottom
                          variant="h4"
                        >
                          Model E100
                        </Typography>
                        <Divider
                          style={{ background: "rgba(255,255,255,0.3)" }}
                          light
                        />
                        <Box m={3}>
                          <Typography
                            align="center"
                            gutterBottom
                            variant="body1"
                          >
                            À partir de
                            <span style={{ fontSize: "2.8rem" }}> 109€</span>
                            <sup style={{ fontSize: "xx-small" }}>TTC/MOIS</sup>
                            <br />
                            Sur 48 mois après un premier loyer
                            <br />
                            majoré de 633€ TTC.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box textAlign={"center"}>
                        <Typography
                          align="center"
                          color="primary"
                          gutterBottom
                          variant="h4"
                        >
                          Model E125
                        </Typography>
                        <Divider
                          style={{ background: "rgba(255,255,255,0.3)" }}
                          light
                        />
                        <Box m={3}>
                          <Typography
                            align="center"
                            gutterBottom
                            variant="body1"
                          >
                            À partir de
                            <span style={{ fontSize: "2.8rem" }}> 169€</span>
                            <sup style={{ fontSize: "xx-small" }}>TTC/MOIS</sup>
                            <br />
                            Sur 48 mois après un premier loyer
                            <br />
                            majoré de 981€ TTC.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box textAlign={"center"}>
                    <Button
                      href={process.env.MENU_CREATE_ORDER}
                      variant="contained"
                      color="primary"
                      onClick={()=> onClickOrderButton()}
                    >
                      Configurer votre Model E
                    </Button>
                  </Box>
                </Box>
              </Container>
            </Section>
          </div>
          <div id="payment">
            <Section style={{ background: "transparent" }}>
              <BackgroundImage bg={bg5} blur={10} opacity={0.5} />
              <Container className={classes.sectionTitle}>
                <Grid container>
                  <Grid xs={12} item style={{ zIndex: 2 }}>
                    <Box py={2}>
                      <Typography variant="h2" color="primary" align="left">
                        02
                      </Typography>
                      <Typography variant="h2" color="inherit" align="left">
                        Paiement en plusieurs fois
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
            <Section style={{ background: "white", padding: 0 }}>
              <Box className={classes.imageLateralContainer}>
                <LateralBackgroundImage bg={bg8} blur={0} />
              </Box>
              <Container>
                <Grid style={{ height: "100%" }} container spacing={5}>
                  <Grid
                    xs={12}
                    sm={6}
                    item
                    style={{
                      zIndex: 2,
                      background: "white",
                      height: "100%",
                      position: "relative",
                      marginTop: "2rem",
                    }}
                  >
                    <Box my={3} position={"relative"} color="main.primary">
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        align="left"
                      >
                        Le paiement en plusieurs fois facilite l'achat de votre
                        Model E - Payez jusqu'en 8 fois maximum !
                      </Typography>
                      <Typography variant="h2" color="textPrimary" align="left">
                        Comment ça marche ?
                      </Typography>
                      <Grid container className={classes.sectionInfo}>
                        <Grid item xs={2}>
                          <img
                            className={classes.picto}
                            src={pictoFilter}
                            alt=""
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography
                            variant="h3"
                            gutterBottom
                            color="primary"
                            align="left"
                          >
                            1.
                          </Typography>
                          <Typography
                            variant="h4"
                            gutterBottom
                            color="primary"
                            align="left"
                          >
                            Configurez votre Model E
                          </Typography>
                          <Typography
                            variant="body1"
                            gutterBottom
                            color="textPrimary"
                            align="left"
                          >
                            Configurez votre Model E selon votre envie et vos
                            besoins (Batteries, Accessoires, Services, etc.).
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container className={classes.sectionInfo}>
                        <Grid item xs={2}>
                          <img
                            className={classes.lettersPicto}
                            src={pictox10}
                            alt=""
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography
                            variant="h3"
                            gutterBottom
                            color="primary"
                            align="left"
                          >
                            2.
                          </Typography>
                          <Typography
                            variant="h4"
                            gutterBottom
                            color="primary"
                            align="left"
                          >
                            Choisissez l'option Paiement en plusieurs fois
                          </Typography>
                          <Typography
                            variant="body1"
                            gutterBottom
                            color="textPrimary"
                            align="left"
                          >
                            Au moment du paiement, choisissez l’option “Paiement
                            en plusieurs fois” et le nombre de mensualités
                            souhaitées.
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.sectionInfo}>
                        <Grid item xs={2}>
                          <img
                            className={classes.picto}
                            src={pictoCreditcard}
                            alt=""
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography
                            variant="h3"
                            gutterBottom
                            color="primary"
                            align="left"
                          >
                            3.
                          </Typography>
                          <Typography
                            variant="h4"
                            gutterBottom
                            color="primary"
                            align="left"
                          >
                            Effectuez votre premier paiement
                          </Typography>
                          <Typography
                            variant="body1"
                            gutterBottom
                            color="textPrimary"
                            align="left"
                          >
                            Vous payez votre première mensualité le jour de la
                            commande afin de déclencher la configuration de
                            votre Model E.
                            <br />
                            Exemple : Si vous avez choisi un paiement en 3 fois,
                            vous réglerez un tiers du montant le jour de la
                            commande.
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.sectionInfo}>
                        <Grid item xs={2}>
                          <img
                            className={classes.picto}
                            src={pictoScooter}
                            alt=""
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography
                            variant="h3"
                            gutterBottom
                            color="primary"
                            align="left"
                          >
                            4.
                          </Typography>
                          <Typography
                            variant="h4"
                            gutterBottom
                            color="primary"
                            align="left"
                          >
                            Recevez votre scooter électrique Model E
                          </Typography>
                          <Typography
                            variant="body1"
                            gutterBottom
                            color="textPrimary"
                            align="left"
                          >
                            Vous ne réglerez les mensualités suivantes qu'après
                            la livraison de votre Model E, configuré et équipé
                            des accessoires choisis. Les mensualités suivantes
                            seront identiques et prélevées mensuellement à date
                            fixe.
                            <br />
                            Exemple : Vous avez choisi un paiement en 3 fois, le
                            deuxième tiers sera prélevé après réception du
                            scooter, et le 3ème un mois après.
                          </Typography>
                        </Grid>
                      </Grid>
                      <Box my={2}>
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          align="left"
                        >
                          Vous avez des questions ? Contactez notre Service
                          Clients par mail  <a href="mailto:support@redelectric.fr">support@redelectric.fr</a> ou téléphone
                          au 01 44 01 53 00.
                        </Typography>
                      </Box>

                      <Typography variant="h2" color="textPrimary" align="left">
                        Vos Avantages
                      </Typography>
                      <Box my={2}>
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          align="left"
                        >
                          Vous étalez le montant de votre acquisition, sur la
                          durée et selon vos capacités.
                          <br />
                          Vous pouvez choisir de régler l'intégralité de votre
                          commande (accessoires inclus) en 2, 3, 4 ou 8 fois.
                        </Typography>

                        <Box textAlign="center">
                          <Button
                            href={process.env.MENU_CREATE_ORDER}
                            variant="contained"
                            color="primary"
                            onClick={()=> onClickOrderButton()}
                          >
                            Je configure mon Model E
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
            <Section
              style={{
                background: theme.palette.background.default,
                padding: 0,
              }}
            >
              <Container className={classes.sectionInfoNumber}>
                <Box py={5}>
                  <Typography variant="h2">Exemples de tarifs</Typography>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="body1">
                      Pour bénéficier du mode de financement en plusieurs fois, veuillez contacter notre service client par mail ​<a href="mailto:support@redelectric.fr">support@redelectric.fr</a> ou téléphone au 01 44 01 53 00.
                      <br /><br />
                    </Typography>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={4}>
                      <Box textAlign={"center"}>
                        <Typography
                          align="center"
                          color="primary"
                          gutterBottom
                          variant="h4"
                        >
                          Model E50
                        </Typography>
                        <Divider
                          style={{ background: "rgba(255,255,255,0.3)" }}
                          light
                        />
                        <Box m={3}>
                          <Typography
                            align="center"
                            gutterBottom
                            variant="body1"
                          >
                            2 mensualités à partir de 2035€
                            <br />
                            3 mensualités à partir de 1370€
                            <br />
                            4 mensualités à partir de 1028€
                            <br />
                            8 mensualités à partir de 544€
                            <br />
                            10 mensualités à partir de 435€
                            <br />
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box textAlign={"center"}>
                        <Typography
                          align="center"
                          color="primary"
                          gutterBottom
                          variant="h4"
                        >
                          Model E100
                        </Typography>
                        <Divider
                          style={{ background: "rgba(255,255,255,0.3)" }}
                          light
                        />
                        <Box m={3}>
                          <Typography
                            align="center"
                            gutterBottom
                            variant="body1"
                          >
                            2 mensualités à partir de 3055€
                            <br />
                            3 mensualités à partir de 2057€
                            <br />
                            4 mensualités à partir de 1543€
                            <br />
                            8 mensualités à partir de 817€
                            <br />
                            10 mensualités à partir de 653€
                            <br />
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box textAlign={"center"}>
                        <Typography
                          align="center"
                          color="primary"
                          gutterBottom
                          variant="h4"
                        >
                          Model E125
                        </Typography>
                        <Divider
                          style={{ background: "rgba(255,255,255,0.3)" }}
                          light
                        />
                        <Box m={3}>
                          <Typography
                            align="center"
                            gutterBottom
                            variant="body1"
                          >
                            2 mensualités à partir de 4585€
                            <br />
                            3 mensualités à partir de 3087€
                            <br />
                            4 mensualités à partir de 2315€
                            <br />
                            8 mensualités à partir de 1225€
                            <br />
                            10 mensualités à partir de 980€
                            <br />
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box textAlign={"center"}>
                    <Button
                      href={process.env.MENU_CREATE_ORDER}
                      variant="contained"
                      color="primary"
                      onClick={()=> onClickOrderButton()}
                    >
                      Configurer votre Model E
                    </Button>
                  </Box>
                </Box>
              </Container>
            </Section>
          </div>
          <div id="ecology">
            <Section style={{ background: "transparent" }}>
              <BackgroundImage bg={bg5} blur={10} opacity={0.5} />
              <Container className={classes.sectionTitle}>
                <Grid container>
                  <Grid
                    xs={12}
                    item
                    style={{
                      zIndex: 2,
                    }}
                  >
                    <Box py={1}>
                      <Typography variant="h2" color="primary" align="left">
                        03
                      </Typography>
                      <Typography variant="h2" color="inherit" align="left">
                        En LLD ou en plusieurs fois,
                        <br /> profitez du Bonus écologique.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
            <Section style={{ background: "white", padding: 0 }}>
              <EcologyBlock displayLink={false} />
            </Section>
          </div>
          <NewsletterBlock />
        </Box>
      </div>
    </div>
  );
};

export const Section = withStyles((t: Theme) => ({
  root: {
    color: "white",
    background: t.palette.background.paper,
    position: "relative",
    overflow: "hidden",
  },
}))(Box);

export default FinancePage;
