import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { LoadingService } from "@common/services/LoadingService";
import GlobalConfig from "@common/data/GlobalConfig";
import Header from "red-electric/components/header";
import { userLogin } from "red-electric/app/userDataSlice";
import { selectIsLoading, selectUIErrors } from "red-electric/app/UISlice";
import LoginForm from "red-electric/components/loginForm";
import { UserLoginAPIRequest } from "red-electric/utils/Api.interface";
import InternalRoutes from "red-electric/utils/InternalRoutes";
import headerLogo from "asset/img/logo-black-red.png";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    paddingTop: "10rem",
    paddingBottom: "8rem",
  },
  logo: {
    width: "8.3rem",
    height: "4rem",
    margin: "0 auto 4rem",
  },
}));

export const LoginPage = () => {
  const trans = GlobalConfig?.appData.blocks.loginPage;

  const classes = useStyles();
  const dispatch = useDispatch();
  const UIErrors = useSelector(selectUIErrors);
  const UILoading = useSelector(selectIsLoading);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    LoadingService?.instance?.on("loadingDone", () => setLoaded(true));
  }, []);

  const handleLogin = (data) => {
    const payload: UserLoginAPIRequest = {
      loginName: data.email,
      password: data.password,
    };
    dispatch(userLogin(payload));
  };

  const handleRegister = (e) => {
    e.preventDefault();
    window.location.href = "/registration";
  };

  return (
    <div className={classes.root}>
      <img src={headerLogo} alt="" className={classes.logo} />
      <Container maxWidth="sm">
        <LoginForm
          isApiLoading={UILoading.userLogin}
          submitLabel={trans.loginButton}
          handleSubmit={handleLogin}
          serverResponse={
            UIErrors.userLogin && (
              <Typography color="error">
                {UIErrors.userLogin.errorMsg}
              </Typography>
            )
          }
        />
        <Box mt={4}>
          <Typography align="center">
            {trans.createAccountLabel}{" "}
            <Typography
              component="a"
              href="/registration"
              onClick={handleRegister}
            >
              {trans.createAccountButton}
            </Typography>
          </Typography>
        </Box>
      </Container>
    </div>
  );
};
