import React, { useEffect, useRef, useState } from "react";
import { Button, Box, Typography } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import GlobalConfig from "@common/data/GlobalConfig";

interface IProps {
  isApiLoading: boolean;
  submitLabel: string;
  handleSubmit: (payload) => void;
  serverResponse?: JSX.Element;
}

export const OrderMatchingForm = (props: IProps) => {
  const trans = GlobalConfig?.appData.blocks.matchingPage;

  const formRef = useRef<ValidatorForm>(null);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [orderNumber, setOrderNo] = useState("");
  const [isMatchingUsingEmail, setIsMatchingUsingEmail] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    // clear fields when switching
    // between email and phone
    setEmail("");
    setPhone("");
  }, [isMatchingUsingEmail]);

  useEffect(() => {
    formRef.current?.isFormValid(true).then(setIsFormValid);
  });

  const handleFormSubmit = () => {
    const payload = { orderNumber, email, phone };
    props.handleSubmit(payload);
  };

  const renderPhoneField = () => (
    <TextValidator
      autoComplete="phone"
      errorMessages={[trans.phoneFieldRequired]}
      fullWidth
      name="phone"
      label={trans.phoneFieldLabel}
      helperText={trans.phoneFieldHelper}
      inputProps={{ inputMode: "numeric" }}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(e.target.value);
      }}
      type="tel"
      validators={["required"]}
      value={phone}
      variant="outlined"
    />
  );

  const renderEmailField = () => (
    <TextValidator
      autoComplete="email"
      errorMessages={[trans.emailFieldRequired, trans.emailFieldInvalid]}
      fullWidth
      name="email"
      label={trans.emailFieldLabel}
      helperText={trans.emailFieldHelper}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
      }}
      type="email"
      validators={["required", "isEmail"]}
      value={email}
      variant="outlined"
    />
  );

  return (
    <ValidatorForm ref={formRef} onSubmit={handleFormSubmit}>
      <Box mb={4}>
        {isMatchingUsingEmail ? renderEmailField() : renderPhoneField()}
        <Box textAlign="right">
          <Typography
            component="u"
            variant="body2"
            onClick={(e) => {
              e.preventDefault();
              setIsMatchingUsingEmail(!isMatchingUsingEmail);
            }}
            style={{ cursor: "pointer" }}
          >
            {isMatchingUsingEmail
              ? trans.matchUsingPhoneLabel
              : trans.matchUsingEmailLabel}
          </Typography>
        </Box>
        <TextValidator
          errorMessages={[
            trans.orderNumberFieldRequired,
            trans.orderNumberFieldInvalid,
          ]}
          fullWidth
          name="orderNumber"
          label={trans.orderNumberFieldLabel}
          helperText={trans.orderNumberFieldHelper}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setOrderNo(e.target.value.toUpperCase());
          }}
          margin="normal"
          validators={["required"]}
          value={orderNumber}
          variant="outlined"
        />
      </Box>
      {props.serverResponse && <Box mb={2}>{props.serverResponse}</Box>}
      <Box mb={6}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          disabled={props.isApiLoading || !isFormValid}
        >
          {props.isApiLoading ? trans.loading : trans.submitButton}
        </Button>
      </Box>
    </ValidatorForm>
  );
};
