import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Container, useMediaQuery } from "@material-ui/core";
import { LoadingService } from "@common/services/LoadingService";
import GlobalConfig from "@common/data/GlobalConfig";
import { userLogout } from "red-electric/app/userDataSlice";
import { selectUIErrors } from "red-electric/app/UISlice";
import useStyles from "./AccountPage.styles";
import { AccountDrawer } from "../../components/accountDrawer/AccountDrawer";
import InfoIcon from "@material-ui/icons/Info";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import LockIcon from "@material-ui/icons/Lock";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import clsx from "clsx";
import { AccountInformation } from "./AccountInformation/AccountInformation";
import { AccountOrders } from "./AccountOrders/AccountOrders";
import { AccountSecurity } from "./AccountSecurity/AccountSecurity";
import { useTheme } from "@material-ui/core/styles";

interface IProps {
  children?: React.ReactChild;
}

export const AccountPage = (props: IProps) => {
  // global
  const trans = GlobalConfig?.appData.blocks;
  const classes = useStyles();
  const dispatch = useDispatch();

  // selectors
  const UIErrors = useSelector(selectUIErrors);

  // states
  const [selectedTab, selectTab] = useState(
    sessionStorage.getItem("account_tab")
      ? sessionStorage.getItem("account_tab")
      : "info"
  );

  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  useEffect(() => {
    setDrawerIsOpen(!isMobile);
  }, [isMobile]);

  // event handlers
  const handleLogout = () => {
    dispatch(userLogout());
  };

  const handleTabClick = (tab: string) => {
    switch (tab) {
      case "logout":
        handleLogout();
      default:
        sessionStorage.setItem("account_tab", tab);
        selectTab(tab);
        break;
    }
    return;
  };

  const listItems = [
    {
      label: "Mes informations",
      icon: <InfoIcon />,
      callback: () => handleTabClick("info"),
      isSelected: selectedTab === "info",
    },
    {
      label: "Mes commandes",
      icon: <ShoppingCartIcon />,
      callback: () => handleTabClick("order"),
      isSelected: selectedTab === "order",
    },
    {
      label: "Mot de passe",
      icon: <LockIcon />,
      callback: () => handleTabClick("password"),
      isSelected: selectedTab === "password",
    },
    {
      label: (
        <Typography variant="body1" color="secondary">
          Déconnexion
        </Typography>
      ),
      icon: <ExitToAppIcon color="secondary" />,
      disableTypography: true,
      callback: () => handleTabClick("logout"),
      isSelected: selectedTab === "logout",
    },
  ];
  return (
    <div className={classes.root}>
      <AccountDrawer
        open={drawerIsOpen}
        onMenuClicked={() => {
          setDrawerIsOpen(!drawerIsOpen);
        }}
        className={classes.drawer}
        listItems={listItems}
      />
      <main
        className={clsx(classes.main, {
          [classes.mainOpen]: drawerIsOpen,
          [classes.mainClose]: !drawerIsOpen,
        })}
      >
        <Container maxWidth={false}>
          {selectedTab === "info" && <AccountInformation />}
          {selectedTab === "order" && <AccountOrders />}
          {selectedTab === "password" && <AccountSecurity />}
        </Container>
      </main>
    </div>
  );
};
