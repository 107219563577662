import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Paper,
  Grid,
  Typography,
  Box,
  Button,
  Hidden,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  TableBody,
  Divider,
  makeStyles,
} from "@material-ui/core";
import bg_1 from "asset/img/background_1.jpg";
import { Theme, useTheme, withStyles } from "@material-ui/core/styles";

import bg10 from "asset/img/background/bg10.jpg";
import bg11 from "asset/img/background/bg11.png";
import bg4 from "asset/img/background/bg4.jpg";
import bg5 from "asset/img/background/bg5.png";
import bg6 from "asset/img/background/bg6.jpg";
import bg12 from "asset/img/background/bg12.png";
import bg14 from "asset/img/background/bg14.jpg";
import bg8 from "asset/img/background/bg8.png";

import fnac_logo from "../../../asset/img/fnac.png";
import darty_logo from "../../../asset/img/darty.png";
import rede_center_logo from "../../../asset/img/rede_center.png";
import Header from "../../components/header";
import { LoadingService } from "@common/services/LoadingService";
import EcologyForm from "../../components/ecologyForm";
import NewsletterBlock from "../../components/newsletterBlock";
import Co2Calculator from "../../components/co2Calculator";
import { NavBar } from "../../components/navBar/NavBar";
import compiledTheme from "../../theme";
import ConversionBlock from "../../components/conversionBlock";

interface IProps {}

const componentName = "InsurancePage";
const debug = require("debug")(`front:${componentName}`);

/**
 * @name InsurancePage
 */

const BackgroundImage = (props) => (
  <div
    style={{
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      zIndex: 1,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${props.bg})`,
      backgroundPosition: "50% 50%",
      filter: `blur(${props.blur}px)`,
    }}
  >
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        background: `rgba(0,0,0,${props.opacity})`,
      }}
    />
  </div>
);

const LateralBackgroundImage = (props, theme) => (
  <div
    style={{
      position: "absolute",
      left: "25%",
      top: 0,
      width: "75%",
      height: "100%",
      zIndex: 1,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${props.bg})`,
      backgroundPosition: "50% 50%",
      filter: `blur(${props.blur}px)`,
      [compiledTheme.breakpoints.down("sm")]: {
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      },
    }}
  >
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        background: `rgba(0,0,0,${props.opacity})`,
      }}
    />
  </div>
);

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "hidden",
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("xs")]: {
      paddingTop: theme.spacing(18),
    },
  },
  sectionTitle: {
    paddingTop: "4rem",
    paddingBottom: "2rem",
  },
  sectionInfo: {
    paddingTop: "1rem",
  },
  sectionInfoNumber: {
    marginTop: "1.5rem",
    paddingBottom: "1.5rem",
  },
  imageLateralContainer: {
    position: "absolute",
    left: "50%",
    top: 0,
    bottom: 0,
    right: 0,
    [theme.breakpoints.down("sm")]: {
      left: 0,
    },
  },
}));

const InsurancePage = (props: IProps) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState(false);
  const theme = useTheme();
  const classes = useStyles();
  const subNavLinks = [
    {
      label: "Les 9 avantages",
      link: "9avantages",
    },
    {
      label: "Vos garanties",
      link: "vos-garanties",
    },
    {
      label: "Les Franchises",
      link: "franchises",
    },
    {
      label: "Presentation",
      link: "presentation",
    },
  ];

  useEffect(() => {
    LoadingService?.instance?.on("loadingDone", () => setLoaded(true));
  }, []);

  return (
    <div ref={rootRef}>
      <NavBar links={subNavLinks} />
      <div style={{ paddingTop: "8rem" }}>
        <Box mt={2}>
          <Section py={2} style={{ background: "transparent" }}>
            <BackgroundImage bg={bg4} blur={25} opacity={0.5} />
            <Container>
              <Grid
                container
                alignItems="center"
                justify="center"
                style={{
                  minHeight: "50vh",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Grid
                  md={6}
                  xs={12}
                  item
                  style={{ zIndex: 2, position: "relative" }}
                >
                  <Typography variant="h1" align="center">
                    L'assurance RED Electric
                  </Typography>
                  <Typography variant="body1" align="center">
                    L'assurance de RED Electric vous porpose des avantages
                    exclusifs pour votre Model E. Nos garanties étudiées
                    spécifiqueemnt pour les acheteurs de Model E peuvent être
                    complétées par des garanties optionnelles qui s'adaptent aux
                    besoins de chacun. Roulez sereinement au guidon de votre
                    Model E. L'assurance RED Electric est là pour vous !
                    <br />
                    <br />
                  </Typography>
                  <Box textAlign="center">
                    <Button
                      href="https://www.redelectric-assurance.fr/"
                      variant="contained"
                      color="primary"
                      target="_blank"
                    >
                      Faire un devis pour mon Model E
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Section>
          <div id="9avantages">
            <Section style={{ background: "transparent" }}>
              <BackgroundImage bg={bg5} blur={10} opacity={0.5} />
              <Container className={classes.sectionTitle}>
                <Grid container>
                  <Grid xs={12} item style={{ zIndex: 2 }}>
                    <Box py={2}>
                      <Typography variant="h2" color="primary" align="left">
                        01
                      </Typography>
                      <Typography variant="h2" color="inherit" align="left">
                        L'assurance RED Electric
                        <br />9 avantages pour vous et votre Model E
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
            <Section style={{ background: "white", padding: 0 }}>
              <Box className={classes.imageLateralContainer}>
                <LateralBackgroundImage bg={bg8} blur={0} />
              </Box>
              <Container>
                <Grid style={{ height: "100%" }} container spacing={5}>
                  <Grid
                    xs={12}
                    sm={6}
                    item
                    style={{
                      zIndex: 2,
                      background: "white",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Typography
                      variant="h3"
                      gutterBottom
                      color="primary"
                      align="left"
                    >
                      1. Assistance 0 km en cas de panne ou d’accident (incluse)
                    </Typography>
                    {/* <Typography
                        variant="h4"
                        gutterBottom
                        color="primary"
                        align="left"
                      >
                        Configurez votre Model E
                      </Typography> */}
                    <Typography
                      variant="body1"
                      gutterBottom
                      color="textPrimary"
                      align="left"
                    >
                      Avec remorquage de votre model E à la borne de recharge la
                      plus proche ou chez un réparateur agréé du réseau RED
                      Electric.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid style={{ height: "100%" }} container spacing={5}>
                  <Grid
                    xs={12}
                    sm={6}
                    item
                    style={{
                      zIndex: 2,
                      background: "white",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Typography
                      variant="h3"
                      gutterBottom
                      color="primary"
                      align="left"
                    >
                      2. Scooter de remplacement (inclus)
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      color="textPrimary"
                      align="left"
                    >
                      En cas d’accident ou de vol de votre Model E.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid style={{ height: "100%" }} container spacing={5}>
                  <Grid
                    xs={12}
                    sm={6}
                    item
                    style={{
                      zIndex: 2,
                      background: "white",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Typography
                      variant="h3"
                      gutterBottom
                      color="primary"
                      align="left"
                    >
                      3. Garantie Pertes pécuniaires (GAP) (incluse)
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      color="textPrimary"
                      align="left"
                    >
                      Nous remboursons à l'organisme de financement la
                      différence de valeur dans le cas où la valeur expertisée
                      de votre scooter suite à un sinistre est inférieure au
                      montant du capital restant dû de votre crédit.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid style={{ height: "100%" }} container spacing={5}>
                  <Grid
                    xs={12}
                    sm={6}
                    item
                    style={{
                      zIndex: 2,
                      background: "white",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Typography
                      variant="h3"
                      gutterBottom
                      color="primary"
                      align="left"
                    >
                      4. Garantie Sérénité Coup dur (incluse)
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      color="textPrimary"
                      align="left"
                    >
                      Nous prenons en charge vos cotisations d’assurance jusqu’à
                      1 000 € en cas de licenciement économique, dépôt de bilan
                      ou longue maladie.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid style={{ height: "100%" }} container spacing={5}>
                  <Grid
                    xs={12}
                    sm={6}
                    item
                    style={{
                      zIndex: 2,
                      background: "white",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Typography
                      variant="h3"
                      gutterBottom
                      color="primary"
                      align="left"
                    >
                      5. Protection du pilote (en option)
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      color="textPrimary"
                      align="left"
                    >
                      Pour vous couvrir en cas de dommages corporels jusqu’à 100
                      000 €.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid style={{ height: "100%" }} container spacing={5}>
                  <Grid
                    xs={12}
                    sm={6}
                    item
                    style={{
                      zIndex: 2,
                      background: "white",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Typography
                      variant="h3"
                      gutterBottom
                      color="primary"
                      align="left"
                    >
                      6. Remplacement de votre phare avant incluse
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      color="textPrimary"
                      align="left"
                    >
                      En cas de collision (formule READY uniquement).
                    </Typography>
                  </Grid>
                </Grid>
                <Grid style={{ height: "100%" }} container spacing={5}>
                  <Grid
                    xs={12}
                    sm={6}
                    item
                    style={{
                      zIndex: 2,
                      background: "white",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Typography
                      variant="h3"
                      gutterBottom
                      color="primary"
                      align="left"
                    >
                      7. Protection juridique (en option)
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      color="textPrimary"
                      align="left"
                    >
                      Nous couvrons les litiges liés à l’achat, location ou
                      vente de votre scooter, nous intervenons aussi pour vous
                      permettre de regagner des points sur votre permis de
                      conduire.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid style={{ height: "100%" }} container spacing={5}>
                  <Grid
                    xs={12}
                    sm={6}
                    item
                    style={{
                      zIndex: 2,
                      background: "white",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Typography
                      variant="h3"
                      gutterBottom
                      color="primary"
                      align="left"
                    >
                      8. Garantie Accessoires (en option)
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      color="textPrimary"
                      align="left"
                    >
                      Avec cette option, vos accessoires RED ELECTRIC sont
                      garantis jusqu’à 500 €.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid style={{ height: "100%" }} container spacing={5}>
                  <Grid
                    xs={12}
                    sm={6}
                    item
                    style={{
                      zIndex: 2,
                      background: "white",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Typography
                      variant="h3"
                      gutterBottom
                      color="primary"
                      align="left"
                    >
                      9. Votre cotisation calculée au plus juste !
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      color="textPrimary"
                      align="left"
                    >
                      Nous prenons en compte le nombre de batteries de votre
                      véhicule Model E pour calculer votre prime d’assurance le
                      plus précisément possible.
                    </Typography>
                    <Box my={2} style={{ marginBottom: "4rem" }}>
                      <Typography variant="body1" color="primary" align="left">
                        Vous avez des questions ?
                        <br />
                        <b>
                          Contactez notre Service Clients par mail
                          devis@redelectric-assurance.fr
                        </b>
                        <br />
                        ou téléphone au 01 83 774 747{" "}
                        <b>(prix d'un appel local)</b>.
                        <br />
                      </Typography>
                      <Box textAlign="center">
                        <Button
                          href="https://www.redelectric-assurance.fr/"
                          variant="contained"
                          color="primary"
                          target="_blank"
                        >
                          Faire un devis pour mon Model E
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
          </div>
          <div id="vos-garanties">
            <Section style={{ background: "transparent" }}>
              <BackgroundImage bg={bg5} blur={10} opacity={0.5} />
              <Container className={classes.sectionTitle}>
                <Grid container>
                  <Grid xs={12} item style={{ zIndex: 2 }}>
                    <Box py={2}>
                      <Typography variant="h2" color="primary" align="left">
                        02
                      </Typography>
                      <Typography variant="h2" color="inherit" align="left">
                        L’assurance de RED Electric
                        <br />
                        Les garanties pour vous et votre Model E
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
            <Section style={{ background: "white", padding: 0 }}>
              <Hidden smDown>
                <Box className={classes.imageLateralContainer}>
                  <LateralBackgroundImage bg={bg14} blur={0} />
                </Box>
              </Hidden>
              <Container>
                <Grid style={{ height: "100%" }} container spacing={5}>
                  <Grid
                    xs={12}
                    sm={6}
                    item
                    style={{
                      zIndex: 2,
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Box my={3} position={"relative"} color="main.primary">
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.sectionInfo}
                      >
                        Roulez sereinement avec votre scooter électrique :
                        L'assurance de RED Electric vous couvre.{" "}
                        <b>Avec l’assistance 0 km</b>, notre réseau de
                        dépanneurs intervient en cas de panne de batteries pour
                        vous conduire au point de recharge le plus proche.{" "}
                        <b>
                          Si nécessaire, en cas de panne ou accident, votre
                          véhicule est emmené systématiquement dans le réseau
                          RED Electric.
                        </b>
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        Découvrez nos garanties parfaitement conçues pour les
                        Model E Red Electric, elles peuvent être complétées de
                        garanties optionnelles pour mieux s'adapter à vos
                        besoins.
                      </Typography>
                      <Box textAlign="center">
                        <Button
                          href="https://www.redelectric-assurance.fr/"
                          variant="contained"
                          color="primary"
                          target="_blank"
                        >
                          Faire un devis pour mon Model E
                        </Button>
                      </Box>
                    </Box>
                    <Box my={5}>
                      <TableContainer component={Paper}>
                        <Table
                          aria-label="simple table"
                          style={{
                            background: "white",
                          }}
                        >
                          <TableHead>
                            <TableRow
                              style={{
                                textTransform: "uppercase",
                              }}
                            >
                              <TableCell
                                align="center"
                                style={{
                                  background: theme.palette.primary.main,
                                  color: "white",
                                }}
                              >
                                Les garanties incluses
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  background: theme.palette.primary.main,
                                  color: "white",
                                }}
                              >
                                Formule START
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  background: theme.palette.primary.main,
                                  color: "white",
                                }}
                              >
                                Formule READY
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Responsabilité civile
                              </TableCell>
                              <TableCell align="center" color="primary">
                                <b>X</b>
                              </TableCell>
                              <TableCell align="center" color="primary">
                                <b>X</b>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Défense Pénale et recours
                              </TableCell>
                              <TableCell align="center" color="primary">
                                <b>X</b>
                              </TableCell>
                              <TableCell align="center" color="primary">
                                <b>X</b>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Vol
                              </TableCell>
                              <TableCell align="center" color="primary">
                                <b>X</b>
                              </TableCell>
                              <TableCell align="center" color="primary">
                                <b>X</b>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Incendie/Evènement climatiques
                              </TableCell>
                              <TableCell align="center" color="primary">
                                <b>X</b>
                              </TableCell>
                              <TableCell align="center" color="primary">
                                <b>X</b>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Catastrophes Naturelles
                              </TableCell>
                              <TableCell align="center" color="primary">
                                <b>X</b>
                              </TableCell>
                              <TableCell align="center" color="primary">
                                <b>X</b>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Catastrophes Technologiques
                              </TableCell>
                              <TableCell align="center" color="primary">
                                <b>X</b>
                              </TableCell>
                              <TableCell align="center" color="primary">
                                <b>X</b>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Attentats et actes de terrorisme
                              </TableCell>
                              <TableCell align="center" color="primary">
                                <b>X</b>
                              </TableCell>
                              <TableCell align="center" color="primary">
                                <b>X</b>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Assistance 0 km avec rapatriement dans le réseau
                                RED Electric
                              </TableCell>
                              <TableCell align="center" color="primary">
                                <b>X</b>
                              </TableCell>
                              <TableCell align="center" color="primary">
                                <b>X</b>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Sérénité Coup dur
                              </TableCell>
                              <TableCell align="center" color="primary">
                                <b>X</b>
                              </TableCell>
                              <TableCell align="center" color="primary">
                                <b>X</b>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Pertes pécuniaires (GAP) (si chat en LOA ou LLD)
                              </TableCell>
                              <TableCell align="center" color="primary">
                                <b>X</b>
                              </TableCell>
                              <TableCell align="center" color="primary">
                                <b>X</b>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Bris d'optiques
                              </TableCell>
                              <TableCell
                                align="center"
                                color="primary"
                              ></TableCell>
                              <TableCell align="center" color="primary">
                                <b>X</b>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Dommages Tous accidents
                              </TableCell>
                              <TableCell
                                align="center"
                                color="primary"
                              ></TableCell>
                              <TableCell align="center" color="primary">
                                <b>X</b>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TableContainer component={Paper}>
                        <Table
                          aria-label="simple table"
                          style={{
                            background: "white",
                          }}
                        >
                          <TableHead>
                            <TableRow
                              style={{
                                textTransform: "uppercase",
                              }}
                            >
                              <TableCell
                                align="center"
                                style={{
                                  background: theme.palette.primary.main,
                                  color: "white",
                                }}
                              >
                              Les garanties optionnelles
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  background: theme.palette.primary.main,
                                  color: "white",
                                }}
                              >
                                Formule START
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  background: theme.palette.primary.main,
                                  color: "white",
                                }}
                              >
                                Formule READY
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Protection du pilote
                              </TableCell>
                              <TableCell align="center" color="primary">
                              </TableCell>
                              <TableCell align="center" color="primary">
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Protection Juridique
                              </TableCell>
                              <TableCell align="center" color="primary">
                              </TableCell>
                              <TableCell align="center" color="primary">
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Accessoires 500€
                              </TableCell>
                              <TableCell align="center" color="primary">
                              </TableCell>
                              <TableCell align="center" color="primary">
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                    <Box my={3} position={"relative"} color="main.primary">
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.sectionInfo}
                      >
                        <b>Votre sécurité est notre priorité : </b>
                        Même bien installé sur votre Model E, circuler à 2 roues n'est pas sans danger, surtout en ville ! C'est pourquoi notre option protection du pilote vous indemnise jusqu'à 100 000 € en cas d'invalidité, on n'est jamais trop prudents.{" "}
                        <br />
                        <br />
                        <b>
                          Focus sur la garantie Pertes pécuniaires (GAP) si achat à crédit ou en LOA /LLD :
                        </b>
                        {" "}Votre scooter est volé ou endommagé totalement et le montant de votre crédit encore à rembourser est supérieur à la valeur expertisée de votre scooter : L'assurance RED Electric prend en charge cette différence de valeur pour que vous soyez en mesure de rembourser intégralement le montant restant de votre crédit ou financement.
                        <br />
                        <br />
                        <b>
                          Focus sur la garantie Sérénité Coup dur :
                        </b>
                        {" "}Vous perdez votre emploi ou vous venez de déposer le bilan ? L'assurance RED Electric prend en charge vos cotisations d’assurance dans la limite de 1 000 €.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
          </div>
          <div id="franchises">
            <Section style={{ background: "transparent" }}>
              <BackgroundImage bg={bg5} blur={10} opacity={0.5} />
              <Container className={classes.sectionTitle}>
                <Grid container>
                  <Grid xs={12} item style={{ zIndex: 2 }}>
                    <Box py={2}>
                      <Typography variant="h2" color="primary" align="left">
                        03
                      </Typography>
                      <Typography variant="h2" color="inherit" align="left">
                        L’assurance de RED Electric
                        <br />
                        Les différentes franchises selon vos besoins
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
            <Section style={{ background: "white", padding: 0 }}>
              <Hidden smDown>
                <Box className={classes.imageLateralContainer}>
                  <LateralBackgroundImage bg={bg6} blur={0} />
                </Box>
              </Hidden>
              <Container>
                <Grid style={{ height: "100%" }} container spacing={5}>
                  <Grid
                    xs={12}
                    sm={7}
                    item
                    style={{
                      zIndex: 2,
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Box my={3} position={"relative"} color="main.primary">
                      <Typography
                        variant="h2"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionTitle}
                      >
                        Retrouvez ci-dessous le montant des franchises des garanties principales :
                      </Typography>
                      <Box my={5}>
                      <TableContainer component={Paper}>
                        <Table
                          aria-label="simple table"
                          style={{
                            background: "white",
                          }}
                        >
                          <TableHead>
                            <TableRow
                              style={{
                                textTransform: "uppercase",
                              }}
                            >
                              <TableCell
                                align="left"
                                style={{
                                  background: theme.palette.primary.main,
                                  color: "white",
                                }}
                              >
                              Cylindrée
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  background: theme.palette.primary.main,
                                  color: "white",
                                }}
                              >
                                Franchise Vol - Incendie
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  background: theme.palette.primary.main,
                                  color: "white",
                                }}
                              >
                                Franchise Dommages
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  background: theme.palette.primary.main,
                                  color: "white",
                                }}
                              >
                                Franchise garantie
                                <br />
                                accessoires (optionnelle)
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Model E50
                              </TableCell>
                              <TableCell align="center" color="primary">
                                260€
                              </TableCell>
                              <TableCell align="center" color="primary">
                                230€
                              </TableCell>
                              <TableCell align="center" color="primary">
                                150€
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Model E100
                              </TableCell>
                              <TableCell align="center" color="primary">
                                290€
                              </TableCell>
                              <TableCell align="center" color="primary">
                                260€
                              </TableCell>
                              <TableCell align="center" color="primary">
                                150€
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Model E125
                              </TableCell>
                              <TableCell align="center" color="primary">
                                470€
                              </TableCell>
                              <TableCell align="center" color="primary">
                                410€
                              </TableCell>
                              <TableCell align="center" color="primary">
                                150€
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                      </Box>

                      <Box my={5}>
                        <Typography variant="body1" color="textPrimary">
                          <i>
                            Pour plus d’information contactez notre service clients par mail devis@redelectric-assurance.fr ou par téléphone au 01 83 774 747 (prix d'un appel local).
                          </i>
                          <br />
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
          </div>
          <div id="presentation">
            <Section style={{ background: "transparent" }}>
              <BackgroundImage bg={bg5} blur={10} opacity={0.5} />
              <Container className={classes.sectionTitle}>
                <Grid container>
                  <Grid xs={12} item style={{ zIndex: 2 }}>
                    <Box py={2}>
                      <Typography variant="h2" color="primary" align="left">
                        04
                      </Typography>
                      <Typography variant="h2" color="inherit" align="left">
                        L’assurance de RED Electric
                        <br />
                        Présentation du partenariat
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
            <Section style={{ background: "white", padding: 0 }}>
              <Hidden smDown>
                <Box className={classes.imageLateralContainer}>
                  <LateralBackgroundImage bg={bg12} blur={0} />
                </Box>
              </Hidden>
              <Container>
                <Grid style={{ height: "100%" }} container spacing={5}>
                  <Grid
                    xs={12}
                    sm={6}
                    item
                    style={{
                      zIndex: 2,
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Box my={3} position={"relative"} color="main.primary">
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.sectionInfo}
                      >
                        Le programme d'assurance de RED Electric a été créé en partenariat entre la société Red-E-Group et la société FMA Assurances, spécialiste de l'assurance des deux roues en France.
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        Développées en étroite collaboration par ces deux entités, les garanties d'assurance pour les scooters RED Electric sont conçues pour répondre aux attentes des clients et à leurs besoins de mobilité.
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        En choisissant notre assurance, vous bénéficiez de nombreux avantages en inclusion :
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        <li>L'assistance 0km avec rapatriement systématique du véhicule dans un garage agréé RED Electric (dans la limite de 100 kms).</li>
                        <li>La garantie sérénité coup dur (prise en charge de vos cotisations en cas de licenciement économique, dépôt de bilan ou longue maladie).</li>
                        <li>
                          Pertes pécuniaires en cas d’achat à crédit ou en LLD / LOA.
                        </li>
                        <li>Garantie Bris d’optique </li>
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        En complément, pour une couverture sur-mesure, ces garanties optionnelles sont à votre disposition :
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        <li>La garantie protection juridique.</li>
                        <li>La garantie équipement du pilote jusqu'à 100 000 €.</li>
                        <li>
                            La garantie de vos accessoires (top case, tablier etc...) à hauteur de 500 €.
                        </li>
                      </Typography>
                      </Box>
                      <Box my={3} textAlign="center" position={"relative"} color="main.primary">
                      <Button
                        href="https://www.redelectric-assurance.fr/"
                        variant="contained"
                        color="primary"
                        target="_blank"
                      >
                        Faire un devis pour mon Model E
                      </Button>
                      </Box>
                      <Box my={3} position={"relative"} color="main.primary">
                      <Typography variant="body1" color="textPrimary">
                        <i>
                          Vous avez des questions ?
                        </i>
                        <br />
                        <i>
                          Contactez notre service clients par mail devis@redelectric-assurance.fr ou par téléphone au 01 83 774 747 (prix d'un appel local).
                        </i>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
          </div>
          <NewsletterBlock />
        </Box>
      </div>
    </div>
  );
};

export const Section = withStyles((t: Theme) => ({
  root: {
    color: "white",
    background: t.palette.background.paper,
    position: "relative",
    overflow: "hidden",
  },
}))(Box);

export default InsurancePage;
