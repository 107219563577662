import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  makeStyles,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  withStyles,
} from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import { useDispatch, useSelector } from "react-redux";
import { LoadingService } from "@common/services/LoadingService";
import {
  MatchingIdentificationAPIRequest,
  MatchingAssignmentAPIRequest,
} from "red-electric/utils/Api.interface";
import GlobalConfig from "@common/data/GlobalConfig";
import Header from "red-electric/components/header";
import {
  matchingIdentification,
  selectMatchingCustomer,
  selectMatchingOrder,
  selectMatchingAccessories,
  selectMatchingMIds,
} from "red-electric/app/orderMatching";
import {
  selectIsLoading,
  selectUIErrors,
  selectUISuccesses,
} from "red-electric/app/UISlice";
import RegistrationForm from "red-electric/components/registrationForm";
import { OrderMatchingCard } from "red-electric/components/orderCard";
import LoginForm from "red-electric/components/loginForm";
import OrderMatchingForm from "red-electric/components/orderMatchingForm";
import {
  UserLoginAPIRequest,
  orderMatchingAPIRequest,
} from "red-electric/utils/Api.interface";
import { userLogin, userCreateAccount } from "red-electric/app/userDataSlice";
import { addMatchingOrder } from "red-electric/app/orderMatching";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    paddingTop: "10rem",
    paddingBottom: "8rem",
  },
}));

export const MatchingPage = () => {
  const trans = GlobalConfig?.appData.blocks.matchingPage;

  const classes = useStyles();
  const dispatch = useDispatch();
  const UIErrors = useSelector(selectUIErrors);
  const UISuccesses = useSelector(selectUISuccesses);
  const UILoading = useSelector(selectIsLoading);
  const matchingCustomer = useSelector(selectMatchingCustomer);
  const matchingOrder = useSelector(selectMatchingOrder);
  const matchingAccessories = useSelector(selectMatchingAccessories);
  const matchingMIds = useSelector(selectMatchingMIds);

  const [loaded, setLoaded] = useState(false);
  const [hasAccount, setHasAccount] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [autoLoginEmail, setAutoLoginEmail] = useState("");
  const [autoLoginPassword, setAutoLoginPassword] = useState("");

  useEffect(() => {
    LoadingService?.instance?.on("loadingDone", () => setLoaded(true));
  }, []);

  const handleSubmitIdentification = (data) => {
    const payload: MatchingIdentificationAPIRequest = {
      email: data.email,
      phone: data.phone,
      orderNumber: data.orderNumber,
    };
    dispatch(matchingIdentification(payload));
  };

  useEffect(() => {
    if (UISuccesses.userCreateAccount) {
      const loginPayload: UserLoginAPIRequest = {
        loginName: autoLoginEmail,
        password: autoLoginPassword,
      };
      dispatch(userLogin(loginPayload));
    }
  }, [UISuccesses.userCreateAccount]);

  useEffect(() => {
    if (UISuccesses.userLogin && UISuccesses.matchingIdentification) {
      const orderPayload: orderMatchingAPIRequest = {
        order: matchingOrder,
        accessories: matchingAccessories,
        orderMIds: matchingMIds,
      };
      dispatch(addMatchingOrder(orderPayload));
    }
  }, [UISuccesses.userLogin]);

  useEffect(() => {
    if (UIErrors.userCreateAccount) {
      setAutoLoginEmail("");
      setAutoLoginPassword("");
    }
  }, [UIErrors.userCreateAccount]);

  const handleRegistration = (payload) => {
    setAutoLoginEmail(payload.email);
    setAutoLoginPassword(payload.password);
    dispatch(userCreateAccount(payload));
    let orderMIDs = [];
    const payload2: MatchingAssignmentAPIRequest = {
      email: payload.email,
      phone: payload.telephone,
      orderIds: orderMIDs,
    };
  };

  const renderIdentificationSection = () => {
    return (
      <Box>
        <Box mb={4}>
          <Typography>{trans.subtitle}</Typography>
        </Box>
        <OrderMatchingForm
          handleSubmit={handleSubmitIdentification}
          isApiLoading={UILoading.matchingIdentification}
          submitLabel={trans.submitButton}
          serverResponse={
            UIErrors.matchingIdentification && (
              <Typography color="error">
                {UIErrors.matchingIdentification.errorMsg}
              </Typography>
            )
          }
        />
      </Box>
    );
  };

  const renderRegistrationSection = () => {
    return (
      <React.Fragment>
        <Box mb={2}>
          <OrderMatchingCard
            productName={matchingOrder.productName}
            orderNo={matchingOrder.orderNo}
            productColour={matchingOrder.productColour}
            status={matchingOrder.status}
            createdTime={matchingOrder.createdTime}
          />
        </Box>
        <Box mb={6} textAlign="center">
          <Typography
            component="u"
            variant="body2"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowHelpModal(true);
            }}
          >
            {trans.notYourOrderButton}
          </Typography>
        </Box>
        <Box mb={3} textAlign="center">
          <Typography variant="h2">{trans.yourNewAccountTitle}</Typography>
        </Box>
        <Box mb={4}>
          <Typography>{trans.yourNewAccountDescription}</Typography>
          <Typography>
            {hasAccount ? trans.noAccountButton : trans.hasAnAccountButton}{" "}
            <Button onClick={() => setHasAccount(!hasAccount)}>
              {hasAccount ? trans.registerButton : trans.loginButton}
            </Button>
          </Typography>
        </Box>
        {hasAccount ? (
          <LoginForm
            isApiLoading={false}
            submitLabel={trans.submitButton}
            handleSubmit={() => {}}
            serverResponse={null}
          />
        ) : (
          <RegistrationForm
            isUpdatingProfile={false}
            isApiLoading={UILoading.userCreateAccount}
            submitLabel={trans.submitButton}
            handleSubmit={handleRegistration}
            serverResponse={
              UIErrors.userCreateAccount && (
                <Typography color="error">
                  {UIErrors.userCreateAccount.errorMsg}
                </Typography>
              )
            }
            initialData={{
              customerFirstName: matchingCustomer.firstName,
              customerLastName: matchingCustomer.lastName,
              email: matchingCustomer.email,
              address: matchingCustomer.address,
              postcode: matchingCustomer.postalCode,
              cityName: matchingCustomer.city,
              countryName: matchingCustomer.country,
              telephone: matchingCustomer.phone,
            }}
          />
        )}
        <Dialog
          fullWidth
          maxWidth="sm"
          open={showHelpModal}
          onClose={() => setShowHelpModal(false)}
        >
          <DialogTitle id="alert-dialog-title">
            {trans.helpModalTitle}
          </DialogTitle>
          <DialogContent>
            <Typography>{trans.helpModalDescription}</Typography>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <StyledAvatar>
                    <PhoneIcon />
                  </StyledAvatar>
                </ListItemAvatar>
                <ListItemText
                  primary={trans.helpModalPhoneLabel1}
                  secondary={trans.helpModalPhoneLabel2}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <StyledAvatar>
                    <EmailIcon />
                  </StyledAvatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      color="textPrimary"
                      component="a"
                      href={`mailto:${trans.helpModalEmailLabel}`}
                      target="_blank"
                    >
                      {trans.helpModalEmailLabel}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowHelpModal(false)} autoFocus>
              {trans.helpModalClose}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Box mb={4}>
          <Typography variant="h1" align="center">
            {trans.title}
          </Typography>
        </Box>
        {UISuccesses.matchingIdentification
          ? renderRegistrationSection()
          : renderIdentificationSection()}
      </Container>
    </div>
  );
};

const StyledAvatar = withStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    borderColor: theme.palette.divider,
    borderWidth: 1,
    borderStyle: "solid",
  },
}))(Avatar);
