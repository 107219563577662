import css from "./HorizontalScrollBlock.module.less";
import React, { useEffect, useRef, useState, Fragment } from "react";
import { merge } from "@common/lib/helpers/classNameHelper";
import { gsap, Quad, TweenMax, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import GlobalConfig from "../../../common/data/GlobalConfig";
import { useScroll } from "../../hooks/useScroll";
import { EBreakpoints } from "@common/atoms/EBreakpoints";
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

interface IProps {
  classNames?: string[];
}

const componentName = "HorizontalScrollBlock";
const debug = require("debug")(`front:${componentName}`);

/**
 * @name HorizontalScrollBlock
 */
function HorizontalScrollBlock(props: IProps) {
  // --------------------------------------------------------------------------- PREPARE

  const rootRef = useRef<HTMLDivElement>(null);
  const leftImgRef = useRef<HTMLImageElement>(null);
  const scrollData = useScroll();

  const isMobile = window.innerWidth < EBreakpoints.TABLET;

  const [scrollMagic, setScrollMagic] = useState({
    controller: new ScrollMagic.Controller(),
    timelineOne: gsap.timeline(),
  });

  // -- Data
  const trans = GlobalConfig?.appData?.blocks?.horizontalScrollBlock;

  const { controller, timelineOne } = scrollMagic;

  let scene = useRef<ScrollMagic.Scene>();

  useEffect(() => {
    //   console.log(scrollData);
  }, [scrollData]);

  useEffect(() => {
    timelineOne.to("#HorizontalScroll_layerContainer", 10, {
      x: "-100%",
      ease: Quad.easeInOut,
    });

    scene.current = new ScrollMagic.Scene({
      triggerElement: "#HorizontalScroll",
      duration: isMobile ? 500 : 2000,
    })
      .triggerHook("onEnter")
      .setPin("#HorizontalScroll", true)
      .setTween(timelineOne)
      .addTo(controller);
  }, []);

  // --------------------------------------------------------------------------- RENDER

  return (
    <div
      id={"HorizontalScroll"}
      ref={rootRef}
      className={merge([css.Root, props.classNames])}
    >
      <div id="HorizontalScroll_layerContainer" className={css.layerContainer}>
        <div
          id="HorizontalScroll_layer-left"
          className={merge([css.layer, css.layer_left])}
        >
          {!isMobile && (
            <Fragment>
              <img
                ref={leftImgRef}
                className={css.background}
                src={require("./left.jpg").default}
                onLoad={() => {
                  scene.current.offset(
                    //  0
                    rootRef.current.getBoundingClientRect().height
                  );
                }}
              />
              <div className={css.textContainer}>
                <h2 className={css.title}>{trans.title}</h2>
                <p className={css.subtitle}>{trans.leftLayer.subtitle}</p>
                <p
                  className={css.text}
                  dangerouslySetInnerHTML={{ __html: trans.leftLayer.text }}
                />
              </div>
            </Fragment>
          )}

          {isMobile && (
            <Fragment>
              <img
                ref={leftImgRef}
                className={merge([css.background, css.background_mobile])}
                src={require("./left-mobile.jpg").default}
                onLoad={() => {
                  scene.current.offset(
                    rootRef.current.getBoundingClientRect().height
                  );
                }}
              />
              <div
                className={merge([css.textContainer, css.textContainer_mobile])}
              >
                <h2 className={css.title}>{trans.title}</h2>
                <p className={css.subtitle}>{trans.leftLayer.subtitle}</p>
                <p
                  className={css.text}
                  dangerouslySetInnerHTML={{ __html: trans.leftLayer.text }}
                />
              </div>
            </Fragment>
          )}
        </div>
        <div
          id="HorizontalScroll_layer-right"
          className={merge([css.layer, css.layer_right])}
        >
          {!isMobile && (
            <Fragment>
              <img
                className={css.background}
                src={require("./right.jpg").default}
              />
              <div className={css.textContainer}>
                <p className={css.subtitle}>{trans.rightLayer.subtitle}</p>
                <p className={css.text}>{trans.rightLayer.text}</p>
              </div>
            </Fragment>
          )}

          {isMobile && (
            <Fragment>
              <img
                ref={leftImgRef}
                className={merge([css.background, css.background_mobile])}
                src={require("./right-mobile.jpg").default}
              />
              <div
                className={merge([css.textContainer, css.textContainer_mobile])}
              >
                <h2 className={css.title}>{trans.title}</h2>
                <p className={css.subtitle}>{trans.rightLayer.subtitle}</p>
                <p className={css.text}>{trans.rightLayer.text}</p>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export default HorizontalScrollBlock;
