import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    card: {
      background: "white",
      overflow: "visible",
      borderRadius: "1rem",
      padding: "1rem",
      margin: "1rem",
      position: "relative",
      "&:hover": {
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
    name_wrapper: {
      [theme.breakpoints.down("sm")]: {
        padding: 0,
        justifyContent: "center",
      },
    },
    image_wrapper: {
      height: "15rem",
      width: "15rem",
      margin: "auto",
    },
    payment: {
      background: theme.palette.success.main,
      color: "white",
      fontWeight: 900,
      textTransform: "uppercase",
      position: "absolute",
      right: "2rem",
      top: "2rem",
    },
    name: {
      margin: 0,
    },
    image: {
      width: "100%",
      height: "100%",
    },
    order_no: {
      position: "absolute",
      right: "1.5rem",
      top: "1.5rem",
      fontSize: "1rem",
    },
    invoice: {
      [theme.breakpoints.down("sm")]: {},
    },
    invoice_line: {},
    invoice_header: {
      "&$invoice_line p": {
        color: theme.palette.text.hint,
        fontFamily: '"Montserrat-bold", sans-serif',
        textTransform: "uppercase",
        fontWeight: "500",
        fontSize: "1rem",
      },
    },
    invoice_total: {
      padding: "1rem 0",
      borderTop: "solid 2px rgba(0,0,0,0.1)",
      "&$invoice_line .MuiTypography-body1": {
        fontSize: "1.8rem",
      },
    },
    invoice_subtotal: {
      padding: "0.5rem 0",
      borderTop: "solid 1px rgba(0,0,0,0.1)",
      "&$invoice_line .MuiTypography-body1": {
        fontSize: "1.5rem",
      },
    },
    date: {
      fontWeight: 500,
      color: theme.palette.grey[800],
      fontSize: "1.2rem",
      textTransform: "uppercase",
    },
    color_circle: {
      borderRadius: "100%",
      border: "solid 1px rgba(0,0,0,0.2)",
      height: "2.5rem",
      width: "2.5rem",
    },
    accessory: {},
    mainCardContent: {
      flex: 1,
    },
    mainCardText: {
      marginBottom: 0,
    },
    imageSmall: {
      width: 140,
      height: 140,
      margin: "0 auto",
      flexShrink: 0,
      [`${theme.breakpoints.up("sm")}`]: {
        margin: 0,
      },
    },
    status: {
      fontWeight: 600,
    },
  })
);
