import React, { useLayoutEffect, useRef, useState } from "react";

/**
 * TODO DOCUMENTER
 * return {number}
 */
export function useMouse(): { x: number; y: number } {
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });

  useLayoutEffect(() => {
    const mouseMove = (e = null) => {
      setMousePos({ x: e.clientX, y: e.clientY });
    };

    // listen touch event

    document.addEventListener("mousemove", mouseMove);
    return () => {
      // stop to listen touch event
      document.removeEventListener("mousemove", mouseMove);
    };
  }, []);

  return mousePos;
}
