import axios from "axios";
import { UIError } from "red-electric/app/UISlice.interface";
import { encrypt } from "./Encryption";
import { setLocalToken, getLocalToken, clearLocalToken } from "./LocalToken";
import { generateRequestId } from "./RequestId";
import commonRequestHeaders from "./Api.headers";
import {
  UserLoginAPIRequest,
  UserCreateAccountAPIRequest,
  UserChangePasswordAPIRequest,
  UserForgotPasswordAPIRequest,
  UserSaveProfileAPIRequest,
  ContactFormAPIRequest,
  NewsletterFormAPIRequest,
  MatchingIdentificationAPIRequest,
  MatchingAssignmentAPIRequest,
  UserResetPasswordAPIRequest,
  orderMatchingAPIRequest,
} from "./Api.interface";

const apiHttpInstance = axios.create({
  baseURL: process.env.APP_API_BASE,
  timeout: 1000 * 30,
});

apiHttpInstance.interceptors.request.use((config) => {
  const CHP_str = config.headers.CHP ?? JSON.stringify(commonRequestHeaders);
  if (config.headers.CHP && config.headers.CHP.requestId) {
    config.headers.CHP = JSON.stringify({
      ...commonRequestHeaders,
      requestId: config.headers.CHP.requestId,
      token: getLocalToken(),
    });
  } else {
    config.headers.CHP = JSON.stringify({
      ...commonRequestHeaders,
      token: getLocalToken(),
    });
  }
  return config;
}, Promise.reject);

apiHttpInstance.interceptors.response.use(
  (response) => {
    if (!response.data.success) {
      if (response.data.errorCode === "W00110001") {
      }
      const error: UIError = {
        errorMsg: response.data.errorMsg,
        errorCode: response.data.errorCode,
      };
      throw error;
    }
    return response.data.result ? response.data.result : response.data;
  },
  (error: any): Partial<UIError> => {
    if (typeof error === "string") throw { errorMsg: error };
    if (error.message) throw { errorMsg: error.message };
    throw error;
  }
);

export function contactFormAPI(formContent: ContactFormAPIRequest) {
  const url = "/ses/t/website/w/api/home/page/contactUsSave";
  const formData = new FormData();
  formData.set("firstName", formContent.firstName);
  formData.set("lastName", formContent.lastName);
  formData.set("email", encrypt(formContent.email));
  formData.set("message", formContent.message);
  const dataTosend = {
    firstName: formContent.firstName,
    lastName: formContent.lastName,
    email: formContent.email,
    message: formContent.message,
  };
  return apiHttpInstance.post(url, dataTosend);
}

export function newsletterSubscriptionAPI(
  formContent: NewsletterFormAPIRequest
) {
  const url = "/ses/t/website/w/api/home/page/emailSubscribe";
  const formData = new FormData();
  formData.set("email", formContent.email);
  return apiHttpInstance.post(url, formData);
}

export function userLoginAPI(payload: UserLoginAPIRequest) {
  const url = "/ses/t/website/w/api/token/auth/login";
  const formData = new FormData();
  formData.set("loginName", encrypt(payload.loginName));
  formData.set("password", encrypt(payload.password));
  // formData.set("code", "");
  return apiHttpInstance.post(url, formData).then((data) => {
    setLocalToken(data.token);
    return data;
  });
}

export function userLogoutAPI() {
  const url = "/ses/t/website/w/api/token/auth/logout";
  return apiHttpInstance.post(url).then(clearLocalToken).catch(clearLocalToken);
}

export function userCreateAccountAPI(payload: UserCreateAccountAPIRequest) {
  const url = "/ses/t/website/w/api/customer/add";
  const formData = new FormData();
  formData.set("email", encrypt(payload.email));
  formData.set("password", encrypt(payload.password));
  formData.set("customerFirstName", payload.firstName);
  formData.set("customerLastName", payload.lastName);
  formData.set("address", payload.address);
  formData.set("cityName", payload.city);
  formData.set("countryName", payload.country);
  formData.set("postcode", payload.district);
  formData.set("countryCode", "+33");
  formData.set("telephone", encrypt(payload.phone));
  formData.set("cfmPassword", encrypt(payload.password));
  return apiHttpInstance.post(url, formData);
}

export function userChangePasswordAPI(payload: UserChangePasswordAPIRequest) {
  const url = "/ses/t/website/w/api/token/auth/resetPassword";
  const formData = new FormData();
  formData.set("oldPassword", encrypt(payload.oldPassword));
  formData.set("newPassword", encrypt(payload.newPassword));
  formData.set("cfmPassword", encrypt(payload.confirmPassword));
  return apiHttpInstance.post(url, formData);
}

export function userResetPasswordAPI(payload: UserResetPasswordAPIRequest) {
  const url = "/ses/t/website/w/api/token/auth/setPassword";
  const formData = new FormData();
  formData.set("newPassword", encrypt(payload.newPassword));
  formData.set("cfmPassword", encrypt(payload.cfmPassword));

  // apiHttpInstance.defaults.headers.requestId = payload.requestId;
  return apiHttpInstance.post(url, formData, {
    headers: {
      CHP: {
        requestId: payload.requestId,
      },
    },
  });
}

export function userFetchOrderAPI(orderId) {
  const url = "/ses/t/ros/w/api/website/orderFormInfo";
  const formData = new FormData();
  formData.set("id", orderId);
  return apiHttpInstance.post(url, formData);
}

export function userForgotPasswordAPI(payload: UserForgotPasswordAPIRequest) {
  const url = "/ses/t/website/w/api/token/auth/forgetPassword";
  const formData = new FormData();
  formData.set("mail", payload.email);
  return apiHttpInstance.post(url, formData);
}

export function userFetchProfileAPI() {
  const url = "/ses/t/website/w/api/customer/details";
  return apiHttpInstance.post(url);
}

export function userFetchOrdersAPI() {
  const url = "/ses/t/website/w/api/order/list";
  return apiHttpInstance.get(url).then((data) => {
    data.map((el) => ({
      ...el,
      orderNumber: data.orderNo,
    }));
    return data;
  });
}

export function userSaveProfileAPI(payload: UserSaveProfileAPIRequest) {
  const url = "/ses/t/website/w/api/customer/edit";
  const formData = new FormData();
  // formData.set("firstName", payload.firstName);
  // formData.set("lastName", payload.lastName);
  // formData.set("address", payload.address);
  // formData.set("district", payload.district);
  // formData.set("cityName", payload.city);
  // formData.set("customerCountry", payload.country);
  // formData.set("countryId", "1000000");
  // formData.set("telephone", payload.phone);
  const dataTosend = {
    customerFirstName: payload.firstName,
    customerLastName: payload.lastName,
    // address: payload.address,
    cityName: "ete",
    // countryCode: "+33",
    countryName: payload.country,
    postcode: payload.district,
    telephone: payload.phone,
    id: payload.id,
  };
  return apiHttpInstance.post(url, dataTosend);
}

export function matchingIdentificationAPI(
  payload: MatchingIdentificationAPIRequest
) {
  const url = "/orders/v1/myorder/retrieve";
  const formData = new URLSearchParams();
  formData.set("orderNumber", payload.orderNumber);
  if (payload.email) formData.set("email", payload.email);
  if (payload.phone) formData.set("phone", payload.phone);
  return apiHttpInstance.get(url, { params: formData });
}

export function matchingIdentificationAPIPost(
  payload: MatchingIdentificationAPIRequest
) {
  const url = "/orders/v1/myorder/retrieve";
  const formData = new FormData();
  formData.set("orderNumber", payload.orderNumber);
  if (payload.email) formData.set("email", payload.email);
  if (payload.phone) formData.set("phone", payload.phone);

  return apiHttpInstance.post(url, formData);
}

export function matchingAssignmentAPI(
  payload: MatchingAssignmentAPIRequest
) {
  const url = "/orders/v1/myorder/assign";
  const formData = new FormData();
  if (payload.email) formData.set("email", payload.email);
  if (payload.phone) formData.set("phone", payload.phone);
  //if (payload.orderIds) formData.set("orderIds", payload.orderIds)
  return apiHttpInstance.post(url, payload);
}

export function addOrderAPI(
  payload: orderMatchingAPIRequest
) {
  const url = "/ses/t/website/w/api/order/add";

  //if (payload.orderIds) formData.set("orderIds", payload.orderIds)
  return apiHttpInstance.post(url, payload.order);
}

export function addOrderAccessoriesAPI(
  payload: orderMatchingAPIRequest
) {
  const url = "/ses/t/website/w/api/order/addOrderParts";
  let postData = {
    orderId: payload.orderId,
    partslist: payload.accessories,
  }
  //if (payload.orderIds) formData.set("orderIds", payload.orderIds)
  return apiHttpInstance.post(url, postData);
}

export function assignOrderItemAPI(
  payload: string
) {
  const url = "/orders/v1/myorder/assignOrders";
  const formData = new URLSearchParams();
  if (payload) formData.set("orderMId", payload);
  //if (payload.orderIds) formData.set("orderIds", payload.orderIds)
  return apiHttpInstance.get(url, { params: formData });
}
