import { createMuiTheme, ThemeOptions } from "@material-ui/core/styles";
import { Palette } from "@material-ui/icons";

// Blue color
export const primaryColor = "#3bb4fa";
// "Black" classic text
export const primaryColorText = "#12121e";
// Pink color
export const secondaryColor = "#fb055a";
export const secondaryColorDark = "#fb055a";
export const secondaryColorLight = "#fb055a";
// Blue text
export const secondaryColorText = "#3bb4fa";
export const errorColor = "#FF0000";
export const successColor = "#4caf50";
// Grey text
export const hintColorText = "#8798a0";
export const whiteColorText = "#ffffff";

// To be able to use breakpoints helper
const theme = createMuiTheme();

export const themeObject: ThemeOptions = {
  palette: {
    common: {
      black: "#12121e",
      white: whiteColorText,
    },
    text: {
      primary: primaryColorText,
      secondary: secondaryColorText,
      hint: hintColorText,
    },
    primary: {
      dark: "#1b1d31",
      main: primaryColor,
      contrastText: whiteColorText,
    },
    secondary: {
      light: secondaryColorLight,
      main: secondaryColor,
      dark: secondaryColorDark,
      contrastText: whiteColorText,
    },
    error: { main: errorColor },
    success: { main: successColor },
    background: {
      // App background
      paper: "#f2f2f2",
      default: "#1b1e31",
    },
  },
  typography: {
    fontFamily: '"Montserrat-regular", sans-serif',
    h1: {
      fontSize: "4rem",
      marginBottom: "2rem",
    },
    h2: {
      fontSize: "2.8rem",
      marginBottom: "1.5rem",
    },
    h3: {
      fontSize: "2.2rem",
      marginBottom: "1.5rem",
    },
    h4: {
      fontSize: "1.5rem",
      marginBottom: "1.5rem",
      fontFamily: '"Montserrat-bold", sans-serif',
    },
    h5: {
      fontWeight: "bold",
      fontSize: "1.5rem",
      fontFamily: '"Montserrat-bold", sans-serif',
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.9375rem",
      },
    },
    h6: {
      fontSize: "1.5rem",
      marginBottom: "0.5rem",
      fontFamily: '"Montserrat-bold", sans-serif',
    },
    subtitle1: {
      fontWeight: "normal",
      fontSize: "2.6rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
    subtitle2: {
      fontWeight: "normal",
      fontSize: "0.875rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.75rem",
      },
    },
    body1: {
      fontWeight: "normal",
      fontSize: "1.5rem",
      // this affects all components
      // marginBottom: "1.5rem",
      lineHeight: "2.6rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.4rem",
        lineHeight: "2.2rem",
      },
    },
    body2: {
      fontWeight: "normal",
      fontSize: "1.2rem",
      lineHeight: 1.6,
    },
    caption: {
      fontWeight: "normal",
      letterSpacing: "0.3rem",
      textTransform: "uppercase",
      fontSize: "0.75rem",
      color: hintColorText,
    },
    button: {
      fontSize: "1.4rem",
      textTransform: "none",
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        background: "transparent",
        boxShadow: "none",
      },
    },
    MuiAvatar: {
      root: {
        border: "solid 1px rgba(255,255,255,0.4) !important",
        height: "4rem",
        width: "4rem",
        color: "white !important",
        fontWeight: 900,
        background: primaryColor + " !important",
      },
    },
    MuiListItem: {
      root: {
        paddingRight: "8rem",
      },
      secondaryAction: {
        paddingRight: "8rem",
      },
    },
    MuiListItemText: {
      secondary: {
        color: hintColorText,
      },
    },
    MuiListSubheader: {
      root: {
        fontSize: "1.8rem",
        textTransform: "uppercase",
        fontWeight: 600,
        color: theme.palette.text.primary,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 25,
        lineHeight: 1.5,
      },
      label: {
        fontSize: "1.4rem",
        fontFamily: '"Montserrat-bold", sans-serif',
      },
      contained: {
        padding: "1.2rem 2.5rem",
        fontWeight: 500,
        color: primaryColor,
        "&$disabled": {
          color: hintColorText,
        },
      },
      containedPrimary: {
        fontWeight: 600,

        "&:hover": {
          color: "white",
          backgroundColor: secondaryColor,
        },
      },
      outlined: {
        padding: "1rem 2rem",
        fontWeight: 500,
        color: primaryColor,
        "&$disabled": {
          color: hintColorText,
        },
      },
    },

    MuiInputBase: {
      root: {
        marginBottom: 0,
      },
    },
    MuiOutlinedInput: {
      root: {
        background: "white",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#bdbdbd",
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: "1.2rem",
        textTransform: "none",
        letterSpacing: "0rem",
      },
    },
    // @ts-ignore
    MuiAlert: {
      root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      },
      icon: {
        marginBottom: 0,
      },
      outlinedSuccess: {
        border: 0,
      },
    },
    // @ts-ignore
    MuiAlertTitle: {
      root: {
        // marginBottom: "2rem",
        // fontSize: "2rem",
        // fontWeight: 600,
      },
    },
  },
};

const compiledTheme = createMuiTheme(themeObject);

export default compiledTheme;
