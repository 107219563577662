import { LocaleStruct } from "../LocaleStruct";

export class fr extends LocaleStruct {
  static metas = {
    title:
      "RED Electric - Découvrez la perfor" +
      "" +
      "mance de nos scooters électriques",
    description:
      "Avec Model  E, RED Electric développe la première gamme de scooters électriques sans compromis : des machines technologiques, qui allient puissance, autonomie et sécurité. Découvrez la performance de l’électrique.",
  };
  static layout = {
    header: {
      models: {
        label: "Nos Model E",
        link: "/",
      },
      technology: {
        label: "Technologie",
        link: "/technology",
      },
      chooseElectric: {
        label: "Choisir l'électrique",
        link: "/choose-electric",
      },
      finance: {
        label: "Financement",
        link: "/finance",
      },
      about: {
        label: "A propos",
        link: "/about-us",
      },
      faq: {
        label: "FAQ",
        link: "/faq",
      },
      account: {
        label: "Mon compte",
        link: process.env.MENU_LOGIN,
      },
      order_tracking: {
        label: "Suivi Commande",
        link: "/login",
      },
      configure: {
        label: "Commander",
        link: process.env.MENU_CREATE_ORDER,
      },
      trial: {
        label: "Réserver un essai",
        link: process.env.BOOK_DEMO,
      },
      preorder: {
        label: "Commander",
        link: process.env.MENU_CREATE_ORDER,
        content:
          "Payez 590€ tout de suite pour réserver votre Model E. Livraison d'une valeur de 199€ offerte !",
      },
      insurance: {
        label: "Assurance",
        link: "/insurance",
      },
      subHeader: {
        modelERange: {
          label: "Gamme Model E",
          link: "section1",
        },
        design: {
          label: "Design",
          link: "section2",
        },
        motorization: {
          label: "Motorisation",
          link: "section2bis",
        },
        onBoardIntelligence: {
          label: "Intelligence embarquée",
          link: "section3",
        },
        autonomy: {
          label: "Autonomie",
          link: "section3bis",
        },
        recharge: {
          label: "Recharge",
          link: "section4",
        },
        technicalCar: {
          label: "Caractéristiques",
          link: "section7",
        },
        pressTalk: {
          label: "La presse en parle",
          link: "section9",
        },
      },
    },
    legalPopup: {
      title: "Mentions légales",
      content:
        "Red-E-Group, société par actions simplifiée immatriculée au RCS de Nancy sous le numéro 812 743 847 dont le siège social est situé au 136 rue de la Côte 54000 Nancy. <br/>Adresse de contact :<a>support@redelectric.fr </a><br/>" +
        "<br/>L'accès et l'utilisation du site https://www.redelectric.fr sont soumis aux présentes « mentions légales » détaillées ci-après ainsi qu'aux lois et/ou règlements applicables.<br/>" +
        "<br/>La connexion, l'utilisation et l'accès à ce site impliquent l'acceptation intégrale et sans réserve de l'internaute de toutes les dispositions des présentes mentions légales :La charte graphique du site (illustrations, typographie, emplacements, dimensions des éléments, etc.), est soumise aux droits d'auteur et protégée par la loi européenne. Les textes proposés sont la propriété de leurs auteurs. Les images et illustrations utilisées sont réalisées par Red-E-Group." +
        "<br/>Il est interdit de reproduire, représenter, transférer, distribuer, ou d'enregistrer tout ou partie de ces éléments, sous quelque forme que ce soit, sans mentionner RedE Group en tant que source de l'élément, dans la présentation de votre contenu et dans la taille originale de la source.<br/>" +
        "<br/> Hébergement des sites de Red-E-Group : Amazon Web Service - Paris <br/>" +
        "Responsable de la publication : Bertrand De La Tour d’Auvergne<br/>" +
        "<br/><strong>Données personnelles</strong><br/>" +
        "<br/>Les données personnelles collectées par redelectric.fr sont uniquement destinées à un usage interne. En aucun cas ces données ne seront communiquées ou vendues à des tiers. Conformément à la législation française, vous disposez d'un droit d'accès, de modification, de rectification et de suppression des données qui vous concernent.<br/>" +
        "<br/><strong>Liens</strong><br/><br/>Les sites reliés directement ou indirectement à redelectric.fr ne sont pas sous son contrôle. En conséquence, Red-E-Group n'assume aucune responsabilité quant aux informations publiées sur ces sites. Les liens avec des sites extérieurs ne sont fournis qu'à titre de commodité et n'impliquent aucune caution quant à leur contenu.",
    },
    newsletterSuccessPopup: {
      title: "Inscription réussie",
      content: "Votre inscription a été effectuée avec succès",
    },
    newsletterFailPopup: {
      title: "Un problème est survenu lors de l'inscription",
      content: "Veuillez réessayer ultérieurement",
    },
    contactSuccessPopup: {
      title: "Envoi réussi",
      content: "Votre formulaire a été envoyé avec succès",
    },
    contactFailPopup: {
      title: "Un problème est survenu lors de l'envoi du formulaire",
      content: "Veuillez réessayer ultérieurement",
    },
    cgvPopup: {
      title: "Conditions Générales de Vente",
      content:
        "<p>&nbsp;</p>" +
        "<p>Derni&egrave;re mise &agrave; jour&nbsp;: 29 juillet 2020</p>" +
        "<p>&nbsp;</p>" +
        "<p><strong>RedE Group SAS</strong>, soci&eacute;t&eacute; par actions simplifi&eacute;e, au capital de 175.000 euros, immatricul&eacute;e au RCS de Nancy sous le num&eacute;ro 812&nbsp;743&nbsp;847 dont le si&egrave;ge social est situ&eacute; au 136 rue de la C&ocirc;te 54000 Nancy (ci-apr&egrave;s &laquo;&nbsp;RedE&nbsp;&raquo;), num&eacute;ro TVA intracommunautaire FR812743847, exploite le site web disponible &agrave; l&rsquo;adresse&nbsp;: <a href='https://www.redelectric.fr'>https://www.redelectric.fr</a>.</p>" +
        "<p>&nbsp;</p>" +
        "<p>Email: contact@redelectric.fr</p>" +
        "<p>Courrier : RedE Group SAS, 136 rue de la C&ocirc;te, 54000 Nancy</p>" +
        "<p>&nbsp;</p>" +
        "<h2><strong>Article 1 &ndash; D&eacute;finitions</strong></h2>" +
        "<p>&nbsp;</p>" +
        "<p><strong>Client</strong>&nbsp;: d&eacute;signe tout Utilisateur qui a achet&eacute; un Produit commercialis&eacute; sur le Site.</p>" +
        "<p>&nbsp;</p>" +
        "<p><strong>Contrat</strong>&nbsp;: d&eacute;signe les pr&eacute;sentes conditions g&eacute;n&eacute;rales de vente (&laquo;&nbsp;CGV&nbsp;&raquo;).</p>" +
        "<p>&nbsp;</p>" +
        "<p><strong>Donn&eacute;es personnelles</strong>&nbsp;: d&eacute;signe toute information se rapportant &agrave; une personne physique identifi&eacute;e ou identifiable, directement ou indirectement, notamment par r&eacute;f&eacute;rence &agrave; un identifiant, tel qu&#039un nom, un num&eacute;ro d&#039identification, des donn&eacute;es de localisation, un identifiant en ligne, ou &agrave; un ou plusieurs &eacute;l&eacute;ments sp&eacute;cifiques propres &agrave; son identit&eacute; physique, physiologique, g&eacute;n&eacute;tique, psychique, &eacute;conomique, culturelle ou sociale.</p>" +
        "<p>&nbsp;</p>" +
        "<p><strong>Fournisseur</strong>&nbsp;: d&eacute;signe le fabricant des Produits commercialis&eacute;s sur le Site.</p>" +
        "<p>&nbsp;</p>" +
        "<p><strong>Produits</strong>&nbsp;: d&eacute;signe les scooters &eacute;lectriques commercialis&eacute;s sur le Site.</p>" +
        "<p>&nbsp;</p>" +
        "<p><strong>Services</strong>&nbsp;: d&eacute;signe l&rsquo;op&eacute;ration de vente en ligne des Produits.</p>" +
        "<p>&nbsp;</p>" +
        "<p><strong>Site</strong>&nbsp;: d&eacute;signe le site disponible &agrave; l&rsquo;URL <a href='https://www.redelectric.fr'>https://www.redelectric.fr</a>.</p>" +
        "<p>&nbsp;</p>" +
        "<p><strong>Utilisateur</strong>&nbsp;: d&eacute;signe toute personne acc&eacute;dant au Site, qu&rsquo;elle proc&egrave;de ou non &agrave; l&rsquo;achat des Produits.</p>" +
        "<p>&nbsp;</p>" +
        "<h2><strong>Article 2 &ndash; Objet </strong></h2>" +
        "<p>&nbsp;</p>" +
        "<p>Les pr&eacute;sentes CGV ont pour objet de d&eacute;finir les termes et conditions dans lesquelles RedE fourni les Services aux Utilisateurs.</p>" +
        "<p>&nbsp;</p>" +
        "<h2><strong>Article 3 &ndash; Opposabilit&eacute; des CGV </strong></h2>" +
        "<p>&nbsp;</p>" +
        "<p>Les CGV sont directement consultables par l&rsquo;Utilisateur sur le Site et peuvent sur simple demande de sa part, lui &ecirc;tre communiqu&eacute;es par email ou par voie postale.</p>" +
        "<p>&nbsp;</p>" +
        "<p>L&rsquo;Utilisateur reconna&icirc;t que les pr&eacute;sentes CGV lui sont opposables et accepte sans r&eacute;serve ces derni&egrave;res qui pourront &ecirc;tre modifi&eacute;es &agrave; tout moment par RedE &agrave; sa seule discr&eacute;tion, &eacute;tant pr&eacute;cis&eacute; que seules les CGV en vigueur &agrave; la date de la commande telle que vis&eacute;e &agrave; l&rsquo;article 4 seront applicables.</p>" +
        "<p>&nbsp;</p>" +
        "<h2><strong>Article 4 &ndash; Produits et commandes </strong></h2>" +
        "<p>&nbsp;</p>" +
        "<p><strong>4.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Produits</strong></p>" +
        "<p>&nbsp;</p>" +
        "<p>Pr&eacute;alablement &agrave; la commande et ce, conform&eacute;ment &agrave; l&rsquo;article L111-1 et suivants du Code de la consommation, les Produits figurant dans le catalogue pr&eacute;sent&eacute; sur le Site, feront l&rsquo;objet d&rsquo;un descriptif permettant &agrave; l&rsquo;Utilisateur d&rsquo;identifier les caract&eacute;ristiques essentielles des Produits.</p>" +
        "<p>&nbsp;</p>" +
        "<p>Les Produits pr&eacute;sent&eacute;s sur le Site sont propos&eacute;s &agrave; la vente dans la limite des stocks disponibles, RedE se r&eacute;servant dans ce cadre, le droit de refuser toute commande.</p>" +
        "<p>&nbsp;</p>" +
        "<p><strong>4.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Commandes</strong></p>" +
        "<p>&nbsp;</p>" +
        "<p>Les commandes sont pass&eacute;es&nbsp;:</p>" +
        "<ul>" +
        "<li>via le Site&nbsp;; ou</li>" +
        "<li>par t&eacute;l&eacute;phone au&nbsp;: 01 44 01 53 00</li></ul>" +
        "<p>&nbsp;</p>" +
        "<p>Quel que soit le mode par lequel la commande a &eacute;t&eacute; pass&eacute;e, un r&eacute;capitulatif de la commande contenant notamment les informations relatives aux Produits, au paiement, et &agrave; la livraison, sera adress&eacute; &agrave; l&rsquo;Utilisateur par email (ou par voie postale), sous format PDF en vue de sa confirmation par l&rsquo;Utilisateur.</p>" +
        "<p>&nbsp;</p>" +
        "<p>Toute modification de commande apr&egrave;s confirmation est soumise &agrave; l&rsquo;acceptation de RedE.</p>" +
        "<p>Toute commande, initiale ou modifi&eacute;e, est valid&eacute;e par RedE sous r&eacute;serve des stocks disponibles, &eacute;tant pr&eacute;cis&eacute; que l&rsquo;insuffisance des informations relatives &agrave; la commande ou des quantit&eacute;s &eacute;lev&eacute;es de Produits objet de la commande constitue un motif l&eacute;gitime de refus de validation de la commande. Une quantit&eacute; de trois (3) &agrave; cinq (5) Produits pour une m&ecirc;me commande pass&eacute;e par un Utilisateur est r&eacute;put&eacute;e &ecirc;tre une quantit&eacute; anormalement &eacute;lev&eacute;e.</p>" +
        "<p>&nbsp;</p>" +
        "<h2><strong>Article 5 &ndash; Prix </strong></h2>" +
        "<p>&nbsp;</p>" +
        "<p>Le prix de vente des Produits est celui en vigueur au jour de la passation de la commande, et est indiqu&eacute; en euros, toutes taxes comprises (TVA et autres taxes applicables) et n&rsquo;inclut pas les frais de livraison, &eacute;tant pr&eacute;cis&eacute; que les frais, taxes, ou droits de douane &agrave; l&rsquo;importation qui seraient applicables en cas de commande pass&eacute;e depuis et livr&eacute;e dans un Etat autre que la France, restent &agrave; la charge exclusive de l&rsquo;Utilisateur.</p>" +
        "<p>&nbsp;</p>" +
        "<p><strong>5.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Exigibilit&eacute;</strong></p>" +
        "<p>&nbsp;</p>" +
        "<p>Le prix est exigible dans sa totalit&eacute; d&egrave;s confirmation de la commande par l&rsquo;Utilisateur qui doit proc&eacute;der au paiement de cette derni&egrave;re par carte bancaire via la plateforme s&eacute;curis&eacute;e mise &agrave; disposition sur le Site. Les d&eacute;bits du prix des Produits command&eacute;s sur le compte bancaire de l&rsquo;Utilisateur, n&rsquo;interviendront qu&rsquo;au moment de l&rsquo;exp&eacute;dition de la commande par RedE.</p>" +
        "<p>&nbsp;</p>" +
        "<p>Tout retard de paiement relatif &agrave; une commande sera productive d&rsquo;int&eacute;r&ecirc;ts au taux l&eacute;gal en vigueur, la livraison de toute nouvelle commande pourra, dans ce contexte, &ecirc;tre suspendue.</p>" +
        "<p><strong>&nbsp;</strong></p>" +
        "<p><strong>5.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Modification</strong></p>" +
        "<p>&nbsp;</p>" +
        "<p>RedE se r&eacute;serve le droit de modifier le Prix des Produits &agrave; tout moment.</p>" +
        "<h2><strong>Article 6 &ndash; Livraison </strong></h2>" +
        "<p>&nbsp;</p>" +
        "<p><strong>6.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Lieu de livraison</strong></p>" +
        "<p>&nbsp;</p>" +
        "<p>Toute commande de Produits sera livr&eacute;e &agrave; l&rsquo;adresse de livraison indiqu&eacute;e dans la commande et selon le mode de livraison choisi par l&rsquo;Utilisateur. Conform&eacute;ment aux dispositions des articles L216-1 et suivants ainsi que L111-1-3&deg;, L221-5, L221-6, L221-7 et L221-13 du Code de la consommation, les d&eacute;lais de livraison de la commande sont mentionn&eacute;s sur la page du Site descriptive des Produits et figurent &eacute;galement dans chaque courrier &eacute;lectronique de confirmation de commande.</p>" +
        "<p>&nbsp;</p>" +
        "<p>En cas d&#039absence du destinataire ou d&#039une personne pouvant prendre possession de la commande lors de la livraison, le transporteur d&eacute;posera un avis de passage &agrave; l&#039adresse de livraison indiqu&eacute;e, vous invitant &agrave; retirer les produits.</p>" +
        "<p>&nbsp;</p>" +
        "<p><strong>6.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Retard et d&eacute;faut de livraison</strong></p>" +
        "<p>&nbsp;</p>" +
        "<p>En application des articles L216-2 et suivants du Code de la consommation, en cas de manquement de RedE &agrave; son obligation de livraison &agrave; la date ou &agrave; l&#039expiration du d&eacute;lai fix&eacute; dans les conditions du premier alin&eacute;a de l&#039article L216-1 ou, &agrave; d&eacute;faut, au plus tard trente jours (30) apr&egrave;s la conclusion du Contrat, le Client peut r&eacute;soudre le contrat, par lettre recommand&eacute;e avec demande d&#039avis de r&eacute;ception ou par un &eacute;crit sur un autre support durable, si, apr&egrave;s avoir enjoint, selon les m&ecirc;mes modalit&eacute;s, RedE d&#039effectuer la livraison dans un d&eacute;lai suppl&eacute;mentaire raisonnable, cette derni&egrave;re ne s&#039est pas ex&eacute;cut&eacute;e dans ce d&eacute;lai.</p>" +
        "<p>&nbsp;</p>" +
        "<p>Le Contrat est consid&eacute;r&eacute; comme r&eacute;solu &agrave; la r&eacute;ception par RedE de la lettre ou de l&#039&eacute;crit l&#039informant de cette r&eacute;solution, &agrave; moins que ce dernier ne se soit ex&eacute;cut&eacute; entre-temps. N&eacute;anmoins, le Client pourra imm&eacute;diatement r&eacute;soudre le Contrat en cas de refus de RedE de proc&eacute;der &agrave; la livraison de la commande ou lorsque cette derni&egrave;re n&#039ex&eacute;cute pas son obligation de livrer &agrave; la date ou &agrave; l&#039expiration du d&eacute;lai convenu, constituant pour le Client une condition essentielle du Contrat en raison des circonstances qui entourent la conclusion du Contrat ou d&#039une demande expresse de sa part avant la conclusion du Contrat.</p>" +
        "<p>&nbsp;</p>" +
        "<p>En cas de r&eacute;solution du Contrat dans les conditions pr&eacute;vues &agrave; l&#039article L216-2 pr&eacute;cit&eacute;, RedE sera tenue de rembourser le Client de la totalit&eacute; des sommes vers&eacute;es, au plus tard dans les quatorze (14) jours suivant la date &agrave; laquelle le Contrat a &eacute;t&eacute; d&eacute;nonc&eacute;. Les sommes vers&eacute;es par le Client sont de plein droit major&eacute;es de 10% si le remboursement intervient au plus tard trente (30) jours au-del&agrave; de ce terme, de 20% jusqu&#039&agrave; soixante (60) jours et de 50% ult&eacute;rieurement.</p>" +
        "<p>&nbsp;</p>" +
        "<p><strong>6.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Transfert de propri&eacute;t&eacute; et des risques</strong></p>" +
        "<p>&nbsp;</p>" +
        "<p>La propri&eacute;t&eacute; des Produits command&eacute;s est transf&eacute;r&eacute;e au Client &agrave; la date de livraison indiqu&eacute;e dans la commande, sous r&eacute;serve du paiement int&eacute;gral du prix.</p>" +
        "<p>RedE se r&eacute;serve tout droit de propri&eacute;t&eacute; sur les Produits vendus jusqu&#039au paiement complet du prix de vente. Dans le cas o&ugrave; le paiement n&#039interviendrait pas dans le d&eacute;lai pr&eacute;vu par les Parties, RedE se r&eacute;serve le droit de reprendre les Produits livr&eacute;s et, si bon lui semble de r&eacute;silier le Contrat.</p>" +
        "<p>&nbsp;</p>" +
        "<p>Tous les risques li&eacute;s aux Produits command&eacute;s sont transf&eacute;r&eacute;s au Client ou &agrave; un tiers qu&rsquo;il a d&eacute;sign&eacute;, d&egrave;s prise de possession physique des Produits par ce dernier.</p>" +
        "<p>Dans le cas o&ugrave; le Client aura choisi le transporteur, les Produits objet de la commande seront livr&eacute;s &agrave; ses risques et p&eacute;rils.</p>" +
        "<p>&nbsp;</p>" +
        "<p>&nbsp;</p>" +
        "<h2><strong>Article 7 &ndash; Garanties</strong></h2>" +
        "<p>&nbsp;</p>" +
        "<p><strong>7.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Garanties de conformit&eacute;</strong></p>" +
        "<p>&nbsp;</p>" +
        "<p>Les Produits vendus par RedE b&eacute;n&eacute;ficient de la garantie l&eacute;gale de conformit&eacute; pr&eacute;vue aux articles L217-4 et suivants du Code de la consommation.</p>" +
        "<p>&nbsp;</p>" +
        "<p>Le Client qui souhaite mettre en &oelig;uvre la garantie l&eacute;gale de non-conformit&eacute;, doit adresser &nbsp;sa demande &agrave; RedE dont les coordonn&eacute;es figurent en t&ecirc;te des pr&eacute;sentes.</p>" +
        "<p>Dans le cadre de cette garantie, le Client&nbsp;:</p>" +
        "<ul>" +
        "<li>dispose d&#039un d&eacute;lai de deux (2) ans &agrave; compter de la d&eacute;livrance du Produit pour intenter une action&nbsp;;</li>" +
        "<li>dispose d&rsquo;une option, &agrave; savoir la r&eacute;paration du Produit ou son remplacement dans les conditions de l&#039article L217-9 du Code de la consommation</li>" +
        "<li>n&rsquo;est pas tenu de rapporter la preuve de l&#039existence du d&eacute;faut de conformit&eacute; du Produit dans les vingt-quatre (24) mois de la d&eacute;livrance du Produit.</li></ul>" +
        "<p>&nbsp;</p>" +
        "<p>La garantie l&eacute;gale de conformit&eacute; s&#039applique ind&eacute;pendamment de la garantie commerciale &eacute;ventuellement consentie par RedE.</p>" +
        "<p>&nbsp;</p>" +
        "<p><strong>7.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Garanties des vices cach&eacute;s</strong></p>" +
        "<p>&nbsp;</p>" +
        "<p>Les Produits vendus par RedE b&eacute;n&eacute;ficient de la garantie des vices cach&eacute;s pr&eacute;vue aux articles 1641 et suivants du Code civil. Dans le cas de la mise en &oelig;uvre de cette garantie, le Client peut choisir entre la r&eacute;solution de la vente ou une r&eacute;duction du prix de vente conform&eacute;ment &agrave; l&#039article 1644 du Code civil.</p>" +
        "<p>&nbsp;</p>" +
        "<p>&nbsp;</p>" +
        "<h2><strong>Article 8 &ndash; Responsabilit&eacute;</strong></h2>" +
        "<p>&nbsp;</p>" +
        "<p><strong>8.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Exclusions</strong></p>" +
        "<p>&nbsp;</p>" +
        "<p>La responsabilit&eacute; de RedE ne saurait &ecirc;tre engag&eacute;e en raison des dommages subis par le Client du fait d&rsquo;une utilisation non-conforme du Produit, notamment &agrave; la suite de toute modification du Produit proscrite et/ou non-valid&eacute;e par le Fournisseur ou tout tiers agr&eacute;&eacute; par ce dernier.</p>" +
        "<p>&nbsp;</p>" +
        "<p>RedE ne saurait &ecirc;tre tenue responsable de la qualit&eacute; informatique du Site qui est fourni &agrave; l&rsquo;Utilisateur en &laquo;&nbsp;l&rsquo;&eacute;tat&nbsp;&raquo;, sans aucune garantie d&rsquo;accessibilit&eacute;, ou de disponibilit&eacute; ininterrompue.</p>" +
        "<p>&nbsp;</p>" +
        "<p><strong>8.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Limitation</strong></p>" +
        "<p>&nbsp;</p>" +
        "<p>RedE ne pourra en aucun cas &ecirc;tre tenue de r&eacute;parer tout dommage immat&eacute;riel et/ou indirect (incluant la perte de chiffre d&rsquo;affaires, perte de client&egrave;le, le trouble commercial, etc.), seuls les dommages directs ouvrant droit &agrave; r&eacute;paration. Dans le cas o&ugrave; la responsabilit&eacute; de RedE serait engag&eacute;e en ex&eacute;cution des pr&eacute;sentes, cette derni&egrave;re ne saurait exc&eacute;der le montant de la commande pay&eacute;e par le Client.</p>" +
        "<p>&nbsp;</p>" +
        "<p>&nbsp;</p>" +
        "<h2><strong>Article 9 &ndash; Droit de r&eacute;tractation </strong></h2>" +
        "<p>&nbsp;</p>" +
        "<p>Les Produits sont soumis au droit de r&eacute;tractation &eacute;dict&eacute; par l&rsquo;article L221-18 du Code de la consommation.</p>" +
        "<p>&nbsp;</p>" +
        "<p>L&rsquo;Utilisateur est ainsi inform&eacute; qu&rsquo;il dispose du droit de se r&eacute;tracter sans motif, dans un d&eacute;lai de quatorze (14) jours. Le d&eacute;lai de r&eacute;tractation expire, au regard du Produit concern&eacute;&nbsp;:</p>" +
        "<p>&nbsp;</p>" +
        "<ul>" +
        "<li>quatorze (14) jours apr&egrave;s le jour o&ugrave; l&rsquo;Utilisateur, ou tout tiers d&eacute;sign&eacute; par ce dernier pour ce faire, &agrave; l&rsquo;exclusion du transporteur, prend physiquement possession du Produit.</li></ul>" +
        "<p>&nbsp;</p>" +
        "<p>Pour exercer le droit de r&eacute;tractation, l&rsquo;Utilisateur est tenu de notifier sa d&eacute;cision de r&eacute;tractation au moyen d&rsquo;une d&eacute;claration d&eacute;nu&eacute;e d&rsquo;ambig&uuml;it&eacute; (courrier, t&eacute;l&eacute;copie, courriel). Cette notification devra contenir le nom, l&rsquo;adresse g&eacute;ographique, et lorsque disponible, le num&eacute;ro de t&eacute;l&eacute;phone, de t&eacute;l&eacute;copieur et l&rsquo;adresse &eacute;lectronique de l&rsquo;Utilisateur.</p>" +
        "<p>&nbsp;</p>" +
        "<p>L&rsquo;Utilisateur, sans y &ecirc;tre oblig&eacute;, a la facult&eacute; d&rsquo;utiliser le formulaire de r&eacute;tractation disponible dans l&rsquo;encadr&eacute; ci-dessous. En cas d&rsquo;option pour le formulaire de r&eacute;tractation, nous vous enverrons sans d&eacute;lai un accus&eacute; de r&eacute;ception de la r&eacute;tractation sur un support durable (courriel par exemple).</p>" +
        "<p>&nbsp;</p>" +
        "<h2 style='text-align: center;'><strong>FORMULAIRE DE RETRACTATION</strong></h2>" +
        "<p>&nbsp;</p>" +
        "<p>A l&#039attention du Service Client de RedE</p>" +
        "<p>&nbsp;</p>" +
        "<p>RedE Group SAS, 189 rue d&rsquo;Aubervilliers, 75018 PARIS</p>" +
        "<p>&nbsp;</p>" +
        "<p>T&eacute;l&eacute;phone : 01 44 01 53 00</p>" +
        "<p>&nbsp;</p>" +
        "<p>contact@redelectric.fr</p>" +
        "<p>&nbsp;</p>" +
        "<p>Je vous notifie par la pr&eacute;sente ma r&eacute;tractation du contrat portant sur la vente ci-dessous :</p>" +
        "<p>&nbsp;</p>" +
        "<p>Command&eacute; le __ __ __</p>" +
        "<p>&nbsp;</p>" +
        "<p>Num&eacute;ro de commande : _________________________</p>" +
        "<p>&nbsp;</p>" +
        "<p>Nom du consommateur : _________________________</p>" +
        "<p>&nbsp;</p>" +
        "<p>Adresse du consommateur : __________________________________________________</p>" +
        "<p>&nbsp;</p>" +
        "<p>Signature du consommateur (Uniquement en cas de notification du pr&eacute;sent formulaire sur papier) : __________________</p>" +
        "<p>&nbsp;</p>" +
        "<p>Date :</p>" +
        "<p>&nbsp;</p>" +
        "<p>&nbsp;</p>" +
        "<p>En cas de r&eacute;tractation, RedE remboursera tous les paiements re&ccedil;us de l&rsquo;Utilisateur, y compris les frais de livraison sans retard injustifi&eacute; et au plus tard quatorze (14) jours &agrave; compter de la date de r&eacute;ception du Produit par RedE.</p>" +
        "<p>&nbsp;</p>" +
        "<p>Il sera proc&eacute;d&eacute; au remboursement en utilisant le moyen de paiement utilis&eacute; par l&rsquo;Utilisateur pour la transaction initiale, sauf s&rsquo;il en a &eacute;t&eacute; convenu autrement en les Parties.</p>" +
        "<p>&nbsp;</p>" +
        "<p>L&rsquo;Utilisateur sera tenu de renvoyer le Produit &agrave; l&rsquo;adresse suivante&nbsp;: RedE Group SAS, 189 rue d&rsquo;Aubervilliers, 75018 Paris, sans retard excessif et, en tout &eacute;tat de cause, au plus tard quatorze (14) jours apr&egrave;s communication par l&rsquo;Utilisateur de sa d&eacute;cision de r&eacute;tractation. Ce d&eacute;lai est r&eacute;put&eacute; respect&eacute; si le renvoi du Produit intervient avant l&rsquo;expiration du d&eacute;lai de quatorze (14) jours.</p>" +
        "<p>&nbsp;</p>" +
        "<p>L&rsquo;Utilisateur prendra en charge les frais de renvoi.</p>" +
        "<p>&nbsp;</p>" +
        "<p>RedE pourra engager la responsabilit&eacute; de l&rsquo;Utilisateur en cas d&eacute;t&eacute;rioration, dommage ou d&eacute;pr&eacute;ciation du Produit r&eacute;sultant d&rsquo;une utilisation et manipulation autres que celles n&eacute;cessaires pour &eacute;tablir ses caract&eacute;ristiques et son bon fonctionnement, ou de toute modification proscrite et/ou non-valid&eacute;e par le Fournisseur ou tout tiers agr&eacute;&eacute; par ce dernier.</p>" +
        "<p>&nbsp;</p>" +
        "<p>En application de l&rsquo;article L. 221-28 du Code de la consommation, l&rsquo;Utilisateur est inform&eacute; que le droit de r&eacute;tractation pr&eacute;vu au pr&eacute;sent article, ne peut &ecirc;tre exerc&eacute; notamment pour les contrats de fourniture de biens confectionn&eacute;s selon les sp&eacute;cifications de l&rsquo;Utilisateur ou nettement personnalis&eacute;s.</p>" +
        "<p>&nbsp;</p>" +
        "<h2><strong>Article 10 &ndash; Propri&eacute;t&eacute; intellectuelle et donn&eacute;es personnelles</strong></h2>" +
        "<p>&nbsp;</p>" +
        "<p><strong>10.1.&nbsp;&nbsp;&nbsp; Propri&eacute;t&eacute; intellectuelle</strong></p>" +
        "<p>&nbsp;</p>" +
        "<p>Tous les &eacute;l&eacute;ments du Site, logo, contenu, graphisme, ainsi que la marque &laquo;&nbsp;RedE&nbsp;&raquo; ainsi que ses d&eacute;riv&eacute;s, sans que cette liste ne soit limitative, sont et demeurent la propri&eacute;t&eacute; exclusive de la soci&eacute;t&eacute; RedE. Toute reproduction ou repr&eacute;sentation, totale ou partielle, du Site ou des &eacute;l&eacute;ments la composant, tels les marques, les logos, la charte graphique, l&rsquo;agencement, les informations, la pr&eacute;sentation et son contenu, sans que cette liste soit limitative, est proscrite. L&rsquo;inscription sur le Site n&rsquo;emporte aucun transfert de droits de propri&eacute;t&eacute; intellectuelle au profit de l&rsquo;Utilisateur qui ne se voit consentir qu&rsquo;un droit d&rsquo;acc&egrave;s et de consultation du Site uniquement, dans les conditions et modalit&eacute;s pr&eacute;vues aux pr&eacute;sentes CGV.</p>" +
        "<p>&nbsp;</p>" +
        "<p>La mise en place par l&rsquo;Utilisateur de tout lien (simple ou par hypertexte) vers le Site est strictement interdit sans un accord &eacute;crit expr&egrave;s de RedE.</p>" +
        "<p>&nbsp;</p>" +
        "<p><strong>10.2.&nbsp;&nbsp;&nbsp; Donn&eacute;es personnelles </strong></p>" +
        "<p>&nbsp;</p>" +
        "<p>RedE est particuli&egrave;rement soucieux de la vie priv&eacute;e des Utilisateurs et de ses Clients. Ainsi, dans le cadre du pr&eacute;sent Contrat, et de l&rsquo;utilisation du Site, les donn&eacute;es personnelles des Utilisateurs et Clients sont collect&eacute;es et trait&eacute;es conform&eacute;ment aux prescriptions du r&egrave;glement 2016/679 (RGPD).</p>" +
        "<p>&nbsp;</p>" +
        "<p>RedE repr&eacute;sent&eacute;e par Valentin Dillenschneider informe l&rsquo;Utilisateur et le Client qu&rsquo;elle est responsable du traitement.</p>" +
        "<p>&nbsp;</p>" +
        "<p>Les donn&eacute;es trait&eacute;es pourront &ecirc;tre transmises aux partenaires ou sous-traitants de RedE, notamment en mati&egrave;re de gestion de la relation client, ou du paiement du prix des Produits.</p>" +
        "<p>L&rsquo;Utilisateur pourra exercer son droit d&rsquo;acc&egrave;s, de rectification, d&rsquo;opposition, &agrave; la limitation du traitement, ainsi que son droit de portabilit&eacute; sur ses donn&eacute;es, son droit d&rsquo;introduire une r&eacute;clamation devant l&rsquo;autorit&eacute; de contr&ocirc;le ainsi que devant toute juridiction comp&eacute;tente dans le cas o&ugrave; il estimerait avoir que ses droits n&rsquo;auraient pas &eacute;t&eacute; respect&eacute;s.</p>" +
        "<p>&nbsp;</p>" +
        "<p>&nbsp;</p>" +
        "<p>&nbsp;</p>" +
        "<h2><strong>Article 11 &ndash; Divers </strong></h2>" +
        "<p>&nbsp;</p>" +
        "<p><strong>11.1.&nbsp;&nbsp;&nbsp; Divisibilit&eacute;</strong></p>" +
        "<p>&nbsp;</p>" +
        "<p>Dans le cas o&ugrave; une ou plusieurs des dispositions des pr&eacute;sentes CGV seraient nulles, ill&eacute;gales ou inapplicables d&rsquo;une mani&egrave;re quelconque au titre d&rsquo;une loi quelconque, la validit&eacute;, la l&eacute;galit&eacute; ou l&rsquo;applicabilit&eacute; des autres dispositions n&rsquo;en serait aucunement affect&eacute;e, sous r&eacute;serve que la ou lesdites disposition(s) n&rsquo;ai(en)t pas &eacute;t&eacute; d&eacute;terminante(s) de la conclusion du Contrat.</p>" +
        "<p>&nbsp;</p>" +
        "<p><strong>11.2.&nbsp;&nbsp;&nbsp; Non-renonciation</strong></p>" +
        "<p>&nbsp;</p>" +
        "<p>Le fait pour l&rsquo;une des Parties de ne pas se pr&eacute;valoir aupr&egrave;s de l&rsquo;autre Partie d&rsquo;un droit ou d&rsquo;un manquement &agrave; l&rsquo;une quelconque des obligations vis&eacute;es au sein des CGV ne saurait &ecirc;tre interpr&eacute;t&eacute; pour l&rsquo;avenir comme une renonciation au droit ou &agrave; l&rsquo;obligation en cause.</p>" +
        "<p>&nbsp;</p>" +
        "<p><strong>11.3.&nbsp;&nbsp;&nbsp; M&eacute;diation</strong></p>" +
        "<p>&nbsp;</p>" +
        "<p>En cas de litige relatif &agrave; une commande, le Client devra contacter en priorit&eacute; &agrave; RedE afin de trouver une solution amiable&nbsp;:</p>" +
        "<ul>" +
        "<li>contact@redelectric.fr</li></ul>" +
        "<p>&nbsp;</p>" +
        "<p>&nbsp;</p>" +
        "<h2><strong>Article 12 &ndash; Droit applicable et r&egrave;glement des litiges </strong></h2>" +
        "<p>&nbsp;</p>" +
        "<p>Les pr&eacute;sentes CGV sont r&eacute;gies par le droit fran&ccedil;ais.</p>" +
        "<p>A d&eacute;faut de r&eacute;solution amiable, tout litige entre les Parties qui na&icirc;trait notamment &agrave; l&rsquo;occasion de la formation ou de l&rsquo;ex&eacute;cution du pr&eacute;sent Contrat sera soumis au tribunal comp&eacute;tent en application de la r&eacute;glementation applicable.</p>" +
        "<p>&nbsp;</p>" +
        "<p>&nbsp;</p>" +
        "<p>&nbsp;</p>" +
        "<h1 style='text-align: center;'><strong>POLITIQUE DE PROTECTION DES DONNEES PERSONNELLES</strong></h1>" +
        "<p>&nbsp;</p>" +
        "<ol>" +
        "<li>" +
        "<h2><strong>Responsable du traitement de vos donn&eacute;es</strong></h2></li></ol>" +
        "<p>&nbsp;</p>" +
        "<p><strong>RedE Group SAS</strong>, soci&eacute;t&eacute; par actions simplifi&eacute;e, au capital de 175.000 euros, immatricul&eacute;e au RCS de Nancy sous le num&eacute;ro 812&nbsp;743&nbsp;847 dont le si&egrave;ge social est situ&eacute; au 136 rue de la C&ocirc;te 54000 Nancy (ci-apr&egrave;s &laquo;&nbsp;RedE&nbsp;&raquo;), num&eacute;ro TVA intracommunautaire&nbsp;: FR812743847</p>" +
        "<p>&nbsp;</p>" +
        "<ol start='2'>" +
        "<li>" +
        "<h2><strong>Identification des donn&eacute;es qui seraient collect&eacute;es</strong></h2></li></ol>" +
        "<p>&nbsp;</p>" +
        "<ul>" +
        "<li>donn&eacute;es de contact (nom, pr&eacute;nom, adresse, email, num&eacute;ro de t&eacute;l&eacute;phone)</li>" +
        "<li>informations socio-d&eacute;mographiques&nbsp;;</li>" +
        "<li>informations li&eacute;es &agrave; vos achats en ligne (ex. articles choisis, adresse de livraison et de facturation, montants de produits, mode de paiement, etc.)&nbsp;;</li>" +
        "<li>informations que vous pouvez &ecirc;tre amen&eacute;es &agrave; communiquer aupr&egrave;s de notre service client ;</li>" +
        "<li>informations de vos comptes sociaux (Facebook, Twitter, etc) ou des comptes d&rsquo;autres services tiers &agrave;</li>" +
        "<li>informations de navigation sur le Site (ex. pages visit&eacute;es, liens cliqu&eacute;s, etc). Certaines de ses informations peuvent &ecirc;tre collect&eacute;es par l&rsquo;interm&eacute;diaire de cookies lors de votre navigation sur notre Site</li></ul>" +
        "<p>&nbsp;</p>" +
        "<ol start='3'>" +
        "<li><h2><strong>Moment de la collecte</strong></h2></li></ol>" +
        "<p>&nbsp;</p>" +
        "<ul>" +
        "<li>cr&eacute;ation compte client&nbsp;;</li>" +
        "<li>achats en ligne&nbsp;;</li>" +
        "<li>&eacute;changes avec le service client RedE&nbsp;;</li>" +
        "<li>souscription newsletter.&nbsp;</li></ul>" +
        "<ol start='4'>" +
        "<li><h2><strong>Finalit&eacute; de la collecte</strong></h2></li></ol>" +
        "<p>&nbsp;</p>" +
        "<ul>" +
        "<li>gestion de la relation client&nbsp;;</li>" +
        "<li>gestion des commandes&nbsp;;</li>" +
        "<li>paiement des commandes&nbsp;;</li>" +
        "<li>personnalisation des Services&nbsp;;</li>" +
        "<li>prospection commerciale.</li></ul>" +
        "<p>&nbsp;</p>" +
        "<ol start='5'>" +
        "<li>" +
        "<h2><strong>Fondements du traitement</strong></h2></li></ol>" +
        "<p>&nbsp;</p>" +
        "<ul>" +
        "<li>l&rsquo;ex&eacute;cution du Contrat conclu avec RedE&nbsp;;</li>" +
        "<li>votre consentement, lorsque vous nous le donner en vue de la prospection commerciale.</li></ul>" +
        "<p>&nbsp;</p>" +
        "<ol start='6'>" +
        "<li>" +
        "<h2><strong>Destinataires de vos donn&eacute;es et transferts &agrave; l&rsquo;&eacute;tranger</strong></h2></li></ol>" +
        "<p>&nbsp;</p>" +
        "<ul>" +
        "<li>RedE incluant ses filiales, et le personnel des entit&eacute;s du groupe</li>" +
        "<li>sous-traitants ou partenaires&nbsp;:" +
        "<ul>" +
        "<li>Prestataires d&rsquo;h&eacute;bergement et de maintenance du Site&nbsp;;</li></ul></li></ul>" +
        "<p>&nbsp;</p>" +
        "<ol start='7'>" +
        "<li>" +
        "<h2><strong>Conservation de vos donn&eacute;es</strong></h2></li></ol>" +
        "<p>&nbsp;</p>" +
        "<p>Vos donn&eacute;es sont conserv&eacute;es pendant la dur&eacute;e strictement n&eacute;cessaire aux finalit&eacute;s du traitement telles vis&eacute;es ci-avant.</p>" +
        "<p>&nbsp;</p>" +
        "<ol start='8'>" +
        "<li>" +
        "<h2><strong>Vos droits </strong></h2></li></ol>" +
        "<p><strong>&nbsp;</strong></p>" +
        "<p>Vous disposez du droit d&rsquo;acc&egrave;s, de rectification, d&rsquo;opposition, &agrave; la limitation du traitement, ainsi que son droit de portabilit&eacute; sur ses donn&eacute;es, du droit d&rsquo;introduire une r&eacute;clamation devant l&rsquo;autorit&eacute; de contr&ocirc;le (www.cnil.fr) ainsi que devant toute juridiction comp&eacute;tente.</p>" +
        "<p>&nbsp;</p>" +
        "<p>Ces droits sont exerc&eacute;s directement aupr&egrave;s de RedE par courrier &agrave; l&rsquo;adresse suivante&nbsp;: 136 rue de la C&ocirc;te 54000 Nancy, ou par email &agrave;&nbsp;</p>" +
        "<p>.</p>" +
        "<ol start='9'>" +
        "<li>" +
        "<h2><strong>Utilisation des cookies</strong></h2></li></ol>" +
        "<p>&nbsp;</p>" +
        "<p>Notre Site utilise des cookies pour vous distinguer des autres Utilisateurs, et en vue d&rsquo;am&eacute;liorer votre exp&eacute;rience lors de votre navigation.</p>" +
        "<p>&nbsp;</p>" +
        "<p>Un cookie est une courte ligne de lettres et de chiffres, que nous conservons sur votre navigateur ou sur le disque dur de votre ordinateur. Afin d&#039optimiser votre exp&eacute;rience sur notre Site et vous faire b&eacute;n&eacute;ficier de certaines fonctionnalit&eacute;s personnalis&eacute;es, votre ordinateur, tablette ou smartphone doit &ecirc;tre configur&eacute; de mani&egrave;re &agrave; accepter les cookies. Notre Site comme beaucoup d&#039autres sites e-commerce fonctionne de mani&egrave;re restreinte sur un appareil bloquant les cookies.</p>" +
        "<p>&nbsp;</p>" +
        "<p>Vous disposez d&#039un droit d&#039acc&egrave;s, de retrait et de modification des donn&eacute;es personnelles communiqu&eacute;es par le biais des cookies. Vous pourrez bloquer les cookies en activant le param&egrave;tre de votre navigateur vous permettant de refuser tous ou certains des cookies. Cependant, si vous choisissez de bloquer tous les cookies vous ne serez plus en mesure d&#039acc&eacute;der pleinement &agrave; tout ou partie de notre site.</p>" +
        "<p>&nbsp;</p>" +
        "<p>Voici la marche &agrave; suivre en fonction de votre navigateur :</p>" +
        "<h3>&nbsp;Google Chrome</h3>" +
        "<ul>" +
        "<li>Dans le menu &laquo; Param&egrave;tres &raquo;, cliquez sur &laquo; Afficher les param&egrave;tres avanc&eacute;s&hellip; &raquo;</li>" +
        "<li>Cliquez ensuite sur &laquo; Param&egrave;tres de contenu&hellip; &raquo;</li>" +
        "<li>Dans la partie haute de la page qui appara&icirc;t alors, vous trouverez plus d&#039informations sur les cookies et aurez la possibilit&eacute; de s&eacute;lectionner les cookies que vous acceptez ou non. Vous avez aussi la possibilit&eacute; de supprimer tous les cookies enregistr&eacute;s jusqu&#039&agrave; pr&eacute;sent.</li></ul>" +
        "<h3>Mozilla Firefox</h3>" +
        "<ul>" +
        "<li>Dans le menu &laquo; Outils &raquo;, cliquez sur &laquo; Options &raquo; (pour les utilisateurs MAC, s&eacute;lectionnez 'Pr&eacute;f&eacute;rences' dans le menu Firefox)</li>" +
        "<li>Rendez-vous ensuite sur l&#039onglet &laquo; Vie priv&eacute;e &raquo;</li>" +
        "<li>Dans la zone &laquo; Historique &raquo;, d&eacute;ployez le menu d&eacute;roulant &laquo; R&egrave;gles de conservation &raquo; puis s&eacute;lectionnez &laquo; Utiliser les param&egrave;tres personnalis&eacute;s pour l&#039historique &raquo;. Vous pourrez alors choisir d&#039accepter ou non les cookies en cochant ou non la case &laquo; Accepter les cookies &raquo;.</li></ul>" +
        "<h3>Internet Explorer 6+</h3>" +
        "<ul>" +
        "<li>Dans le menu &laquo; Outils &raquo;, cliquez sur &laquo; Options internet &raquo;</li>" +
        "<li>Rendez-vous ensuite sur l&#039onglet &laquo; Confidentialit&eacute; &raquo;</li>" +
        "<li>Vous apercevrez alors un curseur vous permettant de choisir parmi 6 degr&eacute;s de restriction des cookies : Bloquer tous les cookies, restriction tr&egrave;s haute, restriction haute, restriction moyenne (s&eacute;lectionn&eacute;e par d&eacute;faut), restriction basse et accepter tous les cookies.</li></ul>" +
        "<h3>Safari</h3>" +
        "<ul>" +
        "<li>Dans le menu Safari, s&eacute;lectionnez &laquo; Pr&eacute;f&eacute;rences &raquo;</li>" +
        "<li>Rendez-vous ensuite sur l&#039onglet &laquo; Confidentialit&eacute; &raquo;</li>" +
        "<li>Puis choisissez l&#039option de votre choix dans la section &laquo; Bloquer les cookies &raquo;</li></ul>" +
        "<h3>Autres navigateurs</h3>" +
        "<ul>" +
        "<li>Pour savoir comment contr&ocirc;ler les cookies sur d&#039autres navigateurs, veuillez consulter votre documentation ou les modules d&#039aide en ligne.</li></ul>" +
        "<p>&nbsp;</p>",
    },
  };
  static blocks = {
    threeSixty: {
      backgroundText: "Nos scooters électriques",
      scootsData: [
        {
          modelName: "E50",
          technicalSummary: [
            {
              title: "Vitesse maximale",
              data: "45 km/h",
            },
            {
              title: "Autonomie maximale",
              data: "300 km",
            },
            {
              title: "0 à 50 km/h",
              data: "7,2 sec",
            },
            {
              title: "Puissance maximale du moteur",
              data: "4 000W",
            },
            {
              title: "Poids à vide",
              data: "109 Kg",
            },
          ],
        },
        {
          modelName: "E100",
          technicalSummary: [
            {
              title: "Vitesse maximale",
              data: "80 km/h",
            },
            {
              title: "Autonomie maximale",
              data: "220 km",
            },
            {
              title: "0 à 50 km/h",
              data: "5,5 sec",
            },
            {
              title: "Puissance maximale du moteur",
              data: "6 000W",
            },
            {
              title: "Poids à vide",
              data: "109 Kg",
            },
          ],
        },
        {
          modelName: "E125",
          technicalSummary: [
            {
              title: "Vitesse maximale",
              data: "120 km/h",
            },
            {
              title: "Autonomie maximale",
              data: "200 km",
            },
            {
              title: "0 à 50 km/h",
              data: "3,4 sec",
            },
            {
              title: "Puissance maximale du moteur",
              data: "11 000W",
            },
            {
              title: "Poids à vide",
              data: "109 Kg",
            },
          ],
        },
      ],
      ctaText: "Voir les caractéristiques complètes",
      ctaProText: "Professionnels de la livraison, découvrez les PRO50",
    },

    powerBlock: {
      title: "Un scooter électrique puissant",
      subtitle:
        "<b>RED HCPM® - Custom Central Motor</b><br/>" +
        "RED Electric a conçu son propre moteur, breveté, sans concession ni compromis quant à la puissance et à la durabilité. </br>" +
        " Son dispositif d’adaptation automatique du couple et de la puissance vous permet d’affronter des côtes jusqu’à 60 %.",
      marketAverage: "Moyenne du marché",
      to: "à",
      equivalent: "soit",
      power: "puissance",
      models: [
        {
          modelName: "Model E50",
          technicalSummary: [
            {
              title: "Couple Maximum",
              data: "277 Nm",
            },
            {
              title: "de 0 à 50km/h",
              data: "7.2 sec",
            },
            {
              title: "Moyenne du marché <a href='#mentions'><sup>1</sup></a>",
              data:
                "Un couple 10 fois supérieur à la moyenne des scooters thermiques du marché<a href='#mentions'><sup>4</sup></a>",
            },
            {
              title: "valeur marché",
              data: 2879,
            },
            {
              title: "valeur rede",
              data: 4000,
            },
          ],
          modelImg: "./scooterImage/ModelE_Noir.png",
          minMakertPower: "1 000 W",
        },
        {
          modelName: "Model E100",
          technicalSummary: [
            {
              title: "Couple Maximum",
              data: "277 Nm",
            },
            {
              title: "de 0 à 50km/h",
              data: "5.5 sec",
            },
            {
              title: "Moyenne du marché <a href='#mentions'><sup>2</sup></a>",
              data:
                "Un couple presque 2 fois supérieur à la moyenne des scooters électriques du marché<a href='#mentions'><sup>5</sup></a>",
            },
            {
              title: "valeur marché",
              data: 4956,
            },
            {
              title: "valeur rede",
              data: 6000,
            },
          ],
          modelImg: "./scooterImage/ModelE_Champagne.png",
          minMakertPower: "4 000 W",
        },
        {
          modelName: "Model E125",
          technicalSummary: [
            {
              title: "Couple Maximum",
              data: "512 Nm",
            },
            {
              title: "de 0 à 50km/h",
              data: "3.4 sec",
            },
            {
              title: "Moyenne du marché <a href='#mentions'><sup>3</sup></a>",
              data:
                "Un couple presque 4 fois supérieur à la moyenne des scooters électriques du marché<a href='#mentions'><sup>6</sup></a>",
            },
            {
              title: "valeur marché",
              data: 9122,
            },
            {
              title: "valeur rede",
              data: 11000,
            },
          ],
          modelImg: "./scooterImage/ModelE_Carbone.png",
          minMakertPower: "4 000 W",
        },
      ],
    },
    autonomyBlock: {
      title: "300 km d'autonomie réelle.<br/> Un record mondial",
      subtitle1:
        "Votre Model E embarque jusqu’à 4 batteries extractibles : choisissez l’autonomie réelle dont vous avez besoin. Le plaisir de rouler, sans stress !",
      subtitle2:
        "Une intelligence Artificielle brevetée gère simultanément les 2, 3 ou 4 batteries embarquées. <br/>Cette technologie offre jusq'à 300km d'autonomie, avec des recharges moins fréquentes. Le BBI® permet une longévité de batterie inédite de 2500 cycles de charge.",

      cta: "En savoir plus sur les technologie BBI® des batteries",
      blocks: [
        {
          title: "Jusqu'à <b>300 km</b> <br/> d'autonomie",
          text:
            "Soit 6 fois plus que les scooters électriques de même catégorie<a href='#mentions'><sup>7</sup></a>",
        },
        {
          title:
            "Temps de recharge <b>2h30</b> <br/> avec le chargeur ultra-rapide HSC®",
          text:
            "Soit 2 fois plus que les scooters électriques de même catégorie<a href='#mentions'><sup>8</sup></a> (en option)",
        },
        {
          title: "<b>2500</b> <br/> cycles de recharge",
          text:
            "Soit une durabilité 4 fois supérieures aux batteries du marché !<a href='#mentions'><sup>9</sup></a>",
        },
      ],
    },
    landingBlock: {
      title: "Design",
      text: "Model E sous toutes les coutures",
    },

    firstLandingBlock: {
      title: "Une nouvelle ère commence",
      text:
        "Avec les Model E, RED Electric développe la première gamme de scooters électriques sans compromis : des machines plongées dans un bain de technologie, qui allient puissance, autonomie et sécurité." +
        "</br></br><strong>Découvrez la performance de l’électrique.</strong>",
    },
    deliveryBlock: {
      title1: "Livraison Premium à domicile prêt à rouler",
      title2: "Notre Service Clients est à votre disposition :",
      title3:
        "Nos Centres Agréés RED Electric",
      cta: "En savoir plus",
      email: "support@redelectric.fr",
      phone: "01 44 01 53 00",
      chat: "Chat",
      schedule: "du lundi au vendredi de 9h à 19h.",
      subtitle1:
        "Votre scooter immatriculé et assuré est livré chez vous, sur le créneau horaire de votre choix",
    },
    ecologyBlock: {
      title: "Jusqu’à 2400 € de Bonus et Incitations Écologiques",
      subtitle:
        "Tous nos Model E sont éligibles au Bonus écologique ! " +
        "<br />Cette aide est accessible à tous les particuliers français, ainsi qu'aux professionnels, pour l'achat d'un véhicule électrique. " +
        "Et si vous êtes un professionnel en Île de France, il existe une subvention dédiée et cumulable. " +
        "<br />" +
        "Calculez le montant du Bonus dont vous pouvez bénéficier !",
      ctaText: "En savoir plus sur les avantages et solutions de financement",
      result: {
        title: "C’est le montant de votre aide !",
        subtitle:
          "Ce montant est donné à titre indicatif et sous réserve d'acceptation du dossier. Pour obtenir votre devis personnalisé, contactez nos conseillers.",
        recalculateBonus: "recalculer votre aide",
      },
      form: {
        model: "Model",
        batteriesCount: "Nombre de batteries",
        youAre: "Vous êtes",
        postalCode: "Code Postal",
        personal: "Particulier",
        professional: "Professionnel",
        calculateBonus: "Calculer mon Aide",
      },
      cta_1: {
        title:
          "Découvrez nos Model E à la FNAC, chez DARTY ou l’un de nos Centres Agréés",
        findShop: "Trouver un Point de Vente",
      },
      cta_2: {
        title: "Venez l'essayer au plus vite",
        bookTrial: "Essayez nos Model E",
        link: process.env.BOOK_DEMO,
      },
    },
    modelEBlock: {
      title: "Gamme Model E",
      text:
        "Plutôt urbain, besoin d'une grande autonomie et en recherche de puissance ?" +
        "</br>La gamme Model E correspond à toutes les envies !",

      modelBox: {
        maximumSpeed: "Vitesse maximale",
        maximumAutonomy: "Autonomie maximum",
        startingFrom: "À partir de",
        month: "mois",
        paymentPlan: "",
        rentIncrease: "après un premier loyer majoré",
        or: "ou",
        cta: "Configurer",
        ttc: "TTC*",
      },
      scootsData: [
        {
          modelName: "Model E50",
          power: "Moteur 4 000 W",
          modelImg: "./scooterImage/ModelE_Noir.png",
          tagLine: "L'agilité en ville, sans permis",
          speed: 45,
          maxSpeed: 120,
          colors: [
            {
              name: "Noir Mat",
              hexa: "#000",
            },
            {
              name: "Champagne",
              hexa: "#978f81",
            },
          ],
          monthlyPrice: "69",
          price: "3 990",
          cta: "#",
          maxAutonomy: 300,
          customOrderLink: "&model_id=" + process.env.REACT_APP_MODEL_E50 + "&model_color_id=" + process.env.REACT_APP_E50_5,
        },

        {
          modelName: "Model E100",
          power: "Moteur 6 000 W",
          modelImg: "./scooterImage/ModelE_Champagne.png",
          tagLine: "La polyvalence totale",
          speed: 80,
          maxSpeed: 120,
          colors: [
            {
              name: "Noir Mat",
              hexa: "#000",
            },
            {
              name: "Champagne",
              hexa: "#978f81",
            },
          ],
          monthlyPrice: "109",
          price: "5 990",
          cta: "#",
          maxAutonomy: 220,
          customOrderLink: "&model_id=" + process.env.REACT_APP_MODEL_E100 + "&model_color_id=" + process.env.REACT_APP_E100_5,
        },

        {
          modelName: "Model E125",
          power: "Moteur 11 000 W",
          modelImg: "./scooterImage/ModelE_Carbone.png",
          tagLine: "La puissance Premium",
          speed: 120,
          maxSpeed: 120,
          colors: [
            {
              name: "Noir Mat",
              hexa: "#000",
            },
            {
              name: "Carbone",
              hexa: "#3b3b3b",
            },
          ],
          monthlyPrice: "169",
          price: "8 990",
          cta: 90,
          maxAutonomy: 200,
          customOrderLink: "&model_id=" + process.env.REACT_APP_MODEL_E125 + "&model_color_id=" + process.env.REACT_APP_E125_5,
        },
      ],
      ctaText: "Voir les caractéristiques complètes",
    },
    batteryBlock: {
      text: {
        title: "Des batteries d’avant-garde",
        paragraph:
          "Nos batteries utilisent les cellules Lithium-Ion 21700 de dernière génération – les mêmes que sur la TESLA Model 3.<br><br>Ces cellules disposent d’une densité énergétique plus élevée qui permet d’emporter beaucoup plus d’énergie, dans un volume et un poids réduits. Elles sont aussi bien plus durables.",
      },
      media: {
        videoNameMobile: "batterie_mobile.mp4",
        videoNameDesktop: "batterie_desktop.mp4",
      },
    },
    firstInfoBlock: {
      title: "Un scooter électrique puissant",
      subtitle: "RED HCPM® - Custom Central Motor",
      paragraph:
        "Un moteur breveté, une technologie française sans compromis pour allier puissance et durabilité.<br>" +
        "Son dispositif d’adaptation automatique du couple et de la puissance vous permet d’aborder des côtes jusqu’à 30°.",
      videoName: "power.mp4",
      videoPosterName: "power-poster.jpg",
      scootsData: [
        {
          modelName: "E50",
          technicalSummary: [
            {
              title: "Vitesse maximale",
              data: "45 km/h",
            },
            {
              title: "Puissance maximale du moteur",
              data: "4000 W",
            },
            {
              title: "Couple maximum",
              data: "277 Nm",
            },
          ],
        },
        {
          modelName: "E100",
          technicalSummary: [
            {
              title: "Vitesse maximale",
              data: "80 km/h",
            },
            {
              title: "Puissance maximale du moteur",
              data: "6000 W",
            },
            {
              title: "Couple maximum",
              data: "277 Nm",
            },
          ],
        },
        {
          modelName: "E125",
          technicalSummary: [
            {
              title: "Vitesse maximale",
              data: "120 km/h",
            },
            {
              title: "Puissance maximale du moteur",
              data: "11000 W",
            },
            {
              title: "Couple maximum",
              data: "560 Nm",
            },
          ],
        },
      ],
    },
    secondInfoBlock: {
      title: "300 Km d'autonomie réelle.<br>Un record mondial",
      paragraph:
        "Votre Model E embarque jusqu’à 4 batteries extractibles : choisissez l’autonomie réelle dont vous avez besoin. Le plaisir de rouler, sans stress !<br><br>Une Intelligence Artificielle brevetée gère simultanément les 2, 3 ou 4 batteries embarquées. Cette technologie offre jusqu'à 300km d'autonomie, avec des recharges moins fréquentes. Le BBI ® permet une longévité des batteries inédite de 2500 cycles de charge.",
      videoName: "bbi.mp4",
      videoReducedScale: true,
      scootsData: [
        {
          modelName: "E50",
          technicalSummary: [
            {
              title: "Autonomie maximale",
              data: "300 km",
            },
            {
              title: "Temps de recharge (Charge ultra-rapide)",
              data: "2h30",
            },
            {
              title: "Cycles de recharge",
              data: "2500",
            },
          ],
        },
        {
          modelName: "E100",
          technicalSummary: [
            {
              title: "Autonomie maximale",
              data: "220 km",
            },
            {
              title: "Temps de recharge (Charge ultra-rapide)",
              data: "2h30",
            },
            {
              title: "Cycles de recharge",
              data: "2500",
            },
          ],
        },
        {
          modelName: "E125",
          technicalSummary: [
            {
              title: "Autonomie maximale",
              data: "200 km",
            },
            {
              title: "Temps de recharge (Charge ultra-rapide)",
              data: "2h30",
            },
            {
              title: "Cycles de recharge",
              data: "2500",
            },
          ],
        },
      ],
    },
    horizontalScrollBlock: {
      title: "Des recharges simples et rapides",
      leftLayer: {
        subtitle: "3 modes de recharge",
        text:
          "Votre Model E est vendu avec un chargeur classique de série. Vous pouvez extraire une ou plusieurs batteries pour les charger chez vous, sur une prise normalisée. Avec le chargeur ultra rapide HSC® (en option), chaque batterie est rechargée en 2h30. Avec le chargeur intelligent SSC® (en option), vous pouvez suivre directement sur votre App, l’état de charge de vos batteries (1 à 4), être prévenu lorsqu’elles sont complètes - et programmer vos recharges aux heures creuses.",
      },
      rightLayer: {
        subtitle: "Recharge directe",
        text:
          "Branchez directement votre scooter sur une prise électrique classique* dans un garage. (*Disponible au 1er semestre 2021)",
      },
    },
    technicalBlock: {
      title: "Caractéristiques techniques",
      rates: "Tarifs",
      showAll:
        "Toutes les caractéristiques techniques et toutes les données des Model E",
      getBrochure: "Télécharger la Brochure Model E",
      subtitles: [
        "Tarifs",
        "Vitesses et Autonomie",
        "Dimensions",
        "Roues",
        "Puissance et Batterie",
        "freins, éclairage, connectivité",
      ],
      scootsData: [
        {
          modelName: "Model E50",
          technicalSummary: [
            {
              title: "Comptant",
              data: "À partir de 3990€ TTC",
            },
            {
              title: "Location longue durée",
              data: "À partir de 69€ TTC/mois** après un premier loyer majoré",
            },
            {
              title: "Prime écologique",
              data: "Jusqu'à 2105€ de Bonus et Incitations Écologiques",
            },
            {
              title: "Vitesse maximale",
              data: "45 km/h",
            },
            {
              title: "Autonomie maximale",
              data: "300 km",
            },
            {
              title: "Longueur",
              data: "2110 mm",
            },
            {
              title: "Largeur",
              data: "800 mm",
            },
            {
              title: "Hauteur",
              data: "1280 mm",
            },
            {
              title: "Empattement",
              data: "1380 mm",
            },
            {
              title: "Taille",
              data: "14 pouces",
            },
            {
              title: "Avant",
              data: "100/80 R14",
            },
            {
              title: "Arrière",
              data: "120/70 R14",
            },
            {
              title: "Marque",
              data: "Michelin",
            },
            {
              title: "Tension nominale",
              data: "72V",
            },
            {
              title: "Nombre de batteries",
              data: "1 à 4",
            },
            {
              title: "Puissance par batterie",
              data: "2,42 kWh",
            },
            {
              title: "Poids par batterie",
              data: "14 Kg",
            },
            {
              title: "Système de freinage",
              data: "Freins à disques AV/AR + CBS",
            },
            {
              title: "Feux de route / croisement",
              data: "LED - Longue portée",
            },
            {
              title: "Connectivité",
              data: "4G, Bluetooth 4.0., WiFi, NFC, CAN BUS Protocole",
            },
          ],
          modelImg: "./scooterImage/ModelE_Noir.png",
        },
        {
          modelName: "Model E100",
          technicalSummary: [
            {
              title: "Comptant",
              data: "À partir de 5990€ TTC",
            },
            {
              title: "Location longue durée",
              data: "À partir de 109€ TTC/mois** après un premier loyer majoré",
            },
            {
              title: "Prime écologique",
              data: "Jusqu’à 2400 € de Bonus et Incitations Écologiques",
            },
            {
              title: "Vitesse maximale",
              data: "80 km/h",
            },
            {
              title: "Autonomie maximale",
              data: "220 km",
            },
            {
              title: "Longueur",
              data: "2110 mm",
            },
            {
              title: "Largeur",
              data: "800 mm",
            },
            {
              title: "Hauteur",
              data: "1280 mm",
            },
            {
              title: "Empattement",
              data: "1380 mm",
            },
            {
              title: "Taille",
              data: "14 pouces",
            },
            {
              title: "Avant",
              data: "100/80 R14",
            },
            {
              title: "Arrière",
              data: "120/70 R14",
            },
            {
              title: "Marque",
              data: "Michelin",
            },
            {
              title: "Tension nominale",
              data: "72V",
            },
            {
              title: "Nombre de batteries",
              data: "2 à 4",
            },
            {
              title: "Puissance par batterie",
              data: "2,42 kWh",
            },
            {
              title: "Poids par batterie",
              data: "14 Kg",
            },
            {
              title: "Système de freinage",
              data: "Freins à disques AV/AR + CBS",
            },
            {
              title: "Feux de route / croisement",
              data: "LED - Longue portée",
            },
            {
              title: "Connectivité",
              data: "4G, Bluetooth 4.0., WiFi, NFC, CAN BUS Protocole",
            },
          ],
          modelImg: "./scooterImage/ModelE_Champagne.png",
        },
        {
          modelName: "Model E125",
          technicalSummary: [
            {
              title: "Comptant",
              data: "À partir de 8990€ TTC",
            },
            {
              title: "Location longue durée",
              data: "À partir de 169€ TTC/mois** après un premier loyer majoré",
            },
            {
              title: "Prime écologique",
              data: "Jusqu'à 2400€ de Bonus et Incitations Écologiques",
            },
            {
              title: "Vitesse maximale",
              data: "120 km/h",
            },
            {
              title: "Autonomie maximale",
              data: "200 km",
            },
            {
              title: "Longueur",
              data: "2210 mm",
            },
            {
              title: "Largeur",
              data: "800 mm",
            },
            {
              title: "Hauteur",
              data: "1280 mm",
            },
            {
              title: "Empattement",
              data: "1380 mm",
            },
            {
              title: "Taille",
              data: "14 pouces",
            },
            {
              title: "Avant",
              data: "100/80 R14",
            },
            {
              title: "Arrière",
              data: "120/70 R14",
            },
            {
              title: "Marque",
              data: "Michelin",
            },
            {
              title: "Tension nominale",
              data: "72V",
            },
            {
              title: "Nombre de batteries",
              data: "4",
            },
            {
              title: "Puissance par batterie",
              data: "2,42 kWh",
            },
            {
              title: "Poids par batterie",
              data: "14 Kg",
            },
            {
              title: "Système de freinage",
              data: "Freins à disques AV/AR + CBS",
            },
            {
              title: "Feux de route / croisement",
              data: "LED - Longue portée",
            },
            {
              title: "Connectivité",
              data: "4G, Bluetooth 4.0., WiFi, NFC, CAN BUS Protocole",
            },
          ],
          modelImg: "./scooterImage/ModelE_Carbone.png",
        },
      ],
    },
    cardSliderBlock: {
      title: "Un scooter électrique intelligent",
      subtitle: "Un scooter électrique plongé dans un bain de technologie",
      cards: [
        {
          thumbnail: "./cards/Card-3.jpeg",
          title: "Smart Dashboard, votre copilote",
          text:
            "Plutôt que l’habituel tableau de bord à cadrans, le Model E est équipé d’un écran géant rétroéclairé de 7 pouces, étanche (certifié IP 65). Il affiche toutes les données dont vous avez besoin et son navigateur GPS intégré vous guide jusqu'à destination.",
        },
        {
          thumbnail: "./cards/Card-1.jpeg",
          title:
            "Votre interface de commande à distance - Disponible sous iOS & Android",
          text:
            "Ne cherchez plus vos clefs dans votre poche ou votre sac, votre Model E détecte automatiquement votre présence et se déverrouille, prêt à démarrer. Éloignez-vous, il s’éteint tout seul et l’ensemble des systèmes dynamiques, moteur, batterie, roues sont bloqués jusqu’à votre retour.",
        },
        {
          thumbnail: "./cards/Card-2.jpeg",
          title: "Sécurité et Sérénité",
          text:
            "Vous êtes automatiquement notifié sur l’App de tout acte de malveillance survenu en votre absence ; le détecteur de vol verrouille l’ensemble des systèmes et bloque les roues pour que lescooter soit inutilisable. Vous seul pouvez le débloquer et le retrouver grâce à la géolocalisation GPS.",
        },
        {
          thumbnail: "./cards/Card-4.jpeg",
          title: "Visibilité parfaite et durable",
          text:
            "RED Electric s'est inspiré de l'industrie automobile pour vous offrir une visibilité optimum. Ses phares LED d'une portée de 75 mètres garantissent une sécurité en toutes circonstances, par tous les temps.",
        },
      ],
    },
    contactForm: {
      title: "Une question ?<br/> Nous répondons en 48h",
      submitButton: "Envoyer",
      loading: "Chargement",
      form: {
        firstNameLabel: "Prénom",
        lastNameLabel: "Nom",
        emailLabel: "Email",
        emailInvalid: "Email invalide",
        messageLabel: "Message",
        required: "Ce champ est obligatoire",
        successMessage: "Votre message a bien été envoyé !",
      },
    },
    preorderBlock: {
      title: "Un constructeur français",
      paragraph:
        "RED Electric est un constructeur français qui développe ses propres technologies grâce à ses équipes R&D et sa chaîne de production exclusive.",
      ctaText: "Nous découvrir",
      blocks: [
        {
          type: "large",
          color: "red",
          title: "Commander",
          paragraph:
            "Commandez dès aujourd'hui - Soyez livré en premier !<br/> Réserver votre Model E en payant seulement 590 €.<br/> Offre spéciale : Livraison d'une valeur de 199€ offerte !",
          buttonText: "Commander",
          buttonType: "preorder",
        },
      ],
    },
    newsletterForm: {
      title: "Pour rester informés,<br />abonnez-vous à notre Newsletter",
      submitButton: "M'inscrire",
      loading: "Chargement",
      form: {
        emailLabel: "Email",
        emailInvalid: "Email invalide",
        required: "Ce champ est obligatoire",
        successMessage: "Inscription réussie",
      },
    },
    newsletterBlock: {
      title: "Pour rester informés, <br/>abonnez-vous à notre Newsletter :",
      inputPlaceholder: "votre email",
      buttonText: "OK",
    },
    pressBlock: {
      title: "La presse en parle",
      articles: [
        {
          content:
            "Ces deux-roues représentent une petite révolution technologique puisqu’ils assurent jusqu’à 300 km d’autonomie",
          link: "#",
          publisherLogo: "./images/leparisien.png",
        },
        {
          content:
            "Top 1 : RED Electric E100 de 100cm3, le plus high tech du classement (et français !). Ce scooter est un petit bijou de technologie et de savoir-faire français. (...) Leurs scooters ont été conçus pour fournir le meilleur aux conducteurs grâce à des technologies embarquées dernier cri et des performances au dessus de la concurrence.",
          link: "#",
          publisherLogo: "./images/logo-monsieur-moto.png",
        },
        {
          content:
            "Le moins que l’on puisse dire, c’est que la présentation du scooter électrique RED Electric attire forcément l’attention. De un, parce que l’entreprise à l’origine de ce projet est française, et de deux, parce que ce modèle jouit d’une large autonomie de 300 kilomètres.",
          link: "#",
          publisherLogo: "./images/Frandroid.png",
        },
        {
          content:
            "Le scooter électrique mise ici clairement sur le côté technologique avec un éclairage à LED, un écran de bord TFT de 7 pouces intégrant une navigation GPS, une connectivité smartphone (...)",
          link: "#",
          publisherLogo: "./images/lerepairedesmotards.png",
        },
        {
          content: `Ce 125 annonce d'ailleurs des performances dignes d'un 125 thermique avec 11 kW, un couple de 560 Nm (!) et une vitesse maxi de 120 km/h. Le moteur est "une technologie française breveté`,
          link: "#",
          publisherLogo: "./images/motoservice.jpeg",
        },
        {
          content: `Chez RED Electric, le haut niveau de fiabilité et la qualité du service sont non-négociables.`,
          link: "#",
          publisherLogo: "./images/Caradisiac.jpg",
        },
      ],
      subtitle:
        "* RED Electric Group SAS - RCS Nancy 812 743 847 - Document non-contractuel - RED Electric se réserve le droit de modifier sans préavis les spécifications techniques des scooters, batteries et chargeurs - Conception: - Crédit photo: RED Electric. " +
        "(1)Moyenne de la puissance des modèles Askoll eS2 EVO, Niu - NQI GTS, Faucon E,3 Orcal Ecooter E2, Tilgreen - Tilscoot, Vespa Elettrica 50, Super Soco CU, Peugeot Citystar 2T, Peugeot Django 50 2T, Peugeot Django 50 4T, Kymco New Like 50 4T, Sym Jet 14 50 AC, Vespa Primavera 50, 4T Yamaha Neo's 4. " +
        "(2)Moyenne de la puissance des modèles Askoll eS3 EVO Faucon E5 Niu NQI Gts` Orcal Ecooter E2 R Super Soco CPx Vespa Elettrica 70 Honda Vision 110 Sym Mio 115 CBS Kymco Agility 125 16+. " +
        "(3)Moyenne de la puissance des modèles Faucon E9 Super Soco CPx Tilgreen Tilmax Silence S01 Rider NG Rider 9000W Niu Nqi GT BMW C-EVO Honda Forza 125 Honda PCX 125 Kawasaki J125 ABS Kymco Downtown 125 Kymco X-Town 125 Peugeot Citystar 125 Peugeot Django 125 Peugeot Pulsion 125 Peugeot Satelis 125 Suzuki Burgman 125 Sym Jet 14 125 Sym FNX 125 Piaggio Medley 125 Vespa Primavera 125 ABS Vespa GTS 125 Yamaha Nmax 125 Yamaha Xmax 125. " +
        "(4)Moyenne de la puissance des modèles Peugeot Kisbee, Piaggio Zip, Kymco Agility. " +
        "(5)Moyenne de la puissance des modèles Askoll eS3 EVO Niu NQI Gts` Orcal Ecooter E2 R Super Soco CPx Vespa Elettrica 70. " +
        "(6)Moyenne de la puissance des modèles Faucon E9 Super Soco CPx Silence S01 Rider NG Rider 9000W Niu Nqi GT BMW C-EVO. " +
        "(7)Moyenne de l'autonomie des modèles Askoll eS2 EVO Niu - NQI GTS Faucon E3 Orcal Ecooter E2 Tilgreen - Tilscoot Vespa Elettrica 50 Super Soco CU " +
        "(8)Moyenne des temps de recharge des modèles Askoll eS2 EVO Niu - NQI GTS Faucon E3 Orcal Ecooter E2 Tilgreen - Tilscoot Vespa Elettrica 50 Super Soco CU. " +
        "(9)Moyenne des temps de recharge des modèles Askoll eS2 EVO Niu - NQI GTS Faucon E3 Super Soco CU" +
        "**sous réserve d'acceptation par notre partenaire financier. Voir l'ensemble des tarifs dans notre FAQ",
    },
    discoverBlock: {
      title: "Découvrir et essayer",
      text:
        "Rendez-vous dans un point de vente pour découvrir votre model ou venir l'essayer au plus vite.",
      findDealer: "Trouver un point de vente",
      findDealerLink: "#",
      bookDemo: "Réserver un essai",
      bookDemoLink: process.env.BOOK_DEMO,
    },
    aboutPage: {
      texts: [
        "RED Electric est un constructeur français de scooters électriques connectés à hautes performances.",
        "Créé en 2015, à Nancy, nous sommes Leaders sur le marché des professionnels depuis 2017 avec notre modèle Pro50 : une machine simple, efficace, extrêmement robuste et fiable. Ce marché - très concurrentiel et exigeant – nous a apporté beaucoup d’expérience pour développer la nouvelle Gamme Model E.",
        "Nos machines offrent des performances sans concession. Avant tout, nous avons créé les Model E pour que passer à l’électrique ne soit plus la version dégradée d’un véhicule thermique, mais un gain. Un gain de puissance, d’autonomie, de contrôle, de sécurité, de plaisir de conduite - mais aussi un gain pour l’environnement, pour notre cadre de vie, pour notre planète. ",
        "Parce que nous aussi, et nos enfants, nous vivons dessus !",
        "Nous avons choisi de construire des scooters électriques, car c’est le plus abouti, le plus mature, le plus polyvalent et le plus performant des véhicules légers électriques. C’est aussi le seul qui permette de transporter deux personnes et leurs bagages sur plusieurs dizaines de kilomètres - et plus encore. En cela, c’est le véhicule décarboné qui se rapproche le plus des avantages d’une voiture dans son utilisation quotidienne.",
        "Avec un Model E, vous pouvez parcourir de courtes distances, pour aller travailler, faire vos courses, au quotidien. Mais vous êtes aussi libre de sortir de la ville pour partir en balade, à deux, pour une journée, un week-end, ou plus...",
        "Nous concevons et construisons des scooters électriques durables, parce que nous sommes de fervents opposants à l’obsolescence programmée et au jetable.",
        "La durabilité est la condition essentielle du caractère écologique d’un véhicule électrique. Nos Model E sont construits pour durer des années et parcourir des milliers et des milliers de km, en économisant l’énergie et les ressources disponibles.",
        "John Fitzgerald Kennedy, lançant le programme Apollo le 12 septembre 1962 a dit : ",
        "<i>Nous choisissons de le faire, non pas parce que c’est facile, mais parce que c’est difficile, parce que cet objectif permettra de mobiliser et de mesurer le meilleur de nos énergies et de nos compétences, parce que c’est un défi que nous sommes prêts à accepter, que nous ne voulons pas reporter, et que nous avons l’intention de gagner ! <sup href=`#mention1`>1</sup> </i>",
        "Même si notre ambition est moindre, à l’échelle d’une entreprise le challenge est du même ordre. Nous sommes passionnés car nous vivons une aventure difficile et complexe - mais passionnante et riche d’enseignements et de développements pour chacun d’entre nous.",
        "Elle rend aussi nos vies plus intéressantes car, tous les jours, nous participons à une aventure qui progresse à grande vitesse sur un marché qui est aussi un changement sociétal fort et puissant, au service de nos clients, à votre service.",
        "Une nouvelle ère commence, avec des machines françaises plongées dans un bain de technologie !",
        "<i>Il est plus facile d’inventer le futur que de le prédire...  <sub>Alan Kay</sub></i>",
        "Alors, nous essayons…"
      ],
      faqText: "Consultez notre FAQ",
      ctaText: "Configurer votre Model E",
      ctaLink: process.env.MENU_CREATE_ORDER,
      subText: "<sup>1</sup> We choose to go to the Moon. We choose to go to the Moon in this decade and do the other things, not because they are easy, but because they are hard, because that goal will serve to organize and measure the best of our energies and skills, because that challenge is one that we are willing to accept, one we are unwilling to postpone, and one which we intend to win, and the others, too.",
    },
    faqPage: {
      data: [
        {
          title: "Gamme Model E",
          shortTitle: "Model E",
          questions: [
            {
              question: "Quels sont les dimensions du Model E ?",
              answer:
                "Les dimensions sont les mêmes pour le Model E50 et E100. Le Model E125 est un peu plus long" +
                "<br/>Le Model E a été conçu pour accueillir 2 personnes" +
                "<br/>Longueur : 2110 mm(Model E125 : 2210 mm)" +
                "<br/>Largeur : 800 mm" +
                "<br/>Hauteur : 1280 mm",
            },
            {
              question: "Quels avantages si je commande aujourd'hui ?",
              answer:
                "Payez seulement 590 € aujourd'hui pour réserver votre Model E. Nous faisons face à une augmentation importante des demandes de personne qui, comme vous, souhaitent changer leurs habitudes de déplacements, nous vous invitons à réserver dès maintenant votre scooter." +
                '<br/>Vous serez ainsi prioritaire sur la livraison à votre domicile ou en concession et nous vous offrons pour votre patience un Chargeur Ultra Rapide HSC® d\'une valeur de 250<span class="pad-lr" style="font-family: sans">€</span> en commandant votre Model E en ligne. Vous ne payez qu\'un acompte de 590<span class="pad-lr" style="font-family: sans">€</span>.',
            },
            {
              question: "Ai-je besoin d'un permis ? Si oui, lequel ?",
              answer:
                `<table class="answer-table"><tr><td colspan="2">Model E50</td><td colspan="2">Model E100</td><td colspan="2">Model E125</td></tr><tr><td colspan="2">Né avant ​1988 : Pas besoin de permis.<br><br>Né ​après ​1988 : BSR et/ou permis B.</td><td colspan="4">Site Officiel en France : <br><br><a href="https://www.service-public.fr/particuliers/vosdroits/F45" target="_blank">https://www.service-public.fr/particuliers/vosdroits/F45</a><br></td></tr></table>` +
                '<br/>Une question ? Écrivez-nous à : <a href="mailto:​permis@redelectric.fr">permis@redelectric.fr</a>',
            },
          ],
        },
        {
          title: "Batterie",
          shortTitle: "Batterie",
          questions: [
            {
              question:
                "Les batteries du Model E seront-elles recyclées à terme ?",
              answer:
                "En fin de vie, RED Electric et ses partenaires agréés vous accompagneront pour assurer le recyclage de l'ensemble des éléments et composants de vos batteries.",
            },
          ],
        },
        {
          title: "Accesoires",
          shortTitle: "Accessoires",
          questions: [
            {
              question: "Quels accessoires seront disponibles ?",
              answer:
                "Le Scooter RED Electric dispose d'un support universel arrière (AR) qui permet de fixer de nombreux modèles de topcases et sacoches de transport pour y loger vos affaires, casques et gants." +
                "<br/<Nous vous proposerons un ensemble d'accessoires disponibles à la finalisation de votre commande (protections, topcases, jupes de protection, etc.), juste avant la livraison.",
            },
          ],
        },
        {
          title: "Garantie",
          shortTitle: "Garantie",
          questions: [
            {
              question: "Mon Model E est-il garanti ?",
              answer:
                "Tous nos Scooters bénéficient de la Garantie Constructeur – Pièces & Main d'œuvre – pendant deux ans à compter de la date d'acquisition." +
                "<br/>Nous vous proposerons des Extensions de Garantie au moment de finaliser votre paiement, quelque temps avant la livraison.",
            },
          ],
        },
        {
          title: "Financement",
          shortTitle: "Financement",
          questions: [
            {
              question: "Proposez-vous des offres de financement ?",
              answer:
                '<table class="answer-table"><tr><td colspan="6">Financement en location longue durée</td></tr>' +
                '<tr><td colspan="1"> </td><td colspan="1">Apport initial</td><td colspan="1">Loyer 36 mois</td><td colspan="1">Loyer 48 mois</td></tr>' +
                '<tr><td colspan="1"><b>Model E 50</b> <br>(1 batterie)</td><td colspan="1">332 €</td><td colspan="1">85 €*</td><td colspan="1">69 €*</td></tr>' +
                '<tr><td colspan="1">avec 2 batteries</td><td colspan="1">332 €</td><td colspan="1">117 €*</td><td colspan="1">97 €*</td></tr>' +
                '<tr><td colspan="1">avec 3 batteries</td><td colspan="1">332 €</td><td colspan="1">150 €*</td><td colspan="1">125 €*</td></tr>' +
                '<tr><td colspan="1">avec 4 batteries</td><td colspan="1">332 €</td><td colspan="1">182 €*</td><td colspan="1">153 €*</td></tr>' +
                '<tr><td colspan="1"><b>Model E 100</b><br>(2 batteries)</td><td colspan="1">524 €</td><td colspan="1">134 €*</td><td colspan="1">109 €*</td></tr>' +
                '<tr><td colspan="1">avec 3 batteries</td><td colspan="1">524 €</td><td colspan="1">166 €*</td><td colspan="1">137 €*</td></tr>' +
                '<tr><td colspan="1">avec 4 batteries</td><td colspan="1">524 €</td><td colspan="1">199 €*</td><td colspan="1">165 €*</td></tr>' +
                '<tr><td colspan="1"><b>Model E 125</b><br>(4 batteries)</td><td colspan="1">812 €</td><td colspan="1">207 €*</td><td colspan="1">169 €*</td></tr></table>' +
                "Voici l’intégralité de nos offres de financement en LLD (tarifs commandes, sous réserve de validation de votre dossier par notre partenaire financier) : " +
                "<br/>Les montants d'apport et de loyer sont exprimés en TTC, calculés à partir des tarifs au 4/11/2020. Le loyer mensuel peut varier avec l’ajout des frais de livraison." +
                "<br>Frais de dossier client: 99€" +
                "<br/>Simulation pour 8000km / an => 0,12€ / km supplémentaires (au-delà des 8000 km / an)" +
                "<br/>Vous pourrez choisir au moment de finaliser votre commande l’offre de leasing qui vous correspond le mieux." +
                '<br/>Une question ? Contactez-nous : <a href="financement@redelectric.fr">financement@redelectric.fr</a>',
            },
            {
              question:
                "Que se passe-t-il si je commande mais que mon financement en leasing est refusé ?",
              answer:
                "En cas de refus par notre partenaire du financement de l’acquisition de votre Model E, RED Electric s’engage à vous rembourser l’intégralité des sommes déjà versées.",
            },
            {
              question: "Quels sont vos conditions de leasing ?",
              answer:
                "L’ensemble de nos offres de leasing sont proposées en partenariat avec des professionnels du métier. Il s’agit d’offres en LLD, c’est-à-dire qu’à la fin du contrat de 24, 36, 48 ou 60 mois, RED Electric se réserve le droit de récupérer le véhicule." +
                "<br/>Au moment de l’acceptation par notre partenaire financier, l’ensemble des conditions générales et particulières de ce financement vous seront transmises.",
            },
          ],
        },
        {
          title: "Bonus Écologique",
          shortTitle: "Bonus Écologique",
          questions: [
            {
              question: "Ai-je droit à un bonus écologique ?",
              answer:
                `<table class="answer-table"><tr><td colspan="2">1 batterie</td><td colspan="4">2 batteries et plus</td></tr><tr><td colspan="2">Bonus = 605<span class="pad-lr" style="font-family: sans">€</span></td><td colspan="2">Bonus = 900<span class="pad-lr" style="font-family: sans">€</span></td></tr></table>` +
                "<br/>Oui, le Model est un véhicule électrique non polluant et, à ce titre, est éligible au Bonus Écologique, et ce, selon le nombre de batteries embarquées." +
                '<br/>Site Officiel en France : <br> <a href="https://www.service-public.fr/particuliers/vosdroits/F34014">https://www.service-public.fr/particuliers/vosdroits/F34014</href>' +
                '<br/>Faire ma demande :​ <br><a href="https://www.​primealaconversion.gouv.fr">​www.primealaconversion.gouv.fr</a>',
            },
            {
              question:
                "Besoin d'un accompagnement pour obtenir votre Bonus Écologique ?",
              answer:
                "RED Electric propose un service d'accompagnement administratif complet (Option)" +
                '<br/>Contactez-nous : <a href="mailto:bonus@redelectric.fr">​bonus@redelectric.fr</a>',
            },
          ],
        },
        {
          title: "Commande & Livraison",
          shortTitle: "Commande & Livraison",
          questions: [
            {
              question: "Quand et où serai-je livré ?",
              answer:
                `<table class="answer-table"><tr><td colspan="2">Model E50</td><td colspan="2">Model E100</td><td colspan="2">Model E125</td></tr><tr><td colspan="2">À partir de mars 2020</td><td colspan="2">À partir de mars 2020</td><td colspan="2">À partir d'avril 2020</td></tr><tr><td colspan="6">Chez un Concessionnaire Agréé<br>ou<br>Directement à mon domicile (Option)</td></tr></table>` +
                "<br/>Votre scooter pourra vous être livré chez notre Concessionnaire Agréé le plus proche de chez vous. Il vous sera remis prêt à rouler et une prise en main sera réalisée." +
                "<br/>Si vous optez pour la livraison à domicile, notre Service de Livraison Premium vous contactera pour convenir d'un rendez-vous et vous remettra directement votre Model devant chez vous, prêt à rouler. Une prise en main sera réalisée.",
            },
          ],
        },
        {
          title: "Assurance & Immatriculation",
          shortTitle: "Assurance & Immatriculation",
          questions: [
            {
              question:
                "Comment obtenir mon Certificat d'Immatriculation (Carte Grise) ?",
              answer:
                'Le Site Officiel en France est : <a href="https://immatriculation.ants.gouv.fr"> https://immatriculation.ants.gouv.fr</a>' +
                '<br/>Un grand nombre d\'autres sites agréés par les autorités pourront vous accompagner dans vos démarches : <br> <a href="https://www.cartegrise-siv.fr">www.cartegrise-siv.fr</a><br><a href="https://www.cartegriseminute.fr">www.cartegriseminute.fr</a><br><a href="https://www.topcartegrise.fr">www.topcartegrise.fr</a>' +
                `<br/>Besoin d'aide ? Écrivez-nous à :<a href="mailto:cartegrise@redelectric.fr"> cartegrise@redelectric.fr</a>`,
            },
            {
              question: "Quelle assurance pour mon scooter ?",
              answer:
                "2 solutions s'offrent à vous :" +
                "<br/>RED Electric se propose de vous accompagner auprès de ses partenaires assureurs et de vous proposer les meilleures polices d'assurance disponibles." +
                "<br/>Vous pouvez contacter votre courtier ou assureur habituel." +
                "<br/>Le RED Electric étant géolocalisé et ses systèmes dynamiques pouvant être bloqués à distance, les primes sont bien moins chères !" +
                "<br/>Besoin d'aide ? Contactez-nous : <a href='mailto:assurance@redelectric.fr'> assurance@redelectric.fr</a>",
            },
          ],
        },
        {
          title: "Application",
          shortTitle: "Application",
          questions: [
            {
              question:
                "Quand pourrai-je télécharger l'Application RED Electric ?",
              answer:
                "L'App RED Electric sera disponible en Mars 2021 pour le grand public (iOS & Android).",
            },
          ],
        },
        {
          title: "Entretien & Réparation",
          shortTitle: "Entretien & Réparation",
          questions: [
            {
              question: "Que se passe-t-il en cas de panne ?",
              answer:
                "Le Model E dispose d'un Système global de maintenance prédictive pour une fiabilité optimisée : L'ensemble des pièces essentielles sont monitorées en permanence. Le système détecte à l'avance tout problème avant qu'il n'arrive. Vous êtes immédiatement prévenu. Les données sont enregistrées dans le scooter et envoyées sur le Cloud pour vous être transmises via l'App RED et informer votre SAV." +
                '<br/>En cas, de panne, vous pouvez nous écrire à :<a href="​support@redescooter.com">  support@redelectric.fr​</a> – ou appeler notre Hotline (Le numéro vous sera transmis en même temps que la livraison de votre RED).' +
                "<br/>Nous interviendrons directement chez vous – ou par l'intermédiaire de l'un de nos Centres de Service Agréé, disponibles partout !" +
                "<br/>Nous vous proposerons un Service de Révisions & de Maintenance Tout-Inclus (Hors accident et utilisation anormale du véhicule) comprenant également le changement des pneus et des pièces d'usure au moment de finaliser votre paiement, quelque temps avant la livraison.",
            },
            {
              question: "Dois-je faire réviser mon Model E régulièrement ?",
              answer:
                "Votre Scooter doit être révisé par un Technicien Agréé tous les 5000 km – ou une fois par an. Une première révision est prévue à 1000km." +
                "<br/>Attention : Le non-respect de cette règle entraîne la déchéance de notre Garantie Constructeur." +
                "<br/>Nous vous proposerons un Service de Révisions & de Maintenance Tout-Inclus (Hors accident et utilisation anormale du véhicule) comprenant également le changement des pneus et des pièces d'usure au moment de finaliser votre paiement, quelque temps avant la livraison.",
            },
          ],
        },
        {
          title: "Conduite & Usage",
          shortTitle: "Conduite & Usage",
          questions: [
            {
              question: "Que se passe-t-il si je me fais voler mon scooter ?",
              answer:
                "Tous nos Scooters sont géolocalisés en temps réel (GPS). Vous pourrez immédiatement le localiser et en bloquer l'intégralité des systèmes [Moteur – Batteries – Roues] à distance via l'App RED Electric." +
                "<br/>Il ne vous restera plus qu'à prévenir les Forces de Sécurité Publique pour le récupérer.<br>Votre assureur devrait apprécier – et son tarif en sera très amélioré !" +
                "<br/>Besoin d'aide ? Écrivez-nous à :​ <a href='mailto:support@redelectric.fr'> support@redelectric.fr</a>",
            },
          ],
        },
        {
          title: "Accompagnement RED Electric",
          shortTitle: "Accompagnement",
          questions: [
            {
              question: "Comment suis-je accompagné ?",
              answer: `Plus de 50 collaborateurs sont à votre disposition tous les jours pour vous garantir un Service Après-Vente de Qualité, directement opéré chez vous, ou au plus près de votre domicile, en 24 à 72 heures.
              <br>
              <br>
              Service client RED Electric : <a href="mailto:support@redelectric.fr">support@redelectric.fr</a>
              <br>
              <br>
              Hotline : Le numéro vous sera transmis en même temps que la livraison de votre RED.`,
            },
            {
              question: "Comment obtenir des informations complémentaires ?",
              answer:
                'Contactez-nous : ​<a href="mailto:support@redelectric.fr">support@redelectric.fr</a>',
            },
            {
              question: "Comment contacter RED Electric ?",
              answer:
                'Vous pouvez nous joindre par e-mail à cette adresse <a href"mailto:support@redelectric.fr">support@redelectric.fr</a> ou par téléphone au 01 44 01 53 00 du lundi au vendredi de 9h à 19h.',
            },
          ],
        },
        {
          title: "Entretien & Réparation",
          shortTitle: "Entretien & Réparation",
          questions: [
            {
              question:
                "En quoi mon empreinte-carbone est-elle réduite grâce à l'acquisition d'un Scooter Électrique RED ?",
              answer:
                "Nos scooters sont fabriqués en tenant compte en permanence de l'impact de nos opérations sur l'environnement et nous optimisons l'indice de réparabilité de nos véhicules au maximum des possibilités technologiques de résistance des matériaux actuels." +
                "<br/>Un scooter électrique ne consomme pas de carburant, son énergie est l'électricité qui, selon son mode de production (Nucléaire, Éolien, Charbon, Hydraulique) est plus ou moins vertueuse." +
                "<br/>Quoi qu'il en soit, l'électricité consommée par un moteur électrique a un impact carbone infiniment inférieur à celui d'un moteur thermique de puissance similaire." +
                "<br/>De plus, aucun gaz ni aucune particule ne sont rejetés." +
                "<br/>Silencieux, un scooter électrique est le meilleur outil contre la pollution sonore dans nos centres urbains. Polyvalent, il permet d'assurer de courts parcours quotidiens et, si son autonomie le permet (c'est le cas du RED avec son record du monde d'autonomie réelle !) de plus longues balades et trajets intercités - sans changer de mode de transport." +
                "<br/>Enfin, la durée de vie des Batteries RED embarquées (2500 cycles contre 800 à 1000 cycles en général) diminue de façon drastique la consommation de matières premières rares et optimise l'indice de longévité globale." +
                "<br/>En fait, quelques dizaines d'euros d'électricité vous suffiront pour parcourir 10 000 km !" +
                "<br/>Quelques chiffres :" +
                "<br/>Ratio CO​2​ d'un scooter essence = 0,071kg/km" +
                "<br/>Ratio CO​2 du RED = < 0,001 kg/km (Variable selon le mode de production de l'électricité)." +
                "<br/>Quelques exemples :" +
                "<br/>Je roule 10km /jour, j'économise 300kg de ​CO2​ / an" +
                "<br/>Je roule 20km /jour, j'économise 520kg de ​CO2 ​/ an" +
                "<br/>Je roule 50km / jour, j'économise 1,3 tonne de ​CO2​ / an",
            },
          ],
        },
      ],
    },
    forgotPasswordPage: {
      title: "Mot de passe oublié?",
      info:
        "Mot de passe oublié? Entrez votre adresse e-mail pour commencer le processus de réinitialisation",
      emailLabel: "Email",
      emailInvalid: "Email non valide",
      emailRequired: "Ce champ est obligatoire",
      submitButton: "Envoyer",
      loading: "Chargement",
      loginLabel: "Déjà un compte ?",
      loginButton: "Se connecter",
      successTitle: "Réussi",
      successMessage:
        "Un email vous a été envoyé pour réinitialiser votre mot de passe",
      backToLogin: "Retour à la page de connexion",
    },
    registrationPage: {
      title: "Créer un compte",
      registrationButton: "Créer un compte",
      loading: "Chargement",
      loginLabel: "Déjà un compte ?",
      loginButton: "Se connecter",
      form: {
        firstNameLabel: "Prénom",
        lastNameLabel: "Nom",
        emailLabel: "Email",
        emailInvalid: "Email invalide",
        phoneLabel: "Numéro de téléphone",
        phoneInvalid: "numéro invalide",
        passwordLabel: "Mot de passe",
        passwordConfirmLabel: "Confirmez le mot de passe",
        addressLabel: "Adresse",
        districtLabel: "Code postal",
        cityLabel: "Ville",
        countryLabel: "Pays",
        required: "Ce champ est obligatoire",
        addressAutocompleteLoadingResults: "Chargement en cours",
        addressAutocompleteNoResults: "Aucun resultat",
        passwordsNotMatch: "Le mot de passe doit être identique",
      },
    },
    password: {
      indication:
        "Certains mot et prénoms parmi les plus fréquents peuvent également être interdits.",
      conditions: "Votre mot de passe doit remplir 4 de ces 5 conditions",
      security: "Sécurité",
      weak: "Faible",
      normal: "Normal",
      strong: "Fort",
      has_min_length: "Au moins 8 caractères (obligatoire)",
      has_uppercase: "Au moins une majuscule",
      has_lowercase: "Au moins une minuscule",
      has_number: "Au moins un chiffre",
      has_special_character: "Au moins un caractère spécial",
    },
    loginPage: {
      emailLabel: "Email",
      emailInvalid: "Email non valide",
      emailRequired: "Ce champ est obligatoire",
      passwordLabel: "Mot de passe",
      passwordRequired: "Ce champ est obligatoire",
      forgotPasswordLabel: "Mot de passe oublié ?",
      loginButton: "S'identifier",
      loading: "Chargement",
      createAccountLabel: "Vous n'avez pas de compte ?",
      createAccountButton: "S'inscrire",
    },
    changePasswordPage: {
      title: "Changer le mot de passe",
      oldPasswordLabel: "Ancien mot de passe",
      newPasswordLabel: "Nouveau mot de passe",
      confirmPasswordLabel: "Confirmez le mot de passe",
      passwordsNotMatch: "Le mot de passe doit être identique",
      required: "Ce champ est obligatoire",
      submitButton: "Changer le mot de passe",
      loading: "Chargement",
      successTitle: "Réussi",
      successMessage: "Votre mot de passe a été modifié",
      backToAccount: "Retour à mon compte",
    },
    accountPage: {
      title: "Mon compte",
      myInfoTitle: "Mes informations",
      updateInfoLabel: "Mettre à jour",
      infoUpdateSuccess: "Sauvegarde réussie",
      infoUpdateError: "Erreur",
      myPasswordTitle: "Mon mot de passe",
      myPasswordButton: "Changer mon mot de passe",
      myOrdersTitle: "Mes commandes",
      viewOrEditOrderLabel: "Voir ou éditer",
      orderedOnLabel: "Date de commande :",
      orderNumberLabel: "Commande N° :",
      paymentStatusLabel: "Statut :",
      logoutTitle: "Ma session",
      logoutLabel: "Déconnexion",
      successMessage: "Votre compte a été mis à jour",
    },
    orderMatching: {
      colors: {
        3: "champagne",
        4: "carbon",
        5: "black",
      },
      makes: {
        1: "Model E50",
        2: "Model E100",
        3: "Model E125",
      },
      statuses: {
        1: "Acompte non payé",
        2: "Acompte payé",
        3: "Commandé",
        4: "Payé",
        5: "Annulé",
      },
    },
    matchingPage: {
      title: "Bienvenue chez RED Electric.",
      subtitle:
        "Dans cet espace, vous allez pouvoir retrouver votre commande passée chez l'un de nos partenaires, et vous créer un compte chez RED Electric.",
      phoneFieldLabel: "Numéro de téléphone mobile *",
      phoneFieldRequired: "Numéro de téléphone requis",
      phoneFieldHelper: "Ex: +33607080910",
      phoneFieldInvalid: "Numéro de téléphone invalide",
      emailFieldLabel: "Email *",
      emailFieldRequired: "Email obligatoire",
      emailFieldHelper: "Ex: vous@email.com",
      emailFieldInvalid: "Email invalide",
      orderNumberFieldLabel: "Numéro de commande *",
      orderNumberFieldRequired: "Champ obligatoire",
      orderNumberFieldHelper: "Ex: 0XXXXXXXXX",
      orderNumberFieldInvalid: "Numéro de commande invalide",
      loading: "Chargement...",
      submitButton: "Continuer",
      matchUsingEmailLabel: "Procéder avec mon adresse email",
      matchUsingPhoneLabel: "Procéder avec mon numéro de téléphone mobile",
      notYourOrderButton: "Ce n'est pas votre commande ?",
      yourNewAccountTitle: "Votre compte de suivi de commande",
      yourNewAccountDescription:
        "Pour activer la livraison de votre commande et suivre son évolution, veuillez vous créer un compte RED Electric. Il vous suffit de remplir le formulaire ci dessous.",
      hasAnAccountButton: "Vous avez déjà un compte  ?",
      loginButton: "Se connecter",
      noAccountButton: "Pas de compte ?",
      registerButton: "S'enregister",
      helpModalTitle: "Ce n'est pas votre commande ?",
      helpModalDescription: "Merci de contacter notre service client :",
      helpModalClose: "Fermer",
      helpModalPhoneLabel1: "01 44 01 53 00",
      helpModalPhoneLabel2: "du lundi au vendredi de 10h à 19h",
      helpModalEmailLabel: "support@redelectric.fr",
    },
    footer: {
      copyright: "© 2020 RED E Group. Tous droits réservés.",
      followUs: "Nous suivre",
      conditions: "CGV/CGU",
      sitePlan: "Plan du site",
      legalMention: "Mentions légales",
      contact: "Contact",
      press: "Presse",
      professional: "Professionnels",
    },
  };

  static stores = [
    {
      id: 1,
      name: "GaasWatt - Marseille",
      address: "145 Rue d'Endoume",
      city: "Marseille",
      postalCode: "13000",
      phone: "04 65 58 04 05",
      lat: 43.286161,
      long: 5.361995,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 2,
      name: "Autoloisirs Royan",
      address: "10 Route de Bordeaux",
      phone: "05 46 23 72 30",
      city: "Semussac",
      postalCode: "17120",
      lat: 45.60328801,
      long: -0.9090935724,
      isGarage: true,
      isDealer: true,
    },
    {
      id: 3,
      name: "Autoloisirs La Rochelle",
      address: "13 Rue Louis de Bougainville - ZAC de Belle Aire Nord",
      phone: "05 46 55 08 03",
      postalCode: "17440",
      city: "Aytre",
      lat: 46.13131682,
      long: -1.090126086,
      isGarage: true,
      isDealer: true,
    },
    {
      id: 4,
      name: "MDS Racing",
      address: "26 Avenue Ribot",
      phone: "05 55 87 57 26",
      city: "Brive La Gaillarde",
      postalCode: "19100",
      lat: 45.16506176,
      long: 1.517343101,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 5,
      name: "Gil Moto",
      address: "13 Rue Sully",
      city: "Nimes",
      postalCode: "30000",
      phone: "04 66 36 18 16",
      lat: 43.841636,
      long: 4.371305,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 6,
      name: "Autoloisirs Libourne",
      address: "26 bis avenue de l'Epinette",
      city: "Libourne",
      postalCode: "33500",
      phone: "05 57 88 88 90",
      lat: 44.91207713,
      long: -0.2341417147,
      isGarage: true,
      isDealer: true,
    },
    {
      id: 7,
      name: "Autoloisirs Libourne",
      address: "16 rue Thalès",
      city: "Mérignac",
      postalCode: "33700",
      phone: "05 57 88 88 90",
      lat: 44.85308956,
      long: -0.6746882288,
      isGarage: true,
      isDealer: true,
    },
    {
      id: 8,
      name: "Mobilect Rennes",
      address: "314 routes de Fougères (ZA des Longs-champs)",
      city: "Rennes",
      postalCode: "35000",
      phone: "02 99 45 05 97",
      lat: 48.13319788,
      long: -1.63456897,
      isGarage: true,
      isDealer: true,
    },
    {
      id: 9,
      name: "Mobilect Saint Malo",
      address: "3 rue Nicolas Bouvier",
      city: "Saint Malo",
      postalCode: "35400",
      phone: "02 23 17 49 22",
      lat: 48.647687,
      long: -2.008055,
      isGarage: true,
      isDealer: true,
    },
    {
      id: 10,
      name: "Mobilect Montgermont",
      address: "5 rue de la Marebaudière",
      city: "Montgermont",
      postalCode: "35760",
      phone: "02 50 08 19 50",
      lat: 48.150656,
      long: -1.719627,
      isGarage: true,
      isDealer: true,
    },
    {
      id: 11,
      name: "Pole Scoot",
      address: "11 Boulevard Paul Langevin",
      city: "Grenoble",
      postalCode: "38600",
      phone: "04 76 46 46 56",
      lat: 42.68545,
      long: 2.874195,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 12,
      name: "Scoot Cash - Reims",
      address: "32 Place des Argonautes",
      city: "Reims",
      postalCode: "51100",
      phone: "03 26 06 28 85",
      lat: 49.226938,
      long: 4.036411,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 13,
      name: "AT Motors",
      address: "4 Rue du Maine",
      city: "Wittenheim",
      postalCode: "68270",
      phone: "02 50 08 19 50",
      lat: 48.150656,
      long: -1.719627,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 14,
      name: "Fun Mobility Service",
      address: "191 Rue du Maréchal Foch",
      city: "Loos",
      postalCode: "59120",
      phone: "03 20 37 23 39",
      lat: 50.614288,
      long: 3.012253,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 15,
      name: "Mobilitix Alençon",
      address: "10 rue de la cave aux boeufs",
      city: "Alençon",
      postalCode: "61000",
      phone: "02 33 28 53 87",
      lat: 48.43056993,
      long: 0.0869746413,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 16,
      name: "JR Auto Scoot",
      address: "19 Rue Saglio",
      city: "Strasbourg",
      postalCode: "67100",
      phone: "07 67 45 62 46",
      lat: 48.555666,
      long: 7.741699,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 17,
      name: "E-DC Center - Lyon",
      address: "107 Avenue Maréchal de Saxe",
      city: "Lyon",
      postalCode: "69003",
      phone: "06 61 82 22 23",
      lat: 45.75906,
      long: 4.846148,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 18,
      name: "Scoot Parc - Annemasse",
      address: "23 Rue du Parc",
      city: "Annemasse",
      postalCode: "74100",
      phone: "04 50 38 57 31",
      lat: 46.193776,
      long: 6.229997,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 19,
      name: "Mobilitix Le Mans",
      address: "19 Avenue Robert Jarry",
      city: "Le Mans",
      postalCode: "72000",
      phone: "02 72 16 50 20",
      lat: 48.01407194,
      long: 0.1890038703,
      isGarage: true,
      isDealer: true,
    },
    {
      id: 20,
      name: "Mobilitix Rennes Sud",
      address: "17 avenue Janvier",
      city: "RENNES",
      postalCode: "35000",
      phone: "02 23 40 45 86",
      lat: 48.10701268,
      long: -1.673297755,
      isGarage: true,
      isDealer: true,
    },
    {
      id: 21,
      name: "Mobilitix Nantes Nord",
      address: "4 boulev ard des Martyrs Nantais",
      city: "Nantes",
      postalCode: "44000",
      phone: "02 40 20 53 98",
      lat: 47.20654539,
      long: -1.548538445,
      isGarage: true,
      isDealer: true,
    },
    {
      id: 22,
      name: "Doc Biker Paris 3ème",
      address: "44 Boulevard de Sébastopol",
      city: "Paris",
      postalCode: "75003",
      phone: "01 43 36 36 50",
      lat: 48.862218,
      long: 2.350598,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 23,
      name: "Doc Biker Paris 10ème",
      address: "33 Boulevard Magenta",
      city: "Paris",
      postalCode: "75010",
      phone: "01 48 03 34 74",
      lat: 48.871623,
      long: 2.359723,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 24,
      name: "Doc Biker Paris 11ème",
      address: "61 rue Amelot",
      city: "Paris",
      postalCode: "75011",
      phone: "01 55 28 30 47",
      lat: 48.858478,
      long: 2.368408,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 25,
      name: "MAZE Garage électrique",
      address: "112 Boulevard Voltaire",
      city: "Paris",
      postalCode: "75011",
      phone: "09 87 72 22 76",
      lat: 48.859086,
      long: 2.377639,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 26,
      name: "Doc Biker Paris 12ème",
      address: "11 Boulevard Soult",
      city: "Paris",
      postalCode: "75012",
      phone: "01 44 68 80 30",
      lat: 48.836317,
      long: 2.407117,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 27,
      name: "Doc Biker Paris 13ème",
      address: "86 bis boulevard de l'Hôpital",
      city: "Paris",
      postalCode: "75013",
      phone: "01 47 07 41 47",
      lat: 48.837273,
      long: 2.359201,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 28,
      name: "Doc Biker Paris 14ème",
      address: "185 Avenue du Maine",
      city: "Paris",
      postalCode: "75014",
      phone: "01 45 40 52 49",
      lat: 48.830744,
      long: 2.326016,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 29,
      name: "Doc Biker Paris 15ème Garibaldi",
      address: "76 - 78 Boulevard Garibaldi",
      city: "Paris",
      postalCode: "75015",
      phone: "01 53 86 89 05",
      lat: 48.845508,
      long: 2.30878,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 30,
      name: "Doc Biker Paris 15ème Vouillé",
      address: "44 rue de Vouillé",
      city: "Paris",
      postalCode: "75015",
      phone: "01 45 33 58 96",
      lat: 48.834074,
      long: 2.306995,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 30,
      name: "Doc Biker Paris 16ème",
      address: "49 Avenue de Versailles",
      city: "Paris",
      postalCode: "75016",
      phone: "09 50 64 44 89",
      lat: 48.848337,
      long: 2.274957,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 31,
      name: "Doc Biker Paris 17ème",
      address: "134 Boulevard Berthier",
      city: "Paris",
      postalCode: "75017",
      phone: "01 47 66 49 49",
      lat: 48.887965,
      long: 2.298975,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 32,
      name: "Doc Biker Paris 19ème",
      address: "141 Avenue Jean Jaurès",
      city: "Paris",
      postalCode: "75019",
      phone: "01 48 03 19 19",
      lat: 48.886688,
      long: 2.384311,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 33,
      name: "Autoloisirs Bressuire",
      address: "1 bis rue du Moulin Jacquet",
      city: "Bressuire",
      postalCode: "79300",
      phone: "02 51 00 34 69",
      lat: 46.83503533,
      long: -0.4748310164,
      isGarage: true,
      isDealer: true,
    },
    {
      id: 34,
      name: "Mob'Elec - Toulon",
      address: "232 Boulevard Général Brosset",
      city: "Toulon",
      postalCode: "83200",
      phone: "04 94 93 42 48",
      lat: 43.128765,
      long: 5.903788,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 35,
      name: "Autoloisirs Fontenay le comte",
      address: "3 Rue du Dr Guerry",
      city: "Fontenay le compte",
      postalCode: "85200",
      phone: "02 51 00 34 69",
      lat: 46.46494792,
      long: -0.8252448029,
      isGarage: true,
      isDealer: true,
    },
    {
      id: 36,
      name: "Autoloisirs Chalans",
      address: "33 Rue de Cholet",
      city: "Challans",
      postalCode: "85300",
      phone: "02 28 12 95 43",
      lat: 46.84976139,
      long: -1.865174815,
      isGarage: true,
      isDealer: true,
    },
    {
      id: 37,
      name: "Doc Biker Boulogne",
      address: "86 Avenue Edouard Vaillant",
      city: "Boulogne Billancourt",
      postalCode: "92100",
      phone: "01 47 61 10 28",
      lat: 48.834476,
      long: 2.24565,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 38,
      name: "Doc Biker Clichy",
      address: "10 Boulevard du Général Leclerc",
      city: "Clichy",
      postalCode: "92110",
      phone: "01 46 17 41 93",
      lat: 48.900238,
      long: 2.317954,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 39,
      name: "Doc Biker Montrouge",
      address: "82 Avenue Aristide Briand",
      city: "Montrouge",
      postalCode: "92120",
      phone: "01 49 85 23 05",
      lat: 48.815683,
      long: 2.325379,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 40,
      name: "Doc Biker Issy les Moulineaux",
      address: "10 bis rue rouget de Lisle",
      city: "Issy Les Moulineaux",
      postalCode: "92130",
      phone: "01 46 62 63 64",
      lat: 48.828817,
      long: 2.264041,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 41,
      name: "Doc Biker Montreuil",
      address: "86 rue de Paris",
      city: "Montreuil",
      postalCode: "93100",
      phone: "01 48 58 88 37",
      lat: 48.856919,
      long: 2.428986,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 42,
      name: "Doc Biker Bezons",
      address: "179 Avenue Gabriel Péri",
      city: "Bezons",
      postalCode: "95870",
      phone: "01 39 98 73 62",
      lat: 48.936824,
      long: 2.206965,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 43,
      name: "Icoolwheel",
      address: "6 Rue René Laennec",
      city: "Coignères",
      postalCode: "78310",
      phone: "09 72 28 49 38",
      lat: 48.754435,
      long: 1.931166,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 44,
      name: "Accel'R Moto 67",
      address: "ZI 16 rue de La Sabliere ",
      city: "Schweighouse-sur-Moder",
      postalCode: "67590",
      phone: "03 88 09 88 08",
      lat: 48.828537,
      long: 7.752141,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 45,
      name: "Dynamo Cycles Avignon",
      address: "109 Avenue Pierre Semard",
      city: "Avignon",
      postalCode: "84000",
      phone: "04 90 01 86 68",
      lat: 43.936369,
      long: 4.828244,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 46,
      name: "Nice Scoot",
      address: "23 Route de Turin",
      city: "Nice",
      postalCode: "06300",
      phone: "04 97 19 17 75",
      lat: 43.709536,
      long: 7.284284,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 47,
      name: "OVER WATT",
      address: "80 Place Georges Frêche",
      city: "Montpellier",
      postalCode: "34000",
      phone: "04 67 22 02 29",
      lat: 43.59928435,
      long: 3.896033587,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 48,
      name: "AS MOTORS",
      address: "34 avenue Gallieni",
      city: "Nice",
      postalCode: "06000",
      phone: "04 93 92 24 81",
      lat: 43.704476,
      long: 7.279775,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 49,
      name: "ZE Trott' Store Grenoble",
      address: "10 Boulevard Agutte Sembat, 38000 Grenoble",
      city: "Grenoble",
      postalCode: "38000",
      phone: "09 52 21 96 01",
      lat: 45.188165,
      long: 5.726139,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 50,
      name: "Bull'Motos",
      address: "890 Avenue des Paluds",
      city: "Aubagne",
      postalCode: "13400",
      phone: "04 42 82 99 94",
      lat: 43.28983063,
      long: 5.596080426,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 51,
      name: "Gordi Moto Services",
      address: "95 Avenue de Palavas",
      city: "Montpellier",
      postalCode: "34000",
      phone: "06 25 57 85 35",
      lat: 43.59424245,
      long: 3.889278757,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 52,
      name: "RESISTH / Groupe Videal",
      address: "ZAC du Plan - 159 allée Barbaroux",
      city: "ENTRAIGUES SUR LA SORGUE",
      postalCode: "84320",
      phone: "04 32 60 63 50",
      lat: 43.98084522,
      long: 4.933296132,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 53,
      name: "ON TRACK",
      address: "8 B AV de BRETAGNE",
      city: "Lille",
      postalCode: "59000",
      phone: "07 82 37 11 80",
      lat: 50.63614966,
      long: 3.025950757,
      isGarage: true,
      isDealer: true,
    },
    {
      id: 54,
      name: "Moto Clinic",
      address: "1, rue nouvelle",
      city: "Faches Thumesnil",
      postalCode: "59000",
      phone: "03 20 96 54 50",
      lat: 50.60474413,
      long: 3.057468341,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 55,
      name: "Martin moto",
      address: "137 avenue Pierre Brossolette",
      city: "TROYES",
      postalCode: "10000",
      phone: "03 25 82 53 76",
      lat: 48.28776586,
      long: 4.077428741,
      isGarage: true,
      isDealer: false,
    },
    {
      id: 56,
      name: "DARTY TERNES",
      address: "8 avenue des Ternes ",
      city: "Paris",
      postalCode: "75017",
      phone: "",
      lat: 48.87850967707505,
      long: 2.296474729668541,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 57,
      name: "FNAC MONTPARNASSE",
      address: "136 rue de rennes",
      city: "Montparnasse",
      postalCode: "75006",
      phone: "",
      lat: 48.84596225628001,
      long: 2.3253866944774257,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 58,
      name: "DARTY NATION",
      address: "21 Cours de Vincennes",
      city: "Paris",
      postalCode: "75020",
      phone: "",
      lat: 48.84852898200806,
      long: 2.4014079566510222,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 59,
      name: "DARTY REPUBLIQUE",
      address: "1 AV de la république ",
      city: "Paris",
      postalCode: "75011",
      phone: "",
      lat: 48.8672545291975,
      long: 2.3660893566514067,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 60,
      name: "FNAC TERNES",
      address: "18/22 Avenue des Ternes, ",
      city: "Paris",
      postalCode: "75017",
      phone: "",
      lat: 48.87914754901626,
      long: 2.294856970143199,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 61,
      name: "DARTY FORUM",
      address: "35 Rue du Pont Neuf",
      city: "Paris",
      postalCode: "75001",
      phone: "",
      lat: 48.86204319247536,
      long: 2.344967712470562,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 62,
      name: "DARTY GENNEVILLIERS",
      address: "106 avenue du vieux chemin de saint Denis",
      city: "GENNEVILLIERS",
      postalCode: "92230",
      phone: "",
      lat: 48.93090863197897,
      long: 2.31903077199736,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 63,
      name: "FNAC LA DEFENSE",
      address: "2, place de La Défense CNIT",
      city: "La Défense",
      postalCode: "92053",
      phone: "",
      lat: 48.892347712376115,
      long: 2.23988395665193,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 64,
      name: "DARTY CHATILLON",
      address: "151 Avenue de Paris",
      city: "Châtillon",
      postalCode: "92320",
      phone: "",
      lat: 48.81023175217359,
      long: 2.2979266836333117,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 65,
      name: "DARTY PLAISIR",
      address: "Zone Commerciale Grand, Rue Francis Carco",
      city: "Plaisir",
      postalCode: "78370",
      phone: "",
      lat: 48.82682681677508,
      long: 1.966804943159032,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 66,
      name: "DARTY BONDY",
      address: "143 Ave Gallieni",
      city: "Bondy",
      postalCode: "93140",
      phone: "",
      lat: 48.907167624108666,
      long: 2.4837238566522286,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 67,
      name: "FNAC ROSNY 2",
      address: "Centre Commercial Rosny 2, Avenue du Général de Gaulle",
      city: "Rosny-sous-Bois",
      postalCode: "93110",
      phone: "",
      lat: 48.881616377715766,
      long: 2.4791170971263754,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 68,
      name: "DARTY NICE NOTRE DAME",
      address: "28 avenue Notre Dame",
      city: "NICE",
      postalCode: "06000",
      phone: "",
      lat: 43.70383226451604,
      long: 7.266590768186882,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 69,
      name: "FNAC NICE",
      address: "44-46 avenue Jean Médecin",
      city: "NICE",
      postalCode: "06000",
      phone: "",
      lat: 43.70550094618365,
      long: 7.266926945319446,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 70,
      name: "DARTY MARSEILLES-TERRASSES",
      address: "9 Quai du Lazaret",
      city: "Marseille",
      postalCode: "13002",
      phone: "",
      lat: 43.30684388098728,
      long: 5.364186498868447,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 71,
      name: "FNAC CANNES",
      address: "83 Rue d'Antibes",
      city: "Cannes",
      postalCode: "06400",
      phone: "",
      lat: 43.5527022673921,
      long: 7.023746498873143,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 72,
      name: "DARTY LYON PART-DIEU",
      address: "Centre Commercial la Part-Dieu, 17 rue docteur Bouchut",
      city: "Lyon",
      postalCode: "69003",
      phone: "",
      lat: 45.76074570984701,
      long: 4.85548578542448,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 73,
      name: "FNAC LYON BELLECOUR",
      address: "138 Rue de la République",
      city: "Lyon",
      postalCode: "69002",
      phone: "",
      lat: 45.75849229818631,
      long: 4.834770441243739,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 74,
      name: "DARTY ANNEMASSE",
      address: "Zone industrielle du, 2, rue des Esserts, Rue du Mont Blanc",
      city: "Annemasse",
      postalCode: "74100",
      phone: "",
      lat: 46.198095659197946,
      long: 6.264235398924698,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 75,
      name: "FNAC GRENOBLE VICTOR HUGO",
      address: "4 rue Saint François",
      city: "Grenoble",
      postalCode: "38000",
      phone: "",
      lat: 45.19059486934821,
      long: 5.726221097051776,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 75,
      name: "FNAC BAYONNE",
      address: "FNAC BAYONNE",
      city: "Bayonne",
      postalCode: "64100",
      phone: "",
      lat: 43.486659857109096,
      long: -1.491549687636546,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 76,
      name: "FNAC BORDEAUX",
      address: "3 rue Arnaud Miqueu",
      city: "BORDEAUX",
      postalCode: "33000",
      phone: "",
      lat: 44.840111638261895,
      long: -0.5739915857574547,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 77,
      name: "FNAC LILLE",
      address: "1 Rue des Ponts de Comines",
      city: "Lille",
      postalCode: "59800",
      phone: "",
      lat: 50.63595515418969,
      long: 3.06483978471217,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 78,
      name: "FNAC ROUEN",
      address: "8 rue de la Poterne",
      city: "Rouen",
      postalCode: "76000",
      phone: "",
      lat: 49.44280389086448,
      long: 1.0933621413188515,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 79,
      name: "FNAC NANCY",
      address: "4 Place André Maginot",
      city: "Nancy",
      postalCode: "54000",
      phone: "",
      lat: 48.689863828876334,
      long: 6.176324525958536,
      isGarage: false,
      isDealer: true,
    },
    {
      id: 80,
      name: "FNAC TOULOUSE WILSON",
      address: "79 Boulevard Lazare Carnot",
      city: "Toulouse",
      postalCode: "31000",
      phone: "",
      lat: 43.60552903067759,
      long: 1.4486659142187641,
      isGarage: false,
      isDealer: true,
    },
  ];
}
