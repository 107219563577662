import css from "./Header.module.less";
import React, { useEffect, useRef, useState } from "react";
import { merge } from "@common/lib/helpers/classNameHelper";
import GlobalConfig from "../../../common/data/GlobalConfig";
import Logo from "./logo.png";
import { useScroll } from "../../hooks/useScroll";
import { gsap } from "gsap";
import { Link as RouterLink } from "react-router-dom";
import { Button, Hidden } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { onClickOrderButton } from "../../app/gtmMiddleware";
const componentName = "Header";
const debug = require("debug")(`front:${componentName}`);

interface IProps {
  classNames?: string[];
  pageVisible?: boolean;
  enableSubNav?: boolean;
  subNavLinks?: any[];
}

Header.defaultProps = {
  pageVisible: false,
  enableSubNav: true,
};

const useStyles = makeStyles((t: Theme) =>
  createStyles({
    conf_btn: {
      width: "auto",
      color: "white",
      fontSize: "1rem",
      position: "absolute",
      borderRadius: 0,
      right: 0,
      top: 0,
      height: "100%",
      textTransform: "uppercase",
    },
  })
);

/**
 * @name Header
 */
function Header(props: IProps) {
  // --------------------------------------------------------------------------- PREPARE

  // -- Hooks
  const scroll = useScroll();

  // -- States
  const [headerIsShrinked, setHeaderIsShrinked] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [hasNavbar, setHasNavbar] = useState(false);

  // -- Refs
  const animatedElementsRefs = useRef([]);
  const menuRef = useRef(null);
  const preorderTipRef = useRef(null);
  const lastScrollValue = useRef(0);
  const classes = useStyles();

  // -- Data
  let trans = GlobalConfig.appData.layout.header;

  useEffect(() => {
    animateHeaderIn();
    resizeHeader();
  }, []);

  const resizeHeader = () => {
    // verify if there is a a navabr
    if (document.getElementById("subheader-navbar")) {
      setHasNavbar(true);
    } else {
      setHasNavbar(false);
    }
  };

  useEffect(() => {
    if (scroll < lastScrollValue.current || scroll < 100)
      setHeaderIsShrinked(false);
    else if (scroll > lastScrollValue.current && scroll >= 100)
      setHeaderIsShrinked(true);
    lastScrollValue.current = scroll;
  }, [scroll]);

  const { pathname } = useLocation();
  useEffect(() => {
    lastScrollValue.current = 9999; // fix allowing the header to keep the right height on page change
    resizeHeader();
  }, [pathname]);

  // --------------------------------------------------------------------------- ANIMATION

  /**
   * Animate in header
   * @param pDuration
   */
  function animateHeaderIn(pDuration = 1) {
    gsap.to(animatedElementsRefs.current, {
      yPercent: 0,
      ease: "Power2.easeInOut",
      duration: pDuration,
      delay: pDuration * 0.5,
      stagger: pDuration * 0.2,
    });
  }

  // --------------------------------------------------------------------------- RENDER

  return (
    <div className={merge([css.Root, props.classNames])}>
      <div
        className={merge([
          css.mainHeader,
          headerIsShrinked ? css.mainHeader_shrinked : "",
          hasNavbar ? "" : css.mainHeader_no_navbar,
        ])}
      >
        <div
          className={`${css.menuToggleButton} ${
            showMenu && css.menuToggleButton_active
          } `}
          onClick={() => setShowMenu(!showMenu)}
        >
          <div className={css.menuToggleButton_bar_1} />
          <div className={css.menuToggleButton_bar_2} />
          <div className={css.menuToggleButton_bar_3} />
        </div>
        <div className={css.logo_wrapper}>
          <a href="/">
            <img
              ref={(el) => (animatedElementsRefs.current[0] = el)}
              className={css.logo}
              src={Logo}
              alt={"Red Electric logo"}
              onClick={() => {
                //scrollToTop(1000);
              }}
            />
          </a>
        </div>

        <Hidden smUp>
          <Button
            size="small"
            variant="contained"
            color="primary"
            ref={(el) => (animatedElementsRefs.current[5] = el)}
            className={classes.conf_btn}
            href={trans.configure.link}
            children={trans.configure.label}
            onClick={()=> onClickOrderButton()}
          />
        </Hidden>

        <div className={`${css.menu} ${showMenu && css.menu_active} `}>
          <div
            ref={menuRef}
            className={css.menu_col}
            onClick={() => {
              setShowMenu(false);
            }}
          >
            <RouterLink
              ref={(el) => (animatedElementsRefs.current[1] = el)}
              className={css.menuElement}
              to={trans.models.link}
              children={trans?.models?.label}
            />
            <RouterLink
              ref={(el) => (animatedElementsRefs.current[1] = el)}
              className={css.menuElement}
              to={trans.technology.link}
              children={trans?.technology?.label}
            />
            <RouterLink
              ref={(el) => (animatedElementsRefs.current[1] = el)}
              className={css.menuElement}
              to={trans.chooseElectric.link}
              children={trans?.chooseElectric?.label}
            />
            <RouterLink
              ref={(el) => (animatedElementsRefs.current[1] = el)}
              className={css.menuElement}
              to={trans.insurance.link}
              children={trans?.insurance?.label}
            />
            <RouterLink
              ref={(el) => (animatedElementsRefs.current[1] = el)}
              className={css.menuElement}
              to={trans.finance.link}
              children={trans?.finance?.label}
            />
            <RouterLink
              ref={(el) => (animatedElementsRefs.current[2] = el)}
              className={css.menuElement}
              to={trans.about.link}
              children={trans?.about?.label}
            />
            <RouterLink
              ref={(el) => (animatedElementsRefs.current[3] = el)}
              className={css.menuElement}
              to={trans.faq.link}
              children={trans?.faq?.label}
            />
            <RouterLink
              ref={(el) => (animatedElementsRefs.current[4] = el)}
              className={css.menuElement}
              to={trans.order_tracking.link}
              children={trans?.order_tracking?.label}
            />
          </div>
          <div className={css.menu_col}>
            <a
              ref={(el) => (animatedElementsRefs.current[5] = el)}
              className={merge([css.menuElement, css.menuElement_configure])}
              href={trans.configure.link}
              children={trans.configure.label}
              onClick={()=> onClickOrderButton()}
            />
            <a
              ref={(el) => (animatedElementsRefs.current[6] = el)}
              className={merge([css.menuElement, css.menuElement_trial])}
              href={trans.trial.link}
              target="_blank"
              children={trans.trial.label}
            />
            <div
              ref={preorderTipRef}
              className={css.preorderTip}
              children={trans?.preorder?.content}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
