import css from "./Technicalblock.module.less";
import React, { useRef, useState } from "react";
import { merge } from "@common/lib/helpers/classNameHelper";
import GlobalConfig from "@common/data/GlobalConfig";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import MuiAlert from "@material-ui/lab/Alert";
import pdfUrl from "asset/doc/REDELECTRIC_BROCHURE_BtoC.pdf";
import { makeStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";

import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Dialog,
  Button,
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";

import { newsletterSubscriptionAPI } from "../../utils/Api";

interface IProps {
  classNames?: string[];
  defaultScooter: number;
  onScooterChanged: (scooter) => void;
}

const componentName = "Technicalblock";
const debug = require("debug")(`front:${componentName}`);
const useStyles = makeStyles({
  root: {
    color: "black",
    textAlign: "center",
  },
});
/**
 * @name Technicalblock
 */
function Technicalblock(props: IProps) {
  const blockData = GlobalConfig?.appData?.blocks?.technicalBlock;
  let count = 0;
  const scootersContainerRef = useRef(undefined);
  const scooterSectionsRefs = useRef([]);
  const modalRef = useRef([]);
  const [activeScooter, setActiveScooter] = useState(0);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [alert, setAlert] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [canDownload, setCanDownload] = useState(false);
  const classes = useStyles();

  const scrollTo = (idx) => {
    setActiveScooter(idx);
    const ref = scooterSectionsRefs.current[idx];
    if (!ref) return false;
    scootersContainerRef.current.scroll({
      left: ref.offsetLeft - scootersContainerRef.current.offsetLeft,
      behaviour: "smooth",
    });
    return false;
  };

  const handleChange = (event) => {
    const email = event.target.value;
    setEmail(email);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      email: email,
    };

    if (!email) {
      setAlert("veuillez remplir une adresse email valide");
      return;
    }
    setIsLoading(true);

    const resp = newsletterSubscriptionAPI(formData);
    resp.then((res: any) => {
      /*if (res?.data?.success) {
        setIsLoading(false);
        setCanDownload(true);
        setEmail("");
      } else {
        setIsLoading(false);
        setAlert("problème lors de l'inscription");
      }*/
      setIsLoading(false);
      setCanDownload(true);
      setEmail("");
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderSuccessAlert = () =>
    alert && (
      <Box m={1}>
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="error"
          children={alert}
        />
      </Box>
    );

  const renderModal = () => (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={open || isLoading}
      onClose={handleClose}
    >
      <ValidatorForm
        onSubmit={handleSubmit}
        onError={() => {
          setAlert("veuillez remplir votre adresse mail.");
        }}
      >
        {!canDownload ? (
          <DialogContent>
            <DialogContentText
              classes={{
                root: classes.root,
              }}
            >
              Pour obtenir votre brochure, merci de renseigner votre adresse
              mail
            </DialogContentText>
            {renderSuccessAlert()}

            <TextValidator
              label="Email"
              onChange={handleChange}
              name="email"
              value={email}
              variant={"outlined"}
              fullWidth={true}
              maxWidth="lg"
              required
              disabled={isLoading}
              validators={["required", "isEmail"]}
              errorMessages={[
                "ce champ est requis",
                "votre email n'es pas valide",
              ]}
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => {
                    setIsSubscribed(!isSubscribed);
                  }}
                  disabled={isLoading}
                  checked={isSubscribed}
                  name="is_subscribed"
                />
              }
              label="S’inscrire à la newsletter RED Electric"
            />
          </DialogContent>
        ) : (
          <DialogContent>
            <DialogContentText
              classes={{
                root: classes.root,
              }}
            >
              <Typography>
                Merci de votre confiance. Cliquez ici pour télécharger votre
                brochure.
              </Typography>
              <Box m={2}>
                <Button
                  href={pdfUrl}
                  variant="contained"
                  color="primary"
                  download
                >
                  <span>{blockData.getBrochure}</span>
                </Button>
              </Box>
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button disabled={isLoading} onClick={handleClose} color="primary">
            <span>Quitter</span>
          </Button>
          {!canDownload && (
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={css.cta_btn}
              disabled={isLoading}
            >
              {isLoading && (
                <CircularProgress className={css.spinner} size={16} />
              )}
              <span>valider</span>
            </Button>
          )}
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );

  return (
    <>
      <div className={merge([css.Root, props.classNames, "technicalSection"])}>
        <div className={css.container}>
          <h2 className={css.title} children={blockData?.title} />
          <div className={css.table}>
            <div className={css.table_side}>
              {[3, 2, 4, 4, 4, 3].map((row_count, idx) => (
                <div key={idx} className={css.table_group}>
                  <div className={merge([css.row, css.row_header])}>
                    <span>{blockData?.subtitles[idx]}</span>
                  </div>
                  {Array(row_count)
                    .fill(0)
                    .map((val, index) => (
                      <div key={index} className={css.row}>
                        <span>
                          {
                            blockData?.scootsData[0].technicalSummary[count++]
                              .title
                          }
                        </span>
                      </div>
                    ))}
                </div>
              ))}
            </div>
            <div className={css.table_main} ref={scootersContainerRef}>
              {blockData.scootsData.map((scoot, idx) => {
                count = 0;
                return (
                  <div
                    key={idx}
                    ref={(el) => (scooterSectionsRefs.current[idx] = el)}
                    className={css.table_col}
                  >
                    <div className={merge([css.row, css.row_scooter])}>
                      <div>
                        <img
                          src={require(`${scoot.modelImg}`).default}
                          alt=""
                        />
                        <span>
                          <i onClick={() => scrollTo(idx - 1)} />
                          {scoot.modelName}{" "}
                          <i onClick={() => scrollTo(idx + 1)} />
                        </span>
                      </div>
                    </div>
                    {[3, 2, 4, 4, 4, 3].map((row_count, idx) => (
                      <div key={idx} className={css.table_group}>
                        <div className={merge([css.row, css.row_header])} />
                        {Array(row_count)
                          .fill(0)
                          .map((val, index) => (
                            <div key={index} className={css.row}>
                              <span>
                                {scoot.technicalSummary[count++].data}
                              </span>
                            </div>
                          ))}
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={css.cta}>
            <p className={css.cta_text}>{blockData.showAll}</p>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
              startIcon={<GetAppIcon />}
            >
              <span>{blockData.getBrochure}</span>
            </Button>
          </div>
        </div>
      </div>
      {renderModal()}
    </>
  );
}

export default Technicalblock;
