import { configureStore } from "@reduxjs/toolkit";
import orderMatchingReducer from "./orderMatching";
import userDataReducer from "./userDataSlice";
import UIReducer from "./UISlice";

const store = configureStore({
  reducer: {
    orderMatching: orderMatchingReducer,
    userData: userDataReducer,
    UIData: UIReducer,
  },
});

export default store;
