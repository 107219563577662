import React, { useEffect, useRef, useState } from "react";
import { LoadingService } from "@common/services/LoadingService";
import GlobalConfig from "@common/data/GlobalConfig";
import Header from "../../components/header";
import FaqQuestionBlock from "../../components/faqQuestionBlock";
import css from "./FaqPage.module.less";
import { faqCategoryData, faqQuestionData } from "@common/data/FAQData";
import DeliveryBlock from "red-electric/components/deliveryBlock";
import { Link, Element } from "react-scroll";

interface IProps {
  parameters: any;
}

// prepare
const componentName = "FaqPage";
const debug = require("debug")(`front:${componentName}`);

/**
 * @name FaqPage
 */
const FaqPage = (props: IProps) => {
  const trans = GlobalConfig?.appData.blocks.faqPage;

  // get root ref
  const rootRef = useRef<HTMLDivElement>(null);

  // -------------------–-------------------–-------------------–--------------- STATES

  const [loaded, setLoaded] = useState(false);

  const [openQuestions, setOpenQuestions] = useState([]);

  // -------------------–-------------------–-------------------–--------------- EFFECTS

  useEffect(() => {
    LoadingService?.instance?.on("loadingDone", () => setLoaded(true));
  }, []);

  // -------------------–-------------------–-------------------–--------------- HELPERS

  const isQuestionOpen = (id: string): boolean => {
    return openQuestions.includes(id);
  };

  const toggleQuestion = (id: string): void => {
    if (isQuestionOpen(id)) {
      setOpenQuestions(openQuestions.filter((qId) => qId !== id));
    } else {
      setOpenQuestions([...openQuestions, id]);
    }
  };

  // -------------------–-------------------–-------------------–--------------- RENDER

  const renderContent = (
    categoryData: faqCategoryData,
    categoryIndex: number
  ) => (
    <Element
      key={categoryIndex}
      name={`faq_${categoryIndex}`}
      className={css.categoryBlock}
    >
      <div className={css.title}>{categoryData.title}</div>
      {categoryData.questions.map(
        (questionData: faqQuestionData, questionIndex: number) => {
          const questionId = `${categoryIndex}.${questionIndex}`;
          return (
            <FaqQuestionBlock
              key={questionId}
              classNames={[css.questionBlock]}
              title={questionData.question}
              open={isQuestionOpen(questionId)}
              requestOpen={() => toggleQuestion(questionId)}
            >
              {questionData.answer}
            </FaqQuestionBlock>
          );
        }
      )}
    </Element>
  );

  const renderNavigation = (
    categoryData: faqCategoryData,
    categoryIndex: number
  ) => (
    <Link
      to={`faq_${categoryIndex}`}
      key={categoryIndex}
      className={css.title}
      onSetActive={() => null}
      activeClass={css.active}
      duration={500}
      spy={true}
      smooth={true}
      offset={-100}
      children={categoryData.shortTitle}
    />
  );

  return (
    <div className={css.Root} ref={rootRef}>
      <div className={css.contentWrapper}>
        <div className={css.navigation}>
          <h2 className={css.pageTitle}>FAQ</h2>
          {trans.data.map(renderNavigation)}
        </div>
        <div className={css.content}>{trans.data.map(renderContent)}</div>
      </div>
      <div className={css.extraContentWrapper}>
        <DeliveryBlock />
      </div>
    </div>
  );
};

export default FaqPage;
