import "./index.less";
import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./app/store";
import App from "./App";

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <Router basename={process.env.PUBLIC_URL}>
      <CssBaseline />
      <App />
    </Router>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("AppContainer")
);
