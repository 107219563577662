import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { RegistrationForm } from "../../../components/registrationForm/RegistrationForm";
import {
  selectIsLoading,
  selectUIErrors,
  selectUISuccesses,
} from "red-electric/app/UISlice";
import { useDispatch, useSelector } from "react-redux";
import GlobalConfig from "@common/data/GlobalConfig";
import {
  selectProfile,
  userFetchProfile,
  userSaveProfile,
} from "../../../app/userDataSlice";
import useStyles from "../AccountPage.styles";

interface AccountInformationProps {}

export const AccountInformation = ({}: AccountInformationProps) => {
  // global
  const trans = GlobalConfig?.appData.blocks;
  const classes = useStyles();
  const dispatch = useDispatch();

  // states
  const [initialFormData, setInitialFormData] = useState<any>(null);

  // selectors
  const UIErrors = useSelector(selectUIErrors);
  const UILoading = useSelector(selectIsLoading);
  const UISuccesses = useSelector(selectUISuccesses);
  const userProfile = useSelector(selectProfile);

  // event handlers
  const handleUpdateProfile = (payload) => {
    dispatch(userSaveProfile({ ...payload, id: userProfile.id }));
  };

  // effects
  useEffect(() => {
    setInitialFormData({
      ...userProfile,
      country: userProfile.customerCountry,
    });
  }, [userProfile]);

  useEffect(() => {
    dispatch(userFetchProfile());
  }, []);

  return (
    <Box my={2}>
      <Typography variant="h2" className={classes.sectionTitle}>
        {trans.accountPage.myInfoTitle}
      </Typography>
      {UIErrors.userFetchProfile ? (
        <Alert severity="error">{UIErrors.userFetchProfile.errorMsg}</Alert>
      ) : (
        <RegistrationForm
          isUpdatingProfile
          isApiLoading={UILoading.userSaveProfile}
          submitLabel={trans.accountPage.updateInfoLabel}
          handleSubmit={handleUpdateProfile}
          serverResponse={
            <React.Fragment>
              {UISuccesses.userUpdateAccount && (
                <Typography color="textSecondary">
                  {trans.accountPage.successMessage}
                </Typography>
              )}
              {UIErrors.userSaveProfile && (
                <Typography color="error">
                  {UIErrors.userSaveProfile.errorMsg}
                </Typography>
              )}
            </React.Fragment>
          }
          initialData={initialFormData}
        />
      )}
    </Box>
  );
};
