import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Button,
  Box,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { LoadingService } from "@common/services/LoadingService";
import GlobalConfig from "@common/data/GlobalConfig";
import Header from "red-electric/components/header";
import { selectIsLoading, selectUIErrors } from "red-electric/app/UISlice";
import { selectOrder, userFetchOrder } from "../../app/userDataSlice";

interface IProps {
  children?: React.ReactChild;
}

interface OrderPageParams {
  orderId: string;
}

const useStyles = makeStyles((t) => {});

export const OrderPage = (props: IProps) => {
  const trans = GlobalConfig?.appData.blocks;

  const classes = useStyles();
  const dispatch = useDispatch();
  const userOrder = useSelector(selectOrder);
  const UILoading = useSelector(selectIsLoading);
  const UIErrors = useSelector(selectUIErrors);
  const params = useParams<OrderPageParams>();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    LoadingService?.instance?.on("loadingDone", () => setLoaded(true));
  }, []);

  useEffect(() => {
    dispatch(userFetchOrder(params.orderId));
  }, []);

  const renderUserOrderSection = () => {
    if (UIErrors.userFetchOrder) {
      return (
        <Alert color="error" icon={false}>
          {UIErrors.userFetchOrder.errorMsg}
        </Alert>
      );
    }
    if (userOrder?.orderId) {
      return <>{JSON.stringify(userOrder)}</>;
    }
    return null;
  };

  return (
    <div>
      <Header pageVisible={loaded} enableSubNav={false} />
      <Container maxWidth="md">{renderUserOrderSection()}</Container>
    </div>
  );
};
