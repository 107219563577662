import css from "./PowerBlock.module.less";
import React, { useRef, useState } from "react";
import { merge } from "@common/lib/helpers/classNameHelper";
import GlobalConfig from "@common/data/GlobalConfig";

import ModelBox from "../modelBox";

interface IProps {
  classNames?: string[];
}

const componentName = "PowerBlock";
const debug = require("debug")(`front:${componentName}`);
const trans = GlobalConfig.appData.blocks.powerBlock;

/**
 * @name PowerBLock
 */
function PowerBlock(props: IProps) {

  const setSliderRange = useRef<any>();
  //const slideValue = Math.round(${scootData.technicalSummary[3]/scootData.technicalSummary[4]});
  return (
    <div className={merge([css.Root, props.classNames])}>
      <div className={css.container}>
        <div className={css.container_text}>
          <h2 className={css.title}>{trans.title}</h2>
          <p
            className={css.subtitle}
            dangerouslySetInnerHTML={{ __html: trans.subtitle }}
          />
        </div>
        <div className={css.container_carousel}>
          {trans.models.map((scootData, index) => (
            <div className={css.box}>
              <div className={merge([css.box_block, css.box_block_first])}>
                <div className={merge([css.box_block_row, css.box_block_head])}>
                  <span className={css.scooter_name}>
                    {scootData.modelName}
                  </span>
                </div>
                <div
                  className={merge([css.box_block_row, css.box_block_content])}
                >
                  <div className={css.scooter_img}>
                    <img
                      src={require(`${scootData.modelImg}`).default}
                      className={css.power_scooter}
                      alt={scootData.modelName}
                    />
                  </div>
                </div>
                <div className={merge([css.box_block_row, css.box_block_foot])}>
                  <div className={css.scooter_info}>
                    <span className={css.scooter_info_value}>
                      {scootData.technicalSummary[1].data}
                    </span>
                    <span className={css.scooter_info_label}>
                      {scootData.technicalSummary[1].title}
                    </span>
                  </div>
                </div>
              </div>
              <div className={merge([css.box_block, css.box_block_second])}>
                <div className={merge([css.box_block_row, css.box_block_head])}>
                  <span className={css.scooter_power_label}>Puissance</span>
                </div>
                <div
                  className={merge([css.box_block_row, css.box_block_content])}
                >
                  <div className={merge([css.slider, css.slider_blue])}>
                    <div className={css.slider_gauge}
                    style={{
                      width: (parseInt(scootData.technicalSummary[3].data)/parseInt(scootData.technicalSummary[4].data))*100 + "%",
                    }}>
                    <span
                      className={css.slider_label}
                      dangerouslySetInnerHTML={{__html: scootData.technicalSummary[2].title}}
                    />
                    </div>
                  </div>

                  <div className={merge([css.slider, css.slider_red])}>
                    <div className={css.slider_gauge}>
                      <span className={css.slider_label}>{scootData.modelName}</span>
                      <span className={css.slider_value}>
                        {scootData.technicalSummary[4].data} W
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className={merge([css.box_block, css.box_block_third])}>
                <div className={merge([css.box_block_row, css.box_block_head])}>
                  <span className={css.acceleration_label}>{scootData.technicalSummary[0].title}</span>
                </div>
                <div
                  className={merge([css.box_block_row, css.box_block_content])}
                >
                  <span className={css.acceleration_title}>
                    {scootData.technicalSummary[0].data}
                  </span>
                  <span
                    className={css.acceleration_text}
                    dangerouslySetInnerHTML={{__html:scootData.technicalSummary[2].data}}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PowerBlock;
