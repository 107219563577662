import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { selectUIErrors } from "red-electric/app/UISlice";
import { useDispatch, useSelector } from "react-redux";
import GlobalConfig from "@common/data/GlobalConfig";
import { selectOrders, userFetchOrders } from "../../../app/userDataSlice";
import useStyles from "../AccountPage.styles";
import { OrderCard } from "../../../components/orderCard/OrderCard";

interface AccountOrdersProps {}

export const AccountOrders = ({}: AccountOrdersProps) => {
  // global
  const trans = GlobalConfig?.appData.blocks;
  const classes = useStyles();
  const dispatch = useDispatch();

  // selectors
  const userOrders = useSelector(selectOrders);

  useEffect(() => {
    dispatch(userFetchOrders());
  }, []);

  // selectors
  const UIErrors = useSelector(selectUIErrors);

  return (
    <Box my={2}>
      <Typography variant="h2" className={classes.sectionTitle}>
        {trans.accountPage.myOrdersTitle}
      </Typography>

      {UIErrors.userFetchOrders && (
        <Alert severity="error">{UIErrors.userFetchOrders.errorMsg}</Alert>
      )}
      {userOrders &&
        Object.values(userOrders).map((data) => {
          return <OrderCard key={`order-card-${data.orderNo}`} {...data} />;
        })}
    </Box>
  );
};
