import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Typography,
} from "@material-ui/core";
import GlobalConfig from "@common/data/GlobalConfig";
import TagManager from "react-gtm-module";

interface IProps {
  classNames?: string[];
}

const componentName = "CookieBanner";
const debug = require("debug")(`front:${componentName}`);

/**
 * @name CookieBlock
 */

function CookieBlock(props: IProps) {
  const [acceptedTerm, setAcceptedTerm] = useState(false);
  const [tab, setTab] = useState("main");

  const [required, setRequired] = useState(true);
  const [showInfo, setShowInfo] = useState(false);
  const [all, setAll] = useState(false);
  const [statistics, setStatistics] = useState(false);
  const [marketing, setMarketing] = useState(false);

  const Content = (props) => {
    switch (props.tab) {
      case "main":
        return <MainContent />;
      case "policy":
        return <PolicyContent />;
      case "detail":
        return <PolicyContent />;
      default:
      // code block
    }
  };

  const handleClose = () => {
    setAcceptedTerm(true);
  };

  const initializeGoogleTag = () => {
    const tagManagerArgs = { gtmId: "GTM-WZBRM8J" };
    TagManager.initialize(tagManagerArgs);
  };

  useEffect(() => {
    setStatistics(localStorage.getItem("acceptedStatistics") === "true");
    setMarketing(localStorage.getItem("acceptedMarketing") === "true");
    setAcceptedTerm(localStorage.getItem("acceptedTerms") === "true");
  }, []);

  useEffect(() => {
    if (acceptedTerm) {
      localStorage.setItem("acceptedStatistics", statistics ? "true" : "false");
      localStorage.setItem("acceptedMarketing", marketing ? "true" : "false");
      localStorage.setItem("acceptedTerms", "true");

      if (statistics || marketing) {
        initializeGoogleTag();
      }
    }
  }, [acceptedTerm]);

  const PolicyContent = (props) => (
    <Box>
      <Typography variant="h2">
        {GlobalConfig.appData.layout.legalPopup.title}
      </Typography>
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: GlobalConfig.appData.layout.legalPopup.content,
        }}
      />
    </Box>
  );

  const CookieList = (props) => (
    <>
      <Grid container justify="space-between">
        <Grid item>
          <Button
            onClick={() => {
              setTab("policy");
            }}
            color="primary"
            size="small"
          >
            Mentions légales
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => {
              setShowInfo(!showInfo);
            }}
          >
            {showInfo ? "Moins" : "Plus"} de détails
          </Button>
        </Grid>
      </Grid>
      {showInfo && (
        <List dense>
          <ListItem divider>
            <ListItemText
              primary={"Requis"}
              secondary={
                showInfo && "Cookies nécessaires au bon fonctionnement du site."
              }
            />
            <ListItemSecondaryAction>
              <Switch
                disabled={true}
                checked={required}
                onChange={(e) => setRequired(e.target.checked)}
                name="required"
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem divider>
            <ListItemText primary={"Accepter tout"} />
            <ListItemSecondaryAction>
              <Switch
                color="primary"
                checked={all}
                onChange={(e) => {
                  setAll(e.target.checked);
                  setMarketing(e.target.checked);
                  setStatistics(e.target.checked);
                }}
                name="all"
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem divider>
            <ListItemText
              primary={"Statistiques"}
              secondary={
                showInfo &&
                "Les services de mesure d'audience permettent de générer des statistiques de fréquentation utiles à l'amélioration du site."
              }
            />
            <ListItemSecondaryAction>
              <Switch
                checked={statistics}
                onChange={(e) => setStatistics(e.target.checked)}
                name="statistics"
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem divider>
            <ListItemText
              primary={"Marketing"}
              secondary={
                showInfo &&
                "Notre site et nos partenaires utilisent des traceurs afin d'afficher de la publicité personnalisée en fonction de votre navigation et de votre profil."
              }
            />
            <ListItemSecondaryAction>
              <Switch
                checked={marketing}
                onChange={(e) => setMarketing(e.target.checked)}
                name="marketing"
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      )}
    </>
  );
  const MainContent = (props) => (
    <Box>
      <Typography variant="body1">
        Ce site utilise des cookies. Ces cookies sont nécessaires au bon
        fonctionnement du site et comprends des cookies de mesure d’audience
        afin d’effectuer des statistiques anonymes et des cookies publicitaires
        nous permettant ainsi qu'à nos partenaires d’afficher des publicités
        pertinentes, personnalisées et adaptées à vos besoins.
      </Typography>
      <CookieList />
    </Box>
  );

  return (
    <>
      {!acceptedTerm && (
        <Dialog
          disableBackdropClick={true}
          scroll={"paper"}
          fullWidth
          open={true}
          onClose={handleClose}
        >
          <DialogContent>
            <Content tab={tab} />
          </DialogContent>
          <DialogActions>
            <Grid
              container
              justify="space-between"
              wrap="nowrap"
              alignItems="center"
            >
              <Grid item xs={12}>
                {tab == "main" && (
                  <Button
                    onClick={() => {
                      setMarketing(false);
                      setStatistics(false);
                      handleClose();
                    }}
                    color="primary"
                    variant="outlined"
                  >
                    Tout refuser
                  </Button>
                )}

                {tab == "policy" && (
                  <Button onClick={() => setTab("main")} color="primary">
                    Retourner
                  </Button>
                )}
              </Grid>
              <Grid item container xs={12} justify="flex-end">
                <Grid item>
                  {tab == "main" && (
                    <Box m={0.5} textAlign={"right"}>
                      <Button onClick={handleClose} color="primary">
                        Accepter
                      </Button>
                    </Box>
                  )}
                </Grid>
                <Grid item>
                  <Box m={0.5} textAlign={"right"}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setMarketing(true);
                        setStatistics(true);
                        handleClose();
                      }}
                      color="primary"
                    >
                      Tout accepter
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default CookieBlock;
