import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  matchingIdentificationAPI,
  addOrderAPI,
  addOrderAccessoriesAPI,
  assignOrderItemAPI,
} from "red-electric/utils/Api";
import {
  MatchingIdentificationAPIRequest,
  orderMatchingAPIRequest,
} from "red-electric/utils/Api.interface";
import { AppThunk, RootState } from "./store.interface";
import {
  resetErrors,
  resetSuccesses,
  updateErrors,
  updateIsLoading,
  updateSuccesses,
} from "./UISlice";

import { MatchingData } from "./orderMatching.interface";

const initialState: MatchingData = {
  customer: {},
  order: {},
};

export const orderMatching = createSlice({
  name: "orderMatching",
  initialState,
  reducers: {
    updateMatching: (state, action: PayloadAction<Partial<MatchingData>>) => {
      return { ...state, ...action.payload };
    },
    resetMatching: () => initialState,
  },
});

export const { updateMatching, resetMatching } = orderMatching.actions;

export const matchingIdentification = (
  payload: MatchingIdentificationAPIRequest
): AppThunk => (dispatch) => {
  dispatch(resetSuccesses("matchingIdentification"));
  dispatch(resetErrors("matchingIdentification"));
  dispatch(updateIsLoading({ matchingIdentification: true }));
  matchingIdentificationAPI(payload)
    .then((data) => {
      dispatch(updateMatching(data));
      dispatch(updateIsLoading({ matchingIdentification: false }));
      dispatch(updateSuccesses({ matchingIdentification: true }));
    })
    .catch((error) => {
      dispatch(updateIsLoading({ matchingIdentification: false }));
      dispatch(updateSuccesses({ matchingIdentification: false }));
      dispatch(updateErrors({ matchingIdentification: error }));
    });
};

export const addMatchingOrder = (
  payload: orderMatchingAPIRequest
): AppThunk => (dispatch) => {
  addOrderAPI(payload)
    .then((data) => {
      const orderId = data.id;
      payload.orderId = orderId;
      dispatch(addMatchingAccessories(payload));
    })
    .catch((error) => {
      console.log("Error on creating order");
    });
};

export const addMatchingAccessories = (
  payload: orderMatchingAPIRequest
): AppThunk => (dispatch) => {
  addOrderAccessoriesAPI(payload)
    .then((data) => {
      //TODO we need to switch to the account page
      dispatch(assignOrderItems(payload));
    })
    .catch((error) => {});
};

export const assignOrderItems = (
  payload: orderMatchingAPIRequest
): AppThunk => (dispatch) => {
  for (let index = 0; index < payload.orderMIds.length; index++) {
    assignOrderItemAPI(payload.orderMIds[index])
      .then((data) => {
        //TODO we need to switch to the account page
        //console.log("Item assigned", payload.orderMIds[index]);
      })
      .catch((error) => {});
  }

};

export const selectMatching = (state: RootState) => state.orderMatching;

export const selectMatchingCustomer = (state: RootState) =>
  state.orderMatching.customer;

export const selectMatchingOrder = (state: RootState) =>
  state.orderMatching.order;

export const selectMatchingAccessories = (state: RootState) =>
  state.orderMatching.accessories;

export const selectMatchingMIds = (state: RootState) =>
  state.orderMatching.orderMIds;

export default orderMatching.reducer;
