import { IScootData } from "./IScootData";
import { IImage } from "@wbe/react-responsive-image";
import { IInfoBlockData } from "@common/data/IInfoBlockData";
import { IPressArticle } from "./IPressArticle";
import { IStoreData } from "@common/data/IStoreData";
import { faqCategoryData } from "./FAQData";

export class LocaleStruct {
  metas: {
    title: string;
    description: string;
  };

  layout: {
    header: {
      models: {
        label: string;
        link: string;
      };
      about: {
        label: string;
        link: string;
      };
      technology: {
        label: string;
        link: string;
      };
      chooseElectric: {
        label: string;
        link: string;
      };
      finance: {
        label: string;
        link: string;
      };
      faq: {
        label: string;
        link: string;
      };
      account: {
        label: string;
        link: string;
      };
      configure: {
        label: string;
        link: string;
      };
      trial: {
        label: string;
        link: string;
      };
      order_tracking: {
        label: string;
        link: string;
      };
      insurance: {
        label: string;
        link: string;
      };
      preorder: {
        label: string;
        link: string;
        content: string;
      };
      subHeader: {
        modelERange: {
          label: string;
          link: string;
        };
        design: {
          label: string;
          link: string;
        };
        motorization: {
          label: string;
          link: string;
        };
        onBoardIntelligence: {
          label: string;
          link: string;
        };
        autonomy: {
          label: string;
          link: string;
        };
        recharge: {
          label: string;
          link: string;
        };
        technicalCar: {
          label: string;
          link: string;
        };
        pressTalk: {
          label: string;
          link: string;
        };
      };
    };
    legalPopup: {
      title: string;
      content: string;
    };
    newsletterSuccessPopup: {
      title: string;
      content: string;
    };
    newsletterFailPopup: {
      title: string;
      content: string;
    };
    contactSuccessPopup: {
      title: string;
      content: string;
    };
    contactFailPopup: {
      title: string;
      content: string;
    };
    cgvPopup: {
      title: string;
      content: string;
    };
  };
  stores: IStoreData[];
  blocks: {
    threeSixty: {
      backgroundText: string;
      scootsData: IScootData[];
      ctaText: string;
      ctaProText?: string;
    };
    deliveryBlock: {
      title1: string;
      title2: string;
      title3: string;
      cta: string;
      email: string;
      phone: string;
      chat?: string;
      schedule: string;
      subtitle1: string;
    };
    ecologyBlock: {
      title: string;
      subtitle: string;
      ctaText?: string;
      result: {
        title: string;
        subtitle: string;
        recalculateBonus: string;
      };
      form: {
        model: string;
        batteriesCount: string;
        youAre: string;
        postalCode: string;
        personal: string;
        professional: string;
        calculateBonus: string;
      };
      cta_1: {
        title: string;
        findShop: string;
      };
      cta_2: {
        title: string;
        bookTrial: string;
        link: string;
      };
    };
    autonomyBlock: {
      title: string;
      subtitle1: string;
      subtitle2: string;
      blocks: [
        {
          title: string;
          text: string;
        },
        {
          title: string;
          text: string;
        },
        {
          title: string;
          text: string;
        }
      ];
      cta: string;
    };
    firstInfoBlock: IInfoBlockData;
    secondInfoBlock: IInfoBlockData;
    landingBlock: {
      title: string;
      text: string;
    };
    firstLandingBlock: {
      title: string;
      text: string;
    };
    modelEBlock: {
      title: string;
      text: string;
      scootsData: IScootData[];
      modelBox: {
        maximumSpeed: string;
        startingFrom: string;
        paymentPlan: string;
        maximumAutonomy: string;
        month: string;
        rentIncrease: string;
        or: string;
        cta: string;
        ttc: string;
      };
    };
    powerBlock: {
      title: string;
      subtitle: string;
      marketAverage: string;
      to: string;
      equivalent: string;
      power: string;
      models: IScootData[];
    };
    horizontalScrollBlock: {
      title: string;
      leftLayer: {
        subtitle: string;
        text: string;
      };
      rightLayer: {
        subtitle: string;
        text: string;
      };
    };
    batteryBlock: {
      text: {
        title: string;
        paragraph: string;
      };
      media: {
        videoNameMobile: string;
        videoNameDesktop: string;
      };
    };
    technicalBlock: {
      title: string;
      subtitles: [string];
      showAll: string;
      getBrochure: string;
      scootsData: IScootData[];
    };
    cardSliderBlock: {
      title: string;
      subtitle: string;
      cards: {
        thumbnail: string;
        title: string;
        text: string;
      }[];
    };
    contactForm: {
      title: string;
      submitButton: string;
      loading: string;
      loginLabel: string;
      loginButton: string;
      form: {
        firstNameLabel: string;
        lastNameLabel: string;
        emailLabel: string;
        emailInvalid: string;
        messageLabel: string;
        required: string;
      };
    };
    newsletterForm: {
      title: string;
      submitButton: string;
      loading: string;
      form: {
        emailLabel: string;
        emailInvalid: string;
        required: string;
        successMessage: string;
      };
    };
    pressBlock: {
      title: string;
      articles: IPressArticle[];
      subtitle: string;
    };
    preorderBlock: {
      title: string;
      paragraph: string;
      ctaText: string;
      blocks: {
        type: string;
        color: string;
        title: string;
        paragraph?: string;
        discoverIntro?: string;
        discoverStore?: string;
        buttonText: string;
        buttonType: string;
      }[];
    };
    discoverBlock: {
      title: string;
      text: string;
      findDealer: string;
      findDealerLink: string;
      bookDemo: string;
      bookDemoLink: string;
    };
    aboutPage: {
      texts: [string];
      faqText: string;
      ctaText: string;
      ctaLink: string;
      subText: string;
    };
    faqPage: {
      data: faqCategoryData[];
    };
    forgotPasswordPage: {
      title: string;
      info: string;
      emailLabel: string;
      emailInvalid: string;
      emailRequired: string;
      submitButton: string;
      loading: string;
      loginLabel: string;
      loginButton: string;
      successTitle: string;
      successMessage: string;
      backToLogin: string;
    };
    registrationPage: {
      title: string;
      registrationButton: string;
      loading: string;
      loginLabel: string;
      loginButton: string;
      form: {
        firstNameLabel: string;
        lastNameLabel: string;
        emailLabel: string;
        emailInvalid: string;
        phoneLabel: string;
        phoneInvalid: string;
        passwordLabel: string;
        passwordConfirmLabel: string;
        addressLabel: string;
        districtLabel: string;
        cityLabel: string;
        countryLabel: string;
        required: string;
        addressAutocompleteLoadingResults: string;
        addressAutocompleteNoResults: string;
        passwordsNotMatch: string;
      };
    };
    password: {
      indication: string;
      conditions: string;
      security: string;
      weak: string;
      normal: string;
      strong: string;
      has_min_length: string;
      has_uppercase: string;
      has_lowercase: string;
      has_number: string;
      has_special_character: string;
    };
    loginPage: {
      emailLabel: string;
      emailInvalid: string;
      emailRequired: string;
      passwordLabel: string;
      passwordRequired: string;
      forgotPasswordLabel: string;
      loginButton: string;
      loading: string;
      createAccountLabel: string;
      createAccountButton: string;
    };
    changePasswordPage: {
      title: string;
      oldPasswordLabel: string;
      newPasswordLabel: string;
      confirmPasswordLabel: string;
      passwordsNotMatch: string;
      required: string;
      submitButton: string;
      loading: string;
      successTitle: string;
      successMessage: string;
      backToAccount: string;
    };
    accountPage: {
      title: string;
      myInfoTitle: string;
      updateInfoLabel: string;
      infoUpdateSuccess: string;
      infoUpdateError: string;
      myPasswordTitle: string;
      myPasswordButton: string;
      myOrdersTitle: string;
      viewOrEditOrderLabel: string;
      orderedOnLabel: string;
      orderNumberLabel: string;
      paymentStatusLabel: string;
      logoutTitle: string;
      logoutLabel: string;
      successMessage?: string;
    };
    orderMatching: {
      colors: {
        3: string;
        4: string;
        5: string;
      };
      makes: {
        1: string;
        2: string;
        3: string;
      };
      statuses: {
        1: string;
        2: string;
        3: string;
      };
    };
    matchingPage: {
      title: string;
      subtitle: string;
      phoneFieldLabel: string;
      phoneFieldRequired: string;
      phoneFieldHelper: string;
      phoneFieldInvalid: string;
      emailFieldLabel: string;
      emailFieldRequired: string;
      emailFieldHelper: string;
      emailFieldInvalid: string;
      orderNumberFieldLabel: string;
      orderNumberFieldRequired: string;
      orderNumberFieldHelper: string;
      orderNumberFieldInvalid: string;
      loading: string;
      submitButton: string;
      matchUsingEmailLabel: string;
      matchUsingPhoneLabel: string;
      notYourOrderButton: string;
      yourNewAccountTitle: string;
      yourNewAccountDescription: string;
      hasAnAccountButton: string;
      loginButton: string;
      noAccountButton: string;
      registerButton: string;
      helpModalTitle: string;
      helpModalDescription: string;
      helpModalClose: string;
      helpModalPhoneLabel1: string;
      helpModalPhoneLabel2: string;
      helpModalEmailLabel: string;
    };
    footer: {
      copyright: string;
      followUs: string;
      conditions: string;
      sitePlan: string;
      legalMention: string;
      contact: string;
      press: string;
      professional?: string;
    };
  };
}
