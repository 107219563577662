import { AppThunk } from "./store.interface";
import { contactFormAPI, newsletterSubscriptionAPI } from "../utils/Api";
import {
  ContactFormAPIRequest,
  NewsletterFormAPIRequest,
} from "../utils/Api.interface";
import TagManager from "react-gtm-module";
import {
  resetErrors,
  resetSuccesses,
  updateErrors,
  updateIsLoading,
  updateSuccesses,
} from "./UISlice";

const tagManagerArgs = {
    dataLayer: {
        event: 'interaction',
        category: 'newsletter',
        action: 'newsletterSubscription',
        label: 'success'
    },
}

const tagManagerArgs2 = {
    dataLayer: {
        event: 'interaction',
        category: 'formulaireContact',
        action: 'formulaireContactSend',
        label: 'success'
    },
}

export const contactForm = (payload: ContactFormAPIRequest): AppThunk => (
  dispatch
) => {
  dispatch(resetErrors("contactForm"));
  dispatch(updateIsLoading({ contactForm: true }));
  contactFormAPI(payload)
    .then((data) => {
      dispatch(updateIsLoading({ contactForm: false }));
      dispatch(updateSuccesses({ contactForm: data }));
      TagManager?.dataLayer(tagManagerArgs2);
    })
    .catch((error) => {
      dispatch(updateIsLoading({ contactForm: false }));
      dispatch(updateErrors({ contactForm: error }));
    });
};

export const newsletterForm = (payload: NewsletterFormAPIRequest): AppThunk => (
  dispatch
) => {
  dispatch(resetErrors("newsletterForm"));
  dispatch(resetSuccesses("newsletterForm"));
  dispatch(updateIsLoading({ newsletterForm: true }));
  newsletterSubscriptionAPI(payload)
    .then((data) => {
      dispatch(updateIsLoading({ newsletterForm: false }));
      dispatch(updateSuccesses({ newsletterForm: data }));
      TagManager?.dataLayer(tagManagerArgs);
    })
    .catch((error) => {
      dispatch(updateIsLoading({ newsletterForm: false }));
      dispatch(updateErrors({ newsletterForm: error }));
    });
};
