import { JSEncrypt } from "jsencrypt";

const resPublicKey = process.env.APP_SECRET;

/**
 * @name encrypt
 * encrypt use the app public key to encrypt the data to be sent to the server
 */
export const encrypt = (value) => {
  const jsEncrypt = new JSEncrypt();
  jsEncrypt.setPublicKey(resPublicKey);
  return jsEncrypt.encrypt(value);
};

/**
 * @name decrypt
 * decrypt the app public key to encrypt the data to be sent to the server
 */
export const decrypt = (value) => {
  const jsEncrypt = new JSEncrypt();
  jsEncrypt.setPublicKey(resPublicKey);
  return jsEncrypt.descrypt(value);
};
