import React from "react";
import GlobalConfig from "@common/data/GlobalConfig";
import redeLogo from "asset/img/rede-logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  Container,
  Typography,
  withStyles,
  makeStyles,
} from "@material-ui/core";
import NewsletterForm from "../newsletterForm";
import { newsletterForm } from "red-electric/app/publicAPI.actions";
import {
  selectIsLoading,
  selectUIErrors,
  selectUISuccesses,
} from "red-electric/app/UISlice";

const useStyles = makeStyles((theme) => ({
  logo: {
    margin: "0 auto 2rem",
    [theme.breakpoints.up("md")]: {
      margin: 0,
    },
  },
}));

export function NewsletterBlock() {
  const trans = GlobalConfig.appData.blocks.newsletterForm;

  const classes = useStyles();
  const dispatch = useDispatch();
  const UIErrors = useSelector(selectUIErrors);
  const UISuccesses = useSelector(selectUISuccesses);
  const UILoading = useSelector(selectIsLoading);

  return (
    <StyledContainer py={6}>
      <Container>
        <Grid container>
          <Grid item xs={12} md={4}>
            <img className={classes.logo} src={redeLogo} alt="" />
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledDescription
              color="textSecondary"
              dangerouslySetInnerHTML={{
                __html: trans.title,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <NewsletterForm
              darkTheme
              isApiLoading={UILoading.newsletterForm}
              handleSubmit={(payload) => dispatch(newsletterForm(payload))}
              submitLabel={trans.submitButton}
              serverResponse={
                <React.Fragment>
                  {UISuccesses.newsletterForm && (
                    <Typography color="textSecondary">
                      {trans.form.successMessage}
                    </Typography>
                  )}
                  {UIErrors.newsletterForm && (
                    <Typography color="error">
                      {UIErrors.newsletterForm.errorMsg}
                    </Typography>
                  )}
                </React.Fragment>
              }
            />
          </Grid>
        </Grid>
      </Container>
    </StyledContainer>
  );
}

const StyledContainer = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
}))(Box);

const StyledDescription = withStyles((theme) => ({
  root: {
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
}))(Typography);
