import React, { ReactDOM, useEffect, useRef, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { IStoreData } from "@common/data/IStoreData";
import { useTheme } from "@material-ui/core/styles";
import { StoreCard } from "../storeCard/StoreCard";

interface IMap {
  mapType: google.maps.MapTypeId;
  mapTypeControl?: boolean;
  stores?: IStoreData[];
  handleMarkerClick?: (e: any) => void;
  selectedStoreId?: number;
  mapCenter: { lat: number; long: number };
  displayGarages?: boolean;
  displayDealers?: boolean;
}

interface IMarker {
  address: string;
  latitude: number;
  longitude: number;
}

type GoogleLatLng = google.maps.LatLng;
type GoogleMap = google.maps.Map;
type GoogleMarker = google.maps.Marker;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    container: {
      width: "100%",
      height: "100%",
    },
    map: {
      width: "100%",
      height: "100%",
    },
  })
);

const Map = ({
  mapType,
  mapTypeControl = false,
  stores = [],
  handleMarkerClick,
  selectedStoreId,
  mapCenter,
  displayGarages = false,
  displayDealers = false,
}) => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<GoogleMap>();
  const theme = useTheme();
  const [markers, setMarkers] = useState(undefined);

  const startMap = (): void => {
    if (!map) {
      defaultMapStart();
    }
  };
  useEffect(startMap, [map]);

  const defaultMapStart = (): void => {
    const defaultAddress = new google.maps.LatLng(
      48.87957651279441,
      2.3676664856476757
    );
    initMap(8, defaultAddress);
  };

  const initEventListener = (): void => {
    if (map) {
      google.maps.event.addListener(map, "click", function(e) { });
    }
  };
  useEffect(initEventListener, [map]);

  useEffect(() => {
    if (selectedStoreId)
      new google.maps.event.trigger(markers[selectedStoreId], "click");
  }, [selectedStoreId]);

  useEffect(() => {
    if (map && mapCenter) {
      const centerLatLong = new google.maps.LatLng(
        mapCenter.lat,
        mapCenter.long
      );
      map.setCenter(centerLatLong);
      new google.maps.Marker({
        position: centerLatLong,
        map: map,
      });
    }
  }, [mapCenter]);

  const getIconAttributes = (iconColor: string) => {
    return {
      path:
        "M22.677,0C12.509,0,4.266,8.243,4.266,18.411c0,9.224,11.471,21.36,16.305,26.065c1.184,1.15,3.056,1.174,4.263,0.047\n" +
        "\t\t\tc4.863-4.533,16.254-16.211,16.254-26.113C41.087,8.243,32.845,0,22.677,0z M22.677,24.393c-4.204,0-7.61-3.406-7.61-7.609\n" +
        "\t\t\ts3.406-7.61,7.61-7.61c4.203,0,7.608,3.406,7.608,7.61S26.88,24.393,22.677,24.393z",
      fillColor: theme.palette.primary.main,
      fillOpacity: 1,
      strokeColor: "white",
      strokeWeight: 2,
      anchor: new google.maps.Point(30, 50),
      labelOrigin: new google.maps.Point(23, 17),
    };
  };

  const initMap = (zoomLevel: number, address: GoogleLatLng): void => {
    if (ref.current) {
      const map_t = new google.maps.Map(ref.current, {
        zoom: zoomLevel,
        center: address,
        mapTypeControl: false,
        streetViewControl: false,
        rotateControl: false,
        scaleControl: false,
        fullscreenControl: false,
        panControl: false,
        zoomControl: false,
        gestureHandling: "cooperative",
        mapTypeId: mapType,
        draggableCursor: "pointer",
      });
      setMap(map_t);

      if (stores) {
        let markers_tmp = {};
        let displayState = true;
        let infoWindows = [];
        stores.map((store, idx) => {
          let marker = null;
          let infoWindow = null;
          displayState =
            (displayGarages &&
              displayDealers &&
              (store.isGarage || store.isDealer)) ||
            (displayGarages && store.isGarage) ||
            (displayDealers && store.isDealer);
          if (displayState)
            marker = new google.maps.Marker({
              position: new google.maps.LatLng(store.lat, store.long),
              map: map_t,
              label: {
                text: store.id.toString(),
                color: theme.palette.primary.main,
                fontWeight: "900",
                fontSize: "15px",
              },
              icon: getIconAttributes("#000000"),
            });

          infoWindow = new google.maps.InfoWindow({
            maxWidth: 9999,
            content: `<div id="infoWindow- ${idx}">
            <p><b>${store.name}</b></p>
            <p>${store.address}</p>
            <p> ${store.postalCode} ${store.city}</p>
            <p>${store.phone}</p></div>`,
          });
          // ReactDOM.render(
          //   <StoreCard isHiden={false} isSelected={false} store={store} />,
          //   document.getElementById(`infoWindow- ${idx}`)
          // );

          infoWindows.push(infoWindow);

          if (marker != null) {
            marker.addListener("click", () => {
              infoWindows.forEach((el) => {
                el.close(map, marker);
              });

              infoWindow.open(map, marker);
              map_t.setZoom(12);
              map_t.setCenter(marker.getPosition() as google.maps.LatLng);
              handleMarkerClick(store.id);
            });
            markers_tmp[store.id] = marker;
          }
        });

        setMarkers(markers_tmp);
      }
    }
  };

  return (
    <div className={classes.container}>
      <div ref={ref} className={classes.map} />
    </div>
  );
};

export default Map;
