import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectToken } from "../app/userDataSlice";
import InternalRoutes from "red-electric/utils/InternalRoutes";

const PublicRoute = ({ component: Component, restricted = false, ...rest }) => {
  const isLoggedIn = useSelector(selectToken);
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn && restricted ? (
          <Redirect to={InternalRoutes.account()} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
export default PublicRoute;
