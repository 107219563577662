import css from "./DeliveryBlock.module.less";
import React, { useRef, useState } from "react";
import { merge } from "@common/lib/helpers/classNameHelper";
import GlobalConfig from "@common/data/GlobalConfig";
import xxPicto from "asset/img/picto/xx-picto.png";
import phonePicto from "asset/img/picto/phone-picto.png";
import truckPicto from "asset/img/picto/truck-picto.png";
import letterPicto from "asset/img/picto/letter-picto.png";
import chatPicto from "asset/img/picto/picto-chat.png";
import rede_center from "asset/img/rede_center.png";
let trans = GlobalConfig.appData.blocks.deliveryBlock;
import { Button, Dialog } from "@material-ui/core";
import StoresMapBlock from "../storesMapBlock";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";

interface IProps {
  classNames?: string[];
}

const componentName = "DeliveryBlock";
const debug = require("debug")(`front:${componentName}`);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    close_icon: {
      position: "absolute",
      fontSize: "4.2rem",
      top: 0,
      right: 0,
      width: "5rem",
      height: "5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      fontWeight: 900,
      color: theme.palette.primary.main,
      textShadow: `0px 0px 2px ${theme.palette.background.default}`,
    },
  })
);
export const StyledDialog = withStyles((t: Theme) => ({
  root: {
    padding: 0,
    height: "100%",
  },
  paper: {
    height: "calc(100% - 64px)",
    backgroundColor: t.palette.background.default,
  },
}))(Dialog);

/**
 * @name DeliveryBlock
 */
function DeliveryBlock(props: IProps) {
  const [showStoresModal, setShowStoresModal] = useState(false);
  const classes = useStyles();

  return (
    <div className={merge([css.Root, props.classNames])}>
      <div className={css.container}>
        <div className={css.row}>
          <div className={css.col}>
            <div>
              <img className={css.picto} src={truckPicto} alt="" />
              <div className={css.subtitle}>{trans.title1}</div>
              <div className={css.text}>
                <span>{trans.subtitle1}</span>
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => window.open(process.env.MENU_FAQ)}
              >
                {trans.cta}
              </Button>
            </div>
          </div>
          <div className={css.col}>
            <div>
              <div className={css.subtitle}>{trans.title2}</div>
              <div className={css.col_row}>
                <img src={phonePicto} alt="" />
                <span>
                  {trans.phone}
                  <br /> {trans.schedule}
                </span>
              </div>
              <div className={css.col_row}>
                <img src={letterPicto} alt="" />
                <a href="mailto:support@redelectric.fr">
                  <span>{trans.email}</span>
                </a>
              </div>
              <div className={css.col_row}>
                <img src={chatPicto} alt="" />
                <span>{trans.chat}</span>
              </div>
            </div>
          </div>
          <div className={css.col}>
            <div>
              <img className={css.pictoCenter} src={rede_center} alt="" />
              <div className={css.subtitle2}>{trans.title3}</div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowStoresModal(true)}
              >
                {trans.cta}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <StyledDialog
        fullWidth={true}
        maxWidth={"lg"}
        open={showStoresModal}
        onClose={() => setShowStoresModal(false)}
      >
        <StoresMapBlock displayGarages={true} displayDealers={false} />
        <div
          className={classes.close_icon}
          onClick={() => setShowStoresModal(false)}
        >
          x
        </div>
      </StyledDialog>
    </div>
  );
}

export default DeliveryBlock;
