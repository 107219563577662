import React, { useLayoutEffect, useRef, useState } from "react";

/**
 * TODO DOCUMENTER
 * return {number}
 */
export function useHorizontalSwipe(): number {
  const [swipe, setSwipe] = useState(0);
  const mouseDown = useRef(false);
  let startPos = 0;

  useLayoutEffect(() => {
    const swipeStart = (e = null) => {
      startPos = e.touches ? e.touches[0].clientX : e.clientX;
      mouseDown.current = true;
    };

    const swipeMove = (e = null) => {
      if (!mouseDown.current) return;
      let diff = startPos - (e.touches ? e.touches[0].clientX : e.clientX);
      setSwipe(diff);
    };

    const swipeStop = () => {
      setSwipe(0);
      mouseDown.current = false;
    };

    // listen touch event
    document.addEventListener("touchstart", swipeStart);
    document.addEventListener("touchmove", swipeMove);
    document.addEventListener("touchend", swipeStop);

    document.addEventListener("mousedown", swipeStart);
    document.addEventListener("mousemove", swipeMove);
    document.addEventListener("mouseup", swipeStop);
    return () => {
      // stop to listen touch event
      document.removeEventListener("touchstart", swipeStart);
      document.removeEventListener("touchmove", swipeMove);
      document.removeEventListener("touchend", swipeStop);
      document.removeEventListener("mousedown", swipeStart);
      document.removeEventListener("mousemove", swipeMove);
      document.removeEventListener("mouseup", swipeStop);
    };
  }, []);

  return swipe;
}
