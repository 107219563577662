// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".HomePage-module__lowEnergyTest--2s-Xq{position:absolute;z-index:-10}@media (min-width:768px){.HomePage-module__siteWrapper--3jmhD{margin-top:7rem}}", "",{"version":3,"sources":["HomePage.module.less"],"names":[],"mappings":"AAAA,uCACE,iBAAkB,CAClB,WACF,CACA,yBACE,qCACE,eACF,CACF","file":"HomePage.module.less","sourcesContent":[".lowEnergyTest {\n  position: absolute;\n  z-index: -10;\n}\n@media (min-width: 768px) {\n  .siteWrapper {\n    margin-top: 7rem;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"lowEnergyTest": "HomePage-module__lowEnergyTest--2s-Xq",
	"siteWrapper": "HomePage-module__siteWrapper--3jmhD"
};
module.exports = exports;
