import css from "./ModelEBlock.module.less";
import React, { useRef } from "react";
import GlobalConfig from "@common/data/GlobalConfig";
import ModelBox from "../modelBox";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { EBreakpoints } from "@common/atoms/EBreakpoints";
import { Link } from "react-scroll";

ModelEBlock.defaultProps = {};

const componentName = "ModelEBlock";
const debug = require("debug")(`front:${componentName}`);
let trans = GlobalConfig.appData.blocks.threeSixty;

/**
 * @name ModelEBlock
 */
function ModelEBlock() {
  const carouselRef = useRef<any>();
  const responsive = {
    desktop: {
      breakpoint: { min: EBreakpoints.TABLET, max: 5000 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: EBreakpoints.TABLET, min: 0 },
      items: 1,
      partialVisibilityGutter: 75,
    },
  };

  function ctaClickHandler() {
    console.log("the link to " + document.querySelector(".technicalSection"));

    document.getElementById("section7").scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start",
    });
  }

  const CarouselButtonsGroup = ({ carouselState }: any) => {
    const dotsCount = carouselState.totalItems - carouselState.slidesToShow + 1;
    const { currentSlide } = carouselState;
    const left = Math.round((currentSlide * 100) / dotsCount);
    const width = Math.round(100 / dotsCount);
    return (
      <div
        className={css.carousel_slider}
        onClick={(e: any) => {
          const rect = e.target.getBoundingClientRect();
          const x = e.clientX - rect.left; //x position within the element.
          const targetSlide = Math.floor((x / rect.width) * dotsCount);
          carouselRef.current.goToSlide(targetSlide);
        }}
      >
        <div className={css.carousel_slider_container}>
          <div
            className={css.carousel_slider_bar}
            style={{
              left: left + "%",
              width: width + "%",
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={css.Root}>
      <div className={css.container}>
        <div className={css.textContainer}>
          <h2 className={css.title}>
            {GlobalConfig.appData.blocks.firstLandingBlock?.title}
          </h2>
          <p
            className={css.text}
            dangerouslySetInnerHTML={{
              __html: GlobalConfig.appData.blocks.firstLandingBlock?.text,
            }}
          />
          <span className={css.separator} />
          <h2 className={css.title}>
            {GlobalConfig.appData.blocks.modelEBlock?.title}
          </h2>
          <p
            className={css.text}
            dangerouslySetInnerHTML={{
              __html: GlobalConfig.appData.blocks.modelEBlock?.text,
            }}
          />
        </div>
        <div className={css.row}>
          <div className={css.col}>
            <div className={css.cta}>
              <div className={css.cta_arrow} />
              <b>
                <a
                  className={css.cta_text}
                  href="https://redescooter.com/"
                  target="_blank"
                  children={trans?.ctaProText}
                />
              </b>
            </div>
          </div>
        </div>
        <div className={css.carousel_container}>
          <Carousel
            ref={carouselRef}
            responsive={responsive}
            swipeable
            draggable
            customButtonGroup={<CarouselButtonsGroup carouselState />}
            minimumTouchDrag={1}
            arrows={false}
            partialVisible={true}
            sliderClass={css.carousel_track}
          >
            {GlobalConfig.appData.blocks.modelEBlock.scootsData.map(
              (scootData, index) => (
                <ModelBox key={index} scootsData={scootData} />
              )
            )}
          </Carousel>
        </div>
        <div className={css.cta_container}>
          <div className={css.cta_arrow} />
          <b>
            <Link
              className={css.cta_text}
              duration={500}
              spy={true}
              smooth={true}
              offset={-200}
              to="section7"
              children={trans?.ctaText}
            />
          </b>
        </div>
      </div>
    </div>
  );
}

export default ModelEBlock;
