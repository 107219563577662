import css from "./PressBlock.module.less";
import React, { useRef } from "react";
import { merge } from "@common/lib/helpers/classNameHelper";
import GlobalConfig from "@common/data/GlobalConfig";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { EBreakpoints } from "@common/atoms/EBreakpoints";
import { IPressArticle } from "@common/data/IPressArticle";

interface IProps {
  classNames?: string[];
}

PressBlock.defaultProps = {};

const componentName = "PressBlock";
const debug = require("debug")(`front:${componentName}`);
const isMobile = window.innerWidth < EBreakpoints.TABLET;

/**
 * @name PressBlock
 */
function PressBlock(props: IProps) {
  const trans = GlobalConfig?.appData?.blocks?.pressBlock;
  const responsive = {
    desktop: {
      breakpoint: { min: EBreakpoints.TABLET, max: 5000 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: EBreakpoints.TABLET, min: 0 },
      items: 1,
    },
  };
  const carouselRef = useRef<any>();
  const CarouselButtonsGroup = ({ carouselState }: any) => {
    const dotsCount = carouselState.totalItems - carouselState.slidesToShow + 1;
    const { currentSlide } = carouselState;
    const left = Math.round((currentSlide * 100) / dotsCount);
    const width = Math.round(100 / dotsCount);
    return (
      <div
        className={css.carousel_slider}
        onClick={(e: any) => {
          const rect = e.target.getBoundingClientRect();
          const x = e.clientX - rect.left; //x position within the element.
          const targetSlide = Math.floor((x / rect.width) * dotsCount);
          carouselRef.current.goToSlide(targetSlide);
        }}
      >
        <div className={css.carousel_slider_container}>
          <div
            className={css.carousel_slider_bar}
            style={{
              left: left + "%",
              width: width + "%",
            }}
          />
        </div>
      </div>
    );
  };

  /**
   * Render
   */
  return (
    <div className={merge([css.Root, props.classNames])}>
      <div className={css.container}>
        <h2 className={css.title}>{trans.title}</h2>
        <div className={css.container_carousel}>
          <Carousel
            ref={carouselRef}
            responsive={responsive}
            swipeable
            draggable
            customButtonGroup={<CarouselButtonsGroup carouselState />}
            minimumTouchDrag={1}
            arrows={false}
            partialVisible={true}
            sliderClass={css.carousel_track}
            autoPlay={!isMobile}
            autoPlaySpeed={3000}
            infinite={true}
          >
            {trans.articles.map((text, idx) => (
              <div key={idx} className={css.block}>
                <p className={css.text_container}>
                  {text?.content}
                </p>
                <div className={css.img_container}>
                <img
                  className={css.img}
                  src={require(`${text?.publisherLogo}`).default}
                />
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        <p id="mentions" className={css.subtitle}>{trans.subtitle}</p>
      </div>
    </div>
  );
}

export default PressBlock;
