import Cookies from "universal-cookie";

const cookies = new Cookies();
const id = "token";

export function setLocalToken(value: string): void {
  cookies.set(id, value, { path: "/" });
}

export function getLocalToken(): string {
  return cookies.get(id);
}

export function clearLocalToken(): void {
  cookies.remove(id);
}
