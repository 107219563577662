import React from "react";
import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";
import GlobalConfig from "@common/data/GlobalConfig";
import productImageBlack from "../../../asset/img/colors/black.png";
import productImageCarbon from "../../../asset/img/colors/carbon.png";
import productImageChampagne from "../../../asset/img/colors/champagne.png";
import useStyles from "./OrderCard.styles";
import { OrderData } from "red-electric/app/userDataSlice.interface";

const productImageColors = {
  black: productImageBlack,
  carbon: productImageCarbon,
  champagne: productImageChampagne,
};

interface IProps {
  children?: JSX.Element;
}

export const OrderMatchingCard = ({
  children,
  productName,
  orderNo,
  productColour,
  status,
  createdTime,
}: OrderData & IProps) => {
  const trans = GlobalConfig?.appData.blocks;
  const classes = useStyles();

  const renderOrderDate = (date: string) => {
    return date
      ? new Intl.DateTimeFormat(window.navigator.language).format(
          new Date(date)
        )
      : 0;
  };

  return (
    <Card key={`order-card-${orderNo}`} className={classes.card}>
      <CardMedia
        className={classes.imageSmall}
        image={productImageColors[productColour?.toLowerCase()]}
        title={trans.orderMatching.colors[productColour]}
      />
      <CardContent className={classes.mainCardContent}>
        <Typography variant="h3" color="textPrimary">
          {productName} - {productColour}
        </Typography>
        <Typography color="textPrimary" className={classes.mainCardText}>
          <u>{trans.accountPage.orderedOnLabel}</u>{" "}
          {renderOrderDate(createdTime)}
          <br />
          <u>{trans.accountPage.orderNumberLabel}</u> {orderNo}
          <br />
          <u>{trans.accountPage.paymentStatusLabel}</u>{" "}
          {trans.orderMatching.statuses[status]}
        </Typography>
        {children}
      </CardContent>
    </Card>
  );
};
