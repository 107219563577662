import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Button,
  Grid,
  Box,
  Typography,
  withStyles,
  makeStyles,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { LoadingService } from "@common/services/LoadingService";
import PasswordInput from "red-electric/components/passwordInput";
import GlobalConfig from "@common/data/GlobalConfig";
import Header from "red-electric/components/header";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsLoading,
  selectUIErrors,
  selectUISuccesses,
  updateSuccesses,
} from "red-electric/app/UISlice";
import { userChangePassword } from "red-electric/app/userDataSlice";
import { useHistory } from "react-router-dom";
import InternalRoutes from "red-electric/utils/InternalRoutes";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    paddingTop: "10rem",
    paddingBottom: "8rem",
  },
}));

export const ChangePasswordPage = () => {
  const trans = GlobalConfig?.appData.blocks.changePasswordPage;

  const formRef = useRef<ValidatorForm>(null);
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const UILoading = useSelector(selectIsLoading);
  const UIErrors = useSelector(selectUIErrors);
  const UISuccesses = useSelector(selectUISuccesses);

  const [loaded, setLoaded] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    LoadingService?.instance?.on("loadingDone", () => setLoaded(true));
  }, []);

  useEffect(() => {
    ValidatorForm.addValidationRule(
      "isPasswordMatch",
      (v) => v === newPassword
    );
    return () => ValidatorForm.removeValidationRule("isPasswordMatch");
  }, [newPassword]);

  useEffect(() => {
    formRef.current?.isFormValid(true).then(setIsFormValid);
  });

  const handleChangePassword = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(userChangePassword({ oldPassword, newPassword, confirmPassword }));
  };

  const handleGoToAccount = (e) => {
    e.preventDefault();
    dispatch(updateSuccesses({ userChangePassword: undefined }));
    history.push(InternalRoutes.account());
  };

  const renderForm = () => (
    <ValidatorForm ref={formRef} onSubmit={handleChangePassword}>
      <Container maxWidth="sm">
        <Box mb={6}>
          <Typography variant="h1" align="center">
            {trans.title}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextValidator
              autoComplete="current-password"
              errorMessages={[trans.required]}
              fullWidth
              label={trans.oldPasswordLabel}
              name="oldPassword"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setOldPassword(e.target.value);
              }}
              type="password"
              validators={["required"]}
              value={oldPassword}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordInput
              password={newPassword}
              onChange={setNewPassword}
              onValidChange={setIsNewPasswordValid}
              label={trans.newPasswordLabel}
            />
          </Grid>
          <Grid item xs={12}>
            <TextValidator
              variant="outlined"
              label={trans.confirmPasswordLabel}
              value={confirmPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setConfirmPassword(e.target.value);
              }}
              fullWidth
              validators={["required", "isPasswordMatch"]}
              errorMessages={[trans.required, trans.passwordsNotMatch]}
              type="password"
              name="confirmPassword"
              disabled={!isNewPasswordValid}
            />
          </Grid>
          <Grid item xs={12}>
            {UIErrors.userChangePassword && (
              <Typography color="error">
                {UIErrors.userChangePassword.errorMsg}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={UILoading.userChangePassword || !isFormValid}
            >
              {UILoading.userChangePassword
                ? trans.loading
                : trans.submitButton}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </ValidatorForm>
  );

  const renderSuccessMessage = () => {
    const AlertButton = withStyles((theme) => ({
      root: {
        display: "inline-block",
        marginTop: "2rem",
        marginLeft: "auto",
        marginRight: "auto",
      },
    }))(Button);

    return (
      <Container maxWidth="sm">
        <Alert severity="success" variant="outlined">
          <AlertTitle>{trans.successTitle}</AlertTitle>
          <Typography>{trans.successMessage}</Typography>
        </Alert>
        <Box textAlign="center">
          <AlertButton
            onClick={handleGoToAccount}
            variant="contained"
            color="primary"
          >
            {trans.backToAccount}
          </AlertButton>
        </Box>
      </Container>
    );
  };

  return (
    <div className={classes.root}>
      {UISuccesses.userChangePassword ? renderSuccessMessage() : renderForm()}
    </div>
  );
};
