import React, { useEffect, useRef, useState } from "react";
import TagManager from "react-gtm-module";
import { Container, Box, Typography, withStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import GlobalConfig from "@common/data/GlobalConfig";
import { LoadingService } from "@common/services/LoadingService";
import ThreeSixtyBlock from "red-electric/components/threeSixtyBlock";
import Technicalblock from "red-electric/components/technicalblock";
import ContactForm from "red-electric/components/contactForm";
import CardSliderBlock from "red-electric/components/cardSliderBlock";
import GalleryBlock from "red-electric/components/galleryBlock";
import LandingBlock from "red-electric/components/landingBlock";
import HorizontalScrollBlock from "red-electric/components/horizontalScrollBlock";
import BatteryBlock from "red-electric/components/batteryBlock";
import NewsletterBlock from "red-electric/components/newsletterBlock";
import ModelEBlock from "red-electric/components/modelEBlock";
import PowerBlock from "red-electric/components/powerBlock";
import EcologyBlock from "red-electric/components/ecologyBlock";
import PressBlock from "red-electric/components/pressBlock";
import DeliveryBlock from "red-electric/components/deliveryBlock";
import AutonomyBlock from "red-electric/components/autonomyBlock";
import { NavBar } from "red-electric/components/navBar/NavBar";
import {
  selectIsLoading,
  selectUIErrors,
  selectUISuccesses,
} from "red-electric/app/UISlice";
import { contactForm } from "red-electric/app/publicAPI.actions";
import css from "./HomePage.module.less";

const HomePage = () => {
  const trans = GlobalConfig.appData.layout.header;
  const translationForm = GlobalConfig.appData.blocks;

  const dispatch = useDispatch();
  const UIErrors = useSelector(selectUIErrors);
  const UISuccesses = useSelector(selectUISuccesses);
  const UILoading = useSelector(selectIsLoading);
  const [loaded, setLoaded] = useState(false);
  const [currentScooter, setCurrentScooter] = useState(0);

  useEffect(() => {
    LoadingService?.instance?.on("loadingDone", () => setLoaded(true));
  }, []);

  return (
    <React.Fragment>
      <NavBar links={Object.values(trans.subHeader)} />
      <div className={css.siteWrapper}>
        <div id="section0">
          <LandingBlock />
        </div>
        <div id="section1">
          <ModelEBlock />
          <EcologyBlock displayLink={true} />
        </div>
        <div id="section2">
          <ThreeSixtyBlock
            defaultScooter={currentScooter}
            onScooterChanged={(pScooter) => {
              setCurrentScooter(pScooter);
            }}
          />
          <GalleryBlock />
        </div>
        <div id="section2bis">
          <PowerBlock />
        </div>
        <div id="section3">
          <CardSliderBlock />
        </div>
        <div id="section3bis">
          <AutonomyBlock />
        </div>
        <div id="section4">
          <HorizontalScrollBlock />
          <BatteryBlock />
        </div>
        <div id="section5">{/*<GalleryBlock />*/}</div>
        <div id="section6">{/*<PreorderBlock />*/}</div>
        <div id="section7">
          <Technicalblock
            defaultScooter={currentScooter}
            onScooterChanged={(pScooter) => {
              setCurrentScooter(pScooter);
            }}
          />
          <DeliveryBlock />
        </div>
        <DarkBox id="section8">
          <Container maxWidth="md">
            <Box py={6}>
              <Box mb={6}>
                <Typography
                  variant="h1"
                  align="center"
                  color="textSecondary"
                  dangerouslySetInnerHTML={{
                    __html: translationForm.contactForm.title,
                  }}
                />
              </Box>
              <ContactForm
                darkTheme
                isApiLoading={UILoading.contactForm}
                handleSubmit={(payload) => dispatch(contactForm(payload))}
                submitLabel={translationForm.contactForm.submitButton}
                serverResponse={
                  <React.Fragment>
                    {UISuccesses.contactForm && (
                      <Typography color="textSecondary">
                        Votre message a bien été envoyé !
                      </Typography>
                    )}
                    {UIErrors.contactForm && (
                      <Typography color="error">
                        {UIErrors.contactForm.errorMsg}
                      </Typography>
                    )}
                  </React.Fragment>
                }
              />
            </Box>
          </Container>
        </DarkBox>
        <div id="section9">
          <PressBlock />
        </div>
        <NewsletterBlock />
      </div>
    </React.Fragment>
  );
};

const DarkBox = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
}))(Box);

export default HomePage;
