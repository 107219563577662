import css from "./FaqQuestionBlock.module.less";
import React from "react";
import TagManager from "react-gtm-module";
import { merge } from "@common/lib/helpers/classNameHelper";
import CloseIcon from "../closeIcon";

interface IProps {
  classNames?: string[];
  title: string;
  open?: boolean;
  requestOpen?: () => void;
  children?: any;
}

const tagManagerArgs = {
    dataLayer: {
        event: 'interaction',
        category: 'FAQ',
        action: 'clicFAQ',
        label: ''
    },
}

FaqQuestionBlock.defaultProps = {
  open: false,
};

function FaqQuestionBlock(props: IProps) {
  const renderTitle = (title: string) => (
    <div
      className={merge([css.questionTitle, props.open && css.active])}
      onClick={() => {
        if(!props.open){
          tagManagerArgs.dataLayer.label = title;
          TagManager?.dataLayer(tagManagerArgs);
        }
        props.requestOpen();
      }}
    >
      <span className={css.questionTitleText}>{title}</span>
      <span>
        {props.open ? <CloseIcon className={css.closeButton} /> : "＋"}
      </span>
    </div>
  );

  const renderContent = (content: string) =>
    props.open && (
      <div
        className={css.questionContent}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    );

  return (
    <div className={merge([css.Root, props.classNames])}>
      {renderTitle(props.title)}
      {renderContent(props.children)}
    </div>
  );
}

export default FaqQuestionBlock;
