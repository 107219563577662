import css from "./ThreeSixtyBlock.module.less";
import React, { useEffect, useRef, useState } from "react";
import { merge } from "@common/lib/helpers/classNameHelper";
import GlobalConfig from "../../../common/data/GlobalConfig";
import ScooterChoiceSlider from "../scooterChoiceSlider";
import {
  EScooterChoiceSliderHalfModeLayout,
  EScooterChoiceSliderLineColor,
  EScooterChoiceSliderScale,
} from "../scooterChoiceSlider/ScooterChoiceSlider";
import { gsap, Quad } from "gsap";
import { useScroll } from "../../hooks/useScroll";
import { useMouse } from "../../hooks/useMouse";
import { EBreakpoints } from "@common/atoms/EBreakpoints";

interface IProps {
  classNames?: string[];
  onScooterChanged: (scooterChoice: number) => void;
  defaultScooter: number;
}

const componentName = "ThreeSixtyBlock";
const debug = require("debug")(`front:${componentName}`);

/**
 * @name ThreeSixtyBlock
 */
function ThreeSixtyBlock(props: IProps) {
  // --------------------------------------------------------------------------- PREPARE

  let trans = GlobalConfig.appData.blocks.threeSixty;

  const isMobile = window.innerWidth < EBreakpoints.TABLET;

  const scooterVideo = useRef<HTMLVideoElement>(null);
  const scooterPoster = useRef<HTMLImageElement>(null);
  const pointerRef = useRef<HTMLDivElement>(null);
  const pointerMobileRef = useRef<HTMLDivElement>(null);
  const dragZoneRef = useRef<HTMLDivElement>(null);
  const mouseOffset = useRef<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [selectedScooter, setSelectedScooter] = useState<number>(0);
  const [isHolding, setIsHolding] = useState(false);
  const [pointerMobileIsVisible, setPointerMobileIsVisible] = useState<boolean>(
    false
  );

  const [isLowEnergy, setIsLowEnergy] = useState(false);
  const scrollData = useScroll();
  const mousePos = useMouse();

  useEffect(() => {
    if (!scooterVideo.current || isNaN(scooterVideo.current.duration)) return;
    const time = scooterVideo.current.duration * currentTime;

    gsap.set(scooterVideo.current, {
      currentTime: time,
    });
  }, [currentTime, scooterVideo]);

  useEffect(() => {
    setSelectedScooter(props.defaultScooter);
  }, [props.defaultScooter]);

  useEffect(() => {
    props.onScooterChanged(selectedScooter);
  }, [selectedScooter]);

  useEffect(() => {
    scooterVideo.current.play();
    let hasPlayed = false;
    scooterVideo.current.addEventListener("play", () => {
      hasPlayed = true;
    });

    setTimeout(() => {
      setIsLowEnergy(!hasPlayed);
    });

    setTimeout(() => {
      scooterVideo?.current?.pause();
    }, 2000);
  }, [scooterVideo]);

  useEffect(() => {
    if (isMobile) return;

    if (isInBlock(mousePos, dragZoneRef.current)) {
      gsap.set(pointerRef.current, {
        display: "block",
        x: mousePos.x,
        y: mousePos.y,
      });
    } else {
      gsap.set(pointerRef.current, {
        display: "none",
      });
    }
  }, [scrollData, mousePos]);

  // --------------------------------------------------------------------------- UTILS

  const isInBlock = (pPoint: { x: number; y: number }, pBlock: HTMLElement) => {
    let isInside = false;

    if (
      pPoint.x >= pBlock.getBoundingClientRect().left &&
      pPoint.x <= pBlock.getBoundingClientRect().right &&
      pPoint.y >= pBlock.getBoundingClientRect().top &&
      pPoint.y <= pBlock.getBoundingClientRect().bottom
    ) {
      isInside = true;
    }
    return isInside;
  };
  // --------------------------------------------------------------------------- HANDLER

  const onMouseDown = (pClientX) => {
    //if (isMobile()) gsap.set("body", { overflow: "hidden" });
    setIsHolding(true);
    mouseOffset.current = pClientX;
  };

  const onMouseUp = (e) => {
    //if (isMobile()) gsap.set("body", { overflow: "initial" });
    setIsHolding(false);
  };
  const onMouseMove = (pClientX, pAmp) => {
    gsap.to(pointerMobileRef.current, 0.5, {
      opacity: 0,
      ease: Quad.easeInOut,
    });

    // if (isHolding) {
    const delta =
      1 - (pClientX - mouseOffset.current) / document.body.offsetWidth;
    setCurrentTime(Math.abs((currentTime + delta) % 1));

    mouseOffset.current = pClientX;
    //}
  };

  function ctaClickHandler() {
    document.querySelector(".technicalSection").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }

  // --------------------------------------------------------------------------- RENDER

  return (
    <div className={merge([css.Root, props.classNames])}>
      <div ref={pointerRef} className={css.pointer}>
        <div className={css.pointerContent}>360°</div>
      </div>
      {!isLowEnergy && (
        <div ref={pointerMobileRef} className={css.pointerMobile}>
          <div className={css.pointerContent}>360°</div>
        </div>
      )}

      {isLowEnergy && (
        <img
          ref={scooterPoster}
          className={merge([css.scooter, css.scooter_poster])}
          src={
            isMobile
              ? require("./360-poster-mobile.jpg").default
              : require("./360-poster.jpg").default
          }
        />
      )}

      <video
        ref={scooterVideo}
        className={css.scooter}
        style={{
          opacity: isLowEnergy ? 0 : 1,
        }}
        src={
          isMobile
            ? require("./new-scooter-mobile.mp4").default
            : require("./new-scooter.mp4").default
        }
        muted={true}
        autoPlay={true}
        playsInline={true}
      />

      <div className={css.container}>
        <div className={css.textContainer}>
          <h2 className={css.title}>
            {GlobalConfig.appData.blocks.landingBlock?.title}
          </h2>
          <span
            ref={dragZoneRef}
            onTouchStart={(e) => {
              onMouseDown(e?.touches[0]?.clientX);
            }}
            onTouchEnd={onMouseUp}
            onMouseLeave={() => {}}
            onMouseMove={(e) => {
              onMouseMove(e.clientX, 5);
            }}
            onTouchMove={(e) => {
              onMouseMove(e?.touches[0]?.clientX, 5);
            }}
            className={css.invisibleSeparator}
          />
        </div>
      </div>
    </div>
  );
}

export default ThreeSixtyBlock;
