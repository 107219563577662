import React, { useEffect, useRef, useState } from "react";
import { Button, Box, Typography } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import GlobalConfig from "@common/data/GlobalConfig";
import { Link } from "react-router-dom";
import InternalRoutes from "red-electric/utils/InternalRoutes";

interface IProps {
  isApiLoading: boolean;
  submitLabel: string;
  handleSubmit: (payload) => void;
  serverResponse?: JSX.Element;
}

export const LoginForm = (props: IProps) => {
  const trans = GlobalConfig?.appData.blocks.loginPage;

  const formRef = useRef<ValidatorForm>(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    formRef.current?.isFormValid(true).then(setIsFormValid);
  });

  const handleFormSubmit = () => {
    const payload = { email, password };
    props.handleSubmit(payload);
  };

  return (
    <ValidatorForm ref={formRef} onSubmit={handleFormSubmit}>
      <Box mt={6} mb={5}>
        <TextValidator
          variant="outlined"
          label={trans.emailLabel}
          fullWidth
          type="email"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value);
          }}
          value={email}
          validators={["required", "isEmail"]}
          errorMessages={[trans.emailRequired, trans.emailInvalid]}
          autoComplete="email"
          name="email"
        />
      </Box>
      <Box mb={2}>
        <TextValidator
          variant="outlined"
          label={trans.passwordLabel}
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(e.target.value);
          }}
          fullWidth
          validators={["required"]}
          errorMessages={[trans.passwordRequired]}
          type="password"
          name="password"
        />
      </Box>
      <Box mb={4}>
        <Typography component={Link} to={InternalRoutes.forgotPassword()}>
          {trans.forgotPasswordLabel}
        </Typography>
      </Box>
      {props.serverResponse && <Box mb={3}>{props.serverResponse}</Box>}
      <Button
        variant="contained"
        color="primary"
        type="submit"
        fullWidth
        disabled={props.isApiLoading || !isFormValid}
      >
        {props.isApiLoading ? trans.loading : props.submitLabel}
      </Button>
    </ValidatorForm>
  );
};
