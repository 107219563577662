import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectToken } from "red-electric/app/userDataSlice";
import InternalRoutes from "red-electric/utils/InternalRoutes";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector(selectToken);
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to={InternalRoutes.login()} />
        )
      }
    />
  );
};

export default PrivateRoute;
