import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  OutlinedInput,
  Paper,
  Typography,
} from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { PasswordInput } from "../../components/passwordInput/PasswordInput";
import { useHistory, useLocation } from "react-router-dom";
import { userResetPassword } from "../../app/userDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { updateSuccesses } from "../../app/UISlice";
import InternalRoutes from "../../utils/InternalRoutes";
import { Alert } from "@material-ui/lab";
import {
  selectUISuccesses,
  selectUIErrors,
  selectIsLoading,
} from "../../app/UISlice";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "10rem",
    height: "calc(100vh - 10rem)",
  },
  grid: {
    height: "100%",
  },
}));

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const ResetPasswordPage = () => {
  const formRef = useRef<ValidatorForm>(null);
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const UISuccesses = useSelector(selectUISuccesses);
  const UIErrors = useSelector(selectUIErrors);
  const isLoading = useSelector(selectIsLoading);

  const [repeatPassword, setRepeatPassword] = useState("");

  const query = useQuery();

  const requestId = query.get("requestId");

  const handleFormSubmit = () => {
    dispatch(
      userResetPassword({
        newPassword: password,
        cfmPassword: repeatPassword,
        requestId,
      })
    );
  };

  const handleGoToLogin = (e) => {
    e.preventDefault();
    dispatch(updateSuccesses({ userResetPassword: undefined }));
    history.push(InternalRoutes.account());
  };

  return (
    <Container className={classes.root}>
      <Grid
        className={classes.grid}
        container
        alignItems="center"
        justify="center"
        alignContent="stretch"
      >
        <Grid item xs={12} md={6}>
          <Paper elevation={20} color="white">
            <Box p={3}>
              <Box my={3} textAlign="center">
                <Typography variant="h2">Réinitialiser mot de passe</Typography>
              </Box>

              {UISuccesses.userResetPassword && (
                <Alert severity="success">
                  Votre mot de passe a bien été modifié.
                </Alert>
              )}

              {UIErrors.userResetPassword && (
                <Alert severity="error">
                  Votre mot de passe n'a pu être modifié.
                  <Typography variant="subtitle2">
                    <b>{UIErrors.userResetPassword.errorCode}:</b>
                    {UIErrors.userResetPassword.errorMsg}
                  </Typography>
                </Alert>
              )}

              <ValidatorForm ref={formRef} onSubmit={handleFormSubmit}>
                <Box my={3}>
                  <PasswordInput
                    label={"Mot de passe"}
                    onChange={(newPassword) => {
                      setPassword(newPassword);
                    }}
                    onValidChange={(isValid) => {
                      setPasswordIsValid(isValid);
                    }}
                    password={password}
                  />
                </Box>
                <Box my={3}>
                  <FormControl error={password !== repeatPassword} fullWidth>
                    <InputLabel>Répeter mot de passe</InputLabel>
                    <OutlinedInput
                      type="password"
                      placeholder={"Répéter mot de passe"}
                      value={repeatPassword}
                      onChange={(e) => {
                        setRepeatPassword(e.target.value);
                      }}
                    />
                    {password !== repeatPassword && (
                      <FormHelperText>
                        Veuillez vous assurer que les mots de passe sont
                        identiques
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box my={3}>
                  <Button
                    disabled={
                      !passwordIsValid ||
                      password !== repeatPassword ||
                      isLoading.userResetPassword
                    }
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                  >
                    {isLoading.userResetPassword && (
                      <CircularProgress size={14} />
                    )}
                    &nbsp; Réinitialiser mot de passe
                  </Button>
                </Box>
              </ValidatorForm>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
