import css from "./EcologyBlock.module.less";
import React, { useState } from "react";
import { merge } from "@common/lib/helpers/classNameHelper";
import GlobalConfig from "@common/data/GlobalConfig";
import { Dialog } from "@material-ui/core";
import StoresMapBlock from "../storesMapBlock";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import EcologyForm from "../ecologyForm";
import ConversionBlock from "../conversionBlock";

let trans = GlobalConfig.appData.blocks.ecologyBlock;

interface IProps {
  classNames?: string[];
  displayLink?: boolean;
}

const componentName = "EcologyBlock";
const debug = require("debug")(`front:${componentName}`);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    close_icon: {
      position: "absolute",
      fontSize: "4.2rem",
      top: 0,
      right: 0,
      width: "5rem",
      height: "5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      fontWeight: 900,
      color: theme.palette.primary.main,
      textShadow: `0px 0px 2px ${theme.palette.background.default}`,
    },
  })
);

/**
 * @name EcologyBlock
 */
function EcologyBlock(props: IProps) {
  /**
     open the link in a new window
     **/
  function openLinkInNewWindow(sLink) {
    window.open(sLink, "_blank");
  }
  const [showStoresModal, setShowStoresModal] = useState(false);

  const classes = useStyles();

  return (
    <div className={merge([css.Root, props.classNames])}>
      <div className={css.container}>
        <div className={merge([css.wrapper_content, css.wrapper_text])}>
          <h2 className={css.title}>{trans.title}</h2>
          <p
            className={css.text}
            dangerouslySetInnerHTML={{
              __html: trans.subtitle,
            }}
          />
        </div>
        <div className={merge([css.wrapper_content, css.wrapper_form])}>
          <EcologyForm />
        </div>
        {props.displayLink && (
          <div className={css.wrapper_contentCta}>
            <div className={css.cta_container}>
              <div className={css.cta_arrow} />
              <b>
                <a
                  className={css.cta_text}
                  href="/finance"
                  children={trans?.ctaText}
                />
              </b>
            </div>
          </div>
        )}
      </div>
      <ConversionBlock />
    </div>
  );
}

export default EcologyBlock;
