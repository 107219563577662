import css from "./BatteryBlock.module.less";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { merge } from "@common/lib/helpers/classNameHelper";
import GlobalConfig from "@common/data/GlobalConfig";
import { EBreakpoints } from "@common/atoms/EBreakpoints";
import { useWindowSize } from "@wbe/libraries";

interface IProps {
  classNames?: string[];
}

const componentName = "BatteryBlock";
const debug = require("debug")(`front:${componentName}`);

/**
 * @name BatteryBlock
 */
function BatteryBlock(props: IProps) {
  // --------------------------------------------------------------------------- PREPARE

  // -- Data
  const data = GlobalConfig?.appData?.blocks?.batteryBlock;

  // -- States
  const [videoData, setVideoData] = useState(null);

  // -- Hooks
  const resize = useWindowSize();

  // -- Others
  const isMobile = window.innerWidth < EBreakpoints.TABLET;
  const videoName = isMobile
    ? data?.media?.videoNameMobile
    : data?.media?.videoNameDesktop;

  // --------------------------------------------------------------------------- EFFECTS

  useEffect(() => setVideoData(require(`./${videoName}`)), [resize]);

  // --------------------------------------------------------------------------- RENDER

  return (
    <div className={merge([css.Root, props.classNames])}>
      <div className={css.text}>
        <div className={css.title} children={data?.text?.title} />
        <p
          className={css.paragraph}
          dangerouslySetInnerHTML={{ __html: data?.text?.paragraph }}
        />
      </div>
      <div className={css.media}>
        {videoData?.default && (
          <video
            className={css.videoElement}
            src={videoData.default}
            autoPlay={true}
            controls={false}
            muted={true}
            playsInline={true}
          />
        )}
      </div>
    </div>
  );
}

export default BatteryBlock;
