import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Chip,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";
import theme from "../../theme";
import GlobalConfig from "@common/data/GlobalConfig";
import productImageBlack from "../../../asset/img/colors/black.png";
import productImageCarbon from "../../../asset/img/colors/carbon.png";
import productImageChampagne from "../../../asset/img/colors/champagne.png";
import useStyles from "./OrderCard.styles";
import { OrderData } from "red-electric/app/userDataSlice.interface";
const dateFormat = require("dateformat");
import DoneIcon from "@material-ui/icons/Done";
import clsx from "clsx";

const productImageColors = {
  black: productImageBlack,
  carbon: productImageCarbon,
  champagne: productImageChampagne,
};

interface OrderCardProps {}

const formatDate = (date) => {
  const d = new Date(date);
  return dateFormat(d, "d mmm  yyyy");
};

interface InvoiceTableProps {
  rows: [any];
}

interface InvoiceLineProps {
  label?: string;
  qty?: number | string;
  price?: number | string;
  isHeader?: boolean;
  isTotal?: boolean;
  isSubtotal?: boolean;
  isNegative?: boolean;
}
const InvoiceLine = ({
  label,
  qty,
  price,
  isHeader,
  isTotal,
  isSubtotal,
  isNegative,
}: InvoiceLineProps) => {
  const classes = useStyles();
  return (
    <Grid
      container
      alignItems={"center"}
      className={clsx(classes.invoice_line, {
        [classes.invoice_header]: isHeader,
        [classes.invoice_total]: isTotal,
        [classes.invoice_subtotal]: isSubtotal,
      })}
    >
      <Grid item xs={6}>
        <Box color={theme.palette.text.hint} my={1}>
          <Typography align="left" variant="body2">
            <b>{label}</b>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Typography align="right" variant="body1">
          <b>{qty}</b>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography align="right" variant="body1">
          <b>{price}</b>
        </Typography>
      </Grid>
    </Grid>
  );
};
export const OrderCard = ({
  orderNo,
  productColour,
  productName,
  paymentName,
  orderBlist,
  amountPaid,
  productPrice,
  scooterQuantity,
  createdTime,
  totalPrice,
}: OrderData & OrderCardProps) => {
  const trans = GlobalConfig?.appData.blocks;
  const classes = useStyles();

  const [showMore, setShowMore] = useState(true);

  const ColorCircle = ({ color }) => {
    const hash = {
      champagne: "#978f81",
      black: "#000",
      carbon: "#3b3b3b",
    };
    return (
      color && (
        <Tooltip title={color.toLowerCase()} aria-label="add" placement="top">
          <Box
            mr={1}
            bgcolor={hash[color.toLowerCase()]}
            className={classes.color_circle}
          />
        </Tooltip>
      )
    );
  };

  return (
    <Card className={classes.card}>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={"auto"}>
          <Box className={classes.image_wrapper}>
            <CardMedia
              className={classes.image}
              image={productImageColors[productColour.toLowerCase()]}
              title={trans.orderMatching.colors[productColour]}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={"auto"} style={{ flex: 1 }}>
          <Grid container alignItems="center" className={classes.name_wrapper}>
            <Grid item>
              <ColorCircle color={productColour} />
            </Grid>
            <Grid item>
              <Box my={2}>
                <Typography className={classes.name} variant="h3">
                  {productName}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box textAlign="center">
            <Grid container justify="space-between">
              <Grid item xs={12} sm={4}>
                <Typography variant="caption">Commande</Typography>
                <Typography variant="body1">#{orderNo}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="caption">Date de commande</Typography>
                <Typography variant="body1">
                  {formatDate(createdTime)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="caption">Paiement</Typography>
                <Typography variant="body1">
                  <Chip
                    label={paymentName}
                    color="primary"
                    deleteIcon={<DoneIcon />}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Grid container justify="flex-end">
            <Grid item>
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  setShowMore(!showMore);
                }}
              >
                Voir {showMore ? "moins" : "plus"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {showMore && (
        <Grid container justify="center">
          <Grid item container xs={12} md={6}>
            <Box
              className={classes.invoice}
              p={2}
              borderRadius={3}
              boxShadow={3}
              bgcolor={theme.palette.background.paper}
              width={"100%"}
            >
              <Box my={1}>
                <Typography variant="caption">Scooter</Typography>
              </Box>
              <InvoiceLine
                label={productName}
                qty={`x ${scooterQuantity}`}
                price={`${productPrice} €`}
              />
              <Box my={1}>
                <Typography variant="caption">Accésoires</Typography>
              </Box>
              {orderBlist &&
                orderBlist.map(({ enName, orderBId, partsPrice, partsQty }) => (
                  <InvoiceLine
                    label={enName}
                    qty={`x ${partsQty}`}
                    price={`${partsPrice} €`}
                  />
                ))}
              <InvoiceLine
                isSubtotal={true}
                label={"Total Accessoires"}
                qty={
                  "x" + orderBlist.reduce((acc, cur) => acc + cur.partsQty, 0)
                }
                price={
                  orderBlist.reduce((acc, cur) => acc + cur.partsPrice, 0) +
                  " € "
                }
              />
              <InvoiceLine
                isSubtotal={true}
                label={"Payé"}
                qty={`x${
                  orderBlist.reduce((acc, cur) => acc + cur.partsQty, 0) +
                  scooterQuantity
                }`}
                price={amountPaid + " € "}
              />
              <InvoiceLine
                isTotal={true}
                label={"Total"}
                price={totalPrice + " € "}
              />
              <InvoiceLine
                isTotal={true}
                isNegative={true}
                label={"Restant à payer"}
                price={`${totalPrice - parseInt(amountPaid)} €`}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Card>
  );
};
