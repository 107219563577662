import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Paper,
  Grid,
  Typography,
  Box,
  Button,
  Hidden,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  TableBody,
  Divider,
  makeStyles,
} from "@material-ui/core";
import bg_1 from "asset/img/background_1.jpg";
import { Theme, useTheme, withStyles } from "@material-ui/core/styles";

import bg10 from "asset/img/background/bg10.jpg";
import bg11 from "asset/img/background/bg11.png";
import bg4 from "asset/img/background/bg4.jpg";
import bg5 from "asset/img/background/bg5.png";
import bg6 from "asset/img/background/bg6.jpg";
import bg12 from "asset/img/background/bg12.png";

import fnac_logo from "../../../asset/img/fnac.png";
import darty_logo from "../../../asset/img/darty.png";
import rede_center_logo from "../../../asset/img/rede_center.png";
import Header from "../../components/header";
import { LoadingService } from "@common/services/LoadingService";
import EcologyForm from "../../components/ecologyForm";
import NewsletterBlock from "../../components/newsletterBlock";
import Co2Calculator from "../../components/co2Calculator";
import { NavBar } from "../../components/navBar/NavBar";
import compiledTheme from "../../theme";
import ConversionBlock from "../../components/conversionBlock";

interface IProps {}

const componentName = "ChooseElectricPage";
const debug = require("debug")(`front:${componentName}`);

/**
 * @name ChooseElectricPage
 */

const BackgroundImage = (props) => (
  <div
    style={{
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      zIndex: 1,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${props.bg})`,
      backgroundPosition: "50% 50%",
      filter: `blur(${props.blur}px)`,
    }}
  >
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        background: `rgba(0,0,0,${props.opacity})`,
      }}
    />
  </div>
);

const LateralBackgroundImage = (props, theme) => (
  <div
    style={{
      position: "absolute",
      left: "25%",
      top: 0,
      width: "75%",
      height: "100%",
      zIndex: 1,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${props.bg})`,
      backgroundPosition: "50% 50%",
      filter: `blur(${props.blur}px)`,
      [compiledTheme.breakpoints.down("sm")]: {
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      },
    }}
  >
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        background: `rgba(0,0,0,${props.opacity})`,
      }}
    />
  </div>
);

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "hidden",
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("xs")]: {
      paddingTop: theme.spacing(18),
    },
  },
  sectionTitle: {
    paddingTop: "4rem",
    paddingBottom: "2rem",
  },
  sectionInfo: {
    paddingTop: "1rem",
  },
  sectionInfoNumber: {
    marginTop: "1.5rem",
    paddingBottom: "1.5rem",
  },
  imageLateralContainer: {
    position: "absolute",
    left: "50%",
    top: 0,
    bottom: 0,
    right: 0,
    [theme.breakpoints.down("sm")]: {
      left: 0,
    },
  },
}));

const ChooseElectricPage = (props: IProps) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState(false);
  const theme = useTheme();
  const classes = useStyles();
  const subNavLinks = [
    {
      label: "Mode de transport polyvalent",
      link: "performance",
    },
    {
      label: "Une technologie économique",
      link: "autonomy",
    },
    {
      label: "Bonus écologique",
      link: "bonus",
    },
    {
      label: "Une technologie écologique",
      link: "ecology",
    },
  ];

  useEffect(() => {
    LoadingService?.instance?.on("loadingDone", () => setLoaded(true));
  }, []);

  return (
    <div ref={rootRef}>
      <NavBar links={subNavLinks} />
      <div style={{ paddingTop: "8rem" }}>
        <Box mt={2}>
          <Section py={2} style={{ background: "transparent" }}>
            <BackgroundImage bg={bg4} blur={25} opacity={0.5} />
            <Container>
              <Grid
                container
                alignItems="center"
                justify="center"
                style={{
                  minHeight: "50vh",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Grid
                  md={6}
                  xs={12}
                  item
                  style={{ zIndex: 2, position: "relative" }}
                >
                  <Typography variant="h1" align="center">
                    Pourquoi choisir un véhicule électrique ?
                  </Typography>
                  <Typography variant="body1" align="center">
                    Parce que la ville demande toujours plus… Plus d’attention,
                    plus d’efficacité, plus de sécurité, plus d’anticipation,
                    plus d’autonomie, plus de temps. Mais aussi, plus de respect
                    et plus de silence. Les moteurs thermiques, bruyants et
                    polluants, n’y ont plus leur place. Chez RED Electric, nous
                    croyons à la performance de l’électrique sans concession à
                    l’efficacité - ni au plaisir.
                  </Typography>
                  <Typography variant="body1" align="center">
                    L’investissement initial vous paraît important ? Ne vous
                    inquiétez pas, il sera largement compensé par un quotidien
                    bien plus économique, plus écologique et moins contraignant.
                    Un exemple ? Moins de 50 € d’électricité vous suffiront pour
                    parcourir 10 000 km ! De plus, de nombreuses incitations -
                    nationales & locales - existent, tel le Bonus Écologique.
                    Renseignez-vous ou utilisez notre Calculateur.
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Section>
          <div id="performance">
            <Section style={{ background: "transparent" }}>
              <BackgroundImage bg={bg5} blur={10} opacity={0.5} />
              <Container className={classes.sectionTitle}>
                <Grid container>
                  <Grid xs={12} item style={{ zIndex: 2 }}>
                    <Box py={2}>
                      <Typography variant="h2" color="primary" align="left">
                        01
                      </Typography>
                      <Typography variant="h2" color="inherit" align="left">
                        Le mode de transport électrique
                        <br />
                        le plus polyvalent
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
            <Section style={{ background: "white", padding: 0 }}>
              <Box className={classes.imageLateralContainer}>
                <LateralBackgroundImage bg={bg10} blur={0} />
              </Box>
              <Container>
                <Grid style={{ height: "100%" }} container spacing={5}>
                  <Grid
                    xs={12}
                    sm={6}
                    item
                    style={{
                      zIndex: 2,
                      background: "white",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Box my={5} position={"relative"} color="main.primary">
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.sectionInfoNumber}
                      >
                        Le scooter électrique est le véhicule léger électrique
                        le plus polyvalent : C’est en effet le seul qui puisse
                        transporter deux passagers et leurs bagages sur
                        plusieurs dizaines de kilomètres - et plusieurs
                        centaines pour nos Model E !
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.sectionInfoNumber}
                      >
                        Nul besoin de pédaler comme sur un vélo électrique –
                        dont la puissance est limitée à 250 W. - ou 500 W. Pour
                        les Speedbikes – et qui ne peut parcourir que quelques
                        kilomètres par jour. De plus, les VAE nécessitent un
                        entretien soutenu et constant.
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.sectionInfoNumber}
                      >
                        Les Model E disposent d’une puissance de 4000 Watts
                        (E50) à 11000 Watts (E125). Une simple visite de
                        maintenance tous les 5000 km suffit.
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.sectionInfoNumber}
                      >
                        <u>Voies de Circulation réservées</u>
                        <br />
                        Les scooters électriques – à l’opposé de leurs
                        équivalents thermiques – sont considérés comme des
                        véhicules à très faibles émissions (VTFE) et bénéficient
                        de conditions de circulation et de stationnement
                        privilégiées :
                        <br />
                        <u>
                          Ils peuvent désormais circuler sur les voies réservées
                          à la circulation des véhicules de transport en commun,
                          des taxis, des véhicules de covoiturage et des
                          véhicules à très faibles émissions.
                        </u>
                        Et leur <u>parking est toujours gratuit !</u>
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        align="left"
                      >
                        Un gage supplémentaire de rapidité et de sécurité pour
                        vos transports en agglomération.
                        <br />
                        Et leur parking est toujours gratuit !
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
          </div>
          <div id="autonomy">
            <Section style={{ background: "transparent" }}>
              <BackgroundImage bg={bg5} blur={10} opacity={0.5} />
              <Container className={classes.sectionTitle}>
                <Grid container>
                  <Grid xs={12} item style={{ zIndex: 2 }}>
                    <Box py={2}>
                      <Typography variant="h2" color="primary" align="left">
                        02
                      </Typography>
                      <Typography variant="h2" color="inherit" align="left">
                        Une technologie plus économique que le thermique
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
            <Section style={{ background: "white", padding: 0 }}>
              <Hidden smDown>
                <Box className={classes.imageLateralContainer}>
                  <LateralBackgroundImage bg={bg11} blur={0} />
                </Box>
              </Hidden>
              <Container>
                <Grid style={{ height: "100%" }} container spacing={5}>
                  <Grid
                    xs={12}
                    sm={6}
                    item
                    style={{
                      zIndex: 2,
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Box my={3} position={"relative"} color="main.primary">
                      <Typography
                        variant="h2"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        Coût d'entretien réduit
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.sectionInfo}
                      >
                        L’entretien des scooters RED Electric Model E se limite
                        à une révision tous les 5000 km (ou une fois par an)
                        contre 3000 km en moyenne pour un scooter 125 à moteur
                        thermique.
                      </Typography>
                      <Typography
                        variant="h2"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        Faible coût d'énergie
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        50€ pour 10000 km soit 50 centimes aux 100 km ? Qui dit
                        mieux ?
                        <br />
                        Certainement pas les scooters thermiques qui réclament
                        400 € d’essence pour parcourir 10000 km (moyenne).
                      </Typography>
                      <Typography
                        variant="h2"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        Une assurance moins chère
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        Les polices d'assurances sont en moyenne 20% moins
                        chères pour un scooter électrique que pour son
                        équivalent thermique. Sur 3 ans, votre économie est de
                        l'ordre de 200 €.
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        Le coût total d'utilisation d'un scooter électrique RED
                        Electric est 35 à 50% moins cher que celui de son
                        équivalent thermique.
                      </Typography>

                      <Box my={5}>
                        <TableContainer component={Paper}>
                          <Table
                            aria-label="simple table"
                            style={{
                              background: "white",
                            }}
                          >
                            <TableHead>
                              <TableRow
                                style={{
                                  textTransform: "uppercase",
                                }}
                              >
                                <TableCell />
                                <TableCell
                                  align="center"
                                  style={{
                                    background: theme.palette.primary.main,
                                    color: "white",
                                  }}
                                >
                                  Scooters Model E
                                </TableCell>
                                <TableCell align="center">
                                  Scooter Équivalent thermique
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  Entretien
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    background: theme.palette.primary.main,
                                    color: "white",
                                  }}
                                >
                                  Tous les 5 000 km
                                </TableCell>
                                <TableCell align="center">
                                  Tous les 3 000 km
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  Coût l'usage
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    background: theme.palette.primary.main,
                                    color: "white",
                                  }}
                                >
                                  50 cts
                                  <br />
                                  au 100 km
                                </TableCell>
                                <TableCell align="center">
                                  4€ environ au 100 km
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  Assurance
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    background: theme.palette.primary.main,
                                    color: "white",
                                  }}
                                >
                                  25€/mois environ
                                </TableCell>
                                <TableCell align="center">
                                  +30€/mois environ
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
          </div>
          <div id="bonus">
            <Section style={{ background: "transparent" }}>
              <BackgroundImage bg={bg5} blur={10} opacity={0.5} />
              <Container className={classes.sectionTitle}>
                <Grid container>
                  <Grid xs={12} item style={{ zIndex: 2 }}>
                    <Box py={2}>
                      <Typography variant="h2" color="primary" align="left">
                        03
                      </Typography>
                      <Typography variant="h2" color="inherit" align="left">
                        Profitez du Bonus écologique pour l'achat d'un Model E
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
            <Section>
              <Container>
                <Grid style={{ height: "100%" }} container spacing={5}>
                  <Grid
                    xs={12}
                    sm={6}
                    item
                    style={{
                      zIndex: 2,
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Box my={3} position={"relative"} color="main.primary">
                      <Typography
                        variant="h2"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionTitle}
                      >
                        Tous nos Model E sont éligibles au Bonus écologique !
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.sectionTitle}
                      >
                        <b>Qui peut bénéficier du nouveau Bonus écologique ?</b><br />
                        Le Bonus écologique 2020 concerne les véhicules électriques,
                        et donc tous nos scooters électriques Model E.<br />
                        Cette aide est accessible à tous les particuliers français,
                        ainsi qu'aux professionnels, pour l'achat ou la location longue durée d'un véhicule électrique.
                        Et il est sans condition de revenu, tout le monde peut en profiter !
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.sectionTitle}
                      >
                      <b>Quel est le montant du bonus écologique ?</b><br />
                      Le montant de l'aide s'élève à 250€ par kWh d'énergie de batterie, dans la limite de 900€ par véhicule.
                      <br />
                        Ainsi, pour chacun de nos modèles, vous pouvez
                        bénéficier de :
                      </Typography>
                      <Box my={5}>
                        <Grid container spacing={0}>
                          <Grid item xs={12} sm={4}>
                            <Box textAlign={"center"}>
                              <Typography
                                align="center"
                                color="primary"
                                gutterBottom
                                variant="h4"
                              >
                                Model E50
                              </Typography>
                              <Box m={1} color="textPrimary">
                                <Typography
                                  align="center"
                                  gutterBottom
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  <small style={{ fontSize: "xx-small" }}>
                                    (Batterie 2,42 kWh)
                                  </small>
                                  <br />
                                  605€
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <Box textAlign={"center"}>
                              <Typography
                                align="center"
                                color="primary"
                                gutterBottom
                                variant="h4"
                              >
                                Model E100
                              </Typography>
                              <Box m={1} color="textPrimary">
                                <Typography
                                  align="center"
                                  gutterBottom
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  <small style={{ fontSize: "xx-small" }}>
                                    (Batterie 4,84 kWh)
                                  </small>
                                  <br />
                                  900€
                                  <br />
                                  <small style={{ fontSize: "xx-small" }}>
                                    Soit le montant maximum du bonus
                                  </small>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Box textAlign={"center"}>
                              <Typography
                                align="center"
                                color="primary"
                                gutterBottom
                                variant="h4"
                              >
                                Model E125
                              </Typography>
                              <Box m={1} color="textPrimary">
                                <Typography
                                  align="center"
                                  gutterBottom
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  <small style={{ fontSize: "xx-small" }}>
                                    (Batterie 9,68 kWh)
                                  </small>
                                  <br />
                                  900€
                                  <br />
                                  <small style={{ fontSize: "xx-small" }}>
                                    soit le montant maximum du bonus
                                  </small>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box my={5}>
                        <Typography variant="body1" color="textPrimary">
                          <b>Comment bénéficier de l’aide pour les scooters électriques ?</b>
                          <br />
                          Tous nos véhicules sont éligibles au Bonus écologique.
                          Il vous suffit simplement
                          d'effectuer une demande de remboursement en ligne, sur
                          le site du <a href="https://www.service-public.fr/particuliers/vosdroits/R49929">service public</a> en fournissant :<br />
                        </Typography>
                        <Typography variant="body1" color="textPrimary">
                          <li>la facture d'achat de votre scooter</li>
                          <li>une photocopie de la Carte Grise</li>
                          <li>
                            une photocopie de votre Carte Nationale d'Identité
                          </li>
                          <li>un justificatif de domicile</li>
                          <li>un RIB ou IBAN</li>
                        </Typography>
                        <Typography variant="body1" color="textPrimary">
                          Le Bonus vous sera ensuite directement versée par
                          virement sur votre compte bancaire, dans un délai de 4
                          à 8 semaines.<br /><br />
                        </Typography>
                        <Typography variant="body1" color="textPrimary">
                          <b>Existe-t-il d'autres aides?</b>
                        </Typography>
                        <Typography variant="body1" color="textPrimary">
                          <li>
                            Si vous êtes un particulier, il peut exister
                            d'autres aides à l'achat d'un véhicule électrique au
                            niveau de votre ville ou région. Sachez cependant
                            qu'elles ne sont généralement pas cumulables avec le
                            bonus écologique (et souvent moins avantageuses).
                          </li>
                        </Typography>
                        <Typography variant="body1" color="textPrimary">
                          <li>
                            Si vous êtes un professionnel en Ile de France, il
                            existe une subvention supplémentaire de 1500€,
                            cumulable avec le bonus écologique.<br />
                            Pour y avoir droit, votre entreprise doit compter jusqu'à 50
                            salariés, avoir son siège en Île-de-France et un
                            chiffre d'affaires annuel ou un total de bilan
                            annuel qui n'excède pas 10 M€.<br /><br />
                          </li>
                        </Typography>
                        <Typography variant="body1" color="textPrimary">
                          A noter : vous devez en faire la demande préalablement
                          à la signature de votre Bon de Commande, en
                          constituant votre dossier sur le <a href="https://www.service-public.fr/particuliers/vosdroits/F35476">site de la Région Ile
                          de France.</a>
                          <br />
                          Enfin, sachez que cette subvention est limitée à 5
                          véhicules par K-bis et que les auto-entrepreneurs y
                          sont éligibles.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box mt={10}>
                      <Typography variant="body1" color="textPrimary">
                        Utilisez le simulateur ci dessous pour calculer votre
                        bonus.
                      </Typography>
                    </Box>
                    <Box my={5}>
                      <EcologyForm />
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
          </div>
          <div id="ecology">
            <Section style={{ background: "transparent" }}>
              <BackgroundImage bg={bg5} blur={10} opacity={0.5} />
              <Container className={classes.sectionTitle}>
                <Grid container>
                  <Grid xs={12} item style={{ zIndex: 2 }}>
                    <Box py={2}>
                      <Typography variant="h2" color="primary" align="left">
                        04
                      </Typography>
                      <Typography variant="h2" color="inherit" align="left">
                        Une technologie écologique et
                        <br />
                        respectueuse de votre cadre de vie
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
            <Section style={{ background: "white", padding: 0 }}>
              <Hidden smDown>
                <Box className={classes.imageLateralContainer}>
                  <LateralBackgroundImage bg={bg12} blur={0} />
                </Box>
              </Hidden>
              <Container>
                <Grid style={{ height: "100%" }} container spacing={5}>
                  <Grid
                    xs={12}
                    sm={6}
                    item
                    style={{
                      zIndex: 2,
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Box my={3} position={"relative"} color="main.primary">
                      <Typography
                        variant="h2"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        Zéro Émission de CO<sub>2</sub>
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.sectionInfo}
                      >
                        Un moteur non-polluant : vous n’émettez aucune émission
                        de CO<sub>2</sub> lorsque vous roulez avec votre Model
                        E.
                      </Typography>
                      <Typography
                        variant="h2"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        Silencieux
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        Moins de pollution sonore pour nos villes, c’est
                        important pour vous, pour les autres, pour notre qualité
                        de vie, tout simplement.
                      </Typography>
                      <Typography
                        variant="h2"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        Conscient de votre empreinte carbone ?
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        Vous pouvez suivre en temps réel votre impact sur
                        l’environnement sur l'application.
                      </Typography>
                      <Typography
                        variant="h2"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        Indice de durabilité élevé
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        Nous sommes de fervents opposants à l’obsolescence
                        programmée et le « jetable ».
                        <br />
                        Parce que la durabilité est un aspect essentiel d’une
                        bonne et saine gestion des ressources de notre planète.
                        2 500 cycles de vie pour les batteries des Model E -
                        contre 800 à 1000 cycles pour celles du marché.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
            <Section style={{ background: "white", padding: 0 }}>
              <Container>
                <Grid style={{ height: "100%" }} container spacing={5}>
                  <Grid
                    xs={12}
                    sm={8}
                    item
                    style={{
                      zIndex: 2,
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Co2Calculator />
                  </Grid>
                </Grid>
              </Container>
            </Section>
          </div>
          <Section
            style={{ background: theme.palette.background.paper, padding: 0 }}
          >
            <ConversionBlock />
          </Section>
          <NewsletterBlock />
        </Box>
      </div>
    </div>
  );
};

export const Section = withStyles((t: Theme) => ({
  root: {
    color: "white",
    background: t.palette.background.paper,
    position: "relative",
    overflow: "hidden",
  },
}))(Box);

export default ChooseElectricPage;
