import React, { useEffect, useRef, useState } from "react";
import { Button, Grid, Box, makeStyles } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import GlobalConfig from "@common/data/GlobalConfig";
import PasswordInput from "../passwordInput";
import AddressAutocomplete from "../addressAutocomplete";
import CloseIcon from "../closeIcon";
import MuiPhoneNumber from "material-ui-phone-number";

interface Fields {
  firstName?: string;
  lastName?: string;
  email?: string;
  address?: string;
  district?: string;
  city?: string;
  country?: string;
  phone?: string;
  customerFirstName?: string;
  customerLastName?: string;
  postcode?: string;
  cityName?: string;
  countryName?: string;
  telephone?: string;
}

interface IProps {
  isUpdatingProfile: boolean;
  isApiLoading: boolean;
  submitLabel: string;
  handleSubmit: (payload) => void;
  serverResponse?: JSX.Element;
  initialData?: Fields;
}

// prepare
const componentName = "RegistrationForm";
const debug = require("debug")(`front:${componentName}`);

const useStyles = makeStyles((theme) => ({
  clearIcon: {
    position: "absolute",
    top: "1.7rem",
    right: "1.5rem",
    fontSize: "2rem",
    opacity: 0.3,
    pointerEvents: "none",
  },
  // fixedAddressFieldWrapper: {
  //   position: "relative",
  //   marginTop: "1rem",
  //   "& input": {
  //     cursor: "pointer",
  //     paddingRight: "5rem",
  //   },
  // },
}));

/**
 * @name RegistrationForm
 */
export const RegistrationForm = (props: IProps) => {
  const classes = useStyles();
  const trans = GlobalConfig?.appData.blocks.registrationPage;

  // get root ref
  const formRef = useRef<ValidatorForm>(null);

  // --------------------------------------------------------------------------- STATES

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  // --------------------------------------------------------------------------- EFFECTS

  useEffect(() => {
    ValidatorForm.addValidationRule("isPasswordMatch", (v) => v === password);
    return () => ValidatorForm.removeValidationRule("isPasswordMatch");
  }, [password]);

  useEffect(() => {
    formRef.current?.isFormValid(true).then(setIsFormValid);
  });

  useEffect(() => {
    if (props.initialData?.customerFirstName) {
      setFirstName(props.initialData.customerFirstName);
      // ValidatorForm.firstName = props.initialData.firstName;
    }
    if (props.initialData?.customerLastName) {
      setLastName(props.initialData.customerLastName);
    }
    if (props.initialData?.email) {
      setEmail(props.initialData.email);
    }
    if (props.initialData?.address) {
      setAddress(props.initialData.address);
    }
    if (props.initialData?.postcode) {
      setDistrict(props.initialData.postcode);
    }
    if (props.initialData?.cityName) {
      setCity(props.initialData.cityName);
    }
    if (props.initialData?.countryName) {
      setCountry(props.initialData.countryName);
    }
    if (props.initialData?.telephone) {
      setPhone(props.initialData.telephone);
    }
  }, [props.initialData]);

  // --------------------------------------------------------------------------- HELPERS

  const handleFormSubmit = () => {
    const payload = {
      firstName,
      lastName,
      email,
      phone,
      password,
      address,
      district,
      city,
      country,
    };
    if (props.isUpdatingProfile) {
      delete payload.email;
      delete payload.password;
    }
    props.handleSubmit(payload);
  };

  const handleClearAddress = () => {
    setAddress("");
    setDistrict("");
    setCity("");
    setCountry("");
  };

  const handleSelectAddress = (payload) => {
    setAddress(payload.address);
    setDistrict(payload.zipcode);
    setCity(payload.city);
    setCountry(payload.country);
  };

  const handleOnPhoneChange = (value) => {
    setPhone(value);
  };

  // --------------------------------------------------------------------------- RENDER

  return (
    <ValidatorForm ref={formRef} onSubmit={handleFormSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextValidator
            variant="outlined"
            label={trans.form.lastNameLabel}
            value={lastName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setLastName(e.target.value);
            }}
            fullWidth
            validators={["required"]}
            errorMessages={[trans.form.required]}
            type="text"
            name="lastName"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextValidator
            variant="outlined"
            label={trans.form.firstNameLabel}
            value={firstName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFirstName(e.target.value);
            }}
            fullWidth
            validators={["required"]}
            errorMessages={[trans.form.required]}
            type="text"
            name="firstName"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt={2} mb={1}>
            <AddressAutocomplete
              placeholder={trans.form.addressLabel}
              handleAddressSelect={handleSelectAddress}
              handleAddressClear={handleClearAddress}
              value={address}
              key={address ?? "empty"}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextValidator
            variant="outlined"
            label={trans.form.districtLabel}
            value={district}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setDistrict(e.target.value);
            }}
            fullWidth
            validators={["required"]}
            errorMessages={[trans.form.required]}
            type="text"
            name="district"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextValidator
            variant="outlined"
            label={trans.form.cityLabel}
            value={city}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCity(e.target.value);
            }}
            fullWidth
            validators={["required"]}
            errorMessages={[trans.form.required]}
            type="text"
            name="city"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <TextValidator
            variant="outlined"
            label={trans.form.countryLabel}
            value={country}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCountry(e.target.value);
            }}
            fullWidth
            validators={["required"]}
            errorMessages={[trans.form.required]}
            type="text"
            name="country"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <TextValidator
            variant="outlined"
            label={trans.form.emailLabel}
            fullWidth
            type="email"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value);
            }}
            value={email}
            validators={["required", "isEmail"]}
            errorMessages={[trans.form.required, trans.form.emailInvalid]}
            autoComplete="email"
            name="email"
            margin="normal"
            disabled={props.isUpdatingProfile}
          />
        </Grid>
        <Grid item xs={12}>
          <MuiPhoneNumber
            variant="outlined"
            label={trans.form.phoneLabel}
            fullWidth
            defaultCountry={"fr"}
            regions={"europe"}
            onChange={handleOnPhoneChange}
            value={phone}
            // errorMessages={[trans.form.required, trans.form.phoneInvalid]}
            name="phone"
            margin="normal"
          />
        </Grid>
        {!props.isUpdatingProfile && (
          <>
            <Grid item xs={12} sm={6}>
              <Box mt={2} mb={1}>
                <PasswordInput
                  password={password}
                  onChange={setPassword}
                  onValidChange={setIsPasswordValid}
                  label={trans.form.passwordLabel}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator
                variant="outlined"
                label={trans.form.passwordConfirmLabel}
                value={passwordConfirm}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPasswordConfirm(e.target.value);
                }}
                fullWidth
                validators={["required", "isPasswordMatch"]}
                errorMessages={[
                  trans.form.required,
                  trans.form.passwordsNotMatch,
                ]}
                type="password"
                name="passwordConfirm"
                margin="normal"
                disabled={!isPasswordValid}
              />
            </Grid>
          </>
        )}
        {props.serverResponse && (
          <Grid item xs={12}>
            <Box mb={3}>{props.serverResponse}</Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box mt={3}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={props.isApiLoading || !isFormValid}
            >
              {props.isApiLoading ? trans.loading : props.submitLabel}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};
