interface InternalRoutesInterface {
  [key: string]: (args?: string) => string;
}

const InternalRoutes: InternalRoutesInterface = {
  about: () => "/about-us",
  account: () => "/account",
  changePassword: () => "/account/change-password",
  chooseElectric: () => "/choose-electric",
  faq: () => "/faq",
  finance: () => "/finance",
  forgotPassword: () => "/forgot-password",
  resetPassword: () => "/reset-password",
  home: () => "/",
  login: () => "/login",
  matching: () => "/matching",
  notFound: () => "/not-found",
  order: (id: string) => `/order/${id}`,
  registration: () => "/registration",
  technology: () => "/technology",
  work: () => "/work",
  insurance: () => "/insurance",
};

export default InternalRoutes;
