import { Card, CardContent, Typography } from "@material-ui/core";
import { merge } from "@common/lib/helpers/classNameHelper";
import phonePicto from "../../../asset/img/picto/picto-phone.png";
import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    store_card: {
      background: theme.palette.primary.dark,
      color: "white",
      border: `solid 0px ${theme.palette.primary.dark}`,
      cursor: "pointer",
      position: "relative",
      "&.active,&:hover": {
        border: `solid 2px ${theme.palette.primary.main}`,
      },
      "&.hidden": {
        display: "none",
      },
    },
    title: {
      fontSize: 14,
    },
    store_id: {
      border: `solid 2px ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      background: "white",
      borderRadius: "50%",
      fontWeight: 900,
      width: "2rem",
      height: "2rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    phone_picto: {
      display: "inline-block",
      verticalAlign: "middle",
    },
    store_distance: {
      position: "absolute",
      right: "1rem",
      top: "1rem",
    },
  })
);

export const StoreCard = ({ isSelected, isHidden, store, distance }: any) => {
  const classes = useStyles();
  return (
    <Card
      className={merge([classes.store_card, isSelected && "active"])}
      hidden={isHidden}
      variant={isSelected ? "elevation" : "outlined"}
    >
      <CardContent>
        <div className={classes.store_distance}>
          <Typography variant="body2">{distance}</Typography>
        </div>
        <div className={classes.store_id}>
          <Typography variant="body2">{store.id}</Typography>
        </div>
        <Typography variant="h6" className={classes.title} color="inherit">
          {store.name}
        </Typography>
        <Typography variant="body2">{store.address}</Typography>
        <Typography variant="body2">
          {store.postalCode} {store.city}
        </Typography>
        <Typography variant="body2">
          <img src={phonePicto} alt="" className={classes.phone_picto} />
          {store.phone}
        </Typography>
      </CardContent>
    </Card>
  );
};
