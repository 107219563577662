import css from "./NavBar.module.less";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-scroll";
import { useScroll } from "../../hooks/useScroll";
import { merge } from "@common/lib/helpers/classNameHelper";
import { useLocation } from "react-router-dom";

interface IProps {
  links?: { link: string; label: string }[];
}

const handleMenuActive = (to) => {
  let el = document.getElementById("navbar_" + to);
  let navbar = document.getElementById("subheader-navbar");
  navbar.scrollTo({
    left: el.offsetLeft + el.offsetWidth / 2 - navbar.offsetWidth / 2,
    behavior: "smooth",
  });
};

export const NavBar = (props: IProps) => {
  const scroll = useScroll();
  const lastScrollValue = useRef(0);
  const [headerIsShrinked, setHeaderIsShrinked] = useState(false);

  useEffect(() => {
    if (scroll < lastScrollValue.current || scroll < 100)
      setHeaderIsShrinked(false);
    else if (scroll > lastScrollValue.current && scroll >= 100)
      setHeaderIsShrinked(true);
    lastScrollValue.current = scroll;
  }, [scroll]);

  const { pathname } = useLocation();
  useEffect(() => {
    lastScrollValue.current = 9999; // fix allowing the header to keep the right height on page change
  }, [pathname]);
  return (
    <div
      id="subheader-navbar"
      className={merge([
        css.subHeader,
        headerIsShrinked ? css.subHeader_shrinked : "",
      ])}
    >
      {props.links.map((value: { link: string; label: string }, index) => {
        return (
          <Link
            id={"navbar_" + value.link}
            key={value.link}
            className={`${css.subHeader_Element}`}
            onSetActive={handleMenuActive}
            activeClass={css.subHeader_Element_active}
            duration={500}
            spy={true}
            smooth={true}
            offset={-90}
            to={value.link}
            children={value.label}
          />
        );
      })}
    </div>
  );
};
