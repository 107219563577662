import React, { useEffect, useRef, useState } from "react";
import { Button, Box, Grid, makeStyles, withStyles } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import GlobalConfig from "@common/data/GlobalConfig";
import { useStyles as useContactFormStyles } from "../contactForm/ContactForm";
import { useSelector } from "react-redux";
import { selectIsLoading } from "../../app/UISlice";

interface Fields {
  email?: string;
}

interface IProps {
  isApiLoading: boolean;
  submitLabel: string;
  handleSubmit: (payload: Fields) => void;
  serverResponse?: JSX.Element;
  darkTheme?: boolean;
  initialData?: Fields;
}

export function NewsletterForm(props: IProps) {
  const trans = GlobalConfig?.appData.blocks.contactForm;

  const classes = useContactFormStyles(props.darkTheme)();
  const formRef = useRef<ValidatorForm>(null);
  const [email, setEmail] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const UILoading = useSelector(selectIsLoading);

  useEffect(() => {
    formRef.current?.isFormValid(true).then(setIsFormValid);
  });

  useEffect(() => {
    if (props.initialData?.email) {
      setEmail(props.initialData.email);
    }
  }, [props.initialData]);

  const handleFormSubmit = () => {
    const payload: Fields = { email };
    props.handleSubmit(payload);
  };

  // when stops loading, reset the form
  useEffect(() => {
    if (UILoading.newsletterForm === false) {
      setEmail("");
    }
  }, [UILoading.newsletterForm]);

  return (
    <Box>
      <ValidatorForm ref={formRef} onSubmit={handleFormSubmit}>
        <Grid container>
          <Grid item xs={12} md={8}>
            <StyledTextValidator
              className={classes.input}
              variant="outlined"
              label={trans.form.emailLabel}
              fullWidth
              type="email"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(e.target.value);
              }}
              value={email}
              validators={["required", "isEmail"]}
              errorMessages={[trans.form.required, trans.form.emailInvalid]}
              autoComplete="email"
              name="email"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledSubmit
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={props.isApiLoading || !isFormValid}
              className={classes.button}
            >
              {props.isApiLoading ? trans.loading : props.submitLabel}
            </StyledSubmit>
          </Grid>
        </Grid>
        {props.serverResponse && (
          <Grid item xs={12}>
            {props.serverResponse}
          </Grid>
        )}
      </ValidatorForm>
    </Box>
  );
}

const StyledSubmit = withStyles((theme) => ({
  root: {
    color: "white",
    [theme.breakpoints.up("md")]: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      minHeight: 56,
      boxShadow: theme.shadows[0],
    },
  },
}))(Button);

const StyledTextValidator = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      marginBottom: 0,
      "& .MuiInputBase-root": {
        boxShadow: theme.shadows[0],
        minHeight: 56,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
  },
}))(TextValidator);
