export const userAgent = navigator.userAgent || navigator.vendor;

export const isAndroid = () => !!/android/i.test(userAgent);
export const isWindowsPhone = () => !!/windows phone/i.test(userAgent);

// iOS detection from: http://stackoverflow.com/a/9039885/177710
export const isIPhone = () =>
  !!(/iPhone|iPod/.test(userAgent) && !window.MSStream);
export const isIPad = () => !!(/iPad/.test(userAgent) && !window.MSStream);
export const isIOS = () => isIPhone() || isIPad();

export const isMobile = () => isIPhone() || isAndroid() || isWindowsPhone();
export const isHandHeld = () =>
  isIPad() || isIPhone() || isAndroid() || isWindowsPhone();

export const isIE = () =>
  !!window.document["documentMode"] ||
  navigator?.appVersion?.indexOf("Trident") > -1;

export const isOldEdge = () => navigator?.appVersion?.indexOf("Edge/") > -1;
export const isNewEdge = () => navigator?.appVersion?.indexOf("Edg/") > -1;
export const isEDGE = () => isOldEdge() || isNewEdge();

export const isFirefox = () => userAgent.toLowerCase().indexOf("firefox") > -1;
