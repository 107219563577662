import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Paper,
  Grid,
  Typography,
  Box,
  Button,
  Hidden,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import bg_1 from "asset/img/background_1.jpg";
import { Theme, useTheme, withStyles } from "@material-ui/core/styles";
import powerGraph from "asset/img/graph_power.png";
import pictoBatteryFull from "asset/img/picto/picto-battery-full.png";
import pictoBatteryArrow from "asset/img/picto/picto-battery-arrow.png";
import pictoBatteryEmpty from "asset/img/picto/picto-battery-empty.png";
import pictoDistance from "asset/img/picto/picto-distance.png";
import pictoHealth from "asset/img/picto/picto-health.png";
import pictoPromo from "asset/img/picto/picto-promo.png";
import pictoDownload from "asset/img/picto/picto-download.png";

import bg2 from "asset/img/background/bg2.png";
import bg3 from "asset/img/background/bg3.jpg";
import bg4 from "asset/img/background/bg4.jpg";
import bg5 from "asset/img/background/bg5.png";
import bg6 from "asset/img/background/bg6.jpg";
import bg7 from "asset/img/background/bg7.png";

import mobileAppLogo from "../../../asset/img/other/mobile_app_logo.png";
import mobileAppScreen from "../../../asset/img/other/mobile_app_screen.png";
import fnac_logo from "../../../asset/img/fnac.png";
import darty_logo from "../../../asset/img/darty.png";
import rede_center_logo from "../../../asset/img/rede_center.png";
import Header from "../../components/header";
import { LoadingService } from "@common/services/LoadingService";
import NewsletterBlock from "../../components/newsletterBlock";
import { NavBar } from "../../components/navBar/NavBar";
import ConversionBlock from "../../components/conversionBlock";
import compiledTheme from "../../theme";
import { newsletterSubscriptionAPI } from "../../utils/Api";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import pdfUrl from "asset/doc/REDELECTRIC_BROCHURE_BtoC.pdf";
import GlobalConfig from "@common/data/GlobalConfig";
import css from "../../components/technicalblock/Technicalblock.module.less";

interface IProps {}

const componentName = "TechnologyPage";
const debug = require("debug")(`front:${componentName}`);

/**
 * @name TechnologyPage
 */

const BackgroundImage = (props) => (
  <div
    style={{
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      zIndex: 1,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${props.bg})`,
      backgroundPosition: "50% 50%",
      filter: `blur(${props.blur}px)`,
    }}
  >
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        background: `rgba(0,0,0,${props.opacity})`,
      }}
    />
  </div>
);

const LateralBackgroundImage = (props, theme) => (
  <div
    style={{
      position: "absolute",
      left: "25%",
      top: 0,
      width: "75%",
      height: "100%",
      zIndex: 1,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${props.bg})`,
      backgroundPosition: "50% 50%",
      filter: `blur(${props.blur}px)`,
      [compiledTheme.breakpoints.down("sm")]: {
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      },
    }}
  >
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        background: `rgba(0,0,0,${props.opacity})`,
      }}
    />
  </div>
);

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "hidden",
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("xs")]: {
      paddingTop: theme.spacing(18),
    },
  },
  sectionTitle: {
    paddingTop: "4rem",
    paddingBottom: "2rem",
  },
  sectionInfo: {
    paddingTop: "1rem",
  },
  sectionInfoNumber: {
    marginTop: "1.5rem",
    paddingBottom: "1.5rem",
  },
  imageLateralContainer: {
    position: "absolute",
    left: "50%",
    top: 0,
    bottom: 0,
    right: 0,
    [theme.breakpoints.down("xs")]: {
      left: 0,
    },
  },
  modal: {
    color: "black",
    textAlign: "center",
  },
}));

const TechnologyPage = (props: IProps) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState(false);
  const theme = useTheme();
  const classes = useStyles();
  const modalRef = useRef([]);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [alert, setAlert] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [canDownload, setCanDownload] = useState(false);
  const blockData = GlobalConfig?.appData?.blocks?.technicalBlock;

  const handleChange = (event) => {
    const email = event.target.value;
    setEmail(email);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      email: email,
    };

    if (!email) {
      setAlert(
        "veuillez remplir l'information et cochez la case d'inscription."
      );
      return;
    }
    setIsLoading(true);

    const resp = newsletterSubscriptionAPI(formData);
    resp.then((res: any) => {
      /*if (res?.data?.success) {
        setIsLoading(false);
        setCanDownload(true);
        setEmail("");
      } else {
        setIsLoading(false);
        setAlert("problème lors de l'inscription");
      }*/
      setIsLoading(false);
      setCanDownload(true);
      setEmail("");
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderSuccessAlert = () =>
    alert && (
      <Box m={1}>
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="error"
          children={alert}
        />
      </Box>
    );

    const renderModal = () => (
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={open || isLoading}
        onClose={handleClose}
      >
        <ValidatorForm
          onSubmit={handleSubmit}
          onError={() => {
            setAlert("veuillez remplir votre adresse mail.");
          }}
        >
          {!canDownload ? (
            <DialogContent>
              <DialogContentText
                classes={{
                  root: classes.modal,
                }}
              >
                Pour obtenir votre brochure, merci de renseigner votre adresse
                mail
              </DialogContentText>
              {renderSuccessAlert()}

              <TextValidator
                label="Email"
                onChange={handleChange}
                name="email"
                value={email}
                variant={"outlined"}
                fullWidth={true}
                maxWidth="lg"
                required
                disabled={isLoading}
                validators={["required", "isEmail"]}
                errorMessages={[
                  "ce champ est requis",
                  "votre email n'es pas valide",
                ]}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      setIsSubscribed(!isSubscribed);
                    }}
                    disabled={isLoading}
                    checked={isSubscribed}
                    name="is_subscribed"
                  />
                }
                label="S’inscrire à la newsletter RED Electric"
              />
            </DialogContent>
          ) : (
            <DialogContent>
              <DialogContentText
                classes={{
                  root: classes.modal,
                }}
              >
                <Typography>
                  Merci de votre confiance. Cliquez ici pour télécharger votre
                  brochure.
                </Typography>
                <Box m={2}>
                  <Button
                    href={pdfUrl}
                    variant="contained"
                    color="primary"
                    download
                  >
                    <span>{blockData.getBrochure}</span>
                  </Button>
                </Box>
              </DialogContentText>
            </DialogContent>
          )}
          <DialogActions>
            <Button disabled={isLoading} onClick={handleClose} color="primary">
              <span>Quitter</span>
            </Button>
            {!canDownload && (
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={css.cta_btn}
                disabled={isLoading}
              >
                {isLoading && (
                  <CircularProgress className={css.spinner} size={16} />
                )}
                <span>valider</span>
              </Button>
            )}
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );

  const subNavLinks = [
    {
      label: "Performance et puissance",
      link: "performance",
    },
    {
      label: "Record d'autonomie",
      link: "autonomy",
    },
    {
      label: "Confort d'usage",
      link: "comfort",
    },
  ];

  useEffect(() => {
    LoadingService?.instance?.on("loadingDone", () => setLoaded(true));
  }, []);

  return (
    <div ref={rootRef}>
      <NavBar links={subNavLinks} />
      <div style={{ paddingTop: "8rem" }}>
        <Box mt={2}>
          <Section py={2} style={{ background: "transparent" }}>
            <BackgroundImage bg={bg4} blur={25} opacity={0.5} />
            <Container>
              <Grid
                container
                alignItems="center"
                justify="center"
                style={{
                  minHeight: "50vh",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Grid
                  md={6}
                  xs={12}
                  item
                  style={{ zIndex: 2, position: "relative" }}
                >
                  <Typography variant="h1" align="center">
                    Des machines plongées dans un bain de technologie.
                  </Typography>
                  <Typography variant="body1" align="center">
                    Plus que des scooters, RED Electric construit des machines
                    100% connectées et plongées dans un bain de technologie. Une
                    technologie qui réinvente les performances de l’électrique
                    et libère votre plaisir de rouler - sans compromis. Avec les
                    Model E, passer à l’électrique n’est plus la version
                    dégradée d’un véhicule thermique, mais un gain de
                    performances et de plaisir de conduite !
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Section>
          <div id="performance">
            <Section style={{ background: "transparent" }}>
              <BackgroundImage bg={bg5} blur={10} opacity={0.5} />
              <Container className={classes.sectionTitle}>
                <Grid container>
                  <Grid xs={12} item style={{ zIndex: 2 }}>
                    <Box py={2}>
                      <Typography variant="h2" color="primary" align="left">
                        01
                      </Typography>
                      <Typography variant="h2" color="inherit" align="left">
                        Performance et Puissance <br />
                        ont guidé la conception des Model E
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
            <Section style={{ background: "white", padding: 0 }}>
              <Box className={classes.imageLateralContainer}>
                <LateralBackgroundImage bg={bg7} blur={0} />
              </Box>
              <Container>
                <Grid style={{ height: "100%" }} container spacing={5}>
                  <Grid
                    xs={12}
                    sm={6}
                    item
                    style={{
                      zIndex: 2,
                      background: "white",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Box my={3} position={"relative"} color="main.primary">
                      <Typography variant="h2" color="textPrimary" align="left">
                        Une puissance sans compromis
                      </Typography>
                      <Typography
                        variant="h4"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        RED HCPM® - Custom Central Motor
                        <br />
                        RED CAPA® - Climbing Automatic Power Adjustment
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.sectionInfo}
                      >
                        Parce que l’on n’est jamais mieux servi que par soi-même
                        et que le moteur est la pièce maîtresse de votre
                        scooter, nous avons beaucoup travaillé et développé
                        notre propre moteur. Un composant d'excellence, sans
                        concession ni compromis au couple, à la puissance, à la
                        durabilité.
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.sectionInfo}
                      >
                        Nous avons opté pour un moteur central et non pour un
                        moteur placé dans la roue que l'on trouve sur la plupart
                        des scooters électriques du marché, limitant
                        drastiquement leurs performances.
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.sectionInfo}
                      >
                        Nous l'avons développé et construit en pensant à vous :
                        aux côtes que vous allez gravir, aux sorties de
                        parkings, à la vitesse que vous souhaiterez prendre sur
                        les routes (dans les limites autorisées, bien sûr…).
                        <br />
                        Nos systèmes détectent le pourcentage de dénivelée et
                        s'adaptent immédiatement à votre parcours afin que la
                        puissance et le couple nécessaires soient immédiatement
                        disponibles. Ainsi, vous ne manquerez jamais
                        d'accélération, ni de reprises.
                      </Typography>
                      <Box my={1}>
                        <TableContainer>
                          <Table
                            aria-label="simple table"
                            style={{
                              background: "white",
                            }}
                          >
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  align="right"
                                  style={{
                                    textTransform: "uppercase",
                                    width: "50%",
                                    borderBottom: "none",
                                  }}
                                >
                                  Puissance Maximale
                                  <br />
                                  du Model E 125
                                </TableCell>
                                <TableCell
                                  style={{
                                    borderBottom: "none",
                                  }}
                                >
                                  <TableContainer>
                                    <Table>
                                      <TableBody>
                                        <TableRow>
                                          <TableCell
                                            align="right"
                                            style={{
                                              background:
                                                theme.palette.primary.main,
                                              height: "2rem",
                                              borderBottom: "none",
                                              paddingTop: "0.5rem",
                                              paddingBottom: "0.5rem",
                                              fontSize: "1.5rem",
                                            }}
                                          >
                                            11 000 W
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                style={{
                                  textTransform: "uppercase",
                                  width: "50%",
                                }}
                              >
                                <TableCell
                                  align="right"
                                  style={{
                                    textTransform: "uppercase",
                                    width: "50%",
                                    borderBottom: "none",
                                  }}
                                >
                                  Puissance Moyenne
                                  <br />
                                  des scooters électriques 125CM<sup>3</sup>
                                </TableCell>
                                <TableCell
                                  style={{
                                    borderBottom: "none",
                                  }}
                                >
                                  <TableContainer>
                                    <Table>
                                      <TableBody>
                                        <TableRow>
                                          <TableCell
                                            align="right"
                                            style={{
                                              background:
                                                theme.palette.primary.main,
                                              height: "2rem",
                                              width: "57%",
                                              borderBottom: "none",
                                              paddingTop: "0.5rem",
                                              paddingBottom: "0.5rem",
                                              fontSize: "1.5rem",
                                            }}
                                          >
                                            6 300 W
                                          </TableCell>
                                          <TableCell
                                            align="right"
                                            style={{
                                              borderBottom: "none",
                                            }}
                                          ></TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                      <Typography
                        variant="h2"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        Des matériaux ultra résistants
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        Les Model E utilisent des alliages spéciaux, très légers
                        et résistants - en traction comme en torsion - et de
                        polymères souples mais extrêmement durables, à l’usage,
                        aux intempéries, au temps.
                        <br />
                        Nos technologies sont à votre service - Nos machines
                        sont construites pour durer.
                        <br />
                        Vos batteries sont utilisées en symbiose, ce qui allonge
                        leur durée de vie [2500 cycles contre 800 à 1000 pour
                        les batteries du marché] et votre sécurité. Un exemple :
                        Une batterie déchargée ou en surchauffe ? Les autres
                        prennent le relais en toute transparence, sans même que
                        vous ne vous en aperceviez !
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
            <Section
              style={{
                background: theme.palette.background.default,
                padding: 0,
              }}
            >
              <Container>
                <Box py={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}>
                      <Box p={1} textAlign={"center"}>
                        <Typography align="center" gutterBottom variant="h2">
                          11 000 W
                        </Typography>
                        <Typography align="center" variant="caption">
                          puissance maximale
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Box p={1} textAlign={"center"}>
                        <Typography align="center" gutterBottom variant="h2">
                          93%
                        </Typography>
                        <Typography align="center" variant="caption">
                          efficacité moyenne
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Box p={1} textAlign={"center"}>
                        <Typography align="center" gutterBottom variant="h2">
                          120 km/h
                        </Typography>
                        <Typography align="center" variant="caption">
                          vitesse maximale limitée électroniquement
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Box p={1} textAlign={"center"}>
                        <Typography align="center" gutterBottom variant="h2">
                          560 NM
                        </Typography>
                        <Typography align="center" variant="caption">
                          couple maximal
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Box p={1} textAlign={"center"}>
                        <Typography align="center" gutterBottom variant="h2">
                          3.4 sec
                        </Typography>
                        <Typography align="center" variant="caption">
                          Accélération de 0 à 50 km/h
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Box p={1} textAlign={"center"}>
                        <Typography align="center" gutterBottom variant="h2">
                          350kg
                        </Typography>
                        <Typography align="center" variant="caption">
                          masse maximale en charge
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
            </Section>
          </div>
          <div id="autonomy">
            <Section style={{ background: "transparent" }}>
              <BackgroundImage bg={bg5} blur={10} opacity={0.5} />
              <Container className={classes.sectionTitle}>
                <Grid container>
                  <Grid xs={12} item style={{ zIndex: 2 }}>
                    <Box py={2}>
                      <Typography variant="h2" color="primary" align="left">
                        02
                      </Typography>
                      <Typography variant="h2" color="inherit" align="left">
                        Une autonomie record pour plus de liberté
                        <br />
                        de déplacement et moins de temps de recharge
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
            <Section style={{ background: "white", padding: 0 }}>
              <Hidden smDown>
                <Box className={classes.imageLateralContainer}>
                  <LateralBackgroundImage bg={bg2} blur={0} />
                </Box>
              </Hidden>
              <Container>
                <Grid style={{ height: "100%" }} container spacing={5}>
                  <Grid
                    xs={12}
                    sm={6}
                    item
                    style={{
                      zIndex: 2,
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Box my={3} position={"relative"} color="main.primary">
                      <Typography
                        variant="h2"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        Plus d'énergie dans moins de volume et de poids
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        21700
                        <br />
                        RED SCM® - Smart Cells Management
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.sectionInfo}
                      >
                        Nos batteries utilisent les cellules Lithium-Ion 21700
                        de dernière génération, les mêmes que sur la TESLA Model
                        3.
                        <br />
                        Ces cellules disposent d’une densité énergétique plus
                        élevée et permettent d’emporter beaucoup plus d’énergie
                        dans moins de volume. Elles sont aussi bien plus
                        durables.
                      </Typography>
                      <Typography
                        variant="h2"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        Profitez de 300 Km d’autonomie
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        RED BBI® - Smart Multi-Battery Management System
                        <br />
                        RED TCM® - Temperature Control Management
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.sectionInfo}
                      >
                        L'algorithme IA du BBI permet une gestion intelligente
                        de l'énergie embarquée.
                        <br />
                        Que vous disposiez de deux, trois ou quatre batteries,
                        cette Intelligence Artificielle monitore en temps réel
                        la puissance du moteur et répartit la demande d’énergie
                        entre plusieurs batteries [2 à 4]. Vous disposez ainsi
                        toujours d’une puissance maîtrisée et du couple maximal
                        disponible.
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.sectionInfo}
                      >
                        Vos batteries sont utilisées en symbiose, ce qui allonge
                        leur durée de vie (2500 cycles contre 800 à 1000 pour
                        les batteries du marché) et votre sécurité. Un exemple ?
                        Une batterie déchargée ou en surchauffe ? Les autres
                        prennent le relais en toute transparence, sans même que
                        vous ne vous en aperceviez !
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
            <Section
              style={{ background: theme.palette.background.paper, padding: 0 }}
            >
              <Container>
                <Box py={2}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Typography color="textPrimary" gutterBottom variant="h2">
                        Vos Avantages :
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className={classes.sectionInfo}>
                    <Grid item xs={12} sm={4}>
                      <Box p={1}>
                        <img
                          style={{ height: "6rem" }}
                          src={pictoBatteryFull}
                          alt=""
                        />
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h3"
                          className={classes.sectionInfo}
                        >
                          Une autonomie supérieure
                        </Typography>
                        <Typography
                          color="textPrimary"
                          variant="body1"
                          className={classes.sectionInfo}
                        >
                          300 km pour un Model E50 avec 4 batteries donc des
                          recharges bien moins fréquentes et une liberté de
                          déplacement totale.
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box p={1}>
                        <img
                          style={{ height: "6rem" }}
                          src={pictoBatteryArrow}
                          alt=""
                        />
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h3"
                          className={classes.sectionInfo}
                        >
                          Des batteries extractibles
                        </Typography>
                        <Typography
                          color="textPrimary"
                          variant="body1"
                          className={classes.sectionInfo}
                        >
                          Pour les charger directement chez vous sur une simple
                          prise de courant normalisée (Plusieurs modèles de
                          chargeurs rapides & ultra-rapides disponibles).
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box p={1}>
                        <img
                          style={{ height: "6rem" }}
                          src={pictoBatteryEmpty}
                          alt=""
                        />
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h3"
                          className={classes.sectionInfo}
                        >
                          2500 cycles de vie
                        </Typography>
                        <Typography
                          color="textPrimary"
                          variant="body1"
                          className={classes.sectionInfo}
                        >
                          Une durée de vie des batteries inégalée, pour une
                          utilisation en toute sécurité.
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
            </Section>
            <Section
              style={{
                background: theme.palette.background.default,
                padding: 0,
              }}
            >
              <Container>
                <Box py={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}>
                      <Box p={1} textAlign={"center"}>
                        <Typography align="center" gutterBottom variant="h2">
                          Cellules 21700
                        </Typography>
                        <Typography align="center" variant="caption">
                          Cellules
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Box p={1} textAlign={"center"}>
                        <Typography align="center" gutterBottom variant="h2">
                          2419 Wh/Batterie
                        </Typography>
                        <Typography align="center" variant="caption">
                          Puissance batterie
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Box p={1} textAlign={"center"}>
                        <Typography align="center" gutterBottom variant="h2">
                          72 Volts
                        </Typography>
                        <Typography align="center" variant="caption">
                          Tension
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Box p={1} textAlign={"center"}>
                        <Typography align="center" gutterBottom variant="h2">
                          2500
                        </Typography>
                        <Typography align="center" variant="caption">
                          Cycles de battrie
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Box p={1} textAlign={"center"}>
                        <Typography align="center" gutterBottom variant="h2">
                          Grade automotive
                        </Typography>
                        <Typography align="center" variant="caption">
                        Résistance ultra-Élevé
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Box p={1} textAlign={"center"}>
                        <Typography align="center" gutterBottom variant="h2">
                          300 W/kg
                        </Typography>
                        <Typography align="center" variant="caption">
                          Densité énergétique
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Box p={1} textAlign={"center"}>
                        <Typography align="center" gutterBottom variant="h2">
                          Certifiée IP65
                        </Typography>
                        <Typography align="center" variant="caption">
                          Étanchéité
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
            </Section>
          </div>
          <div id="comfort">
            <Section style={{ background: "transparent" }}>
              <BackgroundImage bg={bg5} blur={10} opacity={0.5} />
              <Container className={classes.sectionTitle}>
                <Grid container>
                  <Grid xs={12} item style={{ zIndex: 2, paddingTop: "6rem" }}>
                    <Box py={2}>
                      <Typography variant="h2" color="primary" align="left">
                        03
                      </Typography>
                      <Typography variant="h2" color="inherit" align="left">
                        La connectivité globale au service du plaisir de
                        conduite
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
            <Section>
              <Hidden smDown>
                <Box className={classes.imageLateralContainer}>
                  <LateralBackgroundImage bg={bg6} blur={0} />
                </Box>
              </Hidden>
              <Container className={classes.sectionInfoNumber}>
                <Grid style={{ height: "100%" }} container spacing={5}>
                  <Grid xs={12} sm={6} item>
                    <Box my={5} position={"relative"} color="main.primary">
                      <Typography
                        variant="h4"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        RED SDB® - Smart Dashboard
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.sectionInfo}
                      >
                        À la place du classique tableau de bord à cadrans, les
                        Model E sont équipés d’un écran géant blindé
                        rétroéclairé de 7 pouces, étanche (Certifié IP65). Le
                        Smart Dashboard® vous permet d’afficher toutes les
                        données dont vous avez besoin et le navigateur GPS
                        intégré [Contrôlé via l’App RED Electric] vous guide au
                        plus court - ou au plus rapide - vers votre destination.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
            <Section py={3}>
              <Container className={classes.sectionInfoNumber}>
                <Grid
                  style={{ height: "100%" }}
                  container
                  alignItems={"center"}
                >
                  <Grid sm={4} xs={12}>
                    <img
                      style={{ width: "50%", margin: "auto" }}
                      src={mobileAppLogo}
                      alt=""
                    />
                  </Grid>
                  <Grid xs={12} sm={8} item>
                    <Box my={2} position={"relative"} color="main.primary">
                      <Typography
                        variant="h2"
                        color="textPrimary"
                        align="left"
                        className={classes.sectionInfo}
                      >
                        L’App pour Smartphones RED Electric : Votre interface de
                        commande à distance
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.sectionInfo}
                      >
                        L’App RED Electric est connectée à votre Model E et à
                        l’ensemble de ses systèmes, en temps réel. Elle vous
                        permet de contrôler votre scooter et d’agir à distance.
                        <br />
                        Vous pouvez également consulter l’historique de vos
                        précédents trajets et les économies réalisées.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>
            <Section
              py={3}
              style={{ background: theme.palette.background.paper }}
            >
              <Container className={classes.sectionInfoNumber}>
                <Grid
                  style={{
                    height: "100%",
                    [compiledTheme.breakpoints.down("sm")]: {
                      flexDirection: "column-reverse",
                    },
                  }}
                  container
                  alignItems={"center"}
                >
                  <Grid
                    xs={12}
                    sm={6}
                    item
                    style={{
                      zIndex: 2,
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Box my={2} position={"relative"} color="main.primary">
                      <Typography variant="h2" color="textPrimary" align="left">
                        RED S&R® - Sit & Ride Smart Keyless System
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.sectionInfo}
                      >
                        Ne cherchez plus vos clefs dans votre poche ou votre
                        sac.
                        <br />
                        Votre scooter RED Electric détecte automatiquement votre
                        présence et se déverrouille, prêt à partir.
                        <br />
                        Éloignez-vous, il s’éteint tout seul et l’ensemble des
                        systèmes dynamiques moteur – batterie – roues sont
                        bloqués, jusqu’à votre retour. Votre scooter RED
                        Electric détecte automatiquement votre présence et se
                        déverrouille, prêt à partir.
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.sectionInfo}
                      >
                        Un monitoring permanent de votre scooter pour une
                        gestion prédictive des problèmes et la possibilité
                        d'intervenir à distance : Tous les systèmes de votre
                        scooter sont connectés à un ordinateur avec une
                        sauvegarde en temps réel dans le Cloud. Les données
                        brutes de maintenance prédictive permettent de vous
                        informer, par exemple, qu'une révision s'impose.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid sm={6} xs={12} justify="center">
                    <img
                      style={{ width: "50%", margin: "auto" }}
                      src={mobileAppScreen}
                      alt=""
                    />
                  </Grid>
                </Grid>
              </Container>
            </Section>
            <Section
              style={{ background: theme.palette.background.paper, padding: 0 }}
            >
              <Container className={classes.sectionInfoNumber}>
                <Box py={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Box p={1}>
                        <img
                          style={{ height: "6rem" }}
                          src={pictoDistance}
                          alt=""
                        />
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h3"
                          className={classes.sectionInfo}
                        >
                          Intervention à distance de nos techniciens en cas de
                          problème
                        </Typography>
                        <Typography
                          color="textPrimary"
                          variant="body1"
                          className={classes.sectionInfo}
                        >
                          En cas de problème, nos techniciens peuvent intervenir
                          à distance pour détecter l’élément défectueux, le
                          réparer en ligne si c’est possible ou mettre en œuvre
                          un SAV ultra rapide et précis, car l’origine de la
                          panne est connue avant l’intervention.
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box p={1}>
                        <img
                          style={{ height: "6rem" }}
                          src={pictoHealth}
                          alt=""
                        />
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h3"
                          className={classes.sectionInfo}
                        >
                          Scannez l'état de santé de votre scooter à tout moment
                        </Typography>
                        <Typography
                          color="textPrimary"
                          variant="body1"
                          className={classes.sectionInfo}
                        >
                          Tous les systèmes de votre scooter sont connectés à un
                          ordinateur dont les données sont sauvegardées en temps
                          réel dans le Cloud. Ces données brutes nous permettent
                          de vous informer qu’un entretien est nécessaire
                          (maintenance prédictive et optimisation de la
                          durabilité des systèmes).
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box p={1}>
                        <img
                          style={{ height: "6rem" }}
                          src={pictoPromo}
                          alt=""
                        />
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h3"
                          className={classes.sectionInfo}
                        >
                          Coût d'entretien réduit
                        </Typography>
                        <Typography
                          color="textPrimary"
                          variant="body1"
                          className={classes.sectionInfo}
                        >
                          L’entretien de votre scooter RED Electric se limite à
                          une révision tous les 5000 km seulement, ou tous les
                          ans (première visite de contrôle à 1000 km).
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
            </Section>
            <Section
              style={{ background: theme.palette.background.paper, padding: 0 }}
            >
              <Container>
                <Box py={2}>
                  <Grid container spacing={2} alignItems="stretch">
                    <Grid item xs={12} sm={3}>
                      <Box p={1}>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h2"
                        >
                          Un scooter résistant au vol
                        </Typography>
                        <Typography color="textPrimary" variant="h4">
                          RED CAENAⓇ <br />
                          Fall Detection in Any Case <br />
                          RED ATD® <br />
                          Anti-Theft Detection <br />
                          RED EHⓇ <br />
                          Emergency Help System
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Paper style={{ height: "100%", background: "white" }}>
                        <Box p={3}>
                          <Typography color="primary" gutterBottom variant="h4">
                            Chute, accident ?
                          </Typography>
                          <Typography color="textPrimary" variant="h5">
                            Vous êtes alerté en temps réel
                          </Typography>
                          <Typography color="textPrimary" variant="body1">
                            En cas de chute lors d’un trajet, l’ordinateur de
                            bord prévient immédiatement un proche que vous avez
                            préalablement identifié.
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Paper style={{ height: "100%", background: "white" }}>
                        <Box p={3}>
                          <Typography color="primary" gutterBottom variant="h4">
                            Tentative de vol ?
                          </Typography>
                          <Typography color="textPrimary" variant="h5">
                            Votre scooter est entièrement bloqué
                          </Typography>
                          <Typography color="textPrimary" variant="body1">
                            Le détecteur de vol verrouille immédiatement
                            l’ensemble des systèmes dynamiques et bloque les
                            roues pour que votre Model E soit inutilisable. Vous
                            seul pouvez le débloquer et le retrouver grâce à la
                            géolocalisation GPS.
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Paper style={{ height: "100%", background: "white" }}>
                        <Box p={3}>
                          <Typography color="primary" gutterBottom variant="h4">
                            Scooter heurté ou déplacé ?
                          </Typography>
                          <Typography color="textPrimary" variant="h5">
                            Transmettez sa localisation aux forces de sécurité
                          </Typography>
                          <Typography color="textPrimary" variant="body1">
                            Vous êtes automatiquement notifié - directement sur
                            votre Smartphone - de toute chute ou acte de
                            malveillance survenu en votre absence.
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
            </Section>
          </div>
          <Section
            style={{ background: theme.palette.background.paper, padding: 0 }}
          >
            <Container
              style={{
                paddingBottom: "5rem",
              }}
            >
              <Box py={2}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <Typography
                      color="textPrimary"
                      variant="h2"
                      className={classes.sectionInfoNumber}
                    >
                      Fiche Technique
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    spacing={4}
                    alignItems="center"
                    style={{
                      background: "white",
                    }}
                  >
                    <Grid item xs={12} sm={8}>
                      <Typography
                        color="textPrimary"
                        gutterBottom
                        align={"center"}
                        variant="body1"
                      >
                        Toutes les caractéristiques et toutes les données des
                        Model E
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box textAlign="center">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleClickOpen}
                        >
                          <img
                            style={{ height: "1.6rem", margin: "0 1rem" }}
                            src={pictoDownload}
                            alt=""
                          />
                          <span>Obtenir la brochure</span>
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Section>
          <Section
            style={{ background: theme.palette.background.paper, padding: 0 }}
          >
            <ConversionBlock />
          </Section>
          <NewsletterBlock />
        </Box>
      </div>
      {renderModal()}
    </div>

  );
};

export const Section = withStyles((t: Theme) => ({
  root: {
    color: "white",
    background: t.palette.background.paper,
    position: "relative",
    overflow: "hidden",
  },
}))(Box);

export default TechnologyPage;
