import css from "./ConversionBlock.module.less";
import React, { useState } from "react";
import { merge } from "@common/lib/helpers/classNameHelper";
import GlobalConfig from "@common/data/GlobalConfig";
import darty_logo from "asset/img/darty.png";
import fnac_logo from "asset/img/fnac.png";
import rede_center_logo from "asset/img/rede_center.png";
import { Box, Button, Dialog } from "@material-ui/core";
import StoresMapBlock from "../storesMapBlock";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";

let trans = GlobalConfig.appData.blocks.ecologyBlock;

interface IProps {
  classNames?: string[];
}

const componentName = "ConversionBlock";
const debug = require("debug")(`front:${componentName}`);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    close_icon: {
      position: "absolute",
      fontSize: "4.2rem",
      top: 0,
      right: 0,
      width: "5rem",
      height: "5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      fontWeight: 900,
      color: theme.palette.primary.main,
      textShadow: `0px 0px 2px ${theme.palette.background.default}`,
    },
  })
);

/**
 * @name ConversionBlock
 */
function ConversionBlock(props: IProps) {
  /**
     open the link in a new window
     **/
  function openLinkInNewWindow(sLink) {
    window.open(sLink, "_blank");
  }
  const [showStoresModal, setShowStoresModal] = useState(false);

  const classes = useStyles();

  return (
    <div className={css.container}>
      <div
        className={merge([
          css.wrapper_content,
          css.wrapper_cta,
          css.wrapper_cta_one,
        ])}
      >
        <div>
          <p
            className={merge([css.subtitle])}
            dangerouslySetInnerHTML={{ __html: trans.cta_1.title }}
          />
          <Box my={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowStoresModal(true)}
            >
              {trans.cta_1.findShop}
            </Button>
          </Box>
          <div className={css.wrapper_cta_logos}>
            <img src={fnac_logo} alt="logo fnac" />
            <img src={darty_logo} alt="logo darty" />
            <img src={rede_center_logo} alt="logo rede" />
          </div>
        </div>
      </div>
      <div
        className={merge([
          css.wrapper_content,
          css.wrapper_cta,
          css.wrapper_cta_two,
        ])}
      >
        <div>
          <p className={merge([css.subtitle])}>{trans.cta_2.title}</p>
          <Box my={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                openLinkInNewWindow(trans.cta_2.link);
              }}
            >
              {trans.cta_2.bookTrial}
            </Button>
          </Box>
        </div>
      </div>
      <StyledDialog
        fullWidth={true}
        maxWidth={"lg"}
        open={showStoresModal}
        onClose={() => setShowStoresModal(false)}
      >
        <StoresMapBlock />
        <div
          className={classes.close_icon}
          onClick={() => setShowStoresModal(false)}
        >
          x
        </div>
      </StyledDialog>
    </div>
  );
}

export const StyledDialog = withStyles((t: Theme) => ({
  root: {
    padding: 0,
    height: "100%",
  },
  paper: {
    height: "calc(100% - 64px)",
    backgroundColor: t.palette.background.default,
  },
}))(Dialog);

export default ConversionBlock;
