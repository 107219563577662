import React from "react";
import { Box } from "@material-ui/core";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import { useTheme } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import FormHelperText from "@material-ui/core/FormHelperText";
import GlobalConfig from "@common/data/GlobalConfig";

export interface AddressAutocompleteProps {
  handleAddressSelect?: (e: any) => void;
  handleAddressClear?: () => void;
  placeholder?: string;
  value?: string;
  maxInputWidth?: number | string;
  placeholderStyles?: { [index: string]: any };
  errorText?: string;
}

const customSelectStyles = (
  theme: Theme,
  { placeholderStyles = {}, errorStyles = {} }
) => ({
  dropdownIndicator: (provided: Record<string, unknown>) => ({
    ...provided,
    display: "none",
  }),
  control: (
    provided: Record<string, unknown>,
    state: Record<string, unknown>
  ) => ({
    ...provided,
    cursor: "text",
    padding: "1.1rem 1.4rem",
    "&:hover": {
      borderColor: state.isFocused
        ? theme.palette.primary.light
        : theme.palette.common.black,
    },
    borderColor: state.isFocused
      ? theme.palette.primary.light
      : "rgba(0, 0, 0, 0.23)",
    boxShadow: state.isFocused
      ? `0 0 1px 1px ${theme.palette.primary.light}`
      : "none",
    ...theme.typography.body1,
    marginBottom: 0,
    ...errorStyles,
  }),
  indicatorsContainer: (provided: Record<string, unknown>) => ({
    ...provided,
    display: "none",
  }),
  valueContainer: (provided: Record<string, unknown>) => ({
    ...provided,
    padding: 0,
  }),
  placeholder: (provided: Record<string, unknown>) => ({
    ...provided,
    opacity: 0.6,
    margin: 0,
    ...placeholderStyles,
  }),
  menu: (provided: Record<string, unknown>) => ({
    ...provided,
    zIndex: 4,
    fontSize: "1.6rem",
  }),
});

export const AddressAutocomplete = ({
  handleAddressSelect,
  handleAddressClear,
  placeholder,
  placeholderStyles,
  value,
  maxInputWidth,
  errorText,
}: AddressAutocompleteProps) => {
  const theme = useTheme();
  const trans = GlobalConfig?.appData.blocks.registrationPage;

  const formatAndSelectAddress = (address: any) => {
    geocodeByPlaceId(address.value.place_id).then((results) => {
      const result = results[0];
      const zipcode = result.address_components.find(
        (el) => el.types.indexOf("postal_code") > -1
      );
      const country = result.address_components.find(
        (el) => el.types.indexOf("country") > -1
      );
      const city = result.address_components.find(
        (el) => el.types.indexOf("locality") > -1
      );
      getLatLng(result).then((res) => {
        if (handleAddressSelect)
          handleAddressSelect({
            placeId: result.place_id,
            label: result.formatted_address,
            address: result.formatted_address,
            latitude: res.lat,
            longitude: res.lng,
            zipcode: zipcode?.long_name,
            country: country?.long_name,
            countryId: 0,
            city: city?.long_name,
          });
      });
    });
  };

  const resetAddress = () => {
    // if (handleAddressClear) handleAddressClear();
  };

  return (
    <>
      <Box maxWidth={maxInputWidth}>
        <GooglePlacesAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          autocompletionRequest={{
            types: ["address"],
          }}
          selectProps={{
            defaultInputValue: value,
            placeholder: placeholder,
            noOptionsMessage: () => trans.form.addressAutocompleteNoResults,
            loadingMessage: () => trans.form.addressAutocompleteLoadingResults,
            styles: customSelectStyles(theme, {
              placeholderStyles,
              errorStyles: errorText
                ? { "&, &:hover": { borderColor: theme.palette.error } }
                : {},
            }),
            onChange: formatAndSelectAddress,
            onFocus: resetAddress,
          }}
        />
        {errorText && (
          <FormHelperText error variant="outlined">
            {errorText}
          </FormHelperText>
        )}
      </Box>
    </>
  );
};
