import TagManager from "react-gtm-module";

const tagManagerArgsOrder = {
    dataLayer: {
        event: 'interaction',
        category: 'click',
        label: 'enterFunnel'
    },
}

export const onClickOrderButton = () => {
  TagManager.dataLayer(tagManagerArgsOrder);
}
