import { LocaleStruct } from "./LocaleStruct";
import LanguageService from "@common/lib/services/LanguageService";
import { fr } from "@common/data/locales/fr";

const debug = require("debug")("front:GlobalConfig");

/**
 * Add your custom properties here
 */
export class GlobalConfigProperties {
  // Compiled version of the app, from package.json and process.env
  version: string;
  // env (staging, qa, production...)
  env: string;
  // Base URL
  baseUrl: string;
  // Router base URL
  routerBaseUrl: string;
  // Root node where the app DOM will be append
  root: HTMLElement;
  // Locale translation code
  locale: string;
  // site data
  appData: LocaleStruct;
  // bundleName
  bundleName: string;
}

/**
 * @name GlobalConfig
 */
class GlobalConfig extends GlobalConfigProperties {
  // --------------------------------------------------------------------------- METHODS

  constructor() {
    super();

    this.inject({
      version: require("../../../package.json").version,
      baseUrl: process.env.APP_BASE,
      routerBaseUrl: process.env.APP_BASE,
      env: process.env.ENV,
      bundleName: "RedElectric",
    });

    let languages = {
      fr: fr,
    };
    //Inject langs
    this.inject({
      appData: languages["fr"],
    });
  }

  /**
   * Log Global config
   */
  public log = () => debug(this);

  // ------------------------------------------------------------------------- INJECT

  /**
   * Inject arbitrary properties inside this object
   */
  public inject(pProps: any) {
    // Check if props are injectable
    if (pProps == null || typeof pProps !== "object") return;

    // Inject props
    for (let i in pProps) {
      this[i] = pProps[i];
    }
  }
}

// export with singleton
export default new GlobalConfig();
