import React from "react";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  Divider,
  Grid,
  IconButton,
  ListSubheader,
  useMediaQuery,
} from "@material-ui/core";
import { useStyles } from "./AccountDrawer.style";
import { useTheme } from "@material-ui/core/styles";

interface AccountDrawerProperty {
  className?: string;
  listItems?: {
    label: string | JSX.Element;
    icon?: JSX.Element;
    disableTypography?: boolean;
    callback: () => void;
    isSelected: boolean;
  }[];
  open: boolean;
  onMenuClicked: () => void;
}

export const AccountDrawer = ({
  className,
  listItems,
  open,
  onMenuClicked,
}: AccountDrawerProperty) => {
  const classes = useStyles()();

  return (
    <Drawer
      variant="permanent"
      className={clsx(className, classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.drawerContainer}>
        <List>
          <Grid container alignItems="center">
            <Grid item xs={9}>
              {open && (
                <ListSubheader style={{ whiteSpace: "nowrap" }}>
                  Mon compte
                </ListSubheader>
              )}
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justify="center"
              xs={open ? 3 : "auto"}
            >
              <IconButton
                onClick={() => {
                  onMenuClicked();
                }}
              >
                {open ? (
                  <ChevronLeftIcon fontSize="large" />
                ) : (
                  <MenuIcon fontSize="large" />
                )}
              </IconButton>
            </Grid>
          </Grid>

          <Divider />
          {listItems.map(
            ({ label, icon, disableTypography, callback, isSelected }, idx) => (
              <ListItem
                button
                onClick={callback}
                selected={isSelected}
                key={`drawer-item-${idx}`}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText
                  style={{ whiteSpace: "nowrap" }}
                  disableTypography={disableTypography}
                  primary={label}
                />
              </ListItem>
            )
          )}
        </List>
      </div>
    </Drawer>
  );
};
