import { Signal } from "../lib/helpers/Signal";
import { EventEmitter } from "events";

let FontFaceObserver = require("fontfaceobserver");

const fileName = "LoadingService";
const debug = require("debug")(`front:${fileName}`);

export class LoadingService extends EventEmitter {
  /**
   * Singleton instance
   */
  protected static __instance: LoadingService;

  public static get instance(): LoadingService {
    if (LoadingService.__instance == null) {
      LoadingService.__instance = new LoadingService();
    }
    return LoadingService.__instance;
  }

  // --------------------------------------------------------------------------- VAR

  // Timeouts
  private maxTimeout = null;
  private extraWaitTimeout = null;

  // Various
  private _loadedCount: number = 0;
  private _maxTimeoutValue: number = 30000; // If elements are not loaded after this time, show the homepage anyway
  private _dataLoaded: boolean = false;
  private _isActive: boolean = false;
  private _totalElements: number = 2;
  private _startTime: number = 0;

  // --------------------------------------------------------------------------- LIFEYCLE

  /**
   * Start loading logic and start main timeout
   */
  public init() {
    if (LoadingService.__instance) {
      this._startTime = Date.now();
      this._isActive = true;
      // Determine if loader has to be shown
      let showLoaderItem = localStorage.getItem("show-loader");
      if (showLoaderItem) {
        // If item exists in storage
        if (showLoaderItem === "no") {
          // Do not show the loader
          document.getElementById("preloader").remove();
          // Set view loader to true for next times
          localStorage.setItem("show-loader", "yes");
          // Kill instance
          this.kill();
          return;
        }
      }

      // Trigger load checks
      // Wait for dom to be injected by react
      setTimeout(() => {
        this.loadFonts();
        this.loadImages();
        // this.loadVideos();
      }, 1000);

      // After max timeout elapsed, trigger the transition anyway
      this.maxTimeout = setTimeout(() => {
        this.onDataLoaded();
      }, this._maxTimeoutValue);
    }
  }

  /**
   * Clear all timeouts and kill instance
   */
  public kill() {
    if (LoadingService.__instance) {
      if (this.maxTimeout) clearTimeout(this.maxTimeout);
      if (this.extraWaitTimeout) clearTimeout(this.extraWaitTimeout);
      LoadingService.__instance = null;
    }
  }

  // --------------------------------------------------------------------------- DATA
  /**
   * Loading done signal
   */
  protected static __dataLoaded: Signal = new Signal();

  public static get dataLoaded(): Signal {
    return this.__dataLoaded;
  }

  /**
   * Check fonts load
   */
  protected loadFonts() {
    if (LoadingService.__instance) {
      // Time to wait before declaring timeout
      const fontLoadingTimeout = this._maxTimeoutValue - 1000;

      // https://github.com/bramstein/fontfaceobserver

      // Fonts needing to be loaded
      // 'Name': {params}
      const fonts = {
        "montserrat-regular": {},
        "montserrat-italic": {},
        "montserrat-bold": {},
      };

      let observers = [];

      // Create one observer of each font
      Object.keys(fonts).forEach((fontFamily) => {
        let data = fonts[fontFamily];
        let observer = new FontFaceObserver(fontFamily, data);
        observers.push(observer.load(null, fontLoadingTimeout));
      });

      Promise.all(observers)
        .then(() => {
          // On fonts loaded
          debug("All fonts loaded!");
          this.checkAllElementsLoaded();
        })
        .catch((err) => {
          // On error
          // Continue anyway
          this.checkAllElementsLoaded();
          console.warn("A critical error occured while loading fonts: ", err);
        });
    }
  }

  /**
   * Check videos load
   */
  public loadVideos() {
    if (LoadingService.__instance) {
      let vids = document.querySelectorAll("video"),
        len = vids.length,
        counter = 0;
      [].forEach.call(vids, (vid) => {
        if (vid) {
          if (vid.readyState >= 3) {
            incrementVideosCounter();
          } else {
            vid.addEventListener("readystatechange", () => {
              incrementVideosCounter();
            });
          }
        } else {
          // If video element not found (for example not a real video element), continue anyway
          incrementVideosCounter();
        }
      });

      // @ts-ignore
      function incrementVideosCounter() {
        counter++;
        if (counter === len) {
          debug("All videos loaded!", len);
          LoadingService.instance.checkAllElementsLoaded();
        }
      }
    }
  }

  /**
   * Check images load
   */
  public loadImages() {
    if (LoadingService.__instance) {
      let imgs = document.images,
        counter = 0;

      // Count number of images
      let len = imgs.length;

      // Check status of images
      [].forEach.call(imgs, (img) => {
        if (img.complete) incrementImagesCounter();
        else img.addEventListener("load", incrementImagesCounter, false);
      });

      // @ts-ignore
      function incrementImagesCounter() {
        counter++;
        if (counter === len) {
          debug("All images loaded!", len);
          LoadingService.instance.checkAllElementsLoaded();
        }
      }
    }
  }

  /**
   * Called each time an element type (images, videos, fonts) is loaded
   */
  protected checkAllElementsLoaded() {
    if (LoadingService.__instance) {
      // If all elements loaded
      if (this._loadedCount + 1 === this._totalElements) {
        this.onDataLoaded();
      }
      // Else continue incrementing
      else {
        this._loadedCount += 1;
      }
    }
  }

  /**
   * On data loaded, trigger events
   */
  private onDataLoaded() {
    if (!this.extraWaitTimeout) {
      const currentTimestamp = Date.now();
      const extraDelay = currentTimestamp - this._startTime > 2000 ? 0 : 1000; // no extra delay for slow connections
      // Prevent multiple call of this function
      this.extraWaitTimeout = setTimeout(() => {
        // Trigger callback
        LoadingService.dataLoaded.dispatch();
        this.emit("loadingDone");
      }, extraDelay);
    }
  }

  /**
   * Hack to monitor loaded variable
   */
  public isDataLoaded(): Promise<any> {
    return new Promise(async (resolve) => {
      if (!this._isActive) {
        const timeout = setTimeout(() => {
          resolve();
          clearTimeout(timeout);
        }, 100);
      }

      if (this._dataLoaded) resolve();
      const timer = setInterval((pData = this._dataLoaded) => {
        if (pData) {
          clearInterval(timer);
          return resolve();
        }
      }, 1000);
    });
  }
}
