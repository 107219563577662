import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import InternalRoutes from "red-electric/utils/InternalRoutes";
import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import { LoadingService } from "@common/services/LoadingService";
import GlobalConfig from "@common/data/GlobalConfig";
import Header from "red-electric/components/header";
import RegistrationForm from "red-electric/components/registrationForm";
import { UserLoginAPIRequest } from "red-electric/utils/Api.interface";
import { userLogin, userCreateAccount } from "red-electric/app/userDataSlice";
import {
  selectIsLoading,
  selectUIErrors,
  selectUISuccesses,
} from "red-electric/app/UISlice";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    paddingTop: "10rem",
    paddingBottom: "8rem",
  },
}));

const RegistrationPage = () => {
  const trans = GlobalConfig?.appData.blocks.registrationPage;

  const classes = useStyles();
  const dispatch = useDispatch();
  const UIErrors = useSelector(selectUIErrors);
  const UISuccesses = useSelector(selectUISuccesses);
  const UILoading = useSelector(selectIsLoading);

  const [loaded, setLoaded] = useState(false);
  const [autoLoginEmail, setAutoLoginEmail] = useState("");
  const [autoLoginPassword, setAutoLoginPassword] = useState("");

  useEffect(() => {
    LoadingService?.instance?.on("loadingDone", () => setLoaded(true));
  }, []);

  useEffect(() => {
    if (UISuccesses.userCreateAccount) {
      const loginPayload: UserLoginAPIRequest = {
        loginName: autoLoginEmail,
        password: autoLoginPassword,
      };
      dispatch(userLogin(loginPayload));
    }
  }, [UISuccesses.userCreateAccount]);

  useEffect(() => {
    if (UIErrors.userCreateAccount) {
      setAutoLoginEmail("");
      setAutoLoginPassword("");
    }
  }, [UIErrors.userCreateAccount]);

  const handleRegistration = (payload) => {
    setAutoLoginEmail(payload.email);
    setAutoLoginPassword(payload.password);
    dispatch(userCreateAccount(payload));
  };

  const handleGoToLogin = (e) => {
    e.preventDefault();
    window.location.href = "/login";
  };
  return (
    <div className={classes.root}>
      <Container>
        <Box marginBottom={6}>
          <Typography variant="h1" align="center">
            {trans.title}
          </Typography>
        </Box>
        <Box marginBottom={6}>
          <Container maxWidth="sm">
            <RegistrationForm
              isUpdatingProfile={false}
              isApiLoading={UILoading.userCreateAccount}
              submitLabel={trans.registrationButton}
              handleSubmit={handleRegistration}
              serverResponse={
                UIErrors.userCreateAccount && (
                  <Typography color="error">
                    {UIErrors.userCreateAccount.errorMsg}
                  </Typography>
                )
              }
            />
          </Container>
        </Box>
        <Box>
          <Typography align="center">
            {trans.loginLabel}{" "}
            <Typography component={Link} to={InternalRoutes.login()}>
              {trans.loginButton}
            </Typography>
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default RegistrationPage;
