import css from "./Footer.module.less";
import React, { useState } from "react";
import { merge } from "@common/lib/helpers/classNameHelper";
import GlobalConfig from "@common/data/GlobalConfig";
import facebookPicto from "asset/img/picto/picto-facebook.png";
import instagramPicto from "asset/img/picto/picto-instagram.png";
import twitterPicto from "asset/img/picto/picto-twitter.png";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";

interface IProps {
  classNames?: string[];
}

const componentName = "Footer";
const debug = require("debug")(`front:${componentName}`);

/**
 * @name Footer
 */
function Footer(props: IProps) {
  const data = GlobalConfig?.appData?.blocks?.footer;
  const [showModal, setShowModal] = useState<string | boolean>(false);

  const ConditionsContent = (props) => (
    <Box>
      <Typography variant="h2">
        {GlobalConfig.appData.layout.legalPopup.title}
      </Typography>
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: GlobalConfig.appData.layout.legalPopup.content,
        }}
      />
    </Box>
  );

  const LegalContent = (props) => (
    <Box>
      <Typography variant="h2">
        {GlobalConfig.appData.layout.cgvPopup.title}
      </Typography>
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: GlobalConfig.appData.layout.cgvPopup.content,
        }}
      />
    </Box>
  );

  const Modal = (props) => (
    <Dialog
      scroll={"paper"}
      fullWidth
      open={!!showModal}
      onClose={() => setShowModal(false)}
    >
      <DialogContent>
        {showModal == "conditions" && <ConditionsContent />}
        {showModal == "legal" && <LegalContent />}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setShowModal(false)}
          color="primary"
          autoFocus
        >
          Quitter
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div className={merge([css.Root, props.classNames])}>
      <Modal />
      <div className={css.container}>
        <div className={css.row}>
          <div className={css.col}>
            <a
              className={css.link}
              children={data?.conditions}
              onClick={() => setShowModal("conditions")}
            />
            <a
              className={css.link}
              children={data?.press}
              href="mailto:presse@redelectric.fr"
            />
            <a
              className={css.link}
              children={data?.professional}
              target="_blank"
              href="https://redescooter.fr"
            />
          </div>

          <div className={css.col}>
            <a
              className={css.link}
              children={data?.legalMention}
              onClick={() => setShowModal("legal")}
            />
            <a className={css.link} children={data?.contact} href="mailto:contact@redelectric.fr" />
          </div>
          <div className={merge([css.col, css.col_icons])}>
            <span className={css.icons_label}>{data?.followUs}</span>
            <div className={css.icons}>
              <a
                href="https://www.facebook.com/Red.Electric.Scooters"
                target="_blank"
              >
                <img src={facebookPicto} alt="facebok logo" />
              </a>
              <a
                href="https://www.instagram.com/redelectric_officiel/"
                target="_blank"
              >
                <img src={instagramPicto} alt="instagram logo" />
              </a>
              <a href="https://twitter.com/redelectric_fr" target="_blank">
                <img src={twitterPicto} alt="twitter logo" />
              </a>
            </div>
          </div>
        </div>
        <div className={css.row}>
          <div className={css.col}>
            <p className={css.copyright} children={data?.copyright} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
