import React, { Suspense, useEffect } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { Switch } from "react-router-dom";
import { useDispatch } from "react-redux";
import theme from "./theme";
import AboutPage from "./pages/aboutPage";
import { AccountPage } from "./pages/AccountPage/AccountPage";
import ChangePasswordPage from "./pages/changePasswordPage";
import ChooseElectricPage from "./pages/chooseElectricPage";
import FaqPage from "./pages/faqPage";
import FinancePage from "./pages/financePage";
import ForgotPasswordPage from "./pages/forgotPasswordPage";
import HomePage from "./pages/homePage";
import LoginPage from "./pages/loginPage";
import MatchingPage from "./pages/matchingPage";
import NotFoundPage from "./pages/notFoundPage";
import RegistrationPage from "./pages/registrationPage";
import TechnologyPage from "./pages/technologyPage";
import InsurancePage from "./pages/insurancePage";
import WorkPage from "./pages/workPage";
import Loader from "./components/loader/Loader";
import Header from "./components/header";
import Footer from "./components/footer";
import PublicRoute from "./components/PublicRoute";
import PrivateRoute from "./components/PrivateRoute";
import CookieBlock from "./components/cookieBlock";
import { getLocalToken } from "./utils/LocalToken";
import InternalRoutes from "./utils/InternalRoutes";
import { updateUser } from "./app/userDataSlice";
import { useLocation } from "react-router-dom";
import { ResetPasswordPage } from "./pages/resetPasswordPage/ResetPasswordPage";
import { OrderPage } from "./pages/orderPage/orderPage";

const ScrollToTop = (props) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
};

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = getLocalToken();
    dispatch(updateUser({ token }));
  }, []);

  return (
    <ScrollToTop>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Loader />}>
          <div>
            <Header />
            <CookieBlock />
            <Switch>
              <PublicRoute
                exact
                path={InternalRoutes.home()}
                component={HomePage}
              />
              <PublicRoute
                exact
                path={InternalRoutes.about()}
                component={AboutPage}
              />
              <PublicRoute
                exact
                path={InternalRoutes.order(":orderId")}
                component={OrderPage}
              />
              <PrivateRoute
                exact
                path={InternalRoutes.account()}
                component={AccountPage}
              />
              <PrivateRoute
                exact
                path={InternalRoutes.changePassword()}
                component={ChangePasswordPage}
              />
              <PublicRoute
                exact
                path={InternalRoutes.chooseElectric()}
                component={ChooseElectricPage}
              />
              <PublicRoute
                exact
                path={InternalRoutes.faq()}
                component={FaqPage}
              />
              <PublicRoute
                exact
                path={InternalRoutes.finance()}
                component={FinancePage}
              />
              <PublicRoute
                exact
                path={InternalRoutes.login()}
                restricted
                component={LoginPage}
              />
              <PublicRoute
                exact
                path={InternalRoutes.forgotPassword()}
                restricted
                component={ForgotPasswordPage}
              />
              <PublicRoute
                exact
                path={InternalRoutes.resetPassword()}
                restricted
                component={ResetPasswordPage}
              />
              <PublicRoute
                exact
                path={InternalRoutes.matching()}
                restricted
                component={MatchingPage}
              />
              <PublicRoute
                exact
                path={InternalRoutes.notFound()}
                component={NotFoundPage}
              />
              <PublicRoute
                exact
                path={InternalRoutes.registration()}
                restricted
                component={RegistrationPage}
              />
              <PublicRoute
                exact
                path={InternalRoutes.technology()}
                component={TechnologyPage}
              />
              <PublicRoute
                exact
                path={InternalRoutes.insurance()}
                component={InsurancePage}
              />
              <PublicRoute
                exact
                path={InternalRoutes.work()}
                component={WorkPage}
              />
            </Switch>
            <Footer />
          </div>
        </Suspense>
      </ThemeProvider>
    </ScrollToTop>
  );
};

export default App;
