import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: "8rem",
    paddingBottom: "8rem",
    position: "relative",
  },
  main: {
    minHeight: "100vh",
    paddingLeft: theme.spacing(5) + 1,
    [theme.breakpoints.down("sm")]: {},
  },
  drawer: {
    width: "24rem",
  },
  [theme.breakpoints.up("sm")]: {
    mainOpen: {
      paddingLeft: "24rem",
      transition: theme.transitions.create("padding", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    mainClose: {
      transition: theme.transitions.create("padding", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  sectionTitle: {
    marginBottom: "2rem",
  },
}));
