import css from "./AboutPage.module.less";
import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header";
import DiscoverBlock from "../../components/discoverBlock";
import LandingBlock from "../../components/landingBlock";
import { LoadingService } from "@common/services/LoadingService";
import { usePageRegister } from "@common/lib/router/usePageRegister";
import GlobalConfig from "@common/data/GlobalConfig";
import DeliveryBlock from "../../components/deliveryBlock";
import { onClickOrderButton } from "../../app/gtmMiddleware";

interface IProps {
  parameters: any;
}

// prepare
const componentName = "AboutPage";
const debug = require("debug")(`front:${componentName}`);

/**
 * @name AboutPage
 */
const AboutPage = (props: IProps) => {
  const trans = GlobalConfig?.appData;

  // get root ref
  const rootRef = useRef<HTMLDivElement>(null);

  // -------------------–-------------------–-------------------–--------------- STATES

  const [loaded, setLoaded] = useState(false);

  // -------------------–-------------------–-------------------–--------------- REGISTER PAGE

  /**
   * playIn page transition
   * (remove this exemple if not use)
   */
  const playIn = (): Promise<any> => {
    return new Promise((resolve) => resolve());
  };

  /**
   * playOut page transition
   * (remove this exemple if not use)
   */
  const playOut = (): Promise<any> => {
    return new Promise((resolve) => resolve());
  };

  /**
   * Register page for ViewStack
   * NOTE: each page of ViewStack need to be register to work.
   * Minimal register should be: usePageRegister({ componentName, rootRef });
   * (remove playIn and playOut if not use)
   */
  usePageRegister({ componentName, rootRef, playIn, playOut });

  // -------------------–-------------------–-------------------–--------------- EFFECTS

  useEffect(() => {
    LoadingService?.instance?.on("loadingDone", () => setLoaded(true));
  }, []);

  // -------------------–-------------------–-------------------–--------------- RENDER

  return (
    <div className={css.Root} ref={rootRef}>
      <div className={css.siteWrapper}>
        <LandingBlock />
        <div className={css.textWrapper}>
          {trans.blocks.aboutPage.texts.map((text, i) => (
            <p key={i} dangerouslySetInnerHTML={{ __html: text }}></p>
          ))}
          <p
            id="mention1"
            className={css.subtitle}
            dangerouslySetInnerHTML={{ __html: trans.blocks.aboutPage.subText }}
          ></p>
          <p>
            <a
              className={css.link}
              href={trans.layout.header.faq.link}
              data-internal-link
            >
              {trans.blocks.aboutPage.faqText}
            </a>
            .
          </p>
          <button
            className={css.cta}
            onClick={(e) => {
              e.preventDefault();
              onClickOrderButton();
              window.location.href = process.env.MENU_CREATE_ORDER;
            }}
          >
            {trans.blocks.aboutPage.ctaText}
          </button>
        </div>
        <DeliveryBlock />
      </div>
    </div>
  );
};

export default AboutPage;
