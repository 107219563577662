import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((t) => ({
  container: {
    cursor: "pointer",
    width: "100%",
    display: "flex",
    flexDirection: "column-reverse",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    zIndex: 100,
    bottom: "10rem",
    height: "10rem",
    [t.breakpoints.up("xl")]: {
      bottom: "20rem",
    },
    [t.breakpoints.down("sm")]: {
      bottom: "2rem",
      display: "none"
    },
  },
  arrow_wrapper: {
    position: "absolute",
    "-webkit-animation": "$slide 1.2s linear infinite",
    animation: "$slide 1.2s linear infinite",
    "&:nth-child(0)": {
      "-webkit-animation-delay": "0.3s",
      animationDelay: "0.3s",
    },
    "&:nth-child(1)": {
      "-webkit-animation-delay": "0.6s",
      animationDelay: "0.6s",
    },
    "&:nth-child(2)": {
      "-webkit-animation-delay": "0.9s",
      animationDelay: "0.9s",
    },
  },
  arrow: {
    width: "2rem",
    height: "2rem",
    border: "0.4rem solid",
    borderColor: "transparent white white transparent",
    transform: "rotate(45deg)",
  },
  "@keyframes slide": {
    "0%": {
      opacity: 0,
      transform: "translateY(-3rem)",
    },
    "20%": {
      opacity: 1,
      transform: "translateY(-1.5rem)",
    },
    "80%": {
      opacity: 1,
      transform: "translateY(1.5rem)",
    },
    "100%": {
      opacity: 0,
      transform: "translateY(3rem)",
    },
  },
  "@-webkit-keyframes slide": {
    "0%": {
      opacity: 0,
      transform: "translateY(-3rem)",
    },
    "20%": {
      opacity: 1,
      transform: "translateY(-1.5rem)",
    },
    "80%": {
      opacity: 1,
      transform: "translateY(1.5rem)",
    },
    "100%": {
      opacity: 0,
      transform: "translateY(3rem)",
    },
  },
}));

export const ScrollIndicator = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.arrow_wrapper}>
        <div className={classes.arrow} />
      </div>
      <div className={classes.arrow_wrapper}>
        <div className={classes.arrow} />
      </div>
      <div className={classes.arrow_wrapper}>
        <div className={classes.arrow} />
      </div>
    </div>
  );
};
