/**
 * https://stackoverflow.com/questions/21474678/scrolltop-animation-without-jquery
 * @param duration
 */
export function scrollToTop(duration) {
  // cancel if already on top
  if (document.scrollingElement.scrollTop === 0) return;

  const cosParameter = document.scrollingElement.scrollTop / 2;
  let scrollCount = 0,
    oldTimestamp = null;

  function step(newTimestamp) {
    if (oldTimestamp !== null) {
      // if duration is 0 scrollCount will be Infinity
      scrollCount += (Math.PI * (newTimestamp - oldTimestamp)) / duration;
      if (scrollCount >= Math.PI)
        return (document.scrollingElement.scrollTop = 0);
      document.scrollingElement.scrollTop =
        cosParameter + cosParameter * Math.cos(scrollCount);
    }
    oldTimestamp = newTimestamp;
    window.requestAnimationFrame(step);
  }
  window.requestAnimationFrame(step);
}

type TSerializedForm = { [index: string]: number | string | Blob | boolean };
export const serializeForm = (
  form: HTMLFormElement,
  defaults: TSerializedForm = {}
): TSerializedForm => {
  const inputs = form.elements;

  for (let i = 0; i < inputs.length; i++) {
    const input: HTMLInputElement & HTMLSelectElement = inputs[i] as any;
    if (input.name == "") continue;
    let value = input.value;
    if (input.tagName === "SELECT") {
      const selected = input.options[input.selectedIndex];
      value = selected.value || selected.text;
    }
    defaults[input.name] = value;
  }

  return defaults;
};

export const resetForm = (form: HTMLFormElement) => {
  const inputs = form.elements;

  for (let i = 0; i < inputs.length; i++) {
    const input: HTMLInputElement & HTMLSelectElement = inputs[i] as any;
    if (input.name == "") continue;
    input.value = "";
  }
};

/**
 * Open the link in a new window
 * @param link
 */
export function openLinkInNewWindow(sLink) {
  window.open(sLink, "_blank");
};
