import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store.interface";
import {
  UIState,
  LoadingState,
  UIErrors,
  UISuccesses,
} from "./UISlice.interface";

const initialState: UIState = {
  errors: {},
  successes: {},
  isLoading: {
    userCreateAccount: false,
    userLogin: false,
    userLogout: false,
    userFetchProfile: false,
    userSaveProfile: false,
    userFetchOrders: false,
    userFetchOrder: false,
    userForgotPassword: false,
    userChangePassword: false,
    userResetPassword: false,
    contactForm: false,
    newsletterForm: false,
    matchingIdentification: false,
  },
};

export const UISlice = createSlice({
  name: "UIData",
  initialState,
  reducers: {
    updateIsLoading: (state, action: PayloadAction<LoadingState>) => {
      state.isLoading = { ...state.isLoading, ...action.payload };
    },
    updateSuccesses: (state, action: PayloadAction<UISuccesses>) => {
      state.successes = { ...state.successes, ...action.payload };
    },
    updateErrors: (state, action: PayloadAction<UIErrors>) => {
      state.errors = { ...state.errors, ...action.payload };
    },
    resetErrors: (state, action: PayloadAction<keyof UIErrors>) => {
      state.errors = { ...state.errors, [action.payload]: undefined };
    },
    resetSuccesses: (state, action: PayloadAction<keyof UISuccesses>) => {
      state.successes = { ...state.successes, [action.payload]: undefined };
    },
    resetUI: () => {
      return initialState;
    },
  },
});

// --- ACTIONS
export const {
  updateIsLoading,
  updateSuccesses,
  updateErrors,
  resetErrors,
  resetSuccesses,
  resetUI,
} = UISlice.actions;

// --- SELECTORS
export const selectIsLoading = (state: RootState) => {
  return state.UIData.isLoading;
};

export const selectUIErrors = (state: RootState) => {
  return state.UIData.errors;
};

export const selectUISuccesses = (state: RootState) => {
  return state.UIData.successes;
};

export default UISlice.reducer;
