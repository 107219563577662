import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "./store.interface";
import TagManager from "react-gtm-module";
import {
  userLoginAPI,
  userFetchOrdersAPI,
  userFetchProfileAPI,
  userSaveProfileAPI,
  userForgotPasswordAPI,
  userChangePasswordAPI,
  userResetPasswordAPI,
  userCreateAccountAPI,
  userLogoutAPI,
  userFetchOrderAPI,
} from "../utils/Api";
import {
  resetErrors,
  resetUI,
  updateErrors,
  updateIsLoading,
  updateSuccesses,
} from "./UISlice";
import {
  UserLoginAPIRequest,
  UserForgotPasswordAPIRequest,
  UserChangePasswordAPIRequest,
  UserResetPasswordAPIRequest,
  UserCreateAccountAPIRequest,
} from "../utils/Api.interface";
import {
  UserData,
  TokenData,
  OrderData,
  ProfileData,
} from "./userDataSlice.interface";

const initialState: UserData = {
  token: null,
  orders: [],
  profile: {},
};

const tagManagerArgs = {
  dataLayer: {
    event: "interaction",
    category: "account",
    action: "accountCreation",
    label: "success",
  },
};

// --- USER DATA ACTIONS
export const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<Partial<UserData>>) => {
      return { ...state, ...action.payload };
    },
    updateToken: (state, action: PayloadAction<TokenData>) => {
      return { ...state, token: action.payload };
    },
    updateProfile: (state, action: PayloadAction<Partial<ProfileData>>) => {
      return { ...state, profile: action.payload };
    },
    updateOrders: (state, action: PayloadAction<Partial<OrderData[]>>) => {
      return { ...state, orders: action.payload };
    },
    updateOrder: (state, action: PayloadAction<Partial<OrderData>>) => {
      return { ...state, order: action.payload };
    },
    resetUser: () => initialState,
  },
});

export const {
  updateUser,
  updateToken,
  updateProfile,
  updateOrders,
  updateOrder,
  resetUser,
} = userDataSlice.actions;

export const userCreateAccount = (
  payload: UserCreateAccountAPIRequest
): AppThunk => (dispatch) => {
  dispatch(resetErrors("userCreateAccount"));
  dispatch(updateIsLoading({ userCreateAccount: true }));
  userCreateAccountAPI(payload)
    .then((data) => {
      dispatch(updateIsLoading({ userCreateAccount: false }));
      dispatch(updateSuccesses({ userCreateAccount: data }));
      TagManager?.dataLayer(tagManagerArgs);
    })
    .catch((error) => {
      dispatch(updateIsLoading({ userCreateAccount: false }));
      dispatch(updateErrors({ userCreateAccount: error }));
    });
};

export const userLogin = (payload: UserLoginAPIRequest): AppThunk => (
  dispatch
) => {
  dispatch(resetErrors("userLogin"));
  dispatch(updateIsLoading({ userLogin: true }));
  userLoginAPI(payload)
    .then((data) => {
      dispatch(updateToken(data.token));
      dispatch(updateIsLoading({ userLogin: false }));
      dispatch(updateSuccesses({ userLogin: true }));
    })
    .catch((error) => {
      dispatch(updateSuccesses({ userLogin: false }));
      dispatch(updateErrors({ userLogin: error }));
    });
};

export const userLogout = (): AppThunk => (dispatch) => {
  dispatch(updateIsLoading({ userLogout: true }));
  const done = () => {
    dispatch(updateIsLoading({ userLogout: false }));
    dispatch(resetUI());
    dispatch(resetUser());
    window.location.href = "/login";
  };
  userLogoutAPI().then(done).catch(done);
};

export const userForgotPassword = (
  payload: UserForgotPasswordAPIRequest
): AppThunk => (dispatch) => {
  dispatch(resetErrors("userForgotPassword"));
  dispatch(updateIsLoading({ userForgotPassword: true }));
  userForgotPasswordAPI(payload)
    .then((data) => {
      dispatch(updateIsLoading({ userForgotPassword: false }));
      dispatch(updateSuccesses({ userForgotPassword: data }));
    })
    .catch((error) => {
      dispatch(updateIsLoading({ userForgotPassword: false }));
      dispatch(updateErrors({ userForgotPassword: error }));
    });
};

export const userChangePassword = (
  payload: UserChangePasswordAPIRequest
): AppThunk => (dispatch) => {
  dispatch(resetErrors("userChangePassword"));
  dispatch(updateIsLoading({ userChangePassword: true }));
  userChangePasswordAPI(payload)
    .then((data) => {
      dispatch(updateIsLoading({ userChangePassword: false }));
      dispatch(updateSuccesses({ userChangePassword: data }));
    })
    .catch((error) => {
      dispatch(updateIsLoading({ userChangePassword: false }));
      dispatch(updateErrors({ userChangePassword: error }));
    });
};

export const userResetPassword = (
  payload: UserResetPasswordAPIRequest
): AppThunk => (dispatch) => {
  dispatch(resetErrors("userChangePassword"));
  dispatch(updateIsLoading({ userResetPassword: true }));
  userResetPasswordAPI(payload)
    .then((data) => {
      dispatch(updateIsLoading({ userResetPassword: false }));
      dispatch(updateSuccesses({ userResetPassword: data }));
    })
    .catch((error) => {
      dispatch(updateIsLoading({ userResetPassword: false }));
      dispatch(updateErrors({ userResetPassword: error }));
    });
};

export const userFetchProfile = (): AppThunk => (dispatch) => {
  dispatch(resetErrors("userFetchProfile"));
  dispatch(updateIsLoading({ userFetchProfile: true }));
  userFetchProfileAPI()
    .then((data) => {
      dispatch(updateIsLoading({ userFetchProfile: false }));
      dispatch(updateProfile(data));
    })
    .catch((error) => {
      // TODO: use interceptor
      if (error?.errorCode === "W00110001") {
        dispatch(userLogout());
      }
      dispatch(updateIsLoading({ userFetchProfile: false }));
      dispatch(updateErrors({ userFetchProfile: error }));
    });
};

export const userSaveProfile = (payload: ProfileData): AppThunk => (
  dispatch
) => {
  dispatch(resetErrors("userSaveProfile"));
  dispatch(updateIsLoading({ userSaveProfile: true }));
  userSaveProfileAPI(payload)
    .then(() => {
      dispatch(updateIsLoading({ userSaveProfile: false }));
      dispatch(updateSuccesses({ userUpdateAccount: "done" }));
      dispatch(userFetchProfile());
    })
    .catch((error) => {
      dispatch(updateIsLoading({ userSaveProfile: false }));
      dispatch(updateErrors({ userSaveProfile: error }));
    });
};

export const userFetchOrders = (): AppThunk => (dispatch) => {
  dispatch(resetErrors("userFetchOrders"));
  dispatch(updateIsLoading({ userFetchOrders: true }));
  userFetchOrdersAPI()
    .then((data) => {
      dispatch(updateIsLoading({ userFetchOrders: false }));
      dispatch(updateOrders(data));
    })
    .catch((error) => {
      // TODO: use interceptor
      if (error?.errorCode === "W00110001") {
        dispatch(userLogout());
      }
      dispatch(updateIsLoading({ userFetchOrders: false }));
      dispatch(updateErrors({ userFetchOrders: error }));
    });
};

export const userFetchOrder = (id: string): AppThunk => (dispatch) => {
  dispatch(resetErrors("userFetchOrder"));
  dispatch(updateIsLoading({ userFetchOrder: true }));
  userFetchOrderAPI(id)
    .then((data) => {
      dispatch(updateIsLoading({ userFetchOrder: false }));
      dispatch(updateOrder(data));
    })
    .catch((error) => {
      // TODO: use interceptor
      if (error?.errorCode === "W00110001") {
        dispatch(userLogout());
      }
      dispatch(updateIsLoading({ userFetchOrder: false }));
      dispatch(updateErrors({ userFetchOrder: error }));
    });
};

// --- USER DATA SELECTORS
export const selectUser = (state: RootState) => state.userData;
export const selectToken = (state: RootState) => state.userData.token;
export const selectProfile = (state: RootState) => state.userData.profile;
export const selectOrders = (state: RootState) => state.userData.orders;
export const selectOrder = (state: RootState) => state.userData.order;

export default userDataSlice.reducer;
