import React from "react";
import { Box } from "@material-ui/core";
import { ChangePasswordPage } from "../../changePasswordPage/ChangePasswordPage";

interface AccountSecurityProps {}

export const AccountSecurity = ({}: AccountSecurityProps) => {
  return (
    <Box my={2}>
      <ChangePasswordPage />
    </Box>
  );
};
