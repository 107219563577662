import css from "./LandingBlock.module.less";
import React, { useState } from "react";
import {
  IconButton,
  makeStyles,
  useMediaQuery,
  withStyles,
} from "@material-ui/core";
import { Theme, useTheme } from "@material-ui/core/styles";
import { Link } from "react-scroll";
import bg13 from "asset/img/background/bg13.jpg";
import { ScrollIndicator } from "../scrollIndicator/scrollIndicator";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";

const useStyles = makeStyles((theme) => ({
  bg: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },

  bg_video: {
    width: "100vw",
    height: "56.25vw" /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */,
    minHeight: "100vh",
    minWidth: "177.77vh" /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("sm")]: {
      minWidth: "80vh",
      height: "125vw",
      width: "",
      minHeight: "auto",
    },
  },
}));

const LandingBlock = () => {
  // --------------------------------------------------------------------------- PREPARE
  const classes = useStyles();
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));
  const [isGoodConnection, setIsGoodConnection] = useState(true);
  const [videoHasVolume, setVideoHasVolume] = useState(false);

  // --------------------------------------------------------------------------- EFFECTS

  const VolumeBtn = () => {
    return (
      <StyledIconButton
        aria-label="mute video"
        onClick={() => {
          setVideoHasVolume(!videoHasVolume);
        }}
      >
        {videoHasVolume ? (
          <VolumeUpIcon fontSize={"inherit"} />
        ) : (
          <VolumeOffIcon fontSize={"inherit"} />
        )}
      </StyledIconButton>
    );
  };

  const Video = ({ isMobile: boolean }) => {
    const videoId = isMobile ? "501779564" : "501780599";
    return (
      <>
        <iframe
          className={classes.bg_video}
          src={`https://player.vimeo.com/video/${videoId}?background=1&muted=${!videoHasVolume}`}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        />
      </>
    );
  };

  const ImageBg = () => {
    const classes = useStyles();
    return (
      <img className={classes.bg} src={bg13} alt={"Scooters Électriques RED"} />
    );
  };

  return (
    <div className={css.Root}>
      <ImageBg />
      {/* {isGoodConnection && <Video isMobile={isMobile} />} */}
      <Link
        duration={1000}
        spy={true}
        smooth={true}
        offset={-90}
        to={"section1"}
      >
        <ScrollIndicator />
      </Link>
    </div>
  );
};

export const StyledIconButton = withStyles((t: Theme) => ({
  root: {
    position: "absolute",
    top: "2rem",
    right: "2rem",
    fontSize: "3rem",
    zIndex: 101,
    color: t.palette.common.white,
  },
}))(IconButton);

export default LandingBlock;
