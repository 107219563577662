import css from "./StoresMapBlock.module.less";
import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Grid,
  Paper,
  useMediaQuery,
  Typography,
  Card,
  CardActions,
  CardHeader,
  Button,
  Box,
  CardContent,
  ButtonGroup,
  TextField,
} from "@material-ui/core";
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import Map from "../Map/Map";
import { loadMapApi } from "../../utils/GoogleMapsUtils";
import GlobalConfig from "@common/data/GlobalConfig";
import phonePicto from "asset/img/picto/picto-phone.png";
import { merge } from "@common/lib/helpers/classNameHelper";
import { StoreCard } from "../storeCard/StoreCard";

interface IProps {
  displayGarages?: boolean;
  displayDealers?: boolean;
}

const componentName = "StoresMapBlock";
const debug = require("debug")(`front:${componentName}`);
const stores = GlobalConfig.appData.stores;

/**
 * @name StoresMapBlock
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      padding: 0,
    },
    store_card: {
      background: theme.palette.primary.dark,
      color: "white",
      border: `solid 0px ${theme.palette.primary.dark}`,
      cursor: "pointer",
      position: "relative",
      "&.active,&:hover": {
        border: `solid 2px ${theme.palette.primary.main}`,
      },
      "&.hidden": {
        display: "none",
      },
    },
    store_id: {
      border: `solid 2px ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      background: "white",
      borderRadius: "50%",
      fontWeight: 900,
      width: "2rem",
      height: "2rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    map_container: {
      [theme.breakpoints.down("sm")]: {
        paddingTop: "7rem",
      },
    },
    phone_picto: {
      display: "hidden",
      verticalAlign: "middle",
    },
    store_distance: {
      position: "absolute",
      right: "1rem",
      top: "1rem",
    },
    stores_container: {},
    map_control: {
      position: "absolute",
      margin: "auto",
      textAlign: "center",
      padding: theme.spacing(2),
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    search_input: {
      background: "white",
    },
  })
);

const StoresMapBlock = (props: IProps) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showState, setShowState] = useState("map");
  const [selectedStoreId, setSelectedStoreId] = useState(undefined);
  const [mapCenter, setMapCenter] = useState(undefined);
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

  const classes = useStyles();

  useEffect(() => {
    const googleMapScript = loadMapApi();

    if (typeof google === "object" && typeof google.maps === "object") {
      initMap();
    }
    googleMapScript.addEventListener("load", function () {
      initMap();
    });
  }, []);

  const initMap = () => {
    setScriptLoaded(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setMapCenter({
          lat: position.coords.latitude,
          long: position.coords.longitude,
        });
      });
    }
  };

  const isStoreHidden = (store) => {
    let q = searchQuery.toLowerCase().replace(/\s/g, "");
    let name = store.name.toLowerCase().replace(/\s/g, "");
    let address = store.address.toLowerCase().replace(/\s/g, "");
    let phone = store.phone.toLowerCase().replace(/\s/g, "");
    return !name.match(q) && !address.match(q) && !phone.match(q);
  };

  const getDistanceFromStore = (lat, long) => {
    if (!mapCenter) return "? km";

    const R = 6371.071; // Radius of the Earth in miles
    const rlat1 = lat * (Math.PI / 180); // Convert degrees to radians
    const rlat2 = mapCenter.lat * (Math.PI / 180); // Convert degrees to radians
    const difflat = rlat2 - rlat1; // Radian difference (latitudes)
    const difflon = (mapCenter.long - long) * (Math.PI / 180); // Radian difference (longitudes)
    const d =
      Math.round(
        2 *
          R *
          Math.asin(
            Math.sqrt(
              Math.sin(difflat / 2) * Math.sin(difflat / 2) +
                Math.cos(rlat1) *
                  Math.cos(rlat2) *
                  Math.sin(difflon / 2) *
                  Math.sin(difflon / 2)
            )
          ) *
          10
      ) / 10;

    return `${d} km`;
  };

  useEffect(() => {
    if ((showState === "list" || !isMobile) && selectedStoreId) {
      document.getElementById(`store-${selectedStoreId}`).scrollIntoView();
    }
  }, [showState, selectedStoreId]);

  const isStoreDisplayed = (store) => {
    if (props.displayGarages && props.displayDealers)
      return store.isGarage || store.isDealer;
    if (props.displayGarages) return store.isGarage;
    if (props.displayDealers) return store.isDealer;
  };

  return (
    <Container className={classes.root}>
      <ButtonGroup className={classes.map_control} variant="contained">
        <Button
          color={showState == "map" ? "primary" : "default"}
          onClick={() => {
            setShowState("map");
          }}
        >
          Carte
        </Button>
        <Button
          color={showState == "list" ? "primary" : "default"}
          onClick={() => {
            setShowState("list");
          }}
        >
          Liste
        </Button>
      </ButtonGroup>
      <Grid
        container
        className={classes.map_container}
        spacing={0}
        style={{ height: "100%" }}
      >
        {(!isMobile || showState == "list") && (
          <Grid
            container
            alignItems="stretch"
            md={4}
            xs={12}
            style={{ height: "100%" }}
            direction="column"
          >
            <Grid item style={{ width: "100%", height: "6rem" }}>
              <Box m={1}>
                <TextField
                  label={
                    props.displayDealers
                      ? `Chercher un magasin`
                      : `Chercher un centre agréé`
                  }
                  variant="outlined"
                  value={searchQuery}
                  fullWidth
                  className={classes.search_input}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid xs item style={{ width: "100%", overflow: "scroll" }}>
              <Box m={1} className={classes.stores_container}>
                {stores.map((store, idx) => (
                  <Box
                    id={`store-${store.id}`}
                    mt={1}
                    mb={1}
                    key={`store-${idx}`}
                    onClick={() => {
                      setSelectedStoreId(store.id);
                    }}
                  >
                    {isStoreDisplayed(store) && (
                      <>
                        <StoreCard
                          store={store}
                          isSelected={selectedStoreId == store.id}
                          isHidden={isStoreHidden(store)}
                          distance={getDistanceFromStore(store.lat, store.long)}
                        />
                      </>
                    )}
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        )}

        <Grid item md={8} xs={12}>
          {scriptLoaded && (
            <Map
              mapType={google.maps.MapTypeId.ROADMAP}
              mapTypeControl={true}
              stores={stores}
              handleMarkerClick={(id) => {
                setSelectedStoreId(id);
              }}
              selectedStoreId={selectedStoreId}
              mapCenter={mapCenter}
              displayGarages={props.displayGarages}
              displayDealers={props.displayDealers}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

StoresMapBlock.defaultProps = {
  displayGarages: true,
  displayDealers: true,
};

export default StoresMapBlock;
