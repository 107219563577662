import css from "./GalleryBlock.module.less";
import React, { useRef } from "react";
import { merge } from "@common/lib/helpers/classNameHelper";

const firstImage = require("./1.jpg");
const secondImage = require("./2.jpg");
const thirdImage = require("./3.jpg");
const fourImage = require("./4.jpg");
const fiveImage = require("./5.jpg");
const sixImage = require("./6.jpg");
const sevenImage = require("./7.jpg");
const eightImage = require("./8.jpg");
const ninthImage = require("./9.jpg");
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { EBreakpoints } from "@common/atoms/EBreakpoints";

interface IProps {
  classNames?: string[];
}

const componentName = "GalleryBlock";
const debug = require("debug")(`front:${componentName}`);

/**
 * @name GalleryBlock
 */
function GalleryBlock(props: IProps) {
  const carouselRef = useRef<any>();
  const responsive = {
    desktop: {
      breakpoint: { min: EBreakpoints.TABLET, max: 5000 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: EBreakpoints.TABLET, min: 0 },
      items: 1,
      partialVisibilityGutter: 50,
    },
  };

  const CarouselButtonsGroup = ({ carouselState }: any) => {
    const dotsCount = carouselState.totalItems - carouselState.slidesToShow + 1;
    const { currentSlide } = carouselState;
    const left = Math.round((currentSlide * 100) / dotsCount);
    const width = Math.round(100 / dotsCount);
    return (
      <div
        className={css.carousel_slider}
        onClick={(e: any) => {
          const rect = e.target.getBoundingClientRect();
          const x = e.clientX - rect.left; //x position within the element.
          const targetSlide = Math.floor((x / rect.width) * dotsCount);
          carouselRef.current.goToSlide(targetSlide);
        }}
      >
        <div className={css.carousel_slider_container}>
          <div
            className={css.carousel_slider_bar}
            style={{
              left: left + "%",
              width: width + "%",
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={merge([css.Root, props.classNames])}>
      <div className={css.container}>
        <Carousel
          ref={carouselRef}
          responsive={responsive}
          swipeable
          draggable
          customButtonGroup={<CarouselButtonsGroup carouselState />}
          minimumTouchDrag={1}
          arrows={false}
          partialVisible={true}
          sliderClass={css.carousel_track}
        >
          <div className={merge([css.carousel_item, css.carousel_item_first])}>
            <img src={ninthImage?.default} alt={""} />
          </div>
          <div className={merge([css.carousel_item, css.carousel_item_second])}>
            <img src={fiveImage?.default} alt={""} />
            <img src={sixImage?.default} alt={""} />
            <img src={sevenImage?.default} alt={""} />
          </div>
          <div className={merge([css.carousel_item, css.carousel_item_third])}>
            <img src={fourImage?.default} alt={""} />
          </div>
        </Carousel>
      </div>
    </div>
  );
}

export default GalleryBlock;
