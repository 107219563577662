import React, { useState } from "react";
import GlobalConfig from "@common/data/GlobalConfig";
import clsx from "clsx";
import {
  Box,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  LinearProgress,
  RadioGroup,
} from "@material-ui/core";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { TextValidator } from "react-material-ui-form-validator";

export interface PasswordInputProps {
  password?: string;
  label?: string;
  onChange?: (newPassword: string) => void;
  onValidChange?: (isValid: boolean) => void;
}

export const PasswordInput = ({
  password: initialPassword,
  onChange,
  onValidChange,
  label,
}: PasswordInputProps) => {
  const trans = GlobalConfig?.appData.blocks;

  const [password, setPassword] = useState(initialPassword ?? "");
  const [containsUL, setContainsUL] = useState(false);
  const [containsLL, setContainsLL] = useState(false);
  const [containsN, setContainsN] = useState(false);
  const [containsSC, setContainsSC] = useState(false);
  const [contains8C, setContains8C] = useState(false);
  const [IamValid, setIamValid] = useState(false);
  const [securityLevel, setSecurityLevel] = useState(0);
  const [showConditions, setShowConditions] = useState(false);
  const [IamPristine, setIamPristine] = useState(true);

  const mustContainData = [
    ["has_uppercase", containsUL],
    ["has_lowercase", containsLL],
    ["has_number", containsN],
    ["has_special_character", containsSC],
    ["has_min_length", contains8C],
  ] as [string, boolean][];

  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(1),
    },
    icon: {
      borderRadius: 100,
      width: "1em",
      height: "1em",
      boxShadow:
        "inset 0 0 0 1.5px rgba(16,22,26,.2), inset 0 -1.5px 0 rgba(16,22,26,.1)",
      backgroundColor: "transparent",
    },
    checkedIcon: {
      backgroundColor: theme.palette.success.main,
      "&:before": {
        display: "block",
        width: "1em",
        height: "1em",
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
    },
  }));

  function CriteriaCheck(props: CheckboxProps) {
    const classes = useStyles();
    return (
      <Checkbox
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={
          <span className={clsx(classes.icon, classes.checkedIcon)} />
        }
        icon={<span className={classes.icon} />}
        inputProps={{ "aria-label": "decorative checkbox" }}
        tabIndex={-1}
        {...props}
      />
    );
  }

  const validatePassword = () => {
    let level = 0;
    let conditionCount = 0;
    // has uppercase letter
    if (password.toLowerCase() !== password) {
      setContainsUL(true);
      conditionCount += 1;
      level += 10;
    } else setContainsUL(false);

    // has lowercase letter
    if (password.toUpperCase() !== password) {
      setContainsLL(true);
      conditionCount += 1;
      level += 10;
    } else setContainsLL(false);

    // has number
    if (/\d/.test(password)) {
      setContainsN(true);
      conditionCount += 1;
      level += 10;
    } else setContainsN(false);

    // has special character
    if (/[~`!#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(password)) {
      setContainsSC(true);
      conditionCount += 1;
      level += 10;
    } else setContainsSC(false);

    let isValid = conditionCount >= 3;

    // has 8 characters
    if (password.length >= 8) {
      setContains8C(true);
      level += 10;
      if (isValid) {
        level += 50;
      }
    } else {
      setContains8C(false);
      isValid = false;
    }

    if (onValidChange) {
      onValidChange(isValid);
    }
    setIamValid(isValid);
    setSecurityLevel(level);
  };

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
      animationDuration: "550ms",
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    barColorPrimary: {
      backgroundColor: theme.palette.error.main,
    },
    barColorSecondary: {
      backgroundColor: theme.palette.success.main,
    },
    bar: {
      marginTop: 2,
      height: 6,
      borderRadius: 5,
    },
  }))(LinearProgress);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TextValidator
            variant="outlined"
            label={label}
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { value } = e.target;
              setPassword(value);
              if (onChange) onChange(value);
            }}
            onKeyUp={validatePassword}
            fullWidth
            type="password"
            name="password"
            validators={["required"]}
            errorMessages={[trans.registrationPage.form.required]}
            onFocus={() => setShowConditions(true)}
            onBlur={() => {
              setIamPristine(false);
              setShowConditions(false);
            }}
            error={!IamValid && !IamPristine}
          />
        </Grid>
        {showConditions && (
          <Box mt={2}>
            <Grid item xs={12}>
              <Box ml={1}>
                <Typography variant="body2" paragraph>
                  <strong>{trans.password.conditions}:</strong>
                </Typography>
              </Box>
            </Grid>
            {mustContainData.map((option) => (
              <Grid item xs={12} key={option[0]}>
                <Box ml={1}>
                  <RadioGroup
                    aria-label="password_criteria"
                    name="password_criteria"
                  >
                    <StyledFormControlLabel
                      control={<CriteriaCheck checked={!!option[1]} />}
                      label={
                        <Box
                          component="div"
                          position="relative"
                          color={option[1] ? "success.main" : "text.hint"}
                        >
                          <Typography variant="body2">
                            {trans.password[option[0]]}
                          </Typography>
                        </Box>
                      }
                    />
                  </RadioGroup>
                </Box>
              </Grid>
            ))}
          </Box>
        )}
      </Grid>
      {showConditions && (
        <>
          <Grid item xs={12}>
            <Box ml={1}>
              <Typography variant="body2" noWrap>
                <strong>{trans.password.security}:</strong>
                {securityLevel < 40 && (
                  <Box display="inline-block" m={1} color="error.main">
                    <strong> {trans.password.weak}</strong>
                  </Box>
                )}
                {securityLevel >= 40 && securityLevel < 80 && (
                  <Box display="inline-block" m={1} color="error.main">
                    <strong> {trans.password.normal}</strong>
                  </Box>
                )}
                {securityLevel >= 80 && (
                  <Box display="inline-block" m={1} color="success.main">
                    <strong> {trans.password.strong}</strong>
                  </Box>
                )}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box ml={1}>
              <BorderLinearProgress
                color={securityLevel < 80 ? "primary" : "secondary"}
                variant="determinate"
                value={securityLevel}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box ml={1} mt={1} color="text.hint">
              <Typography variant="body2">
                {trans.password.indication}
              </Typography>
            </Box>
          </Grid>
        </>
      )}
    </>
  );
};

const StyledFormControlLabel = withStyles((theme) => ({
  label: { marginBottom: 0 },
}))(FormControlLabel);
